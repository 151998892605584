/**
 * @author qiao / https://github.com/qiao
 * @author mrdoob / http://mrdoob.com
 * @author alteredq / http://alteredqualia.com/
 * @author WestLangley / http://github.com/WestLangley
 * @author erich666 / http://erichaines.com
 * @author Michael Oppitz
 * @author Mathias Höbinger
 */

/* eslint no-console: "off" */

let THREE = require('../../externals/three');

// This set of controls performs orbiting, dollying (zooming), and panning.
// Unlike TrackballControls, it maintains the "up" direction object.up (+Y by default).
//
//    Orbit - left mouse / touch: one finger move
//    Zoom - middle mouse, or mousewheel / touch: two finger spread or squish
//    Pan - right mouse, or arrow keys / touch: three finter swipe

THREE.OrbitControls = function (object, proxy) {

  var undefined;

  this.object = object;

  // True while the controls are being moved or still moving because of damping
  this.isMoving = false;

  // last time stamp at which controls did move
  this.lastMove = 0;

  this.proxy = proxy;

  this.proxy.onContextMenu = onContextMenu;
  this.proxy.onMouseDown = onMouseDown;
  this.proxy.onMouseWheel = onMouseWheel;

  this.proxy.onTouchStart = onTouchStart;
  this.proxy.onTouchEnd = onTouchEnd;
  this.proxy.onTouchMove = onTouchMove;

  this.proxy.onMouseMove = onMouseMove;
  this.proxy.onMouseUp = onMouseUp;

  this.proxy.onKeyDown = onKeyDown;
  
  // Set to false to disable this control
  this.enabled = true;

  // "target" sets the location of focus, where the object orbits around
  this.target = new THREE.Vector3();

  // How far you can dolly in and out ( PerspectiveCamera only )
  this.minDistance = 0;
  this.maxDistance = Infinity;

  // How far you can zoom in and out ( OrthographicCamera only )
  this.minZoom = -Infinity;
  this.maxZoom = Infinity;

  // How far you can orbit vertically, upper and lower limits.
  // Range is 0 to Math.PI radians.
  this.minPolarAngle = 0; // radians
  this.maxPolarAngle = Math.PI; // radians

  // How far you can orbit horizontally, upper and lower limits.
  // If set, must be a sub-interval of the interval [ - Math.PI, Math.PI ].
  this.minAzimuthAngle = - Infinity; // radians
  this.maxAzimuthAngle = Infinity; // radians


  // Additional restriction on the absolute position of the camera.
  this.minPosition = new THREE.Vector3(-Infinity, -Infinity, -Infinity);
  this.maxPosition = new THREE.Vector3(Infinity, Infinity, Infinity);

  // Additional restriction on the absolute position of the camera.
  this.positionSphereCenter = new THREE.Vector3(0, 0, 0);
  this.positionSphereRadius = Infinity;

  // Additional restriction on the absolute position of the target.
  this.minTarget = new THREE.Vector3(-Infinity, -Infinity, -Infinity);
  this.maxTarget = new THREE.Vector3(Infinity, Infinity, Infinity);

  // Additional restriction on the absolute position of the target.
  this.targetSphereCenter = new THREE.Vector3(0,0,0);
  this.targetSphereRadius = Infinity;

  // Set to true to enable damping (inertia)
  // If damping is enabled, you must call controls.update() in your animation loop
  this.enableDamping = false;
  this.panDampingFactor = 0.1;
  this.zoomDampingFactor = 0.1;
  this.rotateDampingFactor = 0.1;

  // This option actually enables dollying in and out; left as "zoom" for backwards compatibility.
  // Set to false to disable zooming
  this.enableZoom = true;
  this.zoomSpeed = 1.0;

  // Set to false to disable rotating
  this.enableRotation = true;
  this.rotationSpeed = 1.0;

  // Set to false to disable panning
  this.enablePan = true;
  this.panSpeed = 1.0;

  this.enableKeyPan = true;
  this.keyPanSpeed = 7.0;	// pixels moved per arrow key push

  // Set to true to automatically rotate around the target
  // If auto-rotate is enabled, you must call controls.update() in your animation loop
  this.enableAutoRotation = false;
  this.autoRotationSpeed = 2.0; // 30 seconds per round when fps is 60

  // Set to false to disable use of the keys
  this.enableKeys = true;

  // The four arrow keys
  this.keys = { LEFT: 37, UP: 38, RIGHT: 39, BOTTOM: 40 };

  // Mouse buttons
  this.mouseButtons = { ORBIT: THREE.MOUSE.LEFT, PAN: THREE.MOUSE.RIGHT };

  // for reset
  this.target0 = this.target.clone();
  this.position0 = this.object.position.clone();
  this.zoom0 = this.object.zoom;

  //
  // public methods
  //

  this.getPolarAngle = function () {

    return spherical.phi;

  };

  this.getAzimuthalAngle = function () {

    return spherical.theta;

  };

  this.reset = function () {

    scope.target.copy(scope.target0);
    scope.object.position.copy(scope.position0);
    scope.object.zoom = scope.zoom0;

    scope.object.updateProjectionMatrix();
    scope.dispatchEvent(changeEvent);

    this.isMoving = true;
    this.lastMove = Date.now();

    scope.update();

    state = STATE.NONE;

  };

  // this method is exposed, but perhaps it would be better if we can make it private...
  this.update = function () {

    var offset = new THREE.Vector3();

    // so camera.up is the orbit axis
    var quat = new THREE.Quaternion().setFromUnitVectors(object.up, new THREE.Vector3(0, 1, 0));
    var quatInverse = quat.clone().inverse();

    var lastPosition = new THREE.Vector3();
    var lastQuaternion = new THREE.Quaternion();

    return function () {

      var position = scope.object.position;

      offset.copy(position).sub(scope.target);

      // rotate offset to "y-axis-is-up" space
      offset.applyQuaternion(quat);

      // angle from z-axis around y-axis
      spherical.setFromVector3(offset);

      if (scope.enableAutoRotation && state === STATE.NONE) {

        sphericalDelta.theta = 0;
        rotateLeft(getAutoRotationAngle());

      }

      spherical.theta += sphericalDelta.theta;
      spherical.phi += sphericalDelta.phi;

      // restrict theta to be between desired limits
      if(spherical.theta > Math.PI){
        spherical.theta -= 2*Math.PI;
        if(scope.minAzimuthAngle > spherical.theta){
          spherical.theta += 2*Math.PI;
        }
      }else if(spherical.theta < -Math.PI){
        spherical.theta += 2*Math.PI;
        if(scope.maxAzimuthAngle < spherical.theta){
          spherical.theta -= 2*Math.PI;
        }
      }

      spherical.theta = Math.max(scope.minAzimuthAngle, Math.min(scope.maxAzimuthAngle, spherical.theta));

      // restrict phi to be between desired limits
      spherical.phi = Math.max(scope.minPolarAngle, Math.min(scope.maxPolarAngle, spherical.phi));

      spherical.makeSafe();


      if (scope.object instanceof THREE.PerspectiveCamera) {
        spherical.radius *= (1 + distanceDelta);
      } else if (scope.object instanceof THREE.OrthographicCamera) {
        scope.object.left *= (1 + orthographicZoomDelta);
        scope.object.right *= (1 + orthographicZoomDelta);
        scope.object.top *= (1 + orthographicZoomDelta);
        scope.object.bottom *= (1 + orthographicZoomDelta);
        scope.object.scale = THREE.Vector3((1 + orthographicZoomDelta), (1 + orthographicZoomDelta), (1 + orthographicZoomDelta));
        scope.object.updateProjectionMatrix();
      }

      // restrict radius to be between desired limits
      spherical.radius = Math.max(scope.minDistance, Math.min(scope.maxDistance, spherical.radius));

      // move target to panned location
      scope.target.add(panOffset);

      // restrict target position to maximum value
      scope.target.setComponent(0, Math.max(scope.minTarget.x, Math.min(scope.maxTarget.x, scope.target.x)));
      scope.target.setComponent(1, Math.max(scope.minTarget.y, Math.min(scope.maxTarget.y, scope.target.y)));
      scope.target.setComponent(2, Math.max(scope.minTarget.z, Math.min(scope.maxTarget.z, scope.target.z)));

      if(scope.target.distanceTo(scope.targetSphereCenter) > scope.targetSphereRadius){
        let dir = new THREE.Vector3(scope.target.x - scope.targetSphereCenter.x, scope.target.y - scope.targetSphereCenter.y, scope.target.z - scope.targetSphereCenter.z).normalize();
        scope.target.setComponent(0, scope.targetSphereCenter.x + dir.x * scope.targetSphereRadius);
        scope.target.setComponent(1, scope.targetSphereCenter.y + dir.y * scope.targetSphereRadius);
        scope.target.setComponent(2, scope.targetSphereCenter.z + dir.z * scope.targetSphereRadius);
      }


      offset.setFromSpherical(spherical);

      // rotate offset back to "camera-up-vector-is-up" space
      offset.applyQuaternion(quatInverse);


      position.copy(scope.target).add(offset);

      // restrict camera position to maximum value
      position.setComponent(0, Math.max(scope.minPosition.x, Math.min(scope.maxPosition.x, position.x)));
      position.setComponent(1, Math.max(scope.minPosition.y, Math.min(scope.maxPosition.y, position.y)));
      position.setComponent(2, Math.max(scope.minPosition.z, Math.min(scope.maxPosition.z, position.z)));

      if(position.distanceTo(scope.positionSphereCenter) > scope.positionSphereRadius){
        let dir = new THREE.Vector3(position.x - scope.positionSphereCenter.x, position.y - scope.positionSphereCenter.y, position.z - scope.positionSphereCenter.z).normalize();
        position.setComponent(0, scope.positionSphereCenter.x + dir.x * scope.positionSphereRadius);
        position.setComponent(1, scope.positionSphereCenter.y + dir.y * scope.positionSphereRadius);
        position.setComponent(2, scope.positionSphereCenter.z + dir.z * scope.positionSphereRadius);
      }

      scope.object.lookAt(scope.target);

      if (scope.enableDamping === true) {
        sphericalDelta.theta *= (1 - scope.rotateDampingFactor);
        sphericalDelta.phi *= (1 - scope.rotateDampingFactor);

        panOffset.x *= (1 - scope.panDampingFactor);
        panOffset.y *= (1 - scope.panDampingFactor);
        panOffset.z *= (1 - scope.panDampingFactor);

        distanceDelta *= (1 - scope.zoomDampingFactor);
        orthographicZoomDelta *= (1 - scope.zoomDampingFactor);
      } else {
        sphericalDelta.set(0, 0, 0);

        panOffset.set(0, 0, 0);

        distanceDelta = 0;
        orthographicZoomDelta = 0;
      }

      // update condition is:
      // min(camera displacement, camera rotation in radians)^2 > EPS
      // using small-angle approximation cos(x/2) = 1 - x^2 / 8

      if (zoomChanged ||
                lastPosition.distanceToSquared(scope.object.position) > EPS ||
                8 * (1 - lastQuaternion.dot(scope.object.quaternion)) > EPS ||
                (scope.object instanceof THREE.OrthographicCamera && Math.abs(orthographicZoomDelta) > EPS)) {

        this.isMoving = true;
        this.lastMove = Date.now();

        lastPosition.copy(scope.object.position);
        lastQuaternion.copy(scope.object.quaternion);
        zoomChanged = false;

        scope.dispatchEvent(changeEvent);
        return true;

      }
      else {
        if (Date.now() - this.lastMove > 100) {
          this.isMoving = false;
          panOffset.set(0, 0, 0);
          sphericalDelta.set(0, 0, 0);
          distanceDelta = 0;
          orthographicZoomDelta = 0;

          scope.dispatchEvent(changeEvent);
        }
      }

      return false;

    };

  }();

  this.dispose = function () {
    scope.proxy.dispose();
    //scope.dispatchEvent( { type: 'dispose' } ); // should this be added here?
  };

  //
  // internals
  //

  var scope = this;

  var changeEvent = { type: 'change' };
  var startEvent = { type: 'start' };
  var endEvent = { type: 'end' };

  var STATE = { NONE: - 1, ROTATE: 0, DOLLY: 1, PAN: 2, TOUCH_ROTATE: 3, TOUCH_DOLLY: 4, TOUCH_PAN: 5 };

  var state = STATE.NONE;

  var EPS = 0.001;

  // current position in spherical coordinates
  var spherical = new THREE.Spherical();
  var sphericalDelta = new THREE.Spherical();

  var distanceDelta = 0;
  var orthographicZoomDelta = 0;
  var panOffset = new THREE.Vector3();
  var zoomChanged = false;

  var rotateStart = new THREE.Vector2();
  var rotateEnd = new THREE.Vector2();
  var rotateDelta = new THREE.Vector2();

  var panStart = new THREE.Vector2();
  var panEnd = new THREE.Vector2();
  var panDelta = new THREE.Vector2();

  var dollyStart = new THREE.Vector2();
  var dollyEnd = new THREE.Vector2();
  var dollyDelta = new THREE.Vector2();

  function getAutoRotationAngle() {

    return 2 * Math.PI / 60 / 60 * scope.autoRotationSpeed;

  }

  function getZoomScale() {

    return Math.pow(0.95, scope.zoomSpeed);

  }

  function rotateLeft(angle) {

    sphericalDelta.theta -= angle;

  }

  function rotateUp(angle) {

    sphericalDelta.phi -= angle;

  }

  var panLeft = function () {

    var v = new THREE.Vector3();

    return function panLeft(distance, objectMatrix) {

      v.setFromMatrixColumn(objectMatrix, 0); // get X column of objectMatrix
      v.multiplyScalar(- distance);

      panOffset.add(v);

    };

  }();

  var panUp = function () {

    var v = new THREE.Vector3();

    return function panUp(distance, objectMatrix) {

      v.setFromMatrixColumn(objectMatrix, 1); // get Y column of objectMatrix
      v.multiplyScalar(distance);

      panOffset.add(v);

    };

  }();

  // deltaX and deltaY are in pixels; right and down are positive
  var pan = function () {

    var offset = new THREE.Vector3();

    return function (deltaX, deltaY) {

      let clientSize = scope.proxy.getClientSize();

      if (clientSize.width == 0 || clientSize.height == 0)
        return;

      if (scope.object instanceof THREE.PerspectiveCamera) {

        // perspective
        var position = scope.object.position;
        offset.copy(position).sub(scope.target);
        var targetDistance = offset.length();

        // half of the fov is center to top of screen
        targetDistance *= Math.tan((scope.object.fov / 2) * Math.PI / 180.0);

        // we actually don't use screenWidth, since perspective camera is fixed to screen height
        panLeft(2 * deltaX * targetDistance / clientSize.width, scope.object.matrix);
        panUp(2 * deltaY * targetDistance / clientSize.height, scope.object.matrix);

      } else if (scope.object instanceof THREE.OrthographicCamera) {

        // orthographic
        panLeft(deltaX * (scope.object.right - scope.object.left) / scope.object.zoom / clientSize.width, scope.object.matrix);
        panUp(deltaY * (scope.object.top - scope.object.bottom) / scope.object.zoom / clientSize.height, scope.object.matrix);

      } else {

        // camera neither orthographic nor perspective
        console.warn('WARNING: OrbitControls.js encountered an unknown camera type - pan disabled.');
        scope.enablePan = false;

      }

    };

  }();


  function assignZoom(zoomValue) {

    if (scope.object instanceof THREE.PerspectiveCamera) {

      distanceDelta = zoomValue;

    } else if (scope.object instanceof THREE.OrthographicCamera) {

      orthographicZoomDelta = zoomValue;

    } else {

      console.warn('WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled.');
      scope.enableZoom = false;

    }

  }

  //
  // event callbacks - update the object state
  //

  function handleMouseDownRotate(event) {

    //console.log( 'handleMouseDownRotate' );

    rotateStart.set(event.clientX, event.clientY);

  }

  function handleMouseDownDolly(event) {

    //console.log( 'handleMouseDownDolly' );

    dollyStart.set(event.clientX, event.clientY);

  }

  function handleMouseDownPan(event) {

    //console.log( 'handleMouseDownPan' );

    panStart.set(event.clientX, event.clientY);

  }

  function handleMouseMoveRotate(event) {

    //console.log( 'handleMouseMoveRotate' );

    rotateEnd.set(event.clientX, event.clientY);
    rotateDelta.subVectors(rotateEnd, rotateStart);

    let clientSize = scope.proxy.getClientSize();
    if (clientSize.width == 0 || clientSize.height == 0)
      return;

    // rotating across whole screen goes 360 degrees around
    rotateLeft(2 * Math.PI * rotateDelta.x / clientSize.width * scope.rotationSpeed);

    // rotating up and down along whole screen attempts to go 360, but limited to 180
    rotateUp(2 * Math.PI * rotateDelta.y / clientSize.height * scope.rotationSpeed);

    rotateStart.copy(rotateEnd);

    scope.update();

  }

  function handleMouseMoveDolly(event) {

    //console.log( 'handleMouseMoveDolly' );

    dollyEnd.set(event.clientX, event.clientY);

    dollyDelta.subVectors(dollyEnd, dollyStart);

    if (dollyDelta.y > 0) {

      assignZoom(getZoomScale());

    } else if (dollyDelta.y < 0) {

      assignZoom(-getZoomScale());

    }

    dollyStart.copy(dollyEnd);

    scope.update();

  }

  function handleMouseMovePan(event) {

    //console.log( 'handleMouseMovePan' );

    panEnd.set(event.clientX, event.clientY);

    panDelta.subVectors(panEnd, panStart);

    pan(panDelta.x * scope.panSpeed, panDelta.y * scope.panSpeed);

    panStart.copy(panEnd);

    scope.update();

  }

  function handleMouseUp(/*event*/) {

    //console.log( 'handleMouseUp' );

  }

  function handleMouseWheel(event) {

    // console.log( 'handleMouseWheel' );

    var delta = 0;

    if (event.wheelDelta !== undefined) {

      // WebKit / Opera / Explorer 9

      delta = event.wheelDelta;

    } else if (event.detail !== undefined) {

      // Firefox

      delta = - event.detail;

    }
    if (delta > 0) {

      assignZoom(-(1 - getZoomScale()));

    } else if (delta < 0) {

      assignZoom((1 - getZoomScale()));

    }

    scope.update();

  }

  function handleKeyDown(event) {

    //console.log( 'handleKeyDown' );

    if(scope.enableKeyPan === false) return;

    switch (event.keyCode) {

      case scope.keys.UP:
        pan(0, scope.keyPanSpeed);
        scope.update();
        break;

      case scope.keys.BOTTOM:
        pan(0, - scope.keyPanSpeed);
        scope.update();
        break;

      case scope.keys.LEFT:
        pan(scope.keyPanSpeed, 0);
        scope.update();
        break;

      case scope.keys.RIGHT:
        pan(- scope.keyPanSpeed, 0);
        scope.update();
        break;

    }

  }

  function handleTouchStartRotate(event) {

    //console.log( 'handleTouchStartRotate' );

    rotateStart.set(event.touches[0].pageX, event.touches[0].pageY);

  }

  function handleTouchStartDolly(event) {

    //console.log( 'handleTouchStartDolly' );

    var dx = event.touches[0].pageX - event.touches[1].pageX;
    var dy = event.touches[0].pageY - event.touches[1].pageY;

    var distance = Math.sqrt(dx * dx + dy * dy);

    dollyStart.set(0, distance);

  }

  function handleTouchStartPan(event) {

    //console.log( 'handleTouchStartPan' );

    panStart.set(event.touches[0].pageX, event.touches[0].pageY);

  }

  function handleTouchMoveRotate(event) {

    //console.log( 'handleTouchMoveRotate' );

    rotateEnd.set(event.touches[0].pageX, event.touches[0].pageY);
    rotateDelta.subVectors(rotateEnd, rotateStart);

    let clientSize = scope.proxy.getClientSize();

    if (clientSize.width == 0 || clientSize.height == 0)
      return;

    // rotating across whole screen goes 360 degrees around
    rotateLeft(2 * Math.PI * rotateDelta.x / clientSize.width * scope.rotationSpeed);

    // rotating up and down along whole screen attempts to go 360, but limited to 180
    rotateUp(2 * Math.PI * rotateDelta.y / clientSize.height * scope.rotationSpeed);

    rotateStart.copy(rotateEnd);

    scope.update();

  }

  function handleTouchMoveDolly(event) {

    //console.log( 'handleTouchMoveDolly' );

    var dx = event.touches[0].pageX - event.touches[1].pageX;
    var dy = event.touches[0].pageY - event.touches[1].pageY;

    var distance = Math.sqrt(dx * dx + dy * dy);

    dollyEnd.set(0, distance);

    dollyDelta.subVectors(dollyEnd, dollyStart);

    if (dollyDelta.y > 0) {

      assignZoom(-(1-getZoomScale()));

    } else if (dollyDelta.y < 0) {

      assignZoom((1 -getZoomScale()));

    }

    dollyStart.copy(dollyEnd);

    scope.update();

  }

  function handleTouchMovePan(event) {

    //console.log( 'handleTouchMovePan' );

    panEnd.set(event.touches[0].pageX, event.touches[0].pageY);

    panDelta.subVectors(panEnd, panStart);

    pan(panDelta.x, panDelta.y);

    panStart.copy(panEnd);

    scope.update();

  }

  function handleTouchEnd(/*event*/) {

    //console.log( 'handleTouchEnd' );

  }

  //
  // event handlers - FSM: listen for events and reset state
  //

  function onMouseDown(event) {

    if (scope.enabled === false) return;

    event.preventDefault();

    if (event.button === scope.mouseButtons.ORBIT) {

      if (scope.enableRotation === false) return;

      handleMouseDownRotate(event);

      state = STATE.ROTATE;

    } else if (event.button === scope.mouseButtons.ZOOM) {

      if (scope.enableZoom === false) return;

      handleMouseDownDolly(event);

      state = STATE.DOLLY;

    } else if (event.button === scope.mouseButtons.PAN) {
      if (scope.enablePan === false) return;

      handleMouseDownPan(event);

      state = STATE.PAN;

    }

    if (state !== STATE.NONE) {

      scope.proxy.onStateChange();

      scope.dispatchEvent(startEvent);

    }

  }

  function onMouseMove(event) {

    if (scope.enabled === false) return;

    event.preventDefault();

    if (state === STATE.ROTATE) {

      if (scope.enableRotation === false) return;

      handleMouseMoveRotate(event);

    } else if (state === STATE.DOLLY) {

      if (scope.enableZoom === false) return;

      handleMouseMoveDolly(event);

    } else if (state === STATE.PAN) {

      if (scope.enablePan === false) return;

      handleMouseMovePan(event);

    }

  }

  function onMouseUp(event) {

    if (scope.enabled === false) return;

    handleMouseUp(event);

    scope.proxy.onStateChangeNone();

    scope.dispatchEvent(endEvent);

    state = STATE.NONE;

  }

  function onMouseWheel(event) {

    if (scope.enabled === false || scope.enableZoom === false || (state !== STATE.NONE && state !== STATE.ROTATE)) return;

    event.preventDefault();
    event.stopPropagation();

    handleMouseWheel(event);

    scope.dispatchEvent(startEvent); // not sure why these are here...
    scope.dispatchEvent(endEvent);

  }

  function onKeyDown(event) {

    if (scope.enabled === false || scope.enableKeys === false || scope.enableKeyPan == false) return;

    handleKeyDown(event);

  }

  function onTouchStart(event) {

    if (scope.enabled === false) return;

    switch (event.touches.length) {

      case 1:	// one-fingered touch: rotate

        if (scope.enableRotation === false) return;

        handleTouchStartRotate(event);

        state = STATE.TOUCH_ROTATE;

        break;

      case 2:	// two-fingered touch: dolly

        if (scope.enableZoom === false) return;

        handleTouchStartDolly(event);

        state = STATE.TOUCH_DOLLY;

        break;

      case 3: // three-fingered touch: pan

        if (scope.enablePan === false) return;

        handleTouchStartPan(event);

        state = STATE.TOUCH_PAN;

        break;

      default:

        state = STATE.NONE;

    }

    if (state !== STATE.NONE) {

      scope.dispatchEvent(startEvent);

    }

  }

  function onTouchMove(event) {

    if (scope.enabled === false) return;

    event.preventDefault();
    event.stopPropagation();

    switch (event.touches.length) {

      case 1: // one-fingered touch: rotate

        if (scope.enableRotation === false) return;
        if (state !== STATE.TOUCH_ROTATE) return; // is this needed?...

        handleTouchMoveRotate(event);

        break;

      case 2: // two-fingered touch: dolly

        if (scope.enableZoom === false) return;
        if (state !== STATE.TOUCH_DOLLY) return; // is this needed?...

        handleTouchMoveDolly(event);

        break;

      case 3: // three-fingered touch: pan

        if (scope.enablePan === false) return;
        if (state !== STATE.TOUCH_PAN) return; // is this needed?...

        handleTouchMovePan(event);

        break;

      default:

        state = STATE.NONE;

    }

  }

  function onTouchEnd(event) {

    if (scope.enabled === false) return;

    handleTouchEnd(event);

    scope.dispatchEvent(endEvent);

    state = STATE.NONE;

  }

  function onContextMenu(event) {

    event.preventDefault();

  }

  // force an update at start

  this.update();

};

THREE.OrbitControls.prototype = Object.create(THREE.EventDispatcher.prototype);
THREE.OrbitControls.prototype.constructor = THREE.OrbitControls;

Object.defineProperties(THREE.OrbitControls.prototype, {

  center: {

    get: function () {

      console.warn('THREE.OrbitControls: .center has been renamed to .target');
      return this.target;

    }

  },

  // backward compatibility

  noZoom: {

    get: function () {

      console.warn('THREE.OrbitControls: .noZoom has been deprecated. Use .enableZoom instead.');
      return !this.enableZoom;

    },

    set: function (value) {

      console.warn('THREE.OrbitControls: .noZoom has been deprecated. Use .enableZoom instead.');
      this.enableZoom = !value;

    }

  },

  noRotate: {

    get: function () {

      console.warn('THREE.OrbitControls: .noRotate has been deprecated. Use .enableRotation instead.');
      return !this.enableRotation;

    },

    set: function (value) {

      console.warn('THREE.OrbitControls: .noRotate has been deprecated. Use .enableRotation instead.');
      this.enableRotation = !value;

    }

  },

  noPan: {

    get: function () {

      console.warn('THREE.OrbitControls: .noPan has been deprecated. Use .enablePan instead.');
      return !this.enablePan;

    },

    set: function (value) {

      console.warn('THREE.OrbitControls: .noPan has been deprecated. Use .enablePan instead.');
      this.enablePan = !value;

    }

  },

  noKeys: {

    get: function () {

      console.warn('THREE.OrbitControls: .noKeys has been deprecated. Use .enableKeys instead.');
      return !this.enableKeys;

    },

    set: function (value) {

      console.warn('THREE.OrbitControls: .noKeys has been deprecated. Use .enableKeys instead.');
      this.enableKeys = !value;

    }

  },

  staticMoving: {

    get: function () {

      console.warn('THREE.OrbitControls: .staticMoving has been deprecated. Use .enableDamping instead.');
      return !this.enableDamping;

    },

    set: function (value) {

      console.warn('THREE.OrbitControls: .staticMoving has been deprecated. Use .enableDamping instead.');
      this.enableDamping = !value;

    }

  },

  dynamicDampingFactor: {

    get: function () {

      console.warn('THREE.OrbitControls: .dynamicDampingFactor has been renamed. Use .dampingFactor instead.');
      return this.dampingFactor;

    },

    set: function (value) {

      console.warn('THREE.OrbitControls: .dynamicDampingFactor has been renamed. Use .dampingFactor instead.');
      this.dampingFactor = value;

    }

  }

});
