/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiInterfaceV1.js*
 *
 * ### Content
 *   * Abstract definition of the ShapeDiver 3D Viewer API V1
 *   * This version of the API is backwards compatible with the API of the viewer V1
 *
 * @module ApiInterfaceV1
 * @author ShapeDiver <contact@shapediver.com>
 */

////////////
////////////
//
// Type definitions
//
////////////
////////////

/**
* Color including alpha value defined by an array of 4 numbers between 0 and 255 [red, green, blue, alpha]. Further supported are all formats accepted by {@link https://www.npmjs.com/package/tinycolor2 tinycolor}.
* @typedef {Number[]|String|Number} Color
*/

/**
 * A 3d point
 * @typedef {Object} Point3d
 * @property {Number} x - x coordinate
 * @property {Number} y - x coordinate
 * @property {Number} z - x coordinate
 */

/**
 * A camera location and target
 * @typedef {Object} CameraLocation
 * @property {module:ApiInterfaceV1~Point3d} position - camera position
 * @property {module:ApiInterfaceV1~Point3d} target - camera target
 */

/**
 * Definition of an export
 * @typedef {Object} ExportDefinition
 * @property {String} id - unique id of export
 * @property {String} plugin - plugin runtime id of export
 * @property {String} name - name of export
 * @property {String} type - type of export, currently we support 'email', 'download', and 'shapeways'
 */

/**
 * Data required for requesting an export
 * @typedef {Object} ExportRequestObject
 * @property {String} [id] - id of export, takes precedence over idOrName and name
 * @property {String} [idOrName] - id or name of export, takes precedence over name
 * @property {String} [name] - name of export, last priority after id and idOrName
 * @property {String} [plugin] - runtime id of plugin the export belongs to
 */

/**
* Model data item
* @typedef {Object} ModelDataItem
* @property {String} name - name of the data item
* @property {String} id - id of the data item
* @property {String} plugin - plugin runtime id this item belongs to
* @property {*} data - the data
*/

/**
 * Object describing the viewer status
 * @typedef {Object} ViewerStatusObject
 * @property {module:ApiInterfaceV1~ViewerStatusCode} statusCode - numeric status code
 * @property {String} statusText - textual representation of status code
 * @property {String} statusInfo - further information
 */

/**
 * Object describing a parameter value
 * @typedef {Object} ParameterValueObject
 * @property {String} id - id of parameter
 * @property {String} [name] - optional name of parameter
 * @property {String} plugin - runtime id of plugin the parameter belongs to
 * @property {*} value - Parameter value
 */

/**
 * Parameter update object
 * @typedef {Object} ParameterUpdateObject
 * @property {String} [id] - id of parameter, takes precedence over idOrName and name
 * @property {String} [idOrName] - id or name of parameter, takes precedence over name
 * @property {String} [name] - name of parameter, last priority after id and idOrName
 * @property {String} [plugin] - optional runtime id of plugin the parameter belongs to
 * @property {*} value - Parameter value to set
 */

/**
 * Viewer status code format, see {@link module:ApiInterfaceV1~ApiInterfaceV1#STATUSCODE} for values
 * @typedef {Number} ViewerStatusCode
 */

/**
 * Process callback token - used by {@link module:ApiInterfaceV1~ApiInterfaceV1#setProcessCallback} and {@link module:ApiInterfaceV1~ApiInterfaceV1#clearProcessCallback}
 * @typedef {Object} ProcessCallbackToken
 */

/**
  * Process callback - used by {@link module:ApiInterfaceV1~ApiInterfaceV1#setProcessCallback}
  * @callback ProcessCallback
  * @param {String} topic - process message topic, typically 'process.TOKEN' where TOKEN is the process token which was used to register the callback
  * @param {Object} message - process message
  */

/**
  * Parameter type, see {@link module:ApiInterfaceV1~ApiInterfaceV1#TYPE} for values
  * @typedef {String} ParameterType
  */

/**
   * Parameter visualization, see {@link module:ApiInterfaceV1~ApiInterfaceV1#VISUALIZATION} for values
   * @typedef {String} ParameterVisualization
   */

/**
  * Parameter definition
  * @typedef {Object} ParameterDefinition
  * @property {String} id - unique id of the parameter (unique within a plugin instance)
  * @property {String} [name] - name of the parameter
  * @property {String} [note] - description of the parameter
  * @property {module:ApiInterfaceV1~ParameterType} [type] - type of the parameter
  * @property {Number} [decimalplaces] - number of decimal places for type Float
  * @property {String[]} [choices] - choices for type StringList
  * @property {Number|String|Boolean} defval - default value, represented as string
  * @property {Number|String|Boolean} value - current value of the parameter
  * @property {String} [group] - group of the parameter for visualization purposes
  * @property {String} [max] - maximum value for numeric types, max length for type String
  * @property {String} [min] - minimum value for numeric types
  * @property {String} [plugin] - optional runtime id of plugin the parameter belongs to
  * @property {module:ApiInterfaceV1~ParameterVisualization} [visualization] - how to visualize the parameter
  */


/**
 * ShapeDiver 3D Viewer API V1
 *
 * The API v1 is offered for backwards compatibility with the API of ShapeDiver's 3D viewer v1.
 *
 * __We discourage use of the API v1 for developing new applications.__
 *
 * @interface ApiInterfaceV1
 */
var ApiInterfaceV1 = function() {

  ////////////
  ////////////
  //
  // API
  //
  ////////////
  ////////////


  // to be discussed before adding:
  //
  //   * loadingComplete()
  //   * loadingState()
  //
  //   * setSceneRotation(center, axis, angle)
  //   * setSceneTransformMatrix(matrix)
  //
  //   * getGeometryByOutputId()
  //   * getNamedGeometry()
  //   * getOutputGeometry()
  //   * parameterIdFromName(name)
  //   * hideOutput(outputId, persistent)
  //   * unhideOutput(outputId)
  //
  //   * sceneAddAmbientLight(id,color,intensity)
  //   * sceneAddDirectionalLight(id,position,target,color,intensity,castShadow)
  //   * sceneAddLight(light, id)
  //   * sceneAddPointLight(id,position,color,intensity,distance,decay,castShadow)
  //   * sceneAddSpotLight(id,position,target,color,intensity,distance,angle,penumbra,decay,castShadow)
  //   * sceneListLights()
  //   * sceneRemoveLight(id)
  //
  //   * setAmbientIntensity(intensity)
  //   * setDirectionallight2direction(direction)
  //   * setDirectionallight2Intensity(intensity)
  //   * setMainLightDirection
  //   * setSpotlightIntensity(intensity)
  //   * toggleDirectionallight2
  //   * toggleFlashlight
  //
  //   * toggleEdgeColorByObject()
  //
  //     possible parameters which are used to invoke the callback (see setCommandResultCallback)
  //
  //      * getGeometryByOutputId (objects) - NOT IMPLEMENTED
  //      * getNamedGeometry (objects) - NOT IMPLEMENTED
  //      * getOutputGeometry (objects) - NOT IMPLEMENTED
  //
  //      * loadingComplete (Boolean) - NOT IMPLEMENTED
  //      * loadingState (Number) - NOT IMPLEMENTED
  //
  //      * sceneAddAmbientLight (Boolean) - NOT IMPLEMENTED
  //      * sceneAddDirectionalLight (Boolean) - NOT IMPLEMENTED
  //      * sceneAddLight (Boolean) - NOT IMPLEMENTED
  //      * sceneAddPointLight (Boolean) - NOT IMPLEMENTED
  //      * sceneAddSpotLight (Boolean) - NOT IMPLEMENTED
  //      * sceneListLights (list of lights) - NOT IMPLEMENTED
  //      * sceneRemoveLight (Boolean) - NOT IMPLEMENTED
  //
  //      * setAmbientIntensity (Boolean) - NOT IMPLEMENTED
  //      * setDirectionallight2direction (Boolean) - NOT IMPLEMENTED
  //      * setDirectionallight2Intensity (Boolean) - NOT IMPLEMENTED
  //      * setMainLightDirection (Boolean) - NOT IMPLEMENTED
  //      * setSpotlightIntensity (Boolean) - NOT IMPLEMENTED
  //      * toggleDirectionallight2 (Boolean) - NOT IMPLEMENTED
  //      * toggleFlashlight (Boolean) - NOT IMPLEMENTED
  //
  //      * setSceneRotation (Boolean) - NOT IMPLEMENTED
  //      * setSceneTransformMatrix (Boolean) - NOT IMPLEMENTED
  //
  //      * parameterIdFromName - NOT IMPLEMENTED
  //
  //      * showUserMessage (Boolean) - WILL NOT BE IMPLEMENTED
  //
  //      * showEdges (Boolean)
  //      * setEdgeColorByObject (Boolean)
  //      * setEdgeColor (Boolean)
  //
  //      * getScreenshot (data url)
  //      * cameraPath (Boolean)
  //      * setCamera (Boolean)
  //      * getCamera (camera target and position)
  //      * setDefaultMaterialColor (Boolean)
  //      * setPointSize (Boolean)
  //      * setBumpAmplitude (Boolean)
  //      * setFOV (Boolean)
  //      * setControlDamping (Boolean)
  //      * setRotateSpeed (Boolean)
  //      * setZoomSpeed (Boolean)
  //      * setAutoRotation (Boolean)
  //      * setZoomExtentFactor (Boolean)
  //      * setBackgroundColor (Boolean)
  //      * activateTopView (Boolean)
  //      * showShadows (Boolean)
  //      * showGrid (Boolean)
  //      * showGroundPlane (Boolean)
  //      * setIgnoreMouse (Boolean)
  //      * restrictCamera (Boolean)
  //      * restrictPan (Boolean)
  //      * restrictZoom (Boolean)
  //      * toggleDisableZoom (Boolean)
  //      * disableZoom (Boolean)
  //      * disablePan (Boolean)
  //      * setAutoRotate (Boolean)
  //      * setButtonColor (Boolean) - EMPTY IMPLEMENTATION
  //      * getParameterDefinitions (parameter definitions)
  //      * getExportDefinitions (export definitions)
  //      * getParameterValues (return value)
  //      * setParameterValue (Boolean)
  //      * setParameterValues (Boolean)
  //      * getModelData (model data)
  //      * hideControls (Boolean)
  //      * showControls (Boolean)
  //      * findTexturedGeometry (name array)
  //      * setExternalTexture (Boolean)
  //      * removeExternalTexture (Boolean)
  //      * getStatus (Number)
  //      * addDraggableShape (Boolean)
  //      * removeDraggableShape (Boolean)
  //      * getDraggableShapePosition (Boolean)
  //      * requestExport {success: true, exportId: id, message: replyMessage, href: d.content[i].href}

  /**
    * Enum for reporting viewer state.
    * @readonly
    * @enum {module:ApiInterfaceV1~ViewerStatusCode}
    */
  this.STATUSCODE = {
    /** Viewer is fully loaded, geometry is being displayed, waiting for next request. */
    READY: 0,
    /** Updated geometry is currently being loaded from server. This may be because of a parameter update or because the initial geometry is still being loaded. */
    BUSY: 1,
    /** Viewer, or at least one plugin, are still initializing. */
    INITIALIZING: 2,
    /** There has been a fatal error, the viewer must be reloaded. Error information is included as a message string. */
    ERROR: 3
  };

  /**
  * Enum for parameter type.
  * @readonly
  * @enum {module:ApiInterfaceV1~ParameterType}
  */
  this.TYPE = {
    /** floating point number */
    FLOAT: 'Float',
    /** integer number */
    INT: 'Int',
    /** even integer number */
    EVEN: 'Even',
    /** odd integer number */
    ODD: 'Odd',
    /** string with maximum length */
    STRING: 'String',
    /** color string, including opacity (10 digits, e.g. 0xffffffaa) */
    COLOR: 'Color',
    /** choose from a list of strings */
    STRINGLIST: 'StringList',
    /** boolean */
    BOOL: 'Bool',
    /** date / time */
    TIME: 'Time'
  };

  /**
  * Enum for parameter visualization.
  * @readonly
  * @enum {module:ApiInterfaceV1~ParameterVisualization}
  */
  this.VISUALIZATION = {
    /** currently the only choice for numeric types (Float, Int, Even, Odd) */
    SLIDER: 'slider',
    /** for type StringList */
    SEQUENCE: 'sequence',
    /** for type StringList */
    CYCLE: 'cycle',
    /** for type StringList */
    DROPDOWN: 'dropdown',
    /** for type StringList */
    CHECKLIST: 'checklist',
    /** for type Time */
    CLOCK: 'clock',
    /** for type Time */
    CALENDAR: 'calendar'
  };

  /**
   * Toggle the top view mode.
   *
   * In top view mode the camera is restricted to a top view of the scene.
   * Panning and zooming is still possible, but rotating the camera in any way is impossible.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#toggleTopView}
   * @param {Boolean} topViewActive - true: top view mode shall be activated, false: top view mode shall be deactivated
   * @param {Boolean} [force=true] - true: override session settings, false: keep value that may have been defined in session settings
   * @return {Boolean} true: top view mode was changed to given value, false: top view mode was already set to the chosen value, or session settings are different
   */
  this.activateTopView = function() {

  };

  /**
   * Add a shape which can be dragged by the user.
   *
   * This function allows you to add a simple shape to the scene which can be dragged by the user.
   * While viewing the scene, the user can drag the object within a plane orthogonal to the current viewing direction.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#getDraggableShapePosition}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#removeDraggableShape}
   * @param {String} id - Id of the draggable shape. This can be used to retrieve information about the object or delete it. The caller is responsible for the uniqueness of this id.
   * @param {String} type - Currently supported values are 'box', 'sphere', 'dodecahedron'
   * @param {Number} diameter - Diameter of the draggable object
   * @param {module:ApiInterfaceV1~Color} [color=red] - Color of the draggable object, any format understood by this library can be used: https://www.npmjs.com/package/tinycolor2
   * @param {Object} location - The initial location of the object
   * @param {Number} location.x - x coordinate
   * @param {Number} location.y - y coordinate
   * @param {Number} location.z - z coordinate
   * @return {Boolean} true: draggable shape was added successfully, false: error adding draggable shape
   */
  this.addDraggableShape = function() {

  };

  /**
   * Tell the viewer camera to follow a given path in a given duration.
   *
   * This command will prompt the viewer to move its camera on a smooth path interpolating a given set of 3d locations.
   * The camera target is interpolated as well.
   * The most straightforward way to come up with proper input values for predefined paths is to manually move the camera
   * to a desired locations and read the values from the viewer using the getCamera command.
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#getCamera}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setCamera}
   * @param {module:ApiInterfaceV1~Point3d[]} positions - Array of 3d points defining the camera positions along the path
   * @param {module:ApiInterfaceV1~Point3d[]} targets - Array of 3d points defining the camera targets along the path
   * @param {Number} duration - Duration of the animation in milliseconds
   * @return {Boolean} true: the path definition is valid and animation will be run, false: animation will not be run
   */
  this.cameraPath = function() {

  };

  /**
   * Set or change the environment map.
   *
   * The viewer supports predefined environment maps which can be chosen by name.
   * As an alternative the environment map can be influenced directly by passing an array of 6 image URLs defining a cube map. *
   * Please refer to the following example explaining the required alignment of images: https://www.shapediver.com/demos/demo_envmap.html
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#toggleSceneBackground}
   * @param {String|String[]} name - The name of the environment map to use, e.g. one of "ocean", "storm", "sky", "mountains", "levelsets", "default", etc. As an alternative an array of 6 image URLs of a cube map can be passed.
   * @return {Boolean} true on success, false on error
   */
  this.changeEnvMap = function() {

  };

  /**
   * Clear callback function that previously may have been set.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setMessagingCallback}
   * @return {Boolean} true on success, false on error (e.g. if no messaging callback was set)
   */
  this.clearMessagingCallback = function() {

  };

  /**
   * Clear a callback function which was previously registered using {@link module:ApiInterfaceV1~ApiInterfaceV1#setProcessCallback}.
   *
   * Your subscription to the process messages for a given process token ends automatically once the process ultimately
   * succeeded or failed. You may unsubscribe before that using this function.
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setParameterValue}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setParameterValues}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setProcessCallback}
   * @param {module:ApiInterfaceV1~ProcessCallbackToken} token - The token which was returned from {@link module:ApiInterfaceV1~ApiInterfaceV1#setProcessCallback}
   * @return {Boolean} true if callback was unregistered successfully, false otherwise.
   */
  this.clearProcessCallback = function() {

  };

  /**
   * Deregister a plugin
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#registerPlugin}
   * @param {String} runtimeId - runtime id of the plugin to deregister
   * @return {Boolean} true in case of success, false otherwise
   */
  this.deregisterPlugin = function() {

  };

  /**
   * Disables / enables panning.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#toggleDisablePan}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#restrictPan}
   * @param {Boolean} bDisable - true: disable panning, false: enable panning
   * @return {Boolean} true if panning toggle was successfully changed, false if it already had the given value or the update failed
   */
  this.disablePan = function() {

  };

  /**
   * Disables / enables zooming.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#toggleDisableZoom}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#restrictZoom}
   * @param {Boolean} bDisable - true: disable zooming, false: enable zooming
   * @return {Boolean} true if zooming toggle was successfully changed, false if it already had the given value or the update failed
   */
  this.disableZoom = function() {

  };

  /**
   * Fade in the viewer (show 3D scene)
   *
   * @return {Boolean} true on success
   */
  this.fadeInViewer = function() {

  };

  /**
   * Finds the names of geometry outputs which contain meshes which have texture coordinates.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#removeExternalTexture}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setExternalTexture}
   * @return {String[]} Array of names of geometry outputs
   */
  this.findTexturedGeometry = function() {

  };

  /**
   * Get the version number of the API.
   *
   * @return {String} Version number of the API (Major.Minor.Patch)
   */
  this.getApiVersion = function() {

  };

  /**
   * Get background color of 3D view.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setBackgroundColor}
   * @return {String} hex color string including alpha value, i.e. formatted like '#rrggbbaa'
   */
  this.getBackgroundColor = function() {

  };

  /**
   * Get camera position and target.
   *
   * This information can be used to later return the camera to a specific position using the setCamera command,
   * or to create camera paths using cameraPath.
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#cameraPath}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setCamera}
   * @return {module:ApiInterfaceV1~CameraLocation} Location and target of the camera.
   */
  this.getCamera = function() {

  };

  /**
   * Get the current position of a draggable shape.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#addDraggableShape}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#removeDraggableShape}
   * @param {String} id - Id of the draggable shape
   * @return {module:ApiInterfaceV1~Point3d} Current location of the draggable shape
   */
  this.getDraggableShapePosition = function() {

  };

  /**
   * Returns a list of available geometry exports.
   *
   * Using the ShapeDiver plugin, designers can define several types of geometry exports in their Grasshopper definitions.
   * Currently, geometry can be downloaded using the browser or sent to an email address specified by the designer.
   * This command returns a list of available exports with their types and names.
   * Exports can be triggered using the requestExport command.
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#requestExport}
   * @param {String} [plugin] - runtime id of plugin to get export definitions for, may be empty in which case export definitions of all plugins will be returned
   * @return {Array.<module:ApiInterfaceV1~ExportDefinition>} array of export definitions
   */
  this.getExportDefinitions = function() {

  };

  /**
   * Returns the names of geometry objects in the scene
   *
   * @param  {String} [creatorId] - Creator id (typically the runtime id of a plugin) for which to get the names of geometry. If omitted, names for all creators are returned.
   * @return {Object} One property for each creator id. The property includes one sub-property per name of geometry, which in turn holds an array of output ids.
   */
  this.getGeometryNames = function() {

  };

  /**
   * Given a geometry name, returns the paths for all output ids containing geometry given that name
   *
   * @param  {String} name - Geometry name to look for
   * @param  {String} [creatorId] - Creator id (typically the runtime id of a plugin) for which to list paths. If omitted, all creators are considered.
   * @return {String[]} Array of paths to geometry which has the given name.
   */
  this.getGeometryPathsByName = function() {

  };

  /**
   * Retrieves model specific data defined by the model's data output components.
   *
   * Retrieves any model data created by ShapeDiver data output Grasshopper components.
   * Values are updated whenever the parameters are changed.
   *
   * @return {Object.<String, module:ApiInterfaceV1~ModelDataItem>} The object's property names are the unique ids of the individual data outputs. Their values include the value and name of the data object.
   */
  this.getModelData = function() {

  };

  /**
   * Retrieves the parameter definitions for all parameters known by the viewer application, or a subset.
   *
   * This prompts the viewer to return an object containing parameter definitions for all parameters
   * currently known by the viewer application.
   * The resulting object will contain one key for each parameter, whose name is the unique parameter id.
   * The content of the parameter definition objects varies depending on the type of the parameter.
   * CAUTION: Parameter ids might not be unique across plugin instances, therefore the returned object might not include
   * all registered parameters. Use {@link module:ApiInterfaceV2~ApiInterfaceV2} to overcome this problem.
   * @param {Object} [settings] - optional properties to search for, works for any setting of parameter
   * @param {String} [settings.id] - id of parameter
   * @param {String} [settings.name] - name of parameter
   * @param {String} [settings.plugin] - runtime id of plugin the parameter belongs to
   * @param {String} [settings.type] - type of parameter
   * @return {Object.<String, module:ApiInterfaceV1~ParameterDefinition>} An object defining all available parameters definitions for the current model.
   */
  this.getParameterDefinitions = function() {

  };

  /**
   * Retrieves current values for all parameters.
   *
   * Returns an object containing the ids and current values for all parameters currently known by the viewer application.
   * Please note that when a parameter is updated by the user or via the API, this command will immediately
   * begin to return the new parameter value as 'current'. However, it might still take a while until the viewer
   * actually displays the updated geometry.
   *
   * To get a full overview over the status of the viewer, combine this command with {@link module:ApiInterfaceV1~ApiInterfaceV1#getStatus}.
   *
   * CAUTION: Parameter ids might not be unique across plugin instances, therefore the returned object might not include
   * all registered parameters. Use {@link module:ApiInterfaceV2~ApiInterfaceV2} to overcome this problem.
   * @return {Object} An object whose properties define parameter ids and their values.
   */
  this.getParameterValues = function() {

  };

  /**
   * Get screenshot as data URI.
   *
   * Returns a screenshot of the viewer canvas without any control elements as a data URI using the png image format.
   * @return {String} Data URI representing the png screeshot image (data:image/png;base64,...).
   */
  this.getScreenshot = function() {

  };

  /**
   * Returns a code and description of the current status.
   *
   * @return {module:ApiInterfaceV1~ViewerStatusObject} Object describing the current status of the viewer
   */
  this.getStatus = function() {

  };

  /**
   * Hides the control panel and its toggle from the viewer window.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#showControls}
   * @return {Boolean} true if the control panel was hidden
   */
  this.hideControls = function() {

  };

  /**
   * Hides the full-screen toggle in the viewer.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#showFullscreenToggle}
   * @return {Boolean} true if the full-screen toggle was hidden
   */
  this.hideFullscreenToggle = function() {

  };

  /**
   * Hides the zoom toggle in the viewer.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#showZoomToggle}
   * @return {Boolean} true if the zoom toggle was hidden
   */
  this.hideZoomToggle = function() {

  };

  /**
   * Loads a static mesh into the viewer.
   *
   * Loads a static external mesh into the viewer. The mesh must be stored in a public online
   * location as an OBJ file, with an accompanying MTL file defining material properties.
   * The MTL file may reference texture images to be downloaded.
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#showStaticMesh}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#removeStaticMesh}
   * @param {String} baseUrl - The base url to use for downloading the .obj and .mtl file
   * @param {String} objFile - Filename of the .obj file to load, will be concatenated with baseUrl
   * @param {String} mtlFile - Filename of the .mtl file to load, will be concatenated with baseUrl
   * @param {String} [id] - Unique id of the statically added mesh, which can be used to reference the mesh later on (show/hide). A unique id will be created if none is provided.
   * @param {String} [texturePath=baseUrl] - The base url to use for downloading texture images
   * @param {String} [sidedNess='double'] - The sidedness to use for the mesh ('front', 'back', 'double')
   * @return {Boolean} true if the mesh could be added to the scene, false otherwise
   */
  this.loadStaticMesh = function() {

  };

  /**
   * Refresh the outputs of a plugin by calling its set*ParameterValues function for the current values of its parameters
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#requestSceneUpdate}
   * @param {String} pluginRuntimeId - runtime id of the plugin to refresh
   * @param {String} [token] - process token to be used
   * @return {Boolean} true if refresh process could be started successfully, false otherwise
   */
  this.refreshPlugin = function() {

  };

  /**
   * Register a plugin
   *
   * Loads the plugin and, unless the plugin's setting deferGeometryLoading is true, asks it to display default geometry
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#deregisterPlugin}
   * @param {Object} plugin - The plugin to load
   * @return {String} plugin runtime id in case of success, nothing (undefined) on error
   */
  this.registerPlugin = function() {

  };

  /**
   * Removes a previously added draggable shape from scene.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#addDraggableShape}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#getDraggableShapePosition}
   * @param {String} id - The id of the shape to be removed from the scene
   * @return {Boolean} true if the shape was found in the scene and successfully removed
   */
  this.removeDraggableShape = function() {

  };

  /**
   * Remove externally defined texture from a geometry output
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setExternalTexture}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#findTexturedGeometry}
   * @param {String} name - Name of the geometry output for which external textures shall be removed
   * @param {String[]} mapNames - Array of map names for which to remove the external texture image. Possible values are 'color', 'alpha', 'bump', 'normal', 'metalness', 'roughness'. A single string can be provided for color textures as a fallback.
   * @param {Boolean} true if at least one externally defined texture image was removed from a geometry output, false otherwise
   */
  this.removeExternalTexture = function() {

  };

  /**
   * Remove a static mesh from the viewer.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#loadStaticMesh}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#showStaticMesh}
   * @param {String} id - The unique id used to reference the loaded mesh
   * @return {Boolean} true if the mesh was found and removed, false otherwise
   */
  this.removeStaticMesh = function() {

  };

  /**
   * Triggers export actions based on the type of the export.
   *
   * Requests the execution of an export using the current parameter set. The triggered action
   * depends on the type of the export.
   *
   *   * For exports of type 'download', the command will result in a download
   *     link to the exported geometry being reported back to the viewer for download,
   *     using the file format and file name specified by the designer.
   *   * Exports of type 'email' will prompt the ShapeDiver servers to send an email containing a download link
   *     and the current parameter set to the email address specified by the designer. In this case,
   *     the user does not get direct access to the exported geometry.
   *   * Exports of type 'shapeways' will be exported directly to ShapeWays, and a link to the
   *     ShapeWays model will be reported back to the viewer.
   *
   * When calling functions like requestExport, which involve several asynchronous operations, the function's return
   * value will only tell you about validity of the parameters which you used to invoke the function. The function will
   * usually return before the process triggered by the function call gets finished. As an example, calling requestExport
   * involves at least one asynchronous request to a backend, etc. If you want to track progress
   * of such a process, register a callback function beforehand using {@link module:ApiInterfaceV1~ApiInterfaceV1#setProcessCallback},
   * making use of the same token that you use when calling this function.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#getExportDefinitions}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#clearProcessCallback}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setProcessCallback}
   * @param {String|module:ApiInterfaceV1~ExportRequestObject} id - The unique id or name of the export to be triggered
   * @param {Boolean} [beSilent=false] - If true, no messages about the export will be displayed to the user.
   * @param {String} [plugin] - Optional plugin runtime id, will be ignored if a {@link module:ApiInterfaceV1~ExportRequestObject} is provided for id.
   * @param {String} [token] - The token which identifies the process
   * @return {Boolean} true if the export could be triggered
   */
  this.requestExport = function() {

  };

  /**
   * Refresh the outputs of all active plugins by calling their set*ParameterValues function for the current values of their parameters
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#refreshPlugin}
   * @return {Boolean} true if refresh process could be started successfully for all active plugins, false otherwise
   */
  this.requestSceneUpdate = function() {

  };

  /**
   * Reset the camera to its default position.
   *
   * This command will make the camera move smoothly to its default position.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#getCamera}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setCamera}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#cameraPath}
   * @param {Boolean} [tween=true] - true: animate movement to new camera position, false: jump to new camera position
   * @param {Number} [duration=800] - Duration of the movement in milliseconds
   * @return {Boolean} true if reset of camera could be initiated
   */
  this.resetCamera = function() {

  };

  /**
   * Restricts camera movement in x, y or z direction.
   *
   * Restricts the allowed movement of the camera a axis-parallel planes.
   * As an example, you could prohibit the camera from moving below the xy-plane by setting a lower bound on the z-coordinate.
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#restrictPan}
   * @param {Number} component - 0 for restriction in the x-direction, 1 for y, 2 for z
   * @param {Boolean} blnMax - If true, the value is set as an upper bound, otherwise as a lower bound
   * @param {Number} value - The coordinate used as an upper or lower bound on camera movement
   * @return {Boolean} true if the camera movement was successfully restricted
   */
  this.restrictCamera = function() {

  };

  /**
   * Defines upper and lower bounds for camera panning.
   *
   * Prohibits the panning of the camera target beyond a given axis parallel plane
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#restrictCamera}
   * @param {Number} component - 0 for restriction in the x-direction, 1 for y, 2 for z
   * @param {Boolean} blnMax - If true, the value is set as an upper bound, otherwise as a lower bound
   * @param {Number} value - The coordinate used as an upper or lower bound on panning
   * @return {Boolean} true if panning was successfully restricted
   */
  this.restrictPan = function() {

  };

  /**
   * Defines minimum and maximum distances for zooming.
   *
   * Prohibits the camera position from moving too close or too far away from the target.
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#restrictCamera}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#disableZoom}
   * @param {Number} min - Minimum distance for zooming
   * @param {Number} max - Maximum distance for zooming
   * @return {Boolean} true if zooming was successfully restricted
   */
  this.restrictZoom = function() {

  };

  /**
   * Starts or stops autorotation of the camera around the object.
   *
   * This command allows to activate autoration of the camera. Pass a boolean to activate/deactivate autorotation. Pass a number to set autorotation speed.
   * @param {Number|Boolean} speed - Speed to be used for autoration of the camera. Typical values between 0 and 10. Setting the speed to 0 or false stops autoration.
   * @return {Boolean} true if autorotation was updated
   */
  this.setAutoRotate = function() {

  };

  /**
   * Starts or stops autorotation of the camera around the object.
   *
   * This command allows to activate autoration of the camera. Pass a boolean to activate/deactivate autorotation. Pass a number to set autorotation speed.
   * @param {Number|Boolean} speed - Speed to be used for autoration of the camera. Typical values between 0 and 10. Setting the speed to 0 or false stops autoration.
   * @return {Boolean} true if autorotation was updated
   */
  this.setAutoRotation = function() {

  };

  /**
   * Sets autorotation speed, use setAutoRotate instead to start/stop autorotation.
   *
   * Alias for {@link module:ApiInterfaceV1~ApiInterfaceV1#setAutoRotate}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setAutoRotate}
   * @param {Number} speed - Speed to be used for autoration of the camera. Typical values between 0 and 10. Setting the speed to 0 stops autoration.
   * @return {Boolean} true if autorotation speed could be updated
   */
  this.setAutoRotationSpeed = function() {

  };

  /**
   * Set background color of 3D view.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#getBackgroundColor}
   * @param {module:ApiInterfaceV2~Color} color - The new background color including alpha, any format understood by this library can be used: https://www.npmjs.com/package/tinycolor2
   * @return {Boolean} true if background color and alpha could be updated
   */
  this.setBackgroundColor = function() {

  };

  /**
   * Set how much the bump map affects the material. Typical values between 0 and 1. Default is 1.
   *
   * @param {Number} bumpAmplitude - How much the bump map affects the material. Typical values between 0 and 1. Default is 1.
   * @return {Boolean} true if bump amplitude could be updated
   */
  this.setBumpAmplitude = function() {

  };

  /**
   * Set image to display while viewer is busy.
   *
   * @param {String} url - The url of the image to display while the viewer is busy
   */
  this.setBusyGraphic = function() {

  };

  /**
   * Set position of image to display while viewer is busy.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setBusyGraphic}
   * @param {String} position - Only supported position: 'center'
   */
  this.setBusyGraphicPosition = function() {

  };

  /**
   * Changes the color of buttons and toggles in the viewer.
   *
   * Buttons and toggles in the parameter widget and the viewer will be changed to this color.
   * The toggles will remain gray if unused, but will show the new color when hovered above or activated.
   * @param {module:ApiInterfaceV1~Color} color - The new color for buttons and toggles, any format understood by this library can be used: https://www.npmjs.com/package/tinycolor2
   * @return {Boolean} true if color was changed successfully
   */
  this.setButtonColor = function() {

  };

  /**
   * Move the camera to a new position.
   *
   * This command will make the camera move smoothly to a new position.
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#getCamera}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#cameraPath}
   * @param {module:ApiInterfaceV1~Point3d} position - New 3d position of the camera.
   * @param {module:ApiInterfaceV1~Point3d} target - New 3d target of the camera.
   * @param {Boolean} [tween=true] - If true, the camera will move smoothly to the new position within the given duration. If false, the move will be instant.
   * @param {Number} [duration=800] - Duration of the movement in milliseconds
   * @return {Boolean} true on success
   */
  this.setCamera = function() {

  };

  /**
   * Allows to define a function to be called whenever a function of the API finishes.
   *
   * @param {Function} callback - Function to be called with the following parameters: command name, command result
   */
  this.setCommandResultCallback = function() {

  };

  /**
   * Set color of default material, used for geometry which does not have a material assigned.
   *
   * @param {module:ApiInterfaceV1~Color} color - color of the default material
   * @return {Boolean} true if default color could be updated
   */
  this.setDefaultMaterialColor = function() {

  };

  /**
   * Update the default texture images of a geometry output using externally defined texture images
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#removeExternalTexture}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#findTexturedGeometry}
   * @param {String} name - Name of the geometry output for which default texture images shall be overridden
   * @param {String|Object} mapContainer - Object that holds texture image URLs (possibly data-uris) for the different map types. Possible members are 'color', 'alpha', 'bump', 'normal', 'metalness', 'roughness'. A single string can be provided for the color texture image as a fallback.
   */
  this.setExternalTexture = function() {

  };

  /**
   * Update the camera frustum vertical field of view angle
   *
   * @param {Number} angle - camera frustum vertical field of view angle in degrees, typical values in range [20,50]
   */
  this.setFOV = function() {

  };

  /**
   * Set the size used for displaying point objects
   *
   * @param {Number} [size=1.0] - size used for displaying point objects
   * @return true in case point size could be set, false on error
   */
  this.setPointSize = function() {

  };

  /**
   * Toggle ambient occlusion rendering setting.
   *
   * @param {Boolean} toggle - True for turning ambient occlusion ON.
   * @return {Boolean} true if ambient occlusion was successfully toggled, false if it wasn't changed
   */
  this.setToggleAmbientOcclusion = function() {

  };

  /**
   * Toggle shadow display for the specified object.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#showShadows}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#toggleShadows}
   * @param {string} path - The path of the object in the scene in dot notation.
   * @param {Boolean} bCast - If true, shadows are toggled ON, otherwise they are toggled OFF.
   * @return {Boolean} true if shadows were toggled successfully
   */
  this.setToggleCastShadow = function() {

  };

  /**
   * Sets the damping factor for camera rotation and panning.
   *
   * @param {Number} damping - Damping factor between 0.05 and 0.25
   * @return {Boolean} true on success
   */
  this.setControlDamping = function() {

  };

  /**
   * Sets the damping factor for camera rotation and panning.
   *
   * Alias for {@link module:ApiInterfaceV1~ApiInterfaceV1#setControlDamping}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setControlDamping}
   * @param {Number} damping - Damping factor between 0.05 and 0.25
   * @return {Boolean} true on success
   */
  this.setDamping = function() {

  };

  /**
   * Define color of visible edges.
   *
   * Define color for all visible edges. This color is only used if setEdgeColorByObject
   * is set to false and showEdges is set to true.
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setEdgeColorByObject}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#showEdges}
   * @param {module:ApiInterfaceV1~Color} color - The new color used to render visible edges, any format understood by this library can be used: https://www.npmjs.com/package/tinycolor2
   * @param {Boolean} [force=true] - true: override session settings, false: keep value that may have been defined in session settings
   * @return {Boolean} true on success
   */
  this.setEdgeColor = function() {

  };

  /**
   * Define how visible edges are colored.
   *
   * Configure how the color of visible edges is defined. If you set this to true,
   * edges will be colored according to the main color of the object they correspond to.
   * Otherwise, all visible edges will have the color set using setEdgeColor.
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setEdgeColor}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#showEdges}
   * @param {Boolean} byObject - true if edges should be colored according to their parent object
   * @param {Boolean} [force=true] - true: override session settings, false: keep value that may have been defined in session settings
   * @return {Boolean} true on success
   */
  this.setEdgeColorByObject = function() {

  };

  /**
   * Ignore all mouse input to the viewer.
   *
   * If set to true, all mouse input to the 3d viewer window will be ignored.
   * This does not apply to the parameter widget, which will still function normally.
   * @param {Boolean} ignoreMouse - true: ignore mouse input, false: enable mouse input
   * @param {Boolean} [force=true] - true: override session settings, false: keep value that may have been defined in session settings
   * @return {Boolean} true on success
   */
  this.setIgnoreMouse = function() {

  };

  /**
   * Set a callback function for being notified about the following events.
   *
   * 'CommPluginLoaded' - in general plugin.getShortName() + 'Loaded' - Whenever plugin enters status ACTIVE
   * 'GeometryUpdateDone' - whenever sth new got published to the scene - message SCENE_SUBSCENE_PUBLISHED
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#clearMessagingCallback}
   * @param {Function} cb - callback to invoke with parameter as described above
   * @return {Boolean} true on success, false on error (e.g. if another callback was set before)
   */
  this.setMessagingCallback = function() {

  };

  /**
   * Tells the viewer to update a given parameter to a new value.
   *
   * Given the unique id of a parameter of the loaded model as well as a valid new value for this parameter,
   * the viewer will update the model to reflect the new value.
   * The viewer will check if the parameter id and value provided are valid based on its stored parameter
   * information and respond accordingly once this check has been finished.
   *
   * When calling functions like setParameterValue, which involve several asynchronous operations, the function's return
   * value will only tell you about validity of the parameters which you used to invoke the function. The function will
   * usually return before the process triggered by the function call gets finished. As an example, calling setParameterValue
   * involves at least one asynchronous request to a backend, updating geometry in the 3D viewer, etc. If you want to track progress
   * of such a process, register a callback function beforehand using {@link module:ApiInterfaceV1~ApiInterfaceV1#setProcessCallback},
   * making use of the same token that you use when calling this function.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#clearProcessCallback}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#getParameterDefinitions}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setParameterValues}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setProcessCallback}
   * @param {String|module:ApiInterfaceV1~ParameterUpdateObject} id - The unique id or name of the parameter which should be updated, or a {@link module:ApiInterfaceV1~ParameterUpdateObject}.
   * @param {String|Number|Boolean} [value] - The new value of the parameter. Used as token in case id is a {@link module:ApiInterfaceV1~ParameterUpdateObject}.
   * @return {Boolean} true if parameter id and value are valid, false otherwise.
   */
  this.setParameterValue = function() {

  };

  /**
   * Tells the viewer to update a number of given parameters to new values.
   *
   * This function allows you to update several or even all parameter values while only causing
   * a single geometry update operation. This might be useful to restore saved settings or if a
   * complex logic requires changes to several parameters based on user input.
   * Please see the setParameterValue command for more detailed info.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#clearProcessCallback}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#getParameterDefinitions}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setParameterValue}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setProcessCallback}
   * @param {String[]|module:ApiInterfaceV1~ParameterUpdateObject[]} ids - The unique ids or names of the parameters which should be updated, or an array of {@link module:ApiInterfaceV1~ParameterUpdateObject}.
   * @param {Array.<String|Number|Boolean>} [values] - The new parameter values. Used as token in case id is an array of {@link module:ApiInterfaceV1~ParameterUpdateObject}.
   * @return {Boolean} true if all parameter ids and values are valid, false otherwise.
   */
  this.setParameterValues = function() {

  };

  /**
   * Register a callback function which will be invoked for every message related to the given process token.
   *
   * When calling functions like {@link module:ApiInterfaceV1~ApiInterfaceV1#setParameterValue}, which involve several asynchronous operations, the function's return
   * value will only tell you about validity of the parameters which you used to invoke the function. The function will
   * usually return before the process triggered by the function call gets finished. As an example, calling {@link module:ApiInterfaceV1~ApiInterfaceV1#setParameterValue}
   * involves at least one asynchronous request to a backend, updating geometry in the 3D viewer, etc. If you want to track progress
   * of such a process, register a callback function using the same token that you use when calling {@link module:ApiInterfaceV1~ApiInterfaceV1#setParameterValue} etc.
   *
   * Your callback will be invoked with the following two parameters:
   *   * message topic, e.g. process.TOKEN
   *   * message object
   *
   * Your subscription to the process messages ends automatically once the process ultimately succeeded or failed. You may
   * unsubscribe before that using {@link module:ApiInterfaceV1~ApiInterfaceV1#clearProcessCallback}.
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setParameterValue}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setParameterValues}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#requestExport}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#clearProcessCallback}
   * @param {String} token - The token which identifies the process
   * @param {module:ApiInterfaceV1~ProcessCallback} callback - The callback to invoke for every message related to the given process token
   * @return {module:ApiInterfaceV1~ProcessCallbackToken} subscription token which can be used for unsubscribing again, undefined in case of error
   */
  this.setProcessCallback = function() {

  };

  /**
   * Sets the rotation speed of the camera.
   *
   * Sets the rotation speed of the camera when using the rotation control of the viewer.
   * Not to be confused with setAutoRotate.
   * @param {Number} speed - Rotation speed, typical values between 0 and 10
   * @return {Boolean} true on success
   */
  this.setRotateSpeed = function() {

  };

  /**
   * Sets the rotation speed of the camera.
   *
   * Alias for {@link module:ApiInterfaceV1~ApiInterfaceV1#setRotateSpeed}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setRotateSpeed}
   * @param {Number} speed - Rotation speed, typical values between 0 and 10
   * @return {Boolean} true on success
   */
  this.setRotationSpeed = function() {

  };


  /**
   * Sets the factor by which the bounding box of the scene is multiplied before zoom extent is called.
   *
   * @param {Number} factor - Zoom extents factor, typical values between 1 and 5
   * @return {Boolean} true on success
   */
  this.setZoomExtentFactor = function() {

  };

  /**
   * Sets the zoom speed of the camera.
   *
   * Sets the zoom speed of the camera when using the zoom control of the viewer.
   * @param {Number} speed - Zoom speed, typical values between 0 and 1
   * @return {Boolean} true on success
   */
  this.setZoomSpeed = function() {

  };

  /**
   * Shows the control panel and its toggle in the viewer.
   *
   * This command activates the display of the control panel and the corresponding toggle in the viewer.
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#hideControls}
   * @return {Boolean} true if the control panel was shown
   */
  this.showControls = function() {

  };

  /**
   * Choose whether to display feature edges for all objects.
   *
   * If set to true, lines are displayed to emphasize the objects' feature edges.
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setEdgeColor}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setEdgeColorByObject}
   * @param {Boolean} show - true: show feature edges, false: hide feature edges
   * @param {Boolean} [force=true] - true: override session settings, false: keep value that may have been defined in session settings
   * @return {Boolean} true if setting was changed, false if setting was already set to the chosen value
   */
  this.showEdges = function() {

  };

  /**
   * Shows the full-screen toggle in the viewer.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#hideFullscreenToggle}
   * @return {Boolean} true if full screen toggle was shown
   */
  this.showFullscreenToggle = function() {

  };

  /**
   * Choose whether to display the ground plane grid.
   *
   * @param {Boolean} [show=true] - true: show grid object, false: hide grid object
   * @param {Boolean} [force=true] - true: override session settings, false: keep value that may have been defined in session settings
   * @return {Boolean} true if setting was changed, false if setting was already set to the chosen value
   */
  this.showGrid = function() {

  };

  /**
   * Choose whether to display the ground plane.
   *
   * @param {Boolean} [show=true] - true: show ground plane object, false: hide ground plane object
   * @param {Boolean} [force=true] - true: override session settings, false: keep value that may have been defined in session settings
   * @return {Boolean} true if setting was changed, false if setting was already set to the chosen value
   */
  this.showGroundPlane = function() {

  };

  /**
   * Show or hide (enable or disable) the rendering of shadows.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setToggleCastShadow}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#toggleShadows}
   * @param {Boolean} [show=true] - true: enable the rendering of shadows, false: disable the rendering of shadows
   * @param {Boolean} [force=true] - true: override session settings, false: keep value that may have been defined in session settings
   * @return {Boolean} true if setting was changed, false if setting was already set to the chosen value
   */
  this.showShadows = function() {

  };

  /**
   * Show or hide a static mesh.
   *
   * If set to true, lines are displayed to emphasize the objects' feature edges.
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#loadStaticMesh}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#removeStaticMesh}
   * @param {String} id - The unique id used to reference the loaded mesh
   * @param {Boolean} show - true: show the static mesh, false: hide the static mesh
   * @return {Boolean} true if the mesh was found and its visibility changed, false otherwise
   */
  this.showStaticMesh = function() {

  };

  /**
   * Shows the zoom toggle in the viewer.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#showZoomToggle}
   * @return {Boolean} true if zoom toggle was shown
   */
  this.showZoomToggle = function() {

  };

  /**
   * Toggle between panning enabled and disabled.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#disablePan}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#restrictPan}
   * @return {Boolean} true if panning toggle was successfully changed
   */
  this.toggleDisablePan = function() {

  };

  /**
   * Toggle between zooming enabled and disabled.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#disableZoom}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#restrictZoom}
   * @return {Boolean} true if zooming toggle was successfully changed
   */
  this.toggleDisableZoom = function() {

  };

  /**
   * Switch the viewer between fullscreen and normal mode.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#hideFullscreenToggle}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#showFullscreenToggle}
   * @param {Boolean} [show] - if no value is provided, switch back and forth between fullscreen and normal mode
   * @return {Boolean} true if setting was changed, false if setting was already set to the chosen value
   */
  this.toggleFullscreen = function() {

  };

  /**
   * Choose whether the environment map is shown in the background.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#changeEnvMap}
   * @param {Boolean} [show=true] - true: show environment map in background, false: hide environment map
   * @return {Boolean} true if setting was changed successfully, false otherwise
   */
  this.toggleSceneBackground = function() {

  };

  /**
   * Toggle between shadows enabled and disabled.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#showShadows}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setToggleCastShadow}
   * @return {Boolean} true if shadow toggle was successfully changed
   */
  this.toggleShadows = function() {

  };

  /**
   * Toggle between top view enabled and disabled.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#activateTopView}
   * @return {Boolean} true if top view toggle was successfully changed
   */
  this.toggleTopView = function() {

  };

  /**
   * Zoom to extents of model.
   *
   * This command will make the camera move smoothly to the extents of the model.
   *
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#getCamera}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#setCamera}
   * @see {@link module:ApiInterfaceV1~ApiInterfaceV1#cameraPath}
   * @param {Boolean} [tween=true] - true: animate movement to new camera position, false: jump to new camera position
   * @param {Number} [duration=800] - Duration of the movement in milliseconds
   */
  this.zoomExtents = function() {

  };

};


// export the constructor
module.exports = ApiInterfaceV1;
