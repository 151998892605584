let ThreeDManager = function (___settings) {
  const THREE = require('../../../externals/three'),
        GLOBAL_UTILS = require('../../../shared/util/GlobalUtils'),
        ThreeDManagerInterface = require('../../interfaces/ThreeDManagerInterface'),
        THREE_D_MANAGER_CONSTANTS = require('./ThreeDManagerConstants'),
        ExpoTHREE = ___settings.ExpoTHREE,
        ThreeAR = ___settings.ThreeAR,
        _settings = ___settings.settings,
        _scene = ___settings.scene,
        _geometryNode = ___settings.geometryNode,
        _container = ___settings.container,
        _additionalDataHandler = typeof ___settings.additionalDataHandler === 'function' ? ___settings.additionalDataHandler : () => {},
        _handlers = {};

  let that, _helpers;

  class ThreeDManager extends ThreeDManagerInterface {

    constructor(___settings) {
      super(___settings, GLOBAL_UTILS);

      that = this;
      _handlers.threeDManager = that;

      _helpers = new (require('../../helpers/ThreeDManagerHelpers'))({
        scene: _scene,
      }, _handlers);
      _handlers.threeDManager.helpers = _helpers;

      ////////////
      ////////////
      //
      // Settings
      //
      ////////////
      ////////////

      require('../../../shared/mixins/SettingsMixin').call(that, _settings, THREE_D_MANAGER_CONSTANTS.defaultSettings, ___settings.runtimeId);

      ////////////
      ////////////
      //
      // Rendering Handler
      //
      ////////////
      ////////////

      this.renderingHandler = new (require('./handlers/RenderingHandler'))({
        settings: that.getSection('render'),
        scene: _scene,
        container: _container,
        rendererFactory: ___settings.rendererFactory
      }, _handlers);
      _handlers.renderingHandler = this.renderingHandler;

      ////////////
      ////////////
      //
      // Material Handler
      //
      ////////////
      ////////////

      this.materialHandler = new (require('./handlers/MaterialHandler'))({
        settings: that.getSection('material'),
      }, _handlers);
      _handlers.materialHandler = this.materialHandler;

      ////////////
      ////////////
      //
      // Camera Handler
      //
      ////////////
      ////////////

      this.cameraHandler = new (require('./handlers/CameraHandler'))({
        settings: that.getSection('camera'),
        container: _container,
        perspectiveCameraFactory: ___settings.perspectiveCameraFactory,
        additionalDataHandler: ___settings.additionalDataHandler,
      }, _handlers);
      _handlers.cameraHandler = this.cameraHandler;

      let data= {};
      data.scene = _scene;
      _additionalDataHandler(data);
    }

    ////////////
    ////////////
    //
    // ThreeDManager API
    //
    ////////////
    ////////////

    init(bb) {
      _helpers.initialize();
      that.adjustScene();
    }

    adjustScene() {
      let bb = _geometryNode.computeSceneBoundingBox();
      let bs = new THREE.Sphere();
      bb.getBoundingSphere(bs);

      _geometryNode.traverse(function(obj){
        if(obj.type === 'Mesh'){
          let data = {};
          data.geometryObject = obj;
          _additionalDataHandler(data);
        }
      });

      _handlers.cameraHandler.adjustToBoundingSphere(bs);
      _handlers.renderingHandler.render();
    }

    destroy() {
      _helpers.destroyViewport();

      // call handler destroy methods
      _handlers.renderingHandler.destroy();

      // de-register hooks and notifiers
      that.deregisterAllHooks();
      that.deregisterAllNotifiers();

      //while (that.container.firstChild)
      //  that.container.removeChild(that.container.firstChild);

      // remove handlers
      for (let key in _handlers)
        delete _handlers[key];

      for (let key in that)
        that[key] = undefined;
    }

    /** @inheritdoc */
    addMesh(mesh, material, properties) {
      _helpers.addMesh(mesh, material, properties);
    }

    /** @inheritdoc */
    removeMesh(mesh) {
      _helpers.removeMesh(mesh);
    }

  }

  return new ThreeDManager(___settings);
};


module.exports = ThreeDManager;
