/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ViewerApp.js*
 *
 * ### Content
 *   * Core application class which combines
 *   * a 3D scene
 *   * user interface elements
 *   * plugins
 *   * a public API
 *
 * @module ViewerApp
 * @author Alex Schiftner <alex@shapediver.com>
 */


/**
 * Import constants
 */
var viewerAppConstants = require('./ViewerAppConstants');

/**
 * Import GlobalUtils
 */
//var GlobalUtils = require('../shared/util/GlobalUtils');


/**
 * Globally required polyfills
 */
require('../polyfills/CustomEvent');

/**
 * Constructor of the viewer application
 * @class
 *
 * @mixes module:GlobalMixin~GlobalMixin
 * @mixes module:SettingsMixin~SettingsMixin
 * @mixes module:LoggingMixin~LoggingMixin
 * @mixes module:MessagingMixin~MessagingMixin
 * @mixes module:ViewerAppLoggingPartial~ViewerAppLoggingPartial
 * @mixes module:ViewerAppMessagingPartial~ViewerAppMessagingPartial
 * @mixes module:ViewerAppPluginManager~ViewerAppPluginManager
 *
 * @param {Object} [settings] - Initial settings to be used
 * @param {HtmlElement} [settings.container] - The container to which we will render
 * @param {Boolean} [settings.blurSceneWhenBusy] - Blur or don't blur the scene while a process is busy
 * @param {module:ApiInterfaceV2~ApiInterfaceV2#Color} [settings.defaultMaterial.color] - Color of the default material
 * @param {Number} [settings.defaultMaterial.bumpAmplitude] - Bump amplitude of the default material
 * @param {Number} [settings.loggingLevel] - Initial logging level
 * @param {Number} [settings.messageLoggingLevel] - Initial logging level for messages
 * @param {Boolean} [settings.showScene=false] - Set to true for showing the scene right away
 * @param {module:ViewerAppConstants~ShowSceneModes} [settings.showSceneMode=ON_FIRST_PLUGIN] - When to fade in the 3D scene, by default the scene will be faded in once the first plugin has finished adding geometry to the scene
 * @param {String} [settings.showSceneTransition] - Transition to use when fading in the 3D scene
 * @param {Boolean} [settings.strictMode=false] - Run parameters manager in strict mode?
 */
var ViewerApp = function(___settings) {

  var that = this;

  ////////////
  ////////////
  //
  // Global prototype
  //
  ////////////
  ////////////

  require('../shared/mixins/GlobalMixin').call(this);

  ////////////
  ////////////
  //
  // Settings
  //
  ////////////
  ////////////

  require('../shared/mixins/SettingsMixin').call(this, ___settings, viewerAppConstants.defaultSettings, 'app');

  ////////////
  ////////////
  //
  // Logging
  //
  // register standard logging functions,
  // and replace default logging to console by pub/sub scheme
  //
  ////////////
  ////////////

  // mix in global logging functions
  require('../shared/mixins/LoggingMixin').call(this);

  // mix in logging functions
  require('./partials/ViewerAppLoggingPartial').call(this);

  // create a pseudo logging handler to object, to pass on to childs
  let _loggingHandler = {
    error: that.error,
    warn: that.warn,
    info: that.info,
    debug: that.debug,
    log: that.log,
  };

  ////////////
  ////////////
  //
  // General messaging via pub/sub
  //
  ////////////
  ////////////

  // mix in global messaging functions
  require('../shared/mixins/MessagingMixin').call(this);

  // mix in messaging functions
  require('./partials/ViewerAppMessagingPartial').call(this);

  ////////////
  ////////////
  //
  // Plugins
  //
  ////////////
  ////////////

  // mix in plugin functions - FIXME Alex: rename ViewerAppPluginManager to ViewerAppPluginHandler
  this.pluginManager = require('./partials/ViewerAppPluginManager').call(that);

  ////////////
  ////////////
  //
  // Parameters
  //
  ////////////
  ////////////

  // mix in parameter functions - FIXME Alex: rename ViewerAppParameterManager to ViewerAppParameterHandler
  this.parameterManager = require('./partials/ViewerAppParameterManager').call(that, { pluginManager: that.pluginManager });

  ////////////
  ////////////
  //
  // Exports
  //
  ////////////
  ////////////

  // mix in export functionality - FIXME Alex: rename ViewerAppExportManager to ViewerAppExportHandler
  this.exportManager = require('./partials/ViewerAppExportManager').call(that, { pluginManager: that.pluginManager, parameterManager: that.parameterManager });

  ////////////
  ////////////
  //
  // Interaction Group Manager
  //
  ////////////
  ////////////

  this.interactionGroupManager = require('../3d/InteractionGroupManager').getInstance();

  ////////////
  ////////////
  //
  // Viewport Manager
  //
  ////////////
  ////////////

  this.viewportManager = require('../3d/ViewportManager').getInstance(that, _loggingHandler);

  ////////////
  ////////////
  //
  // Scene Geometry Manager
  //
  ////////////
  ////////////

  this.sceneGeometryManager = require('../3d/SceneGeometryManager').getInstance(that.viewportManager,
    {
      loggingHandler: _loggingHandler,
      messagingHandler: {
        message: that.message,
        subscribeToMessageStream: that.subscribeToMessageStream,
        unsubscribeFromMessageStream: that.unsubscribeFromMessageStream
      },
    }
  );

  ////////////
  ////////////
  //
  // Container handler
  //
  ////////////
  ////////////

  this.viewportVisibilityHandler = require('./handlers/ViewportVisibilityHandler').call(that, {
    viewportManager: that.viewportManager
  });

  // provide access to container to plugins
  this.getContainer = function() {
    let container = that.viewportManager.getContainers();
    return container.length === 1 ? container[0] : container;
  };

  ////////////
  ////////////
  //
  // Scene manager
  //
  ////////////
  ////////////

  this.sceneManager = require('./partials/ViewerAppSceneManager').call(that, { sceneGeometryManager: that.sceneGeometryManager });

  ////////////
  ////////////
  //
  // Process status handler
  //
  ////////////
  ////////////

  this.processStatusHandler = require('./handlers/ProcessStatusHandler').call(that, { viewportManager: that.viewportManager });

  ////////////
  ////////////
  //
  // API
  //
  ////////////
  ////////////

  /**
   * Create and return an API interface object
   * @public
   * @param {Object} options - Options for creating the API interface object
   * @param {string} [options.version] - Optional version of API to return
   * @param {String} [options.runtimeId] - Optional runtime id to use for the API instance
   * @return {Object} API object according to options
   */
  this.api = function(options) {
    var api;
    // We return API v1 by default for backwards compatibility,
    // even if this does not make much sense given the viewport setup
    options = options || {version: 1};
    if ( options.version && (options.version === 2 || options.version === '2') ) {

      api = new (require('../api/v2/ApiImplementationV2.1.js'))({
        exportHandler: that.exportManager,
        parameterHandler: that.parameterManager,
        pluginHandler: that.pluginManager,
        processStatusHandler: that.processStatusHandler,
        sceneManager: that.sceneManager,
        settingsHandler: that.settingsHandler,
        viewportManager: that.viewportManager,
        interactionGroupManager: that.interactionGroupManager,
        messagingHandler: {
          message: that.message,
          subscribeToMessageStream: that.subscribeToMessageStream,
          unsubscribeFromMessageStream: that.unsubscribeFromMessageStream,
          getFailedStatusMessages: that.getFailedStatusMessages,
        },
        loggingHandler: _loggingHandler,
        app: that
      }, options);
    } else {
      api = new (require('../api/v1/ApiImplementationV1.js'))({
        exportHandler: that.exportManager,
        parameterHandler: that.parameterManager,
        pluginHandler: that.pluginManager,
        processStatusHandler: that.processStatusHandler,
        sceneManager: that.sceneManager,
        settingsHandler: that.settingsHandler,
        threeDManager: that.viewportManager.getDefaultThreeDManagers()[0],
        viewportVisibilityHandler: that.viewportVisibilityHandler,
        messagingHandler: {
          message: that.message,
          subscribeToMessageStream: that.subscribeToMessageStream,
          unsubscribeFromMessageStream: that.unsubscribeFromMessageStream,
          getFailedStatusMessages: that.getFailedStatusMessages,
        },
        loggingHandler: _loggingHandler,
        app: that
      }, options);
    }
    return api;
  };

  that.viewportManager.createThreeDManager(___settings.container);

  ////////////
  ////////////
  //
  // Settings handler
  //
  ////////////
  ////////////

  this.settingsHandler = require('./handlers/SettingsHandler').call(that, {
    threeDManager: that.viewportManager.getDefaultThreeDManagers(),
    parameterManager: that.parameterManager,
    exportManager: that.exportManager,
    pluginManager: that.pluginManager,
    app: that,
  });

  ////////////
  ////////////
  //
  // Message dispatching - last thing to do
  //
  ////////////
  ////////////

  /**
   * Dispatching configuration
   */
  var _dispatchingConfig = require('./conf/DefaultDispatching');

  /**
   * Initialize dispatching
   */
  this.setupDispatching(_dispatchingConfig);

  /**
   * Code example - how to log a message to server
   */
  //this.log(viewerAppConstants.loggingLevels.DEBUG_S, 'ViewerApp.ViewerApp', 'This is a test debug info string, could also be an object', {someMsg:'Further optional debug info'});
  //this.log(viewerAppConstants.loggingLevels.INFO_S, 'ViewerApp.ViewerApp', 'This is a test info string, could also be an object', {someMsg:'Further optional info'});
  //this.log(viewerAppConstants.loggingLevels.WARN_S, 'ViewerApp.ViewerApp', 'This is a test warning string, could also be an object', {someMsg:'Further optional warning'});
  //this.log(viewerAppConstants.loggingLevels.ERROR_S, 'ViewerApp.ViewerApp', 'This is a test error string, could also be an object', {someMsg:'Further optional error'});

  // initialize container
  this.viewportVisibilityHandler.init();

  // log build data
  if (___settings.build_version && ___settings.build_date) {
    console.log('ShapeDiver Viewer App', ___settings.build_version + ', ' + ___settings.build_date); // eslint-disable-line no-console
  }

  return this;
};

// here we could define public functions
//ViewerApp.prototype.

// export the constructor
module.exports = ViewerApp;
