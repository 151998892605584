let ViewportApi = function (_api, ___refs) {
  const THREE_D_MANAGER_CONSTANTS = require('../ThreeDManagerConstants'),
        ViewportApiInterface = require('../../../interfaces/api/ViewportApiInterface'),
        CameraApi = require('./CameraApi');
  
  let _threeDManager = ___refs.threeDManager,
      _viewportManager = ___refs.viewportManager,
      _viewportApiHelpers, _references, 
      _listeners = {};

  class ViewportApi extends ViewportApiInterface {
    
    constructor() {
      super();

      this.utils = _api.utils;
      this.EVENTTYPE = _api.viewports.EVENTTYPE;

      this.camera = new CameraApi(_api, {
        threeDManager: _threeDManager,
        viewportManager: _viewportManager,
        apiResponse: this.utils.APIResponse
      });

      _viewportApiHelpers = require('../../../helpers/api/ViewportApiHelpers').getInstance();
      
      _references = {
        THREE_D_MANAGER_CONSTANTS: THREE_D_MANAGER_CONSTANTS,
        EVENTTYPE: this.EVENTTYPE,
        threeDManager: _threeDManager,
        viewportManager: _viewportManager,
        apiResponse: this.utils.APIResponse,
        listeners: _listeners,
      };
    }

    /** @inheritdoc */
    getRuntimeId() {
      return _viewportApiHelpers.getRuntimeId(_references);
    }
    
    /** @inheritdoc */
    getContainer() {

    }

    /** @inheritdoc */
    addEventListener(type, cb) {
      return _viewportApiHelpers.addEventListener(_references, type, cb);
    }

    /** @inheritdoc */
    removeEventListener(token) {
      return _viewportApiHelpers.removeEventListener(_references, token);
    }

    /** @inheritdoc */
    destroy() {
      return _viewportApiHelpers.destroy(_references);
    }

    /** @inheritdoc */
    reload() {
      return _viewportApiHelpers.reload(_references);
    }

    /** @inheritdoc */
    getScreenshot() {  
      return _threeDManager.renderingHandler.getScreenshot();
    }

    /** @inheritdoc */
    getSettingDefinitions() {
      return _viewportApiHelpers.getSettingDefinitions(_references);
    }

    /** @inheritdoc */
    getSettings(keys) {
      return _viewportApiHelpers.getSettings(_references, keys);
    }

    /** @inheritdoc */
    getSetting(k) {
      return _viewportApiHelpers.getSetting(_references, k);
    }

    /** @inheritdoc */
    updateSettingAsync(k, val) {
      return _viewportApiHelpers.updateSettingAsync(_references, k, val);
    }

    /** @inheritdoc */
    updateSettingsAsync(settings) {
      return _viewportApiHelpers.updateSettingsAsync(_references, settings);
    }
  }

  return new ViewportApi();
};

module.exports = ViewportApi;