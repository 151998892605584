/**
 * @file The default threeDManager. Handles all functionality related to the default viewport.
 *
 * @module ThreeDManagerDefault
 * @author Michael Oppitz
 */

let ThreeDManager = function (___settings) {
  const THREE = require('../../../externals/three'),
        TWEEN = require('@tweenjs/tween.js'),
        GLOBAL_UTILS = require('../../../shared/util/GlobalUtils'),
        ThreeDManagerInterface = require('../../interfaces/ThreeDManagerInterface'),
        THREE_D_MANAGER_CONSTANTS = require('./ThreeDManagerConstants'),
        PATH_UTILS = require('../../PathUtils'),
        START_UP_ID = 'startUpId',
        _settings = ___settings.settings,
        _scene = ___settings.scene,
        _geometryNode = ___settings.geometryNode,
        _container = ___settings.container,
        _handlers = {},
        _exchangeMeshMaterialObjects = [];

  require('./materials/Reflector');

  let that, _helpers,
      _grid, _groundPlane, _groundPlaneReflection,
      _width = _container.offsetWidth,
      _height = _container.offsetHeight,
      _preFullscreenStyle = {};

  ////////////
  ////////////
  //
  // the hooks for all settings
  //
  ////////////
  ////////////

  let _showHook = function (v) {
    if (!GLOBAL_UTILS.typeCheck(v, 'boolean'))
      return false;

    if (_container)
      _container.style.opacity = v ? 1 : 0;

    return true;
  };

  let _showTransitionHook = function (v) {
    if (!GLOBAL_UTILS.typeCheck(v, 'string'))
      return false;

    if (_container) {
      if (v.length > 0) {
        _container.style.transition = 'opacity ' + v;
      } else {
        _container.style.transition = 'none';
      }
    }
    return true;
  };

  /**
   * Toggles the full screen mode.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _fullscreenHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', that.warn, 'ThreeDManager.Hook->fullscreen')) return false;

    if (toggle && !that._isInFullScreen()) {
      if (_container.requestFullscreen) {
        _container.requestFullscreen();
      } else if (_container.webkitRequestFullscreen) {
        _container.webkitRequestFullscreen();
      } else if (_container.msRequestFullscreen) {
        _container.msRequestFullscreen();
      } else if (_container.mozRequestFullScreen) {
        _container.mozRequestFullScreen();
      }

      // save container style before changing it
      _preFullscreenStyle.left = _container.style.left;
      _preFullscreenStyle.top = _container.style.top;
      _container.style.left = '0%';
      _container.style.top = '0%';

      _handlers.renderingHandler.render();
      return true;
    }

    if (!toggle && that._isInFullScreen()) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }

      // restore container style
      if (GLOBAL_UTILS.typeCheck(_preFullscreenStyle.left, 'string')) {
        _container.style.left = _preFullscreenStyle.left;
        delete _preFullscreenStyle.left;
      }
      if (GLOBAL_UTILS.typeCheck(_preFullscreenStyle.top, 'string')) {
        _container.style.top = _preFullscreenStyle.top;
        delete _preFullscreenStyle.top;
      }

      _handlers.renderingHandler.render();
      return true;
    }

    // rare case where the fullscreen was enabled/disabled at another place
    // and the setting had to be adjusted accordingly
    if (that._isInFullScreen() === toggle)
      return true;

    return false;
  };

  /**
   * Toggles the groundPlane visibility mode.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _groundPlaneVisibilityHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', that.warn, 'ThreeDManager.Hook->groundPlaneVisibility')) return false;

    if (_groundPlane) _groundPlane.visible = toggle;
    _handlers.renderingHandler.render();
    return true;
  };

  /**
   * Toggles the groundPlane reflection mode.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _groundPlaneReflectionVisibilityHook = function (toggle) {
    let scope = 'ThreeDManager.Hook->groundPlaneReflectionVisibility';
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', that.warn, scope)) return false;

    if (_groundPlaneReflection) _groundPlaneReflection.visible = toggle;
    // Has to be deisabled for now
    if (toggle) {
      that.warn(scope, 'The ground plane reflection and the environment as background do not work together right now.');
      that.updateSetting('material.environmentMapAsBackground', false);
      _handlers.materialHandler.setSceneBackground(null);
    }

    _handlers.renderingHandler.render();
    return true;
  };

  /**
   * Sets the threshold for the groundPlane reflectivity mode.
   * @param {Number} t The new threshold
   */
  let _groundPlaneReflectionThresholdHook = function (t) {
    if (!GLOBAL_UTILS.typeCheck(t, 'number', that.warn, 'ThreeDManager.Hook->groundPlaneReflectionThreshold')) return false;

    if (_groundPlaneReflection) _groundPlaneReflection.threshold = t;
    _handlers.renderingHandler.render();
    return true;
  };

  /**
   * Toggles the grid visibility mode.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _gridVisibilityHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', that.warn, 'ThreeDManager.Hook->gridVisibility')) return false;

    if (_grid) _grid.visible = toggle;
    _handlers.renderingHandler.render();
    return true;
  };

  /**
   * Toggles whether pointer events are ignored.
   *
   * @param {Boolean} ignore Whether to ignore pointer events
   * @returns {Boolean} If the mode was successfully set
   */
  let _ignorePointerEventsHook = function(toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', that.warn, 'ThreeDManager.Hook->ignorePointerEvents')) return false;
    _handlers.renderingHandler.getDomElement().style.pointerEvents = toggle ? 'none' : '';
    return true;
  };

  /**
   * @extends module:ThreeDManagerInterface~ThreeDManagerInterface
   * @lends module:ThreeDManagerDefault~ThreeDManager
   */
  class ThreeDManager extends ThreeDManagerInterface {

    /**
     * Constructor of the 3D Manager
     */
    constructor(___settings) {
      super(___settings, GLOBAL_UTILS);

      that = this;
      _handlers.threeDManager = that;

      _helpers = new (require('../../helpers/ThreeDManagerHelpers'))({
        scene: _scene,
      }, _handlers);
      _handlers.threeDManager.helpers = _helpers;

      ////////////
      ////////////
      //
      // Settings
      //
      ////////////
      ////////////

      require('../../../shared/mixins/SettingsMixin').call(that, _settings, THREE_D_MANAGER_CONSTANTS.defaultSettings, ___settings.runtimeId);

      ////////////
      ////////////
      //
      // Fullscreen Event Listener
      //
      ////////////
      ////////////

      document.addEventListener('webkitfullscreenchange', function () {
        that.updateSetting('fullscreen', that._isInFullScreen());
      }, false);

      document.addEventListener('mozfullscreenchange', function () {
        that.updateSetting('fullscreen', that._isInFullScreen());
      }, false);

      document.addEventListener('fullscreenchange', function () {
        that.updateSetting('fullscreen', that._isInFullScreen());
      }, false);

      ////////////
      ////////////
      //
      // Rendering Handler
      //
      ////////////
      ////////////

      this.renderingHandler = new (require('./handlers/RenderingHandler'))({
        settings: that.getSection('render'),
        scene: _scene,
        geometryNode: _geometryNode,
        container: _container
      }, _handlers);

      // error in the rendering handler, abort
      if(that.success === false) return;

      _handlers.renderingHandler = this.renderingHandler;

      ////////////
      ////////////
      //
      // Material Handler
      //
      ////////////
      ////////////
      this.materialHandler = new (require('./handlers/MaterialHandler'))({
        settings: that.getSection('material'),
        scene: _scene,
        geometryNode: _geometryNode,
      }, _handlers);

      // error in the material handler, abort
      if(that.success === false) return;

      _handlers.materialHandler = this.materialHandler;

      ////////////
      ////////////
      //
      // Camera Handler
      //
      ////////////
      ////////////

      this.cameraHandler = new (require('./handlers/CameraHandler'))({
        settings: that.getSection('camera'),
        scene: _scene,
        geometryNode: _geometryNode,
        container: _container
      }, _handlers);

      // error in the camera handler, abort
      if(that.success === false) return;

      _handlers.cameraHandler = this.cameraHandler;

      ////////////
      ////////////
      //
      // Light Handler
      //
      ////////////
      ////////////

      this.lightHandler = new (require('./handlers/LightHandler'))({
        settings: that.getSection('lights'),
        shadows: that.getSettingShallow('render.shadows'),
        scene: _scene,
        geometryNode: _geometryNode,
      }, _handlers);

      // error in the light handler, abort
      if(that.success === false) return;

      _handlers.lightHandler = this.lightHandler;

      ////////////
      ////////////
      //
      // Interaction Handler
      //
      ////////////
      ////////////

      this.interactionHandler = new (require('./handlers/InteractionHandler'))({
        settings: that.getSection('interaction'),
        scene: _scene,
        geometryNode: _geometryNode,
      }, _handlers);

      // error in the interaction handler, abort
      if(that.success === false) return;

      _handlers.interactionHandler = this.interactionHandler;

      ////////////
      ////////////
      //
      // Start-Up Methods
      //
      ////////////
      ////////////

      this.registerHook('show', _showHook);
      this.registerHook('showTransition', _showTransitionHook);
      this.registerHook('fullscreen', _fullscreenHook);
      this.registerHook('groundPlaneVisibility', _groundPlaneVisibilityHook);
      this.registerHook('groundPlaneReflectionVisibility', _groundPlaneReflectionVisibilityHook);
      this.registerHook('groundPlaneReflectionThreshold', _groundPlaneReflectionThresholdHook);
      this.registerHook('gridVisibility', _gridVisibilityHook);
      this.registerHook('ignorePointerEvents', _ignorePointerEventsHook);
    }

    _isInFullScreen() {
      return !!(document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement || document.mozFullScreenElement);
    }

    /** @inheritdoc */
    init(bb) {
      _handlers.renderingHandler.unregisterForNoRendering(START_UP_ID);

      let sceneExtents = bb.min.distanceTo(bb.max);

      let divisions = 0.1;
      let gridExtents = sceneExtents;
      if (sceneExtents > 1) {
        let tmp = parseInt(sceneExtents);
        tmp = tmp.toString();
        tmp = tmp.length;
        tmp = Math.pow(10, tmp - 1);
        gridExtents = Math.max(Math.ceil(sceneExtents / tmp) * tmp, 1);
        tmp = tmp / 10;
        divisions = gridExtents / tmp;
      }
      else {
        let zeros = 1 - Math.floor(Math.log(sceneExtents) / Math.log(10)) - 2;
        let r = sceneExtents.toFixed(zeros + 1);
        let firstDigit = parseInt(r.substr(r.length - 1)) + 1;
        gridExtents = '0.';
        for (let i = 0; i < zeros; ++i)
          gridExtents = gridExtents + '0';
        gridExtents = parseFloat(gridExtents + firstDigit);
        divisions = firstDigit * 10;
      }

      // grid
      _grid = new THREE.GridHelper(2 * gridExtents, divisions);
      _grid.material.opacity = 0.15;
      _grid.material.transparent = true;
      _grid.isSelectable = false;
      _grid.rotateX(Math.PI / 2);
      _grid.visible = that.getSetting('gridVisibility');

      let obj3Dgrid = new THREE.Object3D();
      obj3Dgrid.SDLocalPath = 'grid';
      obj3Dgrid.add(_grid);

      // ground plane
      let groundPlaneGeom = new THREE.PlaneGeometry(2 * gridExtents, 2 * gridExtents, 2, 2);
      let groundPlaneMaterialProperties = { color: 0xD3D3D3, side: THREE.FrontSide, roughness: 1.0, metalness: 0.0 };
      _groundPlane = new THREE.Mesh(groundPlaneGeom, _handlers.materialHandler.getMaterial(groundPlaneMaterialProperties));
      _groundPlane.receiveShadow = true;
      _groundPlane.visible = that.getSetting('groundPlaneVisibility');
      let obj3Dgroundplane = new THREE.Object3D();
      obj3Dgroundplane.SDLocalPath = 'groundplane';
      obj3Dgroundplane.add(_groundPlane);
      that.addMesh(_groundPlane, _groundPlane.material, groundPlaneMaterialProperties);

      _groundPlaneReflection = new THREE.Reflector(groundPlaneGeom, {
        clipBias: 0.003,
        textureWidth: _width * window.devicePixelRatio,
        textureHeight: _height * window.devicePixelRatio,
        color: 0x777777,
        recursion: 1
      });
      _groundPlaneReflection.receiveShadow = true;
      _groundPlaneReflection.transparent = true;
      _groundPlaneReflection.visible = that.getSetting('groundPlaneReflectionVisibility');
      if (_groundPlaneReflection.visible)
        that.updateSetting('material.environmentMapAsBackground', false);
      obj3Dgroundplane.add(_groundPlaneReflection);

      // adjust position
      let eps = 0.005;
      _grid.position.set(0, 0, bb.min.z - eps);
      _groundPlane.position.set(0, 0, bb.min.z - eps);
      _groundPlaneReflection.scale.set(1000, 1000, 1000);
      _groundPlaneReflection.position.set(0, 0, bb.min.z - eps);

      _helpers.addSceneObject(obj3Dgrid);
      _helpers.addSceneObject(obj3Dgroundplane);

      _helpers.initialize();
      that.adjustScene();
    }

    ////////////
    ////////////
    //
    // ThreeDManager API
    //
    ////////////
    ////////////

    /** @inheritdoc */
    adjustScene() {
      if(!_helpers.isInitialized()) return;

      let bb = _geometryNode.computeSceneBoundingBox();
      let bs = new THREE.Sphere();
      bb.getBoundingSphere(bs);

      let eps = 0.005;
      _grid.position.set(0, 0, bb.min.z - eps);
      _groundPlane.position.set(0, 0, bb.min.z - eps);
      _groundPlaneReflection.position.set(0, 0, bb.min.z - eps);

      _handlers.materialHandler.adjustToBoundingSphere(bs);
      _handlers.lightHandler.adjustToBoundingSphere(bs);
      _handlers.cameraHandler.adjustToBoundingSphere(bs);

      _handlers.renderingHandler.updateShadowMap();
      _handlers.materialHandler.compile();
      _handlers.renderingHandler.render();
    }

    /** @inheritdoc */
    destroy() {
      delete _handlers.threeDManager;
      _helpers.destroyViewport();

      // call handler destroy methods
      if(_handlers.renderingHandler)
        _handlers.renderingHandler.destroy();

      // de-register hooks and notifiers
      that.deregisterAllHooks();
      that.deregisterAllNotifiers();

      while (_container.firstChild)
        _container.removeChild(_container.firstChild);

      // remove handlers
      for (let key in _handlers)
        delete _handlers[key];
    }

    /** @inheritdoc */
    addMesh(mesh, material, properties) {
      _helpers.addMesh(mesh, material, properties);
    }

    /** @inheritdoc */
    removeMesh(mesh) {
      _helpers.removeMesh(mesh);
    }

    /** @inheritdoc */
    addAnchor(mesh, properties) {
      _helpers.addAnchor(mesh, properties);
    }

    /** @inheritdoc */
    removeAnchor(mesh) {
      _helpers.removeAnchor(mesh);
    }

    /** @inheritdoc */
    fadeIn(path, duration) {
      let obj = PATH_UTILS.getPathObject(_geometryNode, path);
      if (obj == null) {
        return Promise.reject(new Error('Could not fade in geometry, path not found'));
      }

      return new Promise(function (resolve) {
        _helpers.toggleMeshes(true);
        obj.traverseVisible(function (o) {
          if (o.SDMaterialDefinition && o.material) { // FIXME Alex to Michael: I changed o.hasOwnProperty('SDMaterialDefinition') to o.SDMaterialDefinition && o.material, because it seems for 2D text tags 'material' can be set to null, which causes a crash
            //if (!(o instanceof THREE.Tag2D)) {
            o.material.transparent = true;
            //} else {
            //  o.origOpacity = o.node.style.opacity;
            //}
          }
        });
        _helpers.toggleMeshes(false);

        let fadeInProperties = { opacity: 0 };
        let fadeInTween = new TWEEN.Tween(fadeInProperties)
          .to({
            opacity: 1
          }, duration)
          .onUpdate(function () {
            _helpers.toggleMeshes(true);
            obj.traverseVisible(function (o) {
              if (o.SDMaterialDefinition && o.material) { // FIXME Alex to Michael: I changed o.hasOwnProperty('SDMaterialDefinition') to o.SDMaterialDefinition && o.material, because it seems for 2D text tags 'material' can be set to null, which causes a crash
                let opacityTarget = o.SDMaterialDefinition.getTransparent() ? 1.0 - o.SDMaterialDefinition.getTransparency() : 1.0;
                //if (!(o instanceof THREE.Tag2D)) {
                o.material.opacity = fadeInProperties.opacity * opacityTarget;
                //} else {
                //  o.node.style.opacity = fadeInProperties.opacity * o.origOpacity;
                //}
              }
            });
            _helpers.toggleMeshes(false);
          })
          .onComplete(function () {
            _helpers.toggleMeshes(true);
            obj.traverseVisible(function (o) {
              if (o.SDMaterialDefinition && o.material) { // FIXME Alex to Michael: I changed o.hasOwnProperty('SDMaterialDefinition') to o.SDMaterialDefinition && o.material, because it seems for 2D text tags 'material' can be set to null, which causes a crash
                //if (!(o instanceof THREE.Tag2D)) {
                o.material.opacity = 1.0 - o.SDMaterialDefinition.getTransparency();
                o.material.transparent = o.SDMaterialDefinition.getTransparent();
                //} else {
                //  o.node.style.opacity = o.origOpacity;
                //  delete o.origOpacity;
                //}

              }
            });
            _helpers.toggleMeshes(false);
            _handlers.renderingHandler.unregisterForContinuousRendering('FADE_IN_'+path);
            resolve();
          });

        _handlers.renderingHandler.registerForContinuousRendering('FADE_IN_'+path);
        fadeInTween.start();
      });
    }

    /** @inheritdoc */
    fadeOut(path, duration) {
      let obj = PATH_UTILS.getPathObject(_geometryNode, path);
      if (obj == null) {
        return Promise.reject(new Error('Could not fade out, geometry, path not found.'));
      }

      return new Promise(function (resolve) {
        _helpers.toggleMeshes(true);
        obj.traverseVisible(function (o) {
          if (o.hasOwnProperty('material')) {
            o.material.transparent = true;
          }
        });
        _helpers.toggleMeshes(false);

        let fadeOutProperties = { opacity: 1 };
        let fadeOutTween = new TWEEN.Tween(fadeOutProperties)
          .to({
            opacity: 0
          }, duration)
          .onUpdate(function () {
            _helpers.toggleMeshes(true);
            obj.traverseVisible(function (o) {
              if (o.SDMaterialDefinition && o.material) { // FIXME Alex to Michael: I changed o.hasOwnProperty('SDMaterialDefinition') to o.SDMaterialDefinition && o.material, because it seems for 2D text tags 'material' can be set to null, which causes a crash
                o.material.opacity = fadeOutProperties.opacity * (1.0 - o.SDMaterialDefinition.getTransparency());
              }
            });
            _helpers.toggleMeshes(false);
          })
          .onComplete(function () {
            _helpers.toggleMeshes(true);
            obj.traverseVisible(function (o) {
              if (o.SDMaterialDefinition && o.material) { // FIXME Alex to Michael: I changed o.hasOwnProperty('SDMaterialDefinition') to o.SDMaterialDefinition && o.material, because it seems for 2D text tags 'material' can be set to null, which causes a crash
                o.material.opacity = 0;
              }
            });
            _helpers.toggleMeshes(false);
            _handlers.renderingHandler.unregisterForContinuousRendering('FADE_OUT_'+path);
            resolve();
          });

        _handlers.renderingHandler.registerForContinuousRendering('FADE_OUT_'+path);
        fadeOutTween.start();
      });
    }

    /** @inheritdoc */
    updateInteractions(path, options) {
      if (!options) return;
      if (GLOBAL_UTILS.typeCheck(options.interactionGroup, 'string')) {

        _handlers.highlightingHandler.setToggleHighlights(false);

        let group = options.interactionGroup;

        let mode = 'sub';
        if (GLOBAL_UTILS.typeCheck(options.interactionMode, 'string')) {
          if (options.interactionMode === 'global') {
            mode = 'global';
          }
        }

        let n = GLOBAL_UTILS.typeCheck(options.dragPlaneNormal, 'vector3any') ? GLOBAL_UTILS.toVector3(options.dragPlaneNormal) : undefined;

        _handlers.interactionHandler.addToInteractionGroup(path, group, mode, n);

        _handlers.highlightingHandler.setToggleHighlights(true);
      }
    }

    /** @inheritdoc */
    removeFromInteractions(path) {
      _handlers.interactionHandler.removeFromInteractions(path);
    }

    /**
     * Computes the scene bounding box or the bounding box for the objects provided
     * 
     * @param {module:ApiInterfaceV2~ScenePathType[]} [scenePaths] - The paths of the scene which should be included in the bounding box
     * @returns {THREE.Box3} the bounding box
     */
    computeBoundingBox(scenePaths) {
      return _geometryNode.computeBoundingBox(scenePaths);
    }

    getExchangeMeshMaterialObjects(){
      return _exchangeMeshMaterialObjects;
    }
  }

  return new ThreeDManager(___settings);
};

module.exports = ThreeDManager;
