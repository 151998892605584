var defaultLights = {
  default: {
    light1: {
      type: 0,
      properties: {color: 0xffffff, intensity: .35}
    },
    light2: {
      type: 1,
      properties: {direction: {x: .5774, y: -.5774, z: .5774}, shadows: true, intensity: .35}
    },
    light3: {
      type: 1,
      properties: {direction: {x: -.25, y: 1, z: 1}, shadows: false, intensity: .25}
    },
  },
  default_legacy: {
    light0: {
      type: 999,
      properties: {}
    },
    light1: {
      type: 0,
      properties: {color: 0x646464}
    },
    light2: {
      type: 1,
      properties: {direction: {x: .5774, y: -.5774, z: .5774}, shadows: true}
    },
  },
  test: {
    light1: {
      type: 1,
      properties: {direction: {x: 1, y: 1, z: 1}, shadows: false, intensity: 0.5}
    },
    light2: {
      type: 1,
      properties: {direction: {x: 1, y: -1, z: 1}, shadows: false, intensity: 0.5}
    },
    light3: {
      type: 1,
      properties: {direction: {x: -1, y: -1, z: 1}, shadows: false, intensity: 0.5}
    },
    light4: {
      type: 1,
      properties: {direction: {x: -1, y: 1, z: 1}, shadows: false, intensity: 0.5}
    }
  },
};

module.exports = defaultLights;
