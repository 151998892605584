
let RenderingHandlerHelpers = function (___handlers) {
  const THREE = require('../../../externals/three'),
        MESSAGING_CONSTANTS = require('../../../shared/constants/MessagingConstants'),
        LOGGING_CONSTANTS = require('../../../shared/constants/LoggingConstants'),
        MESSAGE_PROTOTYPE = require('../../../shared/messages/MessagePrototype'),
        _handlers = ___handlers;

  let that,
      _gl, _container, _renderer, _properties,
      _screenVector, _pos, _raycaster;

  class RenderingHandlerHelpers {

    constructor() {
      that = this;
      _screenVector = new THREE.Vector3();
      _pos = new THREE.Vector3();
      _raycaster = new THREE.Raycaster();
    }

    createWebGLRenderer(container, properties) {
      let response = {
        success: true,
        message: '',
        messageLevel: LOGGING_CONSTANTS.loggingLevels.INFO,
      /* webglreport: FIXME include webglreport into this message,
         use code from https://github.com/AnalyticalGraphicsInc/webglreport
         and www.http://webglreport.com */
      };

      _properties = Object.assign({}, properties);
      _container = container;

      try {
        let canvas = _properties.canvas;

        // if no canvas is provided, create a new one
        if (!canvas) {
          canvas = document.createElementNS('http://www.w3.org/1999/xhtml', 'canvas');
          let idPrefix = typeof _container.id === 'string' && _container.id.length > 0 ? _container.id : 'sdv-container-viewport';
          canvas.id = idPrefix + '-canvas';
          _container.appendChild(canvas);
          _properties.canvas = canvas;
        }

        // add id of container to response
        response.containerId = _container.id;

        canvas.addEventListener('webglcontextlost', that.onContextLost, false);
        canvas.addEventListener('webglcontextrestored', that.onContextRestore, false);

        let contextAttributes = {
          alpha: _properties.alpha,
          depth: _properties.depth,
          stencil: _properties.stencil,
          antialias: _properties.antialias,
          premultipliedAlpha: _properties.premultipliedAlpha,
          preserveDrawingBuffer: _properties.preserveDrawingBuffer,
          powerPreference: _properties.powerPreference
        };

        _gl = canvas.getContext('webgl', contextAttributes) || canvas.getContext('experimental-webgl', contextAttributes);

        // creation failed
        if (_gl === null) {

          // create without the attributes
          _gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

          if(_gl !== null) {
            response.message = 'We were unable to get a WebGL context using the requested attributes, falling back to default attributes.';
            response.messageLevel = LOGGING_CONSTANTS.loggingLevels.WARN;
          } else {
            throw new Error('We were unable to get a WebGL context.');
          }
        }

        // Some experimental-webgl implementations do not have getShaderPrecisionFormat
        if (_gl.getShaderPrecisionFormat === undefined) {
          _gl.getShaderPrecisionFormat = function () {
            return { 'rangeMin': 1, 'rangeMax': 1, 'precision': 1 };
          };
        }

        _properties.context = _gl;
        _renderer = new THREE.WebGLRenderer(_properties);
        //_renderer.bla();// for testing - force an exception
        response.renderer = _renderer;
      } catch (error) {

        if(_properties.canvas) {
          let parent = _properties.canvas.parentNode;
          if (parent)
            parent.removeChild(_properties.canvas);
          delete _properties.canvas;
        }

        response.success = false;
        response.message = error.message;
        response.messageLevel = LOGGING_CONSTANTS.loggingLevels.ERROR;
      }

      return response;
    }

    getWebGLContext() {
      return _gl;
    }

    handleCreateWebGLRendererResponse(scope, response, msg_success, msg_failed) {
      let msg = response.success === true ? msg_success : msg_failed;
      if (msg) {
        response.messageScope = msg;
      }

      if(response.success === false) {
        // 3. if it fails, error handling:
        // 3.1. send a message STATUS_FAILED, which can be reacted to by an api app (e.g. show a "blue screen" message)
        let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.STATUS_MESSAGE, response);
        _handlers.threeDManager.message(MESSAGING_CONSTANTS.messageTopics.STATUS_FAILED, m);
      }

      // 3.2. send a log message to a model view interface in any case
      _handlers.threeDManager.log(response.messageLevel, scope, msg, response.message);
      let response_partial = {
        success: response.success,
        message: response.message,
        messageLevel: response.messageLevel,
        messageScope: response.messageScope,
        webglreport: response.webglreport
      };
      _handlers.threeDManager.log(response.messageLevel | LOGGING_CONSTANTS.loggingLevels.LOG_TO_SERVER, scope, response_partial);
    
      return response.success === true;
    }

    onContextLost( event ) {
      event.preventDefault();
      // prevent three.js event
      event.stopImmediatePropagation();

      if(!_handlers.threeDManager) return;

      let scope = 'onContextLost';

      // destroy the current canvas
      if(_properties.canvas) {
        _properties.canvas.removeEventListener('webglcontextlost', that.onContextLost, false);
        _properties.canvas.removeEventListener('webglcontextrestored', that.onContextRestore, false);
        let parent = _properties.canvas.parentNode;
        if (parent)
          parent.removeChild(_properties.canvas);
        delete _properties.canvas;
      }

      // 1. try to recreate context
      // 2. if it fails, try context with default attributes
      // doing both 1. and 2. in one step
      let response = that.createWebGLRenderer(_container, _properties);

      // handle response (logging and messaging)
      that.handleCreateWebGLRendererResponse(scope, response, 'WebGL context was lost and could be restored', 'WebGL context was lost and could not be restored');
      if(response.success === false){
        return;
      }

      // if it is a success reload the viewport
      // keep runtimeId
      // we know the context creation works
      _handlers.threeDManager.api.reload();

    }

    onContextRestore( event ) {
      event.preventDefault();
      // prevent three.js event
      event.stopImmediatePropagation();

      // maybe not even needed, just to prevent the default three.js event
    }

    processAnchors(renderer, scene, camera, anchors) {
      let anchor, object, properties, canvas, width, height, distance, i, len,
          devicePixelRatio = window.devicePixelRatio;

      if(anchors.length > 0) {
        canvas = renderer.context.canvas;
        width = canvas.width;
        height = canvas.height;
      }

      for(i = 0, len = anchors.length; i < len; i++) {
        anchor = anchors[i];
        object = anchor.object;
        properties = anchor.properties;
        if(!properties.update) continue;

        object.updateMatrixWorld();
        _screenVector.setFromMatrixPosition(object.matrixWorld);
        _pos.set(_screenVector.x, _screenVector.y, _screenVector.z);
        _pos.project(camera);

        _pos.x = ( _pos.x * (width / 2) ) + (width / 2);
        _pos.y = - ( _pos.y * (height / 2) ) + (height / 2);

        _pos.x = _pos.x < 0 ? Math.ceil(_pos.x) : Math.floor(_pos.x);
        _pos.y = _pos.y < 0 ? Math.ceil(_pos.y) : Math.floor(_pos.y);

        if(_pos.x !== properties.lastPos.x || _pos.y !== properties.lastPos.y) {
          let canvasPageCoordinates = canvas.getBoundingClientRect(),
              overflow = false;

          if(_pos.x <= 1 || _pos.x >= width -1 || _pos.y <= 1 || _pos.y >= height -1)
            overflow = true;

          // take care of correction by device pixel ratio
          _pos.xPixel = _pos.x / devicePixelRatio;
          _pos.yPixel = _pos.y / devicePixelRatio;

          _raycaster.ray.direction.copy(_screenVector);
          _raycaster.ray.origin.set(0, 0, 0);
          camera.localToWorld(_raycaster.ray.origin);
          _raycaster.ray.direction.sub(_raycaster.ray.origin);

          distance = _raycaster.ray.direction.length();
          _raycaster.ray.direction.normalize();

          let closestIntersectionDistance = Number.MAX_VALUE;
          scene.traverseVisible(function(obj) {
            let curIntersections = _raycaster.intersectObject(obj, true);
            if (curIntersections.length)
              if (curIntersections[0].distance < closestIntersectionDistance)
                closestIntersectionDistance = curIntersections[0].distance;
          });

          camera.getWorldDirection(_screenVector);
          let viewingAngle = _raycaster.ray.direction.dot(_screenVector);

          let anchorData = {
            location: {
              x: object.position.x,
              y: object.position.y,
              z: object.position.z,
            },
            containerX: _pos.xPixel,
            containerY: _pos.yPixel,
            containerWidth: width / devicePixelRatio,
            containerHeight: height / devicePixelRatio,
            clientX: _pos.xPixel + canvasPageCoordinates.x,
            clientY: _pos.yPixel + canvasPageCoordinates.y,
            pageX: _pos.xPixel + canvasPageCoordinates.x + window.scrollX,
            pageY: _pos.yPixel + canvasPageCoordinates.y + window.scrollY,
            viewport: _handlers.threeDManager.runtimeId,
            hidden: closestIntersectionDistance < distance,
            overflow: overflow,
            distance: distance,
            viewingAngle: viewingAngle,
            scenePath: properties.path,
            format: properties.format,
            data: properties.data,
            runtimeId: properties.runtimeId,
          };

          properties.lastPos.x = _pos.x;
          properties.lastPos.y = _pos.y;

          properties.update(anchorData);
        }

      }
    }

  }

  return new RenderingHandlerHelpers();
};

module.exports = RenderingHandlerHelpers;
