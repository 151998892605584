let THREE = require('../../../../externals/three');
let Shaders = require('../shaders/ShaderFile');

(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    define('three.Reflector', ['three'], factory);
  }
  else if ('undefined' !== typeof exports && 'undefined' !== typeof module) {
    module.exports = factory(require('three'));
  }
  else {
    factory(root.THREE);
  }
}(this, function (THREE) {

  /**
   * @author Slayvin / http://slayvin.net
   */

  THREE.Reflector = function (geometry, options) {

    THREE.Mesh.call(this, geometry);

    this.type = 'Reflector';

    var scope = this;

    options = options || {};

    var color = (options.color !== undefined) ? new THREE.Color(options.color) : new THREE.Color(0x7F7F7F);
    var textureWidth = options.textureWidth || 512;
    var textureHeight = options.textureHeight || 512;
    var clipBias = options.clipBias || 0;
    var shader = options.shader || THREE.Reflector.ReflectorShader;
    var recursion = options.recursion !== undefined ? options.recursion : 0;

    //

    var reflectorPlane = new THREE.Plane();
    var normal = new THREE.Vector3();
    var reflectorWorldPosition = new THREE.Vector3();
    var cameraWorldPosition = new THREE.Vector3();
    var rotationMatrix = new THREE.Matrix4();
    var lookAtPosition = new THREE.Vector3(0, 0, - 1);
    var clipPlane = new THREE.Vector4();
    var viewport = new THREE.Vector4();

    var view = new THREE.Vector3();
    var target = new THREE.Vector3();
    var q = new THREE.Vector4();

    var textureMatrix = new THREE.Matrix4();
    var virtualCamera = new THREE.PerspectiveCamera();

    var parameters = {
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      format: THREE.RGBFormat,
      stencilBuffer: false
    };

    var renderTarget = new THREE.WebGLRenderTarget(textureWidth, textureHeight, parameters);
    if (!THREE.Math.isPowerOfTwo(textureWidth) || !THREE.Math.isPowerOfTwo(textureHeight))
      renderTarget.texture.generateMipmaps = false;
    

    let _shader = {
      uniforms: {
        color: { type: 'c', value: null },
        tDiffuse: { type: 't', value: null },
        tDepth: { type: 't', value: null },
        textureMatrix: { type: 'm4', value: null },
        worldPosition: { type: 'v3', value: new THREE.Vector3(0,0,0) },
        far: { type: 'f', value: 1.0 },
        threshold: { type: 'f', value: 100.0 }
      },
      fragmentShader: Shaders.refl_frag,
      vertexShader: Shaders.refl_vert,
    };
    var material = new THREE.ShaderMaterial(_shader);

    let _depthShader = {
      uniforms: {
        far: { type: 'f', value: 1.0 }
      },
      vertexShader: Shaders.world_z_vert,
      fragmentShader: Shaders.world_z_frag
    };
    let _depthMaterial = new THREE.ShaderMaterial(_depthShader);

    let _worldZRenderTarget = new THREE.WebGLRenderTarget(textureWidth, textureHeight, {
      minFilter: THREE.NearestFilter,
      magFilter: THREE.NearestFilter,
      format: THREE.RGBAFormat,
      type: THREE.FloatType,
    });

    material.uniforms.tDiffuse.value = renderTarget.texture;
    material.uniforms.tDepth.value = _worldZRenderTarget.texture;
    material.uniforms.color.value = color;
    material.uniforms.textureMatrix.value = textureMatrix;
    material.uniforms.worldPosition.value = reflectorWorldPosition;
    material.uniforms.threshold.value = scope.threshold = 0.01;

    this.material = material;
    this.material.transparent = true;

    this.onBeforeRender = function (renderer, scene, camera) {
      if ('recursion' in camera.userData) {

        if (camera.userData.recursion === recursion) return;

        camera.userData.recursion++;

      }

      material.uniforms.threshold.value = scope.threshold;
      _depthMaterial.uniforms.far.value = camera.far;
      material.uniforms.far.value = camera.far;

      reflectorWorldPosition.setFromMatrixPosition(scope.matrixWorld);
      cameraWorldPosition.setFromMatrixPosition(camera.matrixWorld);

      rotationMatrix.extractRotation(scope.matrixWorld);

      normal.set(0, 0, 1);
      normal.applyMatrix4(rotationMatrix);

      view.subVectors(reflectorWorldPosition, cameraWorldPosition);

      // Avoid rendering when reflector is facing away

      if (view.dot(normal) > 0) return;

      view.reflect(normal).negate();
      view.add(reflectorWorldPosition);

      rotationMatrix.extractRotation(camera.matrixWorld);

      lookAtPosition.set(0, 0, - 1);
      lookAtPosition.applyMatrix4(rotationMatrix);
      lookAtPosition.add(cameraWorldPosition);

      target.subVectors(reflectorWorldPosition, lookAtPosition);
      target.reflect(normal).negate();
      target.add(reflectorWorldPosition);

      virtualCamera.position.copy(view);
      virtualCamera.up.set(0, 1, 0);
      virtualCamera.up.applyMatrix4(rotationMatrix);
      virtualCamera.up.reflect(normal);
      virtualCamera.lookAt(target);

      virtualCamera.far = camera.far; // Used in WebGLBackground

      virtualCamera.updateMatrixWorld();
      virtualCamera.projectionMatrix.copy(camera.projectionMatrix);

      virtualCamera.userData.recursion = 0;

      // Update the texture matrix
      textureMatrix.set(
        0.5, 0.0, 0.0, 0.5,
        0.0, 0.5, 0.0, 0.5,
        0.0, 0.0, 0.5, 0.5,
        0.0, 0.0, 0.0, 1.0
      );
      textureMatrix.multiply(virtualCamera.projectionMatrix);
      textureMatrix.multiply(virtualCamera.matrixWorldInverse);
      textureMatrix.multiply(scope.matrixWorld);

      // Now update projection matrix with new clip plane, implementing code from: http://www.terathon.com/code/oblique.html
      // Paper explaining this technique: http://www.terathon.com/lengyel/Lengyel-Oblique.pdf
      reflectorPlane.setFromNormalAndCoplanarPoint(normal, reflectorWorldPosition);
      reflectorPlane.applyMatrix4(virtualCamera.matrixWorldInverse);

      clipPlane.set(reflectorPlane.normal.x, reflectorPlane.normal.y, reflectorPlane.normal.z, reflectorPlane.constant);

      var projectionMatrix = virtualCamera.projectionMatrix;

      q.x = (Math.sign(clipPlane.x) + projectionMatrix.elements[8]) / projectionMatrix.elements[0];
      q.y = (Math.sign(clipPlane.y) + projectionMatrix.elements[9]) / projectionMatrix.elements[5];
      q.z = - 1.0;
      q.w = (1.0 + projectionMatrix.elements[10]) / projectionMatrix.elements[14];

      // Calculate the scaled plane vector
      clipPlane.multiplyScalar(2.0 / clipPlane.dot(q));

      // Replacing the third row of the projection matrix
      projectionMatrix.elements[2] = clipPlane.x;
      projectionMatrix.elements[6] = clipPlane.y;
      projectionMatrix.elements[10] = clipPlane.z + 1.0 - clipBias;
      projectionMatrix.elements[14] = clipPlane.w;

      // Render

      scope.visible = false;
      var sb = scene.background;
      scene.background = null;

      var currentRenderTarget = renderer.getRenderTarget();

      var currentVrEnabled = renderer.vr.enabled;
      var currentShadowAutoUpdate = renderer.shadowMap.autoUpdate;
      let originalClearColor = renderer.getClearColor().getHex();
      let originalClearAlpha = renderer.getClearAlpha();
      renderer.vr.enabled = false; // Avoid camera modification and recursion
      renderer.shadowMap.autoUpdate = false; // Avoid re-computing shadows


      renderer.render(scene, virtualCamera, renderTarget, true);

      renderer.setClearColor(0xffffff, 1);
      scene.overrideMaterial = _depthMaterial;
      renderer.render(scene, virtualCamera, _worldZRenderTarget, true);

      renderer.vr.enabled = currentVrEnabled;
      renderer.shadowMap.autoUpdate = currentShadowAutoUpdate;
      renderer.setClearColor(originalClearColor, originalClearAlpha);
      scene.overrideMaterial = null;
      renderer.setRenderTarget(currentRenderTarget);
      scene.background = sb;

      // Restore viewport

      var bounds = camera.bounds;

      if (bounds !== undefined) {

        var size = renderer.getSize();
        var pixelRatio = renderer.getPixelRatio();

        viewport.x = bounds.x * size.width * pixelRatio;
        viewport.y = bounds.y * size.height * pixelRatio;
        viewport.z = bounds.z * size.width * pixelRatio;
        viewport.w = bounds.w * size.height * pixelRatio;

        renderer.state.viewport(viewport);

      }

      scope.visible = true;

    };

    this.getRenderTarget = function () {

      return renderTarget;

    };

  };

  THREE.Reflector.prototype = Object.create(THREE.Mesh.prototype);
  THREE.Reflector.prototype.constructor = THREE.Reflector;
}));