
let threeDManagerConstants;
{
  const GLOBAL_UTILS = require('../../../shared/util/GlobalUtils'),
        LOGGING_CONSTANTS = require('../../../shared/constants/LoggingConstants'),
        LOGGING_LEVELS = LOGGING_CONSTANTS.loggingLevels;

  let _settingsDefinition = {
    show: {
      value: false,
      setting: 'show',
      desc: 'Show / hide the scene',
      type: 'boolean'
    },
    showTransition: {
      value: '1s'
    },
    render: {
      devicePixelRatio: {
        value: 1.0
      },
      clearColor: {
        value: '#ffffff',
        setting: 'render.clearColor',
        desc: 'Set background color',
        type: (v) => (GLOBAL_UTILS.typeCheck(v, 'color') ? true : false)
      }
    },
    camera: {
      far: {
        value: 100
      },
      fov: {
        value: 45,
        setting: 'camera.fov',
        desc: 'Camera frustum vertical field of view angle, unit degree',
        type: (v) => (typeof v === 'number' && v > 0 && v < 180 ? true : false)
      },
      near: {
        value: 0.01
      },
    },
    material: {
      defaultColor: {
        value: '#00aaaa',
        setting: 'material.defaultColor',
        desc: 'The default color of the material',
        type: (v) => (GLOBAL_UTILS.typeCheck(v, 'color') ? true : false)
      }
    }
  };
  
  function _hasSubValue(def) {
    if (typeof def === 'object') {
      for (let key in def) {
        if (key === 'value')
          return true;
        if (_hasSubValue(def[key]))
          return true;
      }
    }

    return false;
  }

  function _convert(def, global) {
    for (let key in def) {
      if (key === 'value') {
        if (_hasSubValue(def[key])) {
          return global = _convert(def[key], global);
        } else {
          return def[key];
        }
      } else if (key !== 'setting' && key !== 'desc' && key !== 'type') {
        global[key] = {};
        global[key] = _convert(def[key], global[key]);
      }
    }
    return global;
  }

  let _defaultSettings = {};
  _convert(_settingsDefinition, _defaultSettings);
  
  threeDManagerConstants = {
    settingsDefinition: _settingsDefinition,
    defaultSettings: _defaultSettings,
    loggingLevels: LOGGING_LEVELS
  };
}

module.exports = require('../../../shared/util/GlobalUtils').deepCopy(threeDManagerConstants);
