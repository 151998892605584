// FIXME to be documented by Alex

const GLOBAL_UTILS = require('../../shared/util/GlobalUtils');

let UIkitViewportOverlays = function(api, options) {

  // check for UIkit
  if (!window.UIkit) return null;

  // sanity check
  options = options || {};
  // prefix for container ids
  options.domElementIdPrefix = options.domElementIdPrefix || 'sdv-container';
  // check for container for buttons
  if (!options.hasOwnProperty('containerViewport'))
    options.containerViewport = document.getElementById(options.domElementIdPrefix + '-viewport');
  if (!options.containerViewport) return null;
  // check for container for controls (optional)
  if (!options.hasOwnProperty('containerControls'))
    options.containerControls = document.getElementById(options.domElementIdPrefix + '-controls');
  // check for container for settings (optional)
  if (!options.hasOwnProperty('containerSettings'))
    options.containerSettings = document.getElementById(options.domElementIdPrefix + '-settings');

  let buttonDiv,
      buttonUl,
      buttons = {},
      spinnerBusyDiv,
      addButton,
      destroy,
      toggleVisibility,
      toggleFullscreenIcon,
      buttonOnClick,
      containerViewport = options.containerViewport,
      containerControls = options.containerControls,
      containerSettings = options.containerSettings,
      showMessage,
      tmp,
      messageSubscriptionTokens = [],
      setBusyGraphic,
      setBusyGraphicPosition,
      centerDiv,
      hideInitialSpinner,
      viewportOverlaySettings = ['brandedMode','createButtons','showControlsButton','showSettingsButton',
        'showZoomButton','showFullscreenButton','showInitialSpinner','showBusySpinner'];

  viewportOverlaySettings.forEach(function(key) {
    if ( typeof options[key] !== 'boolean' ) {
      options[key] = GLOBAL_UTILS.getBooleanNodeAttribute(options.container, key.toLowerCase(), true);
    }
  });

  // create div for buttons
  buttonUl = document.createElement('ul');
  buttonUl.classList.add('uk-iconnav');
  buttonUl.classList.add('shapediver-iconnav');
  buttonDiv = document.createElement('div');
  buttonDiv.appendChild(buttonUl);
  containerViewport.appendChild(buttonDiv);

  // shall buttons be created at all?
  if (options.createButtons) {

    addButton = function(icon, visible, onclick) {
      let li = document.createElement('li');
      let button = document.createElement('a');
      button['href'] = '#';
      button.setAttribute('uk-icon', 'icon: ' + icon);
      if (typeof onclick === 'function') button.onclick = onclick;
      if (!visible) li.style.display = 'none';
      li.appendChild(button);
      buttonUl.appendChild(li);
      return {li: li, a: button, onclick: onclick};
    };

    // show / hide settings button
    if ( containerSettings ) {
      buttons['settings'] = addButton('pencil', options.showSettingsButton, function(evt) {
        // prevent default action
        typeof evt.preventDefault === 'function' && evt.preventDefault();
        // toggle containerSettings.style.display between 'none' and ''
        let d = containerSettings.style.display === 'none' ? '' : 'none';
        d = typeof evt === 'boolean' ? evt : d;
        containerSettings.style.display = d;
        if ( api && api.scene && typeof api.scene.render === 'function' ) {
          api.scene.render();
        }
      });
      // show settings widget initially
      if (options.showSettingsInitial) containerSettings.style.display = '';
    }

    // show / hide controls button
    if ( containerControls ) {
      buttons['controls'] = addButton('settings', options.showControlsButton, function(evt) {
        // prevent default action
        typeof evt.preventDefault === 'function' && evt.preventDefault();
        // toggle containerControls.style.display between 'none' and ''
        let d = containerControls.style.display === 'none' ? '' : 'none';
        d = typeof evt === 'boolean' ? evt : d;
        containerControls.style.display = d;
        if ( api && api.scene && typeof api.scene.render === 'function' ) {
          api.scene.render();
        }
      });
      // show controls widget initially
      if (options.showControlsInitial) containerControls.style.display = '';
    }

    // zoom extents button
    if ( api && api.scene && api.scene.camera && typeof api.scene.camera.resetAsync === 'function') {
      let timeoutId = 0;
      buttons['zoom'] = addButton('camera', options.showZoomButton, function(evt) {
        // prevent default action
        typeof evt.preventDefault === 'function' && evt.preventDefault();
        if (options.zoomButtonResetsCamera) {
          api.scene.camera.resetAsync({coordinates: 'spherical'});
        } else {
          // double click resets the camera
          if ( timeoutId !== 0 ) {
            api.scene.camera.resetAsync({coordinates: 'spherical'});
            clearTimeout(timeoutId);
            timeoutId = 0;
          } else {
            api.scene.camera.zoomAsync();
            timeoutId = setTimeout( function() {timeoutId = 0;}, 500);
          }
        }
      });
      // make sure there is a nonzero camera movement duration by default
      let s = 'scene.camera.cameraMovementDuration';
      if ( api.getSetting(s) === 0 ) {
        api.updateSettingAsync(s, 1000);
      }
    }

    // fullscreen button
    if ( api && typeof api.getSetting === 'function' && typeof api.updateSettingAsync === 'function') {
      buttons['fullscreen'] = addButton('expand', options.showFullscreenButton, function(evt) {
        // prevent default action
        typeof evt.preventDefault === 'function' && evt.preventDefault();
        let inFullscreen = api.getSetting('scene.fullscreen');
        api.updateSettingAsync('scene.fullscreen', !inFullscreen);
      });
      // install event listeners to change icon on fullscreen
      let isInFullscreen = function() {
        return !!(document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement || document.mozFullScreenElement);
      };
      toggleFullscreenIcon = function() {
        if (isInFullscreen()) {
          buttons['fullscreen'].a.setAttribute('uk-icon', 'icon: shrink');
        } else {
          buttons['fullscreen'].a.setAttribute('uk-icon', 'icon: expand');
        }
      };
      document.addEventListener('webkitfullscreenchange', toggleFullscreenIcon);
      document.addEventListener('mozfullscreenchange', toggleFullscreenIcon);
      document.addEventListener('fullscreenchange', toggleFullscreenIcon);
    }
  }

  // helper function for centering an element
  centerDiv = function(element) {
    element.style.position = 'absolute';
    element.style.top = '50%';
    element.style.left = '50%';
    element.style.transform = 'translateX(-50%) translateY(-50%)';
    element.style['-webkit-transform'] = element.style.transform;
  };

  // show initial spinner
  if (api && api.scene && options.showInitialSpinner) {
    let spinnerInitialElem;
    if (options.brandedMode) {
      spinnerInitialElem = document.createElement('img');
      spinnerInitialElem.style.maxWidth = '66%';
      spinnerInitialElem.style.maxHeight = '66%';
      spinnerInitialElem.style.backgroundColor = 'none';
      spinnerInitialElem.src = 'https://d38x54cqf2vcuk.cloudfront.net/img/logo_color.png';
    } else {
      spinnerInitialElem = document.createElement('div');
      spinnerInitialElem.setAttribute('uk-spinner', 'ratio: 2');
    }
    centerDiv(spinnerInitialElem);
    containerViewport.parentNode.appendChild(spinnerInitialElem);
    let token;
    hideInitialSpinner = function() {
      if (spinnerInitialElem) {
        containerViewport.parentNode.removeChild(spinnerInitialElem);
        spinnerInitialElem = null;
      }
      if (token) {
        api.scene.removeEventListener(token.data);
        token = null;
      }
    };
    token = api.scene.addEventListener(api.scene.EVENTTYPE.VISIBILITY_ON, hideInitialSpinner);
  }

  // show busy spinner
  if (api && api.state && options.showBusySpinner) {
    const setDefaultStyle = function(spinnerBusyDiv) {
      spinnerBusyDiv.classList.add('shapediver-spinner-busy');
      spinnerBusyDiv.setAttribute('uk-spinner', 'ratio: 1');
    };
    let busyImg;
    spinnerBusyDiv = document.createElement('div');
    setDefaultStyle(spinnerBusyDiv);
    buttonDiv.appendChild(spinnerBusyDiv);
    tmp = api.state.addEventListener(api.state.EVENTTYPE.BUSY, function() {
      spinnerBusyDiv.style.display = '';
    });
    if (tmp.data) messageSubscriptionTokens.push(tmp.data);
    tmp = api.state.addEventListener(api.state.EVENTTYPE.IDLE, function() {
      spinnerBusyDiv.style.display = 'none';
    });
    if (tmp.data) messageSubscriptionTokens.push(tmp.data);
    // functions for setting a user defined busy icon
    setBusyGraphic = function(url, classes) {
      if (busyImg) {
        spinnerBusyDiv.removeChild(busyImg);
      } else {
        spinnerBusyDiv.removeAttribute('uk-spinner');
        spinnerBusyDiv.classList.remove('uk-spinner', 'uk-icon');
      }
      if (url) {
        busyImg = document.createElement('img');
        busyImg.src = url;
        if (classes) {
          classes = Array.isArray(classes) || [classes];
          classes.forEach(function(c) {
            spinnerBusyDiv.classList.add(c);
          });
        }
        centerDiv(spinnerBusyDiv);
        spinnerBusyDiv.appendChild(busyImg);
      } else {
        setDefaultStyle(spinnerBusyDiv);
      }
    };
    // dummy function for setting position of busy graphic
    setBusyGraphicPosition = () => {};
  }

  /**
  * Show a message notification overlaying the viewport
  * @param {String} [status='primary'] - one of 'primary', 'success', 'warning', 'danger'
  * @param {String} message - The message to show
  */
  showMessage = function(status, message) {
    status = status || 'primary';
    window.UIkit.notification({
      message: message,
      status: status,
      pos : 'top-center'
    });
  };

  // register to user message events
  tmp = api.state.addEventListener(api.state.EVENTTYPE.MESSAGE, function(event) {
    // check if we should show user messages at all
    if ( api.getSetting('showMessages') && event.message ) {
      let status,
          level = event.messageLevel;
      if (typeof level === 'number') {
        level = level & 7;
        if (level === window.SDVApp.constants.loggingLevels.WARN)
          status = 'warning';
        else if (level > window.SDVApp.constants.loggingLevels.WARN)
          status = 'danger';
        else if (level < window.SDVApp.constants.loggingLevels.WARN)
          status = 'primary';
      }
      showMessage(status, event.message);
    }
  });
  if (tmp.data) messageSubscriptionTokens.push(tmp.data);

  // register to fatal error events
  let failedEventHandler = function(event) {
    if (typeof hideInitialSpinner === 'function') {
      hideInitialSpinner();
    }
    // hide scene
    api.updateSettingAsync('scene.show', false);
    // show "blue screen" error message
    let blueScreenDiv = document.createElement('div');
    centerDiv(blueScreenDiv);
    blueScreenDiv.classList.add('uk-text-middle');
    blueScreenDiv.classList.add('uk-text-break');
    blueScreenDiv.classList.add('uk-text-center');
    containerViewport.parentNode.appendChild(blueScreenDiv);
    // main message
    let html = '<h1 class="uk-text-lead">We are sorry</h1>';
    //messageScope
    if (event.messageScope) {
      html += event.messageScope;
    }
    //message - ignore for now
    blueScreenDiv.innerHTML = html;
  };
  let failedEventMessages = api.state.get().data.failedStatusMessages;
  if (failedEventMessages.length > 0) {
    failedEventHandler(failedEventMessages[0]);
  } else {
    tmp = api.state.addEventListener(api.state.EVENTTYPE.FAILED, failedEventHandler);
    if (tmp.data) messageSubscriptionTokens.push(tmp.data);
  }

  // toggle visibility of a button
  toggleVisibility = function(id, visible) {
    if (!buttons[id]) return;
    if (visible) {
      buttons[id].li.style.display = '';
    } else {
      buttons[id].li.style.display = 'none';
    }
  };

  // simulate a button being clicked, optionally passing a value as event
  buttonOnClick = function(id, value) {
    if (!buttons[id]) return;
    buttons[id].onclick(value);
  };

  // get rid of all traces
  destroy = function() {
    if (buttonDiv) {
      containerViewport.removeChild(buttonDiv);
      buttonDiv = null;
    }
    if (typeof hideInitialSpinner === 'function') {
      hideInitialSpinner();
    }
    if (toggleFullscreenIcon) {
      document.removeEventListener('webkitfullscreenchange', toggleFullscreenIcon);
      document.removeEventListener('mozfullscreenchange', toggleFullscreenIcon);
      document.removeEventListener('fullscreenchange', toggleFullscreenIcon);
    }
    messageSubscriptionTokens.forEach((t) => (api.removeEventListener(t)));
    buttonDiv = null;
  };

  // provide external access
  return {
    destroy: destroy,
    toggleVisibility : toggleVisibility,
    buttonOnClick : buttonOnClick,
    setBusyGraphic: setBusyGraphic,
    setBusyGraphicPosition: setBusyGraphicPosition,
    showMessage: showMessage
  };
};

module.exports = UIkitViewportOverlays;
