/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiImplementationV2.1.js*
 *
 * ### Content
 *   * Implementation of the ShapeDiver 3D Viewer API V2.1
 *
 * @module ApiImplementationV2
 * @author ShapeDiver <contact@shapediver.com>
 */

const COMM_PLUGIN = require('../../plugins/comm/CommPlugin');

/**
  * Imported messaging constant definitions
  */
var messagingConstants = require('../../shared/constants/MessagingConstants');

/**
  * Imported global plugin constants
  */
var pluginConstantsGlobal = require('../../shared/constants/PluginConstantsGlobal');

/**
  * Import GlobalUtils
  */
var GlobalUtils = require('../../shared/util/GlobalUtils');

/**
 * Message prototype
 */
var MessagePrototype = require('../../shared/messages/MessagePrototype');

/**
 * ApiInterfaceV2
 */
var ApiInterfaceV2 = new (require('./ApiInterfaceV2.1'))();

/**
 * APIResponse constructor
 */
var APIResponse = function (err, data, payload) {
  let _o = new Object();
  // did we get an error
  if (err !== undefined && err !== null) {
    if (GlobalUtils.typeCheck(err, 'string')) // string error message
      _o.err = new Error(err);
    else if (err instanceof Error) // Error object
      _o.err = err;
    else { // special case: string error message and data related to error
      if (err.hasOwnProperty('m')) {
        _o.err = new Error(err.m);
        if (err.hasOwnProperty('d'))
          _o.err.data = new Error(err.d);
      }
      else { // data related to error
        _o.err = new Error();
        _o.err.data = err;
      }
    }
  }
  // pass through data and payload
  if (data !== undefined && data !== null) _o.data = data;
  if (payload !== undefined && payload !== null) _o.payload = payload;
  return _o;
};

////////////
////////////
//
// State Interface
//
////////////
////////////

/**
  * ShapeDiver 3D Viewer API V2 - State Interface
  * @class
  * @implements {module:ApiInterfaceV2~ApiStateInterface}
  * @param {Object} api - The global api object to which this api belongs
  * @param {Object} references.messagingHandler - Reference to the messaging handler
  * @param {Object} references.processStatusHandler - Reference to the process status handler
  */
var StateApi = function (_api, ___refs) {

  var that = this;

  // include enums from interface definition
  this.EVENTTYPE = ApiInterfaceV2.state.EVENTTYPE;

  // shortcuts to handlers
  var _messagingHandler = ___refs.messagingHandler;
  var _processStatusHandler = ___refs.processStatusHandler;

  /** @inheritdoc */
  this.get = function () {
    let s = _processStatusHandler.getSummary();
    s.failedStatusMessages = _messagingHandler.getFailedStatusMessages();
    return APIResponse(null, s);
  };

  /** @inheritdoc */
  this.setBusy = function (busy, progress) {
    let data = {};
    if (progress) {
      if (typeof progress !== 'number' || progress < 0 || progress > 1) {
        return APIResponse('progess must be a number between 0 and 1', _processStatusHandler.getSummary());
      }
      data.progress = progress;
    }

    if (busy) {
      data.busy = true;
      _processStatusHandler.updateProcessStatus(_api.getRuntimeId(), data);
    }
    else {
      _processStatusHandler.unregisterProcess(_api.getRuntimeId());
    }
    return this.get();
  };

  /** @inheritdoc */
  this.addEventListener = function (type, cb) {
    // check if event type is supported
    if (!Object.keys(that.EVENTTYPE).find((k) => (that.EVENTTYPE[k] === type)))
      return APIResponse('Unsupported event type');
    // compose topic and subscribe to message stream
    let t = messagingConstants.messageTopics.STATUS + '.' + type;
    let subtokens = _api.subscribeToMessageStream(t, function (topic, msg) {
      // create event object, add common event properties
      let event = new CustomEvent(type);
      event.api = msg.api;
      if (msg.token) event.token = msg.token;
      // get relevant data parts from message, add special event properties
      let partTypes = {};
      partTypes[messagingConstants.messageDataTypes.APP_STATUS] = { progress: 1 }; // null: copy all properties
      partTypes[messagingConstants.messageDataTypes.STATUS_MESSAGE] = null; // null: copy all properties
      for (let pt in partTypes) {
        let part = msg.getUniquePartByType(pt);
        if (part) {
          if (part.data) part = part.data;
          // add special event properties
          let props = partTypes[pt] ? partTypes[pt] : part;
          for (let k in props) {
            event[k] = part[k];
          }
        }
      }
      // invoke callback (exception handling takes place in _api.subscribeToMessageStream)
      cb(event);
    });
    return APIResponse(null, subtokens);
  };

  /** @inheritdoc */
  this.removeEventListener = function (token) {
    return APIResponse(null, _api.unsubscribeFromMessageStream(token));
  };
};


////////////
////////////
//
// Scene Interface
//
////////////
////////////

/**
 * ShapeDiver 3D Viewer API V2 - Scene Interface
 * @class
 * @implements {module:ApiInterfaceV2~ApiSceneInterface}
 * @param {Object} api - The global api object to which this api belongs
 * @param {Object} references.sceneManager - Reference to the scene manager
 * @param {Object} references.viewportManager - Reference to the viewport manager
 * @param {Object} references.interactionGroupManager - Reference to the interaction group manager
 */
var SceneApi = function (_api, ___refs) {

  var that = this;

  // include enums from interface definition
  this.FORMAT = ApiInterfaceV2.scene.FORMAT;
  this.EVENTTYPE = ApiInterfaceV2.scene.EVENTTYPE;
  this.INTERACTIONMODETYPE = ApiInterfaceV2.scene.INTERACTIONMODETYPE;
  this.SELECTIONMODETYPE = ApiInterfaceV2.scene.SELECTIONMODETYPE;

  // shortcuts to handlers
  var _sceneManager = ___refs.sceneManager;
  var _viewportManager = ___refs.viewportManager;
  var _interactionGroupManager = ___refs.interactionGroupManager;
  var _messagingHandler = ___refs.messagingHandler;

  let _threeDManager = _viewportManager.getDefaultThreeDManagers()[0];

  if (_threeDManager) {
    let viewportApi = _threeDManager.api;

    /** @inheritdoc */
    this.lights = viewportApi.lights;

    /** @inheritdoc */
    this.camera = viewportApi.camera;
  }


  /** @inheritdoc */
  this.updateAsync = function (assets, pluginId, payload) {

    // parameter sanity checks
    if (!Array.isArray(assets)) {
      assets = [assets];
    }

    // use API runtime id unless we were given a plugin id
    let creatorId = pluginId || _api.getRuntimeId();

    let outputVersions = [];
    let assetIds = [];

    for (let asset of assets) {

      // check and collect asset ids
      if (!asset || !asset.id) {
        return Promise.resolve(APIResponse('Asset without id property', null, payload));
      }
      assetIds.push(asset.id);

      // copy asset object so that we don't change the original
      // exception: the canvas property is assigned
      let newJSON = GlobalUtils.deepCopy(asset, ['canvas', 'threeObject', 'transformations']);

      // get json definition of the current version of this output
      // we reuse all properties not set in the asset from the current state
      // without persistent attributes (except version)
      let oldJSON = _sceneManager.getJSONOutputVersion(asset.id, creatorId, false);
      if (oldJSON) {
        let keys = Object.keys(oldJSON);
        for (let k of keys) {
          if (!newJSON.hasOwnProperty(k) && k !== 'version') {
            newJSON[k] = oldJSON[k];
          }
        }
      }

      // create dummy version if none was specified
      if (!newJSON.hasOwnProperty('version')) {
        newJSON.version = GlobalUtils.createRandomId();
      }

      outputVersions.push(newJSON);
    }

    // we start a process and need a token
    let token = messagingConstants.makeMessageToken();
    token.payload = payload;

    // this promise resolves when the scene update has succeeded,
    // or rejects if the scene update failed
    let updateCompletePromise = new Promise(function (resolve, reject) {

      // send a process status message for starting a process in the ProcessStatusHandler
      let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_STATUS, { busy: false, progress: 0 }, token);
      _messagingHandler.message(messagingConstants.messageTopics.PROCESS, m);

      // tell scene manager about the amount of output versions to expect
      let res = _sceneManager.initSubScene(outputVersions.length, creatorId, token);
      if (!res) {
        reject(new Error('Could not initialize subscene.'));
        return;
      }

      // set individual output versions
      let ovPromises = [];
      for (let outputVersion of outputVersions) {
        ovPromises.push(_sceneManager.addJSONOutputVersionToSubScene(outputVersion, creatorId, token));
      }

      return Promise.all(ovPromises).then(
        function () { // be aware that addJSONOutputVersionToSubScene resolves to false, if update has not been published
          // all ovPromises resolved
          // get data for published ids
          let publishedAssets = [];
          for (let id of assetIds) {
            let asset = _sceneManager.getJSONOutputVersion(id, creatorId, true);
            if (asset) {
              // add addEventListener property to asset
              asset.addEventListener = function (type, cb) {
                // sanity check
                if (!GlobalUtils.typeCheck(type, 'string') || type.length <= 0)
                  return APIResponse('Event type must be a string');
                let t = type;
                if (!type.endsWith(asset.scenePath)) {
                  t = type + '.' + asset.scenePath;
                }
                return that.addEventListener(t, cb);
              };
              // add to list
              publishedAssets.push(asset);
            }
          }
          if (publishedAssets.length == assetIds.length) {
            resolve(publishedAssets);
          }
          else {
            reject('Failed to publish all assets, unknown error.');
          }
        },
        function (err) {
          // one of ovPromises rejected
          reject(err);
        }
      );
    });

    // final promise ensuring we send an APIResponse
    return updateCompletePromise.then(
      function (data) {
        return APIResponse(null, data, payload);
      },
      function (err) {
        return Promise.resolve(APIResponse(err, null, payload));
      });
  };


  /** @inheritdoc */
  this.get = function (filterAsset, pluginId, blnScene) {
    filterAsset = filterAsset || {};
    if (typeof filterAsset !== 'object') filterAsset = {};

    // get assets for a given pluginId, or this API instance
    let creatorIds = [];
    if (GlobalUtils.typeCheck(pluginId, 'string')) {
      creatorIds = [pluginId];
    } else {
      creatorIds = [_api.getRuntimeId()];
      // Later we might change the API behavior to optionally return all assets in the scene,
      // but this requires a bigger change of the API interface definition
      //creatorIds = [_sceneManager.getCreatorIds();]
    }

    // get the JSON versions
    let jsonOutputIds = [];

    for (let creatorId of creatorIds) {
      // retrieve all relevant output ids from scene manager
      let outputIds = filterAsset.hasOwnProperty('id') ? [filterAsset.id] : _sceneManager.getOutputIds(creatorId);

      for (let oid of outputIds) {
        // get a deep copy of the JSON output version
        // The assets returned by getJSONOutputVersion describe their original state, without persistent attributes applied.
        let joid = _sceneManager.getJSONOutputVersion(oid, creatorId, blnScene);
        if (joid === null) {
          //id property should work as a filter criterion, i.e. we don't raise an error if no
          //asset with the given id is found, instead we return an empty array
          //return APIResponse('Id ' + oid + ' not found in scene.');
          return APIResponse(undefined, []);
        }
        jsonOutputIds.push(joid);
      }
    }

    // apply further filters
    let filters = Object.keys(filterAsset);
    for (let f of filters) {
      jsonOutputIds = jsonOutputIds.filter(function (jsoid) {
        return jsoid[f] === filterAsset[f];
      });
    }

    return APIResponse(null, jsonOutputIds);
  };

  /** @inheritdoc */
  this.getData = function (filter, plugin) {
    let f = GlobalUtils.deepCopy(filter);
    if (GlobalUtils.typeCheck(plugin, 'string')) f.plugin = plugin;
    let r = _sceneManager.getModelData(f);
    return APIResponse(null, r);
  };

  /** @inheritdoc */
  this.convertTo2D = function (position) {
    if ( !GlobalUtils.typeCheck(position, 'vector3obj') ) {
      return null;
    }
    let result = [];
    let apis = _api.viewports.get();
    for (let key in apis)
      result.push(apis[key].convertTo2D(position));

    return result.length === 1 ? result[0] : result;
  };

  /** @inheritdoc */
  this.getScreenshot = function () {
    let screenShots = [];
    let apis = _api.viewports.get();
    for (let key in apis)
      screenShots.push(apis[key].getScreenshot());

    return screenShots.length === 1 ? screenShots[0] : screenShots;
  };

  /** @inheritdoc */
  this.updateSelected = function (select, deselect) {
    if (_viewportManager.onlyOneDefault())
      return _viewportManager.getDefaultThreeDManagers()[0].interactionHandler.setSelectedPaths(select, deselect);

    // FIXME Alex:
    // error if there are other managers, how to return error?
    that.error('ApiImplementationV2.updateSelected', 'Either there is no default viewport defined, or there are multiple viewports. If there are multiple viewports use the api of a specific viewport.');
    return null;
  };

  /** @inheritdoc */
  this.getSelected = function () {
    if (_viewportManager.onlyOneDefault())
      return _viewportManager.getDefaultThreeDManagers()[0].interactionHandler.getSelectedPaths();

    // FIXME Alex:
    // error if there are other managers, how to return error?
    that.error('ApiImplementationV2.updateSelected', 'Either there is no default viewport defined, or there are multiple viewports. If there are multiple viewports use the api of a specific viewport.');
    return null;
  };

  /** @inheritdoc */
  this.removeAsync = function (filter, pluginId, payload) {
    // have we been given a pluginId?
    let creatorId = pluginId || _api.getRuntimeId();

    // get list of filtered assets
    let res_get = that.get(filter, creatorId, true);
    res_get.payload = payload;
    if (res_get.err) return res_get;

    let idArr = [];
    for (let asset of res_get.data) {
      idArr.push(asset.id);
    }

    return _sceneManager.removeOutputIds(creatorId, idArr).then(
      function () {
        return APIResponse(null, res_get.data, payload);
      },
      function (err) {
        return Promise.resolve(APIResponse(err, null, payload));
      }
    );

  };

  /** @inheritdoc */
  this.updatePersistentAsync = function (assets, pluginId, payload) {
    if (!Array.isArray(assets)) {
      assets = [assets];
    }

    let creatorId = pluginId || _api.getRuntimeId();

    let promises = [];
    for (let asset of assets) {
      if (asset === undefined || typeof asset !== 'object') {
        return Promise.resolve(APIResponse('Unexpected asset format', null, payload));
      }
      if (!asset.hasOwnProperty('id')) {
        return Promise.resolve(APIResponse('Asset does not have "id" attribute.', null, payload));
      }
      promises.push(_sceneManager.setPersistentAttributes(asset.id, creatorId, asset));
    }

    return Promise.all(promises).then(function (data) {
      return APIResponse(null, data, payload);
    }).catch(function (err) {
      return Promise.resolve(APIResponse(err, null, payload));
    });
  };

  /** @inheritdoc */
  this.getPersistent = function (filter, pluginId) {
    // if no plugin id has been specified, use this API
    let creatorId = pluginId || _api.getRuntimeId();
    // get assets matching filter, based on assets without persistent attributes applied
    let res = that.get(filter, creatorId, false);
    if (res.err) return res;
    // get persistent attributes for each asset
    let attr = [];
    for (let asset of res.data) {
      let a = _sceneManager.getPersistentAttributes(asset.id, creatorId);
      if (a) attr.push(a);
    }
    return APIResponse(null, attr);
  };

  /** @inheritdoc */
  this.updateInteractionGroups = function (groups) {

    // parameter sanity checks
    if (!Array.isArray(groups)) {
      groups = [groups];
    }

    for (let group of groups) {

      if (!group || !group.id) {
        return APIResponse('Interaction group without id property');
      }

      // set default values for hoverable, selectable, draggable
      let g = {};
      ['hoverable', 'selectable', 'draggable'].forEach(function (attr) {
        g[attr] = group[attr] || false;
      });
      if (group.selectionMode)
        g.selectionMode = group.selectionMode;
      g.name = group.id;

      let checkEffect = function (effect) {
        let ps = ['active', 'passive'];
        for (let p in ps) {
          let e = effect[p];
          if (e) {
            if (typeof e !== 'object') {
              return 'Effect has invalid type. Should be object.';
            }
            if (!e.name) {
              return 'Effect has no name property.';
            }
            if (['colorHighlight', 'opacityHighlight'].indexOf(e.name) == -1) {
              return 'Invalid effect type name.';
            }
          }
        }
      };

      if (g.hoverable && group.hoverEffect) {
        let msg = checkEffect(group.hoverEffect);
        if (msg) {
          return APIResponse(msg);
        } else {
          g.hoverableHighlight = group.hoverEffect;
        }
      }
      if (g.selectable && group.selectionEffect) {
        let msg = checkEffect(group.selectionEffect);
        if (msg) {
          return APIResponse(msg);
        } else {
          g.selectableHighlight = group.selectionEffect;
        }
      }
      if (g.draggable && group.dragEffect) {
        let msg = checkEffect(group.dragEffect);
        if (msg) {
          return APIResponse(msg);
        } else {
          g.draggableHighlight = group.dragEffect;
        }
      }

      if (_interactionGroupManager.isInteractionGroup(g.name)) {
        let oldGroup = _interactionGroupManager.getGroup(g.name);

        if (!g.hasOwnProperty('hoverable')) {
          g.hoverable = oldGroup.isHoverable();
        }
        if (!g.hasOwnProperty('selectable')) {
          g.selectable = oldGroup.isSelectable();
        }
        if (!g.hasOwnProperty('selectionMode')) {
          g.selectionMode = oldGroup.getSelectionMode();
        }
        if (!g.hasOwnProperty('draggable')) {
          g.draggable = oldGroup.isDraggable();
        }
        if (!g.hasOwnProperty('hoverEffect')) {
          g.hoverEffect = oldGroup.getHoverableHighlight();
        }
        if (!g.hasOwnProperty('selectionEffect')) {
          g.selectionEffect = oldGroup.getSelectableHighlight();
        }
        if (!g.hasOwnProperty('dragEffect')) {
          g.dragEffect = oldGroup.getDraggableHighlight();
        }
      }

      _interactionGroupManager.addOrReplaceInteractionGroup(g);
    }
    return APIResponse(null, true);
  };

  /** @inheritdoc */
  this.getInteractionGroups = function () {
    let groups = _interactionGroupManager.getGroups();
    let gs = [];
    for (let group of groups) {
      let g = {};
      g.id = group.getName();
      g.hoverable = group.isHoverable();
      g.selectable = group.isSelectable();
      g.selectionMode = group.getSelectionMode();
      g.draggable = group.isDraggable();
      g.hoverEffect = group.getHoverableHighlight();
      g.selectionEffect = group.getSelectableHighlight();
      g.dragEffect = group.getDraggableHighlight();

      // convert colors
      ['dragEffect', 'hoverEffect', 'selectionEffect'].forEach(function (effect) {
        if (g[effect]) {
          let e = g[effect];
          let outEffect = {};
          ['active', 'passive'].forEach(function (ap) {
            if (e[ap]) {
              outEffect[ap] = { name: e[ap].name };
              if (e[ap].options) {
                outEffect[ap].options = {};
                if (outEffect[ap].options.opacity) {
                  outEffect[ap].options.opacity = e[ap].options.opacity;
                }
                if (e[ap].options.color) {
                  let c = e[ap].options.color;
                  if (['r', 'g', 'b'].every((attr) => { return c.hasOwnProperty(attr); })) {
                    outEffect[ap].options.color = [c.r * 255, c.g * 255, c.b * 255];
                  }
                }
              }
            }
          });
          g[effect] = outEffect;
        }
      });
      gs.push(g);
    }
    return APIResponse(null, gs);
  };

  /** @inheritdoc */
  this.addEventListener = function (typeAndPath, cb) {
    // sanity check
    if (!GlobalUtils.typeCheck(typeAndPath, 'string') || typeAndPath.length <= 0)
      return APIResponse('Event type must be a string');
    // check if event type is supported
    let type;
    if (!Object.keys(that.EVENTTYPE).find((k) => {
      let ty = that.EVENTTYPE[k];
      if (typeAndPath.startsWith(ty)) {
        type = ty;
        return true;
      }
      return false;
    })) {
      return APIResponse('Unsupported event type');
    }
    // compose topic and subscribe to message stream
    let t = messagingConstants.messageTopics.SCENE + '.' + typeAndPath;
    let subtokens = _api.subscribeToMessageStream(t, function (topic, msg) {
      // create event object, add common event properties
      let event = new CustomEvent(type);
      event.api = msg.api;
      if (msg.token) event.token = msg.token;
      // get relevant data parts from message, add special event properties
      let partTypes = {};
      partTypes[messagingConstants.messageDataTypes.SCENE_INTERACTION] = null; // null: copy all properties
      partTypes[messagingConstants.messageDataTypes.GENERIC] = null; // null: copy all properties
      partTypes[messagingConstants.messageDataTypes.SCENE_ANCHORDATA] = null; // null: copy all properties
      partTypes[messagingConstants.messageDataTypes.SUBSCENE_DEFINITION] = { outputIds: 1 }; // null: copy all properties
      for (let pt in partTypes) {
        let part = msg.getUniquePartByType(pt);
        if (part) {
          if (part.data) part = part.data;
          // add special event properties
          let props = partTypes[pt] ? partTypes[pt] : part;
          for (let k in props) {
            // FIXME Alex in case event[k] is an array, and part[k] is an array, merge the arrays
            event[k] = part[k];
          }
        }
      }
      // invoke callback (exception handling takes place in _api.subscribeToMessageStream)
      cb(event);
    });
    return APIResponse(null, subtokens);
  };

  /** @inheritdoc */
  this.removeEventListener = function (token) {
    return APIResponse(null, _api.unsubscribeFromMessageStream(token));
  };

  /** @inheritdoc */
  this.render = function () {
    _viewportManager.render();
  };

};


////////////
////////////
//
// Parameter Interface
//
////////////
////////////

/**
 * ShapeDiver 3D Viewer API V2 - Parameter Interface
 * @class
 * @implements {module:ApiInterfaceV2~ApiParameterInterface}
 * @param {Object} api - The global api object to which this api belongs
 * @param {Object} references.parameterHandler - Reference to the parameter handler
 */
var ParameterApi = function (_api, ___refs) {

  var that = this;

  // include enums from interface definition
  this.RESULT = ApiInterfaceV2.parameters.RESULT;
  this.TYPE = ApiInterfaceV2.parameters.TYPE;
  this.VISUALIZATION = ApiInterfaceV2.parameters.VISUALIZATION;
  this.EVENTTYPE = ApiInterfaceV2.parameters.EVENTTYPE;

  // shortcuts to handlers
  var _parameterHandler = ___refs.parameterHandler;

  /** @inheritdoc */
  this.get = function (filter) {
    let r = _parameterHandler.getParameterDefinitionsAndValues(filter);
    if (r) return APIResponse(null, r);
    else return APIResponse('Failed to get parameter definitions');
  };

  /** is it a ParameterUpdateObject */
  var _isParameterUpdateObject = function (o) {
    if (!o || typeof o !== 'object')
      return false;
    // at least one of id, idOrName, or name must exist
    if (!GlobalUtils.typeCheck(o.id, 'string') && !GlobalUtils.typeCheck(o.idOrName, 'string') && !GlobalUtils.typeCheck(o.name, 'string'))
      return false;
    // if plugin exists, it must be a string
    if (o.plugin && !GlobalUtils.typeCheck(o.plugin, 'string'))
      return false;
    // value must exist
    if (o.value === undefined)
      return false;
    // got it
    return true;
  };

  /** @inheritdoc */
  this.updateAsync = function (_values, payload) {
    var scope = 'ApiImplementationV2.ParameterApi.updateAsync';

    // parameter sanity check
    if (!Array.isArray(_values)) {
      _values = [_values];
    }
    for (let v of _values) {
      if (!_isParameterUpdateObject(v)) {
        return Promise.resolve(APIResponse('Invalid input, expecting array of ParameterUpdateObject.', null, payload));
      }
    }

    // create a deep copy of the value object, because we will add properties to it for our response
    let values = GlobalUtils.deepCopy(_values);

    // some values might be Blobs, we can't copy them, therefore we assign
    for (let i = 0, imax = _values.length; i < imax; i++) {
      if (_values[i].value instanceof Blob) {
        values[i].value = _values[i].value;
      }
    }

    // create a random process token id
    let token = messagingConstants.makeMessageToken();
    token.payload = payload;

    return new Promise(function (resolve) {

      // define function for parameter update
      let f_update = function () {

        // create a process callback and register it
        let processCallback = function (t, v) {
          if (v.hasOwnProperty('parts')) {
            for (let p of v.parts) {
              if (p.type == messagingConstants.messageDataTypes.PROCESS_SUCCESS) {
                _api.clearProcessCallback(subToken);
                resolve(APIResponse(null, values, payload));
                return;
              }
              if (p.type == messagingConstants.messageDataTypes.PROCESS_ABORT) {
                _api.clearProcessCallback(subToken);
                resolve(APIResponse(p.data, null, payload));
                return;
              }
              if (p.type == messagingConstants.messageDataTypes.PROCESS_ERROR) {
                _api.clearProcessCallback(subToken);
                resolve(APIResponse(p.data, null, payload));
                return;
              }
            }
          }
        };
        let subToken = _api.setProcessCallback(token.id, processCallback);

        // call parameterHandler
        var r = _parameterHandler.setMultipleParameterValues(values, token);

        // in case of error in parameterHandler, report it and immediately stop subscription
        if (r.err) {
          _api.error(scope, '_parameterHandler.setMultipleParameterValues returned error', r);
          _api.clearProcessCallback(subToken);
          resolve(APIResponse({ m: 'Failed to set parameter values', d: r }, null, payload));
          return;
        }
        else if (r.warn) {
          _api.warn(scope, '_parameterHandler.setMultipleParameterValues returned warning', r);
        }

        // copy result values for each parameter for returning them to caller
        for (let p of r.params) {
          values.find((v) => (v.id === p.id && v.plugin === p.plugin)).result = p.result;
        }

        // wait for process message (see above)
      };

      // check for parameters which require upload
      let requiresPriorUpload = _parameterHandler.updateRequiresPriorUpload(values);
      if (requiresPriorUpload === undefined) {
        resolve(APIResponse('Parameter not found', null, payload));
      } else if (requiresPriorUpload === false) {
        f_update();
      } else {
        // in case there are, call the upload
        _parameterHandler.priorUpload(values)
          .then(
            function (r) {
              if (r.err) {
                _api.error(scope, '_parameterHandler.priorUpload returned error', r);
                resolve(APIResponse({ m: 'Failed to upload blobs', d: r }, null, payload));
              } else {
                f_update();
              }
            },
            function (error) {
              _api.error(scope, '_parameterHandler.priorUpload failed', error);
              resolve(APIResponse({ m: 'Failed to upload blobs', d: error }, null, payload));
            }
          )
          ;
      }
    });
  };

  /** @inheritdoc */
  this.canGoBackInHistory = function () {
    return _parameterHandler.canGoBackInHistory();
  };

  /** @inheritdoc */
  this.canGoForwardInHistory = function () {
    return _parameterHandler.canGoForwardInHistory();
  };

  /** @inheritdoc */
  this.goBackInHistoryAsync = function (payload) {
    let scope = 'ApiImplementationV2.goBackInHistoryAsync';
    // create a random process token id
    let token = messagingConstants.makeMessageToken();
    token.payload = payload;
    // recall history
    return new Promise(function (resolve) {
      // create a process callback and register it
      let processCallback = function (t, v) {
        if (v.hasOwnProperty('parts')) {
          for (let p of v.parts) {
            if (p.type == messagingConstants.messageDataTypes.PROCESS_SUCCESS) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(null, _parameterHandler.getParameterState(), payload));
              return;
            }
            if (p.type == messagingConstants.messageDataTypes.PROCESS_ABORT) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(p.data, null, payload));
              return;
            }
            if (p.type == messagingConstants.messageDataTypes.PROCESS_ERROR) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(p.data, null, payload));
              return;
            }
          }
        }
      };
      let subToken = _api.setProcessCallback(token.id, processCallback);
      //
      if (!_parameterHandler.goBackInHistory(token)) {
        _api.error(scope, '_parameterHandler.goBackInHistory returned false');
        _api.clearProcessCallback(subToken);
        resolve(APIResponse('Failed to go back in history', null, payload));
        return;
      }
      // wait for process message (see above)
    });
  };

  /** @inheritdoc */
  this.goForwardInHistoryAsync = function (payload) {
    let scope = 'ApiImplementationV2.goForwardInHistoryAsync';
    // create a random process token id
    let token = messagingConstants.makeMessageToken();
    token.payload = payload;
    // recall history
    return new Promise(function (resolve) {
      // create a process callback and register it
      let processCallback = function (t, v) {
        if (v.hasOwnProperty('parts')) {
          for (let p of v.parts) {
            if (p.type == messagingConstants.messageDataTypes.PROCESS_SUCCESS) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(null, _parameterHandler.getParameterState(), payload));
              return;
            }
            if (p.type == messagingConstants.messageDataTypes.PROCESS_ABORT) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(p.data, null, payload));
              return;
            }
            if (p.type == messagingConstants.messageDataTypes.PROCESS_ERROR) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(p.data, null, payload));
              return;
            }
          }
        }
      };
      let subToken = _api.setProcessCallback(token.id, processCallback);
      //
      if (!_parameterHandler.goForwardInHistory(token)) {
        _api.error(scope, '_parameterHandler.goForwardInHistory returned false');
        _api.clearProcessCallback(subToken);
        resolve(APIResponse('Failed to go forward in history', null, payload));
        return;
      }
      // wait for process message (see above)
    });
  };

  /** @inheritdoc */
  this.updateProperties = function (definitions) {
    return APIResponse(null, _parameterHandler.updateMultipleParameters(definitions));
  };

  /** @inheritdoc */
  this.addEventListener = function (type, cb) {
    // check if event type is supported
    if (!Object.keys(that.EVENTTYPE).find((k) => (that.EVENTTYPE[k] === type)))
      return APIResponse('Unsupported event type');
    // compose topic and subscribe to message stream
    let t = messagingConstants.messageTopics.PARAMETER + '.' + type;
    let subtokens = _api.subscribeToMessageStream(t, function (topic, msg) {
      // create event object, add common event properties
      let event = new CustomEvent(type);
      event.api = msg.api;
      if (msg.token) event.token = msg.token;
      event.parameter = {};
      // get relevant data parts from message, add special event properties
      let partTypes = {};
      partTypes[messagingConstants.messageDataTypes.PARAMETER_DEFINITION] = null; // null: copy all properties
      partTypes[messagingConstants.messageDataTypes.PARAMETER_UPDATE] = null; // null: copy all properties
      for (let pt in partTypes) {
        let part = msg.getUniquePartByType(pt);
        if (part) {
          if (part.data) part = part.data;
          // add special event properties
          let props = partTypes[pt] ? partTypes[pt] : part;
          for (let k in props) {
            event.parameter[k] = part[k];
          }
        }
      }
      // invoke callback (exception handling takes place in _api.subscribeToMessageStream)
      cb(event);
    });
    return APIResponse(null, subtokens);
  };

  /** @inheritdoc */
  this.removeEventListener = function (token) {
    return APIResponse(null, _api.unsubscribeFromMessageStream(token));
  };

};


////////////
////////////
//
// Export Interface
//
////////////
////////////

/**
 * ShapeDiver 3D Viewer API V2 - Export Interface
 * @class
 * @implements {module:ApiInterfaceV2~ApiExportInterface}
 * @param {Object} api - The global api object to which this api belongs
 * @param {Object} references.exportHandler - Reference to the export handler
 */
var ExportApi = function (_api, ___refs) {

  var that = this;

  // include enums from interface definition
  this.RESULT = ApiInterfaceV2.exports.RESULT;
  this.EVENTTYPE = ApiInterfaceV2.exports.EVENTTYPE;

  // shortcuts to handlers
  var _exportHandler = ___refs.exportHandler;

  /** @inheritdoc */
  this.get = function () {
    return APIResponse(null, _exportHandler.getExportDefinitions());
  };

  /** check for a ExportRequestObject */
  var _isExportRequestObject = function (o) {
    if (!o || typeof o !== 'object')
      return false;
    // at least one of id, idOrName, or name must exist
    if (!GlobalUtils.typeCheck(o.id, 'string') && !GlobalUtils.typeCheck(o.idOrName, 'string') && !GlobalUtils.typeCheck(o.name, 'string'))
      return false;
    // if plugin exists, it must be a string
    if (o.plugin && typeof o.plugin !== 'string')
      return false;
    // got it
    return true;
  };

  /** @inheritdoc */
  this.requestAsync = function (_ero, payload) {
    var scope = 'ApiImplementationV2.ExportApi.requestAsync';

    // parameter sanity check
    if (!_isExportRequestObject(_ero)) {
      return Promise.resolve(APIResponse('Invalid input, expecting an ExportRequestObject.', null, payload));
    }

    // find unique export matching _ero
    let ero = _exportHandler.getUniqueExportByRequestObject(_ero);
    if (ero === undefined) {
      return Promise.resolve(APIResponse('Invalid input, ExportRequestObject did not match a unique export definition.', null, payload));
    }

    // create a random process token id
    let token = messagingConstants.makeMessageToken();
    token.payload = payload;
    if (_ero.silent) token.attributes = { silent: true };

    return new Promise(function (resolve) {

      // create a process callback and register it
      let processCallback = function (t, v) {
        if (v.hasOwnProperty('parts')) {
          for (let p of v.parts) {
            if (p.type == messagingConstants.messageDataTypes.EXPORT_RESULT) {
              // copy p.data to ero and return
              GlobalUtils.defaults(ero, p.data, true);
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(null, ero, payload));
              return;
            }
            if (p.type == messagingConstants.messageDataTypes.PROCESS_ABORT) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(p.data, null, payload));
              return;
            }
            if (p.type == messagingConstants.messageDataTypes.PROCESS_ERROR) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(p.data, null, payload));
              return;
            }
          }
        }
      };
      let subToken = _api.setProcessCallback(token.id, processCallback);

      // call _exportHandler
      var r = _exportHandler.requestExport(ero, token);
      ero.resultcode = r;

      // in case of error in _exportHandler, report it and immediately stop subscription
      if (r !== pluginConstantsGlobal.requestExportResults.CACHE &&
        r !== pluginConstantsGlobal.requestExportResults.LOAD) {
        _api.error(scope, '_exportHandler.requestExport returned error', r);
        _api.clearProcessCallback(subToken);
        resolve(APIResponse({ m: 'Failed to request export', d: r }, ero, payload));
        return;
      }

      // wait for process message (see above)
    });
  };

  /** @inheritdoc */
  this.updateProperties = function (definitions) {
    return APIResponse(null, _exportHandler.updateMultipleExports(definitions));
  };

  /** @inheritdoc */
  this.addEventListener = function (type, cb) {
    // check if event type is supported
    if (!Object.keys(that.EVENTTYPE).find((k) => (that.EVENTTYPE[k] === type)))
      return APIResponse('Unsupported event type');
    // compose topic and subscribe to message stream
    let t = messagingConstants.messageTopics.EXPORT + '.' + type;
    let subtokens = _api.subscribeToMessageStream(t, function (topic, msg) {
      // create event object, add common event properties
      let event = new CustomEvent(type);
      event.api = msg.api;
      if (msg.token) event.token = msg.token;
      event.export = {};
      // get relevant data parts from message, add special event properties
      let partTypes = {};
      partTypes[messagingConstants.messageDataTypes.EXPORT_DEFINITION] = null; // null: copy all properties
      partTypes[messagingConstants.messageDataTypes.EXPORT_RESULT] = null; // null: copy all properties
      partTypes[messagingConstants.messageDataTypes.EXPORT_STATUS] = null; // null: copy all properties
      for (let pt in partTypes) {
        let part = msg.getUniquePartByType(pt);
        if (part) {
          if (part.data) part = part.data;
          // add special event properties
          let props = partTypes[pt] ? partTypes[pt] : part;
          for (let k in props) {
            event.export[k] = part[k];
          }
        }
      }
      // invoke callback (exception handling takes place in _api.subscribeToMessageStream)
      cb(event);
    });
    return APIResponse(null, subtokens);
  };

  /** @inheritdoc */
  this.removeEventListener = function (token) {
    return APIResponse(null, _api.unsubscribeFromMessageStream(token));
  };

};

////////////
////////////
//
// Plugin Interface
//
////////////
////////////

/**
 * ShapeDiver 3D Viewer API V2 - Plugin Interface
 * @class
 * @implements {module:ApiInterfaceV2~ApiPluginInterface}
 * @param {Object} api - The global api object to which this api belongs
 * @param {Object} references.pluginHandler - Reference to the plugin handler
 * @param {Object} references.messagingHandler - Reference to the messaging handler
 * @param {Object} references.parameterHandler - Reference to the parameter handler
 */
var PluginApi = function (_api, ___refs) {

  var that = this;

  // include enums from interface definition
  this.STATUS = ApiInterfaceV2.plugins.STATUS;

  // shortcuts to handlers
  var _pluginHandler = ___refs.pluginHandler;
  var _messagingHandler = ___refs.messagingHandler;
  var _parameterHandler = ___refs.parameterHandler;

  /** @inheritdoc */
  this.get = function () {
    return APIResponse(null, _pluginHandler.getStatusDescription());
  };

  /** @inheritdoc */
  this.registerPluginAsync = function (plugin) {

    return new Promise(function (resolve) {

      try {
        // get runtime id of plugin
        let runtimeId = plugin.getRuntimeId();

        // msg subscription tokens
        let subtokens = [];

        // callback for message subscription
        var cb = function (topic, msg) {
          let pf = msg.getUniquePartByType(messagingConstants.messageDataTypes.PLUGIN_RUNTIME_ID);
          if (pf && pf.data === runtimeId) {
            subtokens.forEach((t) => {
              _messagingHandler.unsubscribeFromMessageStream(t);
            });
            resolve(APIResponse(null, plugin.getStatusDescription()));
          }
        };

        // register to messages of type messagingConstants.messageTopics.PLUGIN_ACTIVE and messagingConstants.messageTopics.PLUGIN_FAILED
        subtokens.push(_messagingHandler.subscribeToMessageStream(messagingConstants.messageTopics.PLUGIN_ACTIVE, cb));
        subtokens.push(_messagingHandler.subscribeToMessageStream(messagingConstants.messageTopics.PLUGIN_FAILED, cb));

        // call _pluginHandler's registerPlugin
        if (_pluginHandler.registerPlugin(plugin) === undefined) {

          // in case registerPlugin failed, unsubscribe from messages
          subtokens.forEach((t) => {
            _messagingHandler.unsubscribeFromMessageStream(t);
          });

          resolve(APIResponse(`Failed to register plugin.`, plugin.getStatusDescription()));
        }
      } catch (ex) {
        resolve(APIResponse(ex));
      }
    });
  };

  /** @inheritdoc */
  this.registerCommPluginAsync = function (settings) {
    let plugin = new COMM_PLUGIN(settings);
    return that.registerPluginAsync(plugin);
  };

  /** @inheritdoc */
  this.refreshPluginAsync = function (id, payload) {
    let scope = 'ApiImplementationV2.refreshPluginAsync';
    // create a random process token id
    let token = messagingConstants.makeMessageToken();
    token.payload = payload;
    // recall history
    return new Promise(function (resolve) {
      // create a process callback and register it
      let processCallback = function (t, v) {
        if (v.hasOwnProperty('parts')) {
          for (let p of v.parts) {
            if (p.type == messagingConstants.messageDataTypes.PROCESS_SUCCESS) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(null, _parameterHandler.getParameterState(), payload));
              return;
            }
            if (p.type == messagingConstants.messageDataTypes.PROCESS_ABORT) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(p.data, null, payload));
              return;
            }
            if (p.type == messagingConstants.messageDataTypes.PROCESS_ERROR) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(p.data, null, payload));
              return;
            }
          }
        }
      };
      let subToken = _api.setProcessCallback(token.id, processCallback);
      //
      let res = _parameterHandler.refreshPlugin(id, token);
      if (res.err) {
        _api.error(scope, '_parameterHandler.refreshPlugin failed');
        _api.clearProcessCallback(subToken);
        resolve(APIResponse('Failed to refresh plugin ' + id, null, payload));
        return;
      }
      // wait for process message (see above)
    });
  };

  /** @inheritdoc */
  this.saveDefaultParameterValuesAsync = function (ids) {
    if (!ids) ids = _pluginHandler.getRuntimeIds();
    if (!Array.isArray(ids)) ids = [ids];
    let promises = [];
    // for all plugin runtime ids...
    for (let i = 0, imax = ids.length; i < imax; i++) {
      let id = ids[i];
      //...get plugin,
      let plugin = _pluginHandler.getPluginByRuntimeId(id);
      if (!plugin) {
        promises.push(Promise.resolve(false));
        continue;
      }
      //...check whether it has the required capability,
      if (!plugin.getCapabilities().includes(pluginConstantsGlobal.pluginCapabilities.DEFAULTPARAM)) {
        promises.push(Promise.resolve(false));
        continue;
      }
      //...get parameter values,
      let kvps = _parameterHandler.getParameterValuesForPlugin(id, true /* string values */);
      //...save them as default
      promises.push(plugin.saveDefaultParameterValues(kvps));
    }
    return Promise.all(promises)
      .then(
        function (arrResult) {
          return APIResponse(null, arrResult.every((r) => (r)));
        },
        function (err) {
          return APIResponse(err, false);
        }
      )
      ;
  };

  /** @inheritdoc */
  this.deregisterPluginAsync = function () {
    // FIXME
    return Promise.reject('not implemented yet');
  };

};

////////////
////////////
//
// Viewport Interface
//
////////////
////////////

/**
 * ### ShapeDiver 3D Viewer API V2 - Viewport Interface
 *
 * The viewport interface is part of the global {@link module:ApiInterfaceV2~ApiInterfaceV2#viewports API v2 viewport}.
 *
 * It allows to
 *  * get viewport apis
 *  * create and destroy viewports
 *
 * @interface ApiViewportsInterface
 * @param {Object} api - The global api object to which this api belongs
 * @param {Object} references - Object containing references to various handlers
 * @param {Object} references.viewportManager - Reference to the viewport manager
 */
var ApiViewportsInterface = function (_api, ___refs) {

  let _viewportManager = ___refs.viewportManager;
  this.TYPE = ApiInterfaceV2.viewports.TYPE;
  this.EVENTTYPE = ApiInterfaceV2.viewports.EVENTTYPE;

  this.get = function(runtimeId) {
    if(!runtimeId)
      return _viewportManager.getApis();

    if(!GlobalUtils.typeCheck(runtimeId, 'string'))
      return null;

    let apis = _viewportManager.getApis();
    let api = apis[0];
    if(api)
      return api;

    return null;
  };

  this.create = function(obj) {
    return _viewportManager.createThreeDManager(obj)[0];
  };

  this.destroy = function(runtimeId) {
    return this.get(runtimeId).destroy();
  };

};


/**
* ShapeDiver 3D Viewer API V2
*
* @class
* @implements {module:ApiInterfaceV2~ApiInterfaceV2}
* @param {Object} references - Object containing references to various handlers
* @param {Object} references.app - Reference to the complete app (will likely be removed at some point)
* @param {Object} references.exportHandler - Reference to the export handler
* @param {Object} references.loggingHandler - Reference to the logging handler
* @param {Object} references.messagingHandler - Reference to the messaging handler
* @param {Object} references.parameterHandler - Reference to the parameter handler
* @param {Object} references.pluginHandler - Reference to the plugin handler
* @param {Object} references.processStatusHandler - Reference to the process status handler
* @param {Object} references.sceneManager - Reference to the scene manager
* @param {Object} references.settingsHandler - Reference to the settings handler for persistent storage of settings
* @param {Object} references.viewportManager - Reference to the viewport manager
* @param {Object} references.interactionGroupManager - Reference to the interaction group manager
* @param {Object} settings - API settings
* @param {String} [settings.runtimeId] - Optional runtime id to use for the API instance
*/
var ApiImplementationV2 = function (___refs, ___settings) {

  var that = this;

  // deep copy the settings
  let _settings = GlobalUtils.deepCopy(___settings);

  // create a runtime id if none was given
  if (!_settings.runtimeId || !GlobalUtils.typeCheck(_settings.runtimeId, 'string'))
    _settings.runtimeId = GlobalUtils.createRandomId();

  // include enums from interface definition
  this.EVENTTYPE = ApiInterfaceV2.EVENTTYPE;

  /** @inheritdoc */
  this.getRuntimeId = function () {
    return _settings.runtimeId;
  };

  // shortcuts for accessing ViewerApp functionality
  var _app = ___refs.app;
  var _exportHandler = ___refs.exportHandler;
  var _messagingHandler = ___refs.messagingHandler;
  var _parameterHandler = ___refs.parameterHandler;
  var _pluginHandler = ___refs.pluginHandler;
  var _processStatusHandler = ___refs.processStatusHandler;
  var _sceneManager = ___refs.sceneManager;
  var _settingsHandler = ___refs.settingsHandler;
  var _viewportManager = ___refs.viewportManager;
  var _interactionGroupManager = ___refs.interactionGroupManager;

  // inject logging functionality
  GlobalUtils.inject(___refs.loggingHandler, that);

  // container for process subscription tokens
  var _processSubscriptions = {};

  /**
   * Clear a callback function which was previously registered using {@link module:ApiInterfaceV2~ApiInterfaceV2#setProcessCallback setProcessCallback}.
   *
   * Your subscription to the process messages for a given process token ends automatically once the process ultimately
   * succeeded or failed. You may unsubscribe before that using this function.
   * @param {String} token - The token which was returned from {@link module:ApiInterfaceV2~ApiInterfaceV2#setProcessCallback setProcessCallback}.
   * @return {Boolean} true if callback was unregistered successfully, false otherwise.
   */
  this.clearProcessCallback = function (token) {
    // allow one subscription per token only (for now, easier to implement)
    if (!_processSubscriptions.hasOwnProperty(token)) {
      return false;
    }
    _messagingHandler.unsubscribeFromMessageStream(_processSubscriptions[token].subToken);
    delete _processSubscriptions[token];
    return true;
  };

  /**
   * Register a callback function which will be invoked for every message related to the given process token.
   *
   * Your callback will be invoked with the following two parameters:
   *   * message topic, e.g. process.TOKEN
   *   * message object
   *
   * Your subscription to the process messages ends automatically once the process ultimately succeeded or failed. You may
   * unsubscribe before that using {@link module:ApiInterfaceV2~ApiInterfaceV2#clearProcessCallback clearProcessCallback}.
   * @param {String} token - The token which identifies the process
   * @param {Function} callback - The callback to invoke for every message related to the given process token
   * @return {Object} subscription token which can be used for unsubscribing again, undefined in case of error
   */
  this.setProcessCallback = function (token, cb) {
    var scope = 'ApiImplementationV2.setProcessCallback';

    // allow one subscription per token only (for now, easier to implement)
    if (_processSubscriptions.hasOwnProperty(token)) {
      return;
    }

    // compose topic for subscription, subscribe
    let t = messagingConstants.messageTopics.PROCESS + '.' + token;
    let subToken = _messagingHandler.subscribeToMessageStream(t, (topic, msg) => {

      // get process token from topic (string after first dot)
      let topicToken = topic.substring(topic.indexOf('.') + 1);

      // get handle to process subscription object
      if (!_processSubscriptions.hasOwnProperty(token)) {
        that.warn(scope, 'Message for unknown token:', msg);
        return;
      }
      let s = _processSubscriptions[token];

      // handle msgs which have a part messagingConstants.messageDataTypes.PROCESS_FORK
      // in that case replace array of process tokens
      let pf = msg.getUniquePartByType(messagingConstants.messageDataTypes.PROCESS_FORK);
      if (pf && Array.isArray(pf.data)) {
        // check new process tokens which shall replace topicToken,
        // every one of them must be a sub-token of the original one (i.e. define a sub-topic, otherwise we won't receive its messages)
        let r = pf.data.every((n) => {
          return n.startsWith(token + '.');
        });
        if (!r) {
          that.error(scope, 'Forked process token must be a sub-token of ' + token);
        }
        else {
          // remove old process token
          let newProcTokens = s.procTokens;
          newProcTokens = newProcTokens.splice(newProcTokens.indexOf(topicToken), 1);
          // add new process tokens and remember them
          newProcTokens = newProcTokens.concat(pf.data);
          s.procTokens = newProcTokens;
        }
        try {
          cb(topic, msg);
        } catch (e) {
          that.error(scope, 'Exception in process callback:', e);
        }
        return;
      }

      // handle msgs which have a part messagingConstants.messageDataTypes.PROCESS_ERROR or PROCESS_SUCCESS
      // in that case remember status for the corresponding process token
      let pe = msg.getUniquePartByType(messagingConstants.messageDataTypes.PROCESS_ERROR);
      let pa = msg.getUniquePartByType(messagingConstants.messageDataTypes.PROCESS_ABORT);
      let ps = msg.getUniquePartByType(messagingConstants.messageDataTypes.PROCESS_SUCCESS);
      if (pe) {
        s.procTokenStat[topicToken] = messagingConstants.messageDataTypes.PROCESS_ERROR;
      }
      else if (pa) {
        s.procTokenStat[topicToken] = messagingConstants.messageDataTypes.PROCESS_ABORT;
      }
      else if (ps) {
        s.procTokenStat[topicToken] = messagingConstants.messageDataTypes.PROCESS_SUCCESS;
      }

      // once we have collected a status for all process tokens, cancel the subscription
      let r = s.procTokens.every((t) => {
        return s.procTokenStat.hasOwnProperty(t);
      });
      if (r) {
        that.debug(scope, 'Clearing process callback for token ' + token);
        that.clearProcessCallback(token);
      }

      try {
        cb(topic, msg);
      } catch (e) {
        that.error(scope, 'Exception in process callback:', e);
      }
    });

    // store subscription token and process token
    _processSubscriptions[token] = {
      subToken: subToken, // subscription token
      procTokens: [token], // list of process tokens (process might be forked)
      procTokenStat: {} // status for each process token
    };
    return token;
  };

  /**
    * Helper functionality for pubsub subscriptions.
    * Catches exceptions and logs them as warning.
    *
    * @param {String} topic
    * @param {Function} callback
    * @return {Object} subscription token
    */
  this.subscribeToMessageStream = function (t, cb) {
    let scope = 'ApiImplementationV2.subscribeToMessageStream';
    return _messagingHandler.subscribeToMessageStream(t, (topic, msg) => {
      try {
        // add / change properties of msg
        msg = msg || {};
        msg.api = that;
        cb(topic, msg);
      } catch (e) {
        that.warn(scope, 'Exception in callback for topic ' + topic, e, msg);
      }
    });
  };

  /**
    * Helper functionality for pubsub unsubscription.
    *
    * @param {Object|Object[]} tokens
    */
  this.unsubscribeFromMessageStream = function (tokens) {
    return _messagingHandler.unsubscribeFromMessageStream(tokens);
  };

  /** @inheritdoc */
  this.state = new StateApi(that, {
    processStatusHandler: _processStatusHandler,
    messagingHandler: _messagingHandler
  });

  /** @inheritdoc */
  this.parameters = new ParameterApi(that, {
    parameterHandler: _parameterHandler,
    messagingHandler: _messagingHandler
  });

  /** @inheritdoc */
  this.plugins = new PluginApi(that, {
    pluginHandler: _pluginHandler,
    messagingHandler: _messagingHandler,
    parameterHandler: _parameterHandler
  });

  /** @inheritdoc */
  this.exports = new ExportApi(that, {
    exportHandler: _exportHandler
  });

  /** @inheritdoc */
  this.viewports = new ApiViewportsInterface(that, {
    viewportManager: _viewportManager,
  });

  /** @inheritdoc */
  this.scene = new SceneApi(that, {
    sceneManager: _sceneManager,
    viewportManager: _viewportManager,
    interactionGroupManager: _interactionGroupManager,
    messagingHandler: _messagingHandler
  });

  // definition of settings
  var _settingsDef = {};

  // logging settings
  _settingsDef['defaultMaterial.color'] = {
    handler: _app,
    namespace: 'app',
    setting: 'defaultMaterial.color',
    desc: 'Color of the default material',
    type: (v) => (GlobalUtils.typeCheck(v, 'color') ? true : false)
  };
  _settingsDef['defaultMaterial.bumpAmplitude'] = {
    handler: _app,
    namespace: 'app',
    setting: 'defaultMaterial.bumpAmplitude',
    desc: 'Bump amplitude of the default material',
    type: 'notnegative'
  };
  _settingsDef['defaultMaterial.metalness'] = {
    handler: _app,
    namespace: 'app',
    setting: 'defaultMaterial.metalness',
    desc: 'Metalness of the default material',
    type: (v) => (GlobalUtils.typeCheck(v, 'number') && v >= 0 && v <= 1)
  };
  _settingsDef['defaultMaterial.roughness'] = {
    handler: _app,
    namespace: 'app',
    setting: 'defaultMaterial.roughness',
    desc: 'Roughness of the default material',
    type: (v) => (GlobalUtils.typeCheck(v, 'number') && v >= 0 && v <= 1)
  };
  _settingsDef['blurSceneWhenBusy'] = {
    handler: _app,
    namespace: 'app',
    setting: 'blurSceneWhenBusy',
    desc: 'Blur or don\'t blur the scene while a process is busy',
    type: 'boolean'
  };
  _settingsDef['loggingLevel'] = {
    handler: _app,
    namespace: 'app',
    setting: 'loggingLevel',
    desc: 'Level of log messages shown on the console, allowed values: -1 (none), 0 (error), 1 (warn), 2 (info), 3 (debug)',
    type: (v) => (GlobalUtils.typeCheck(v, 'number') && v >= -1 && v <= 3)
  };
  _settingsDef['messageLoggingLevel'] = {
    handler: _app,
    namespace: 'app',
    setting: 'messageLoggingLevel',
    desc: 'Log level to be used for logging internal messages, allowed values: -1 (none), 0 (error), 1 (warn), 2 (info), 3 (debug)',
    type: (v) => (GlobalUtils.typeCheck(v, 'number') && v >= -1 && v <= 3)
  };
  _settingsDef['scene.show'] = {
    handler: _app,
    namespace: 'app',
    setting: 'showScene',
    desc: 'Show / hide the scene',
    type: 'boolean'
  };
  _settingsDef['showMessages'] = {
    handler: _app,
    namespace: 'app',
    setting: 'showMessages',
    desc: 'Show or don\'t show user messages in the viewport',
    type: 'boolean'
  };

  let _threeDManager = _viewportManager.getDefaultThreeDManagers()[0],
    _viewportApi;
  // TODO FIXME let call
  if (_threeDManager) {
    _viewportApi = _threeDManager.api;

    /** @inheritdoc */
    this.lights = _viewportApi.lights;

    /** @inheritdoc */
    this.camera = _viewportApi.camera;
  }

  /** @inheritdoc */
  this.addEventListener = function (type, cb) {
    // check if event type is supported
    if (!Object.keys(that.EVENTTYPE).find((k) => (that.EVENTTYPE[k] === type)))
      return APIResponse('Unsupported event type');
    // compose topic and subscribe to message stream
    let t = type;
    let subtokens = that.subscribeToMessageStream(t, function (topic, msg) {
      // create event object, add common event properties
      let event = new CustomEvent(type);
      event.api = msg.api;
      if (msg.token) event.token = msg.token;
      event.settings = {};
      let settings = event.settings;
      // get relevant data parts from message, add special event properties
      let part = msg.getUniquePartByType(messagingConstants.messageDataTypes.SETTINGS_UPDATE);
      if (!part) return;
      if (part.data) part = part.data;
      // transform settings 'key' using 'namespace' and _settingsDef, unless it is a viewport setting
      if (_viewportApi && part.namespace === _viewportApi.getRuntimeId()) {
        settings.key = part.key;
      } else {
        for (let k in _settingsDef) {
          let def = _settingsDef[k];
          if (def.namespace === part.namespace && def.setting === part.key) {
            settings.key = k;
            break;
          }
        }
      }
      if (!settings.key) return;
      settings.valueNew = part.valueNew;
      settings.valueOld = part.valueOld;
      // invoke callback (exception handling takes place in _api.subscribeToMessageStream)
      cb(event);
    });
    return APIResponse(null, subtokens);
  };

  /** @inheritdoc */
  this.removeEventListener = function (token) {
    return APIResponse(null, that.unsubscribeFromMessageStream(token));
  };

  /** @inheritdoc */
  this.getSettingDefinitions = function () {
    var defs = {};
    Object.keys(_settingsDef).forEach((k) => {
      let sk = _settingsDef[k];
      let o = { description: sk.desc };
      if (sk.type) o.type = sk.type;
      let d = GlobalUtils.deepCopy(o);
      defs[k] = d;
    });
    if (_viewportApi) {
      let defViewportApi = _viewportApi.getSettingDefinitions();
      for (let key in defViewportApi)
        defs['scene.' + key] = defViewportApi[key];
    }
    return defs;
  };

  /** @inheritdoc */
  this.getSettings = function (keys) {
    if (!Array.isArray(keys))
      keys = Object.keys(that.getSettingDefinitions());

    var settings = {};
    keys.forEach((k) => {
      let sk = _settingsDef[k];
      if (sk !== undefined) {
        let v = sk.handler.getSetting(sk.setting);
        GlobalUtils.forceAtPath(settings, k, v);
      } else if (_viewportApi) {
        let v = _viewportApi.getSetting(k.replace('scene.', ''));
        if (v !== undefined) GlobalUtils.forceAtPath(settings, k, v);
      }
    });
    return settings;
  };

  /** @inheritdoc */
  this.getSetting = function (k) {
    let sk = _settingsDef[k];
    if (sk === undefined) {
      if (_viewportApi) {
        return _viewportApi.getSetting(k.replace('scene.', ''));
      } else {
        return;
      }
    }
    return sk.handler.getSetting(sk.setting);
  };

  /** @inheritdoc */
  this.updateSettingAsync = function (k, val) {
    let m = _settingsDef[k];
    if (m === undefined) {
      if (_viewportApi) {
        return _viewportApi.updateSettingAsync(k.replace('scene.', ''), val);
      } else {
        return Promise.resolve(APIResponse('Setting does not exist', false));
      }
    }

    // type checking
    if (m.type !== undefined && m.type !== null) {
      if (GlobalUtils.typeCheck(m.type, 'string')) {
        if (GlobalUtils.typeCheck(val, 'm.type')) {
          return Promise.resolve(APIResponse('Setting has wrong value type', false));
        }
      }
      else if (typeof m.type === 'function') {
        if (!m.type(val)) {
          return Promise.resolve(APIResponse('Setting has wrong value type', false));
        }
      }
    }
    // transformation
    if (m.hasOwnProperty('transform') && typeof m.transform === 'function') {
      val = m.transform(val);
    }
    // update setting
    return m.handler.updateSettingAsync(m.setting, val).then(
      (r) => {
        if (!r) {
          return Promise.resolve(APIResponse('Update of setting failed', false));
        }
        else {
          return Promise.resolve(APIResponse(null, true));
        }
      },
      () => {
        return Promise.resolve(APIResponse('Update of setting failed', false));
      }
    );
  };

  /** @inheritdoc */
  this.updateSettingsAsync = function (settings) {
    // get paths of settings object
    let paths = [];
    GlobalUtils.getPaths(settings, paths);

    // create an empty object which will hold the results
    let results = {};

    // create an empty promise to attach further ones to
    let promiseChain = Promise.resolve();
    for (let path of paths) {
      // attach promise for updating the setting at path
      promiseChain = promiseChain.then(function () {
        return that.updateSettingAsync(path, GlobalUtils.getAtPath(settings, path));
      });
      // attach promise for storing the result of the setting update
      promiseChain = promiseChain.then(
        function (r) {
          if (r.err) {
            GlobalUtils.forceAtPath(results, path, false);
          } else {
            GlobalUtils.forceAtPath(results, path, true);
          }
        },
        function () {
          GlobalUtils.forceAtPath(results, path, false);
        }
      );
    }

    // add final result to promise chain
    return promiseChain.then(
      function () {
        return APIResponse(null, results);
      }
    );
  };

  /** @inheritdoc */
  this.saveSettingsAsync = function (plugin) {
    return _settingsHandler.saveSettings(plugin)
      .then(
        function (result) {
          return APIResponse(null, result);
        },
        function (err) {
          return APIResponse(err, false);
        }
      );
  };

  /**
  * attach GlobalUtils
  */
  this.utils = GlobalUtils;

  /**
   * attach the APIResponse to make it accessable from the viewportApis
   */
  this.utils.APIResponse = APIResponse;

};

// export the constructor
module.exports = ApiImplementationV2;
