let ThreeDManager = function (___settings) {
  const THREE = require('../../../externals/three'),
        GLOBAL_UTILS = require('../../../shared/util/GlobalUtils'),
        ThreeDManagerInterface = require('../../interfaces/ThreeDManagerInterface'),
        THREE_D_MANAGER_CONSTANTS = require('./ThreeDManagerConstants'),
        _settings = ___settings.settings,
        _scene = ___settings.scene,
        _geometryNode = ___settings.geometryNode,
        _container = ___settings.container,
        _handlers = {};

  let that, _helpers;

  ////////////
  ////////////
  //
  // the hooks for all settings
  //
  ////////////
  ////////////

  let _showHook = function (v) {
    if (!GLOBAL_UTILS.typeCheck(v, 'boolean'))
      return false;

    if (_container)
      _container.style.opacity = v ? 1 : 0;

    return true;
  };

  let _showTransitionHook = function (v) {
    if (!GLOBAL_UTILS.typeCheck(v, 'string'))
      return false;

    if (_container) {
      if (v.length > 0) {
        _container.style.transition = 'opacity ' + v;
      } else {
        _container.style.transition = 'none';
      }
    }
    return true;
  };

  class ThreeDManager extends ThreeDManagerInterface {

    constructor(___settings) {
      super(___settings, GLOBAL_UTILS);

      that = this;
      _handlers.threeDManager = that;

      _helpers = new (require('../../helpers/ThreeDManagerHelpers'))({
        scene: _scene,
      }, _handlers);
      _handlers.threeDManager.helpers = _helpers;

      ////////////
      ////////////
      //
      // Settings
      //
      ////////////
      ////////////

      require('../../../shared/mixins/SettingsMixin').call(that, _settings, THREE_D_MANAGER_CONSTANTS.defaultSettings, ___settings.runtimeId);

      ////////////
      ////////////
      //
      // Rendering Handler
      //
      ////////////
      ////////////

      this.renderingHandler = new (require('./handlers/RenderingHandler'))({
        settings: that.getSection('render'),
        scene: _scene,
        container: _container,
      }, _handlers);

      // error in the rendering handler, abort
      if(that.success === false) return;

      _handlers.renderingHandler = this.renderingHandler;

      ////////////
      ////////////
      //
      // Material Handler
      //
      ////////////
      ////////////

      this.materialHandler = new (require('./handlers/MaterialHandler'))({
        settings: that.getSection('material'),
      }, _handlers);
      
      // error in the material handler, abort
      if(that.success === false) return;

      _handlers.materialHandler = this.materialHandler;

      ////////////
      ////////////
      //
      // Camera Handler
      //
      ////////////
      ////////////

      this.cameraHandler = new (require('./handlers/CameraHandler'))({
        settings: that.getSection('camera'),
        container: _container,
      }, _handlers);
            
      // error in the camera handler, abort
      if(that.success === false) return;

      _handlers.cameraHandler = this.cameraHandler;

      this.registerHook('show', _showHook);
      this.registerHook('showTransition', _showTransitionHook);
    }

    ////////////
    ////////////
    //
    // ThreeDManager API
    //
    ////////////
    ////////////

    init(bb) {
      _helpers.initialize();
      that.adjustScene();
    }

    adjustScene() {
      let bb = _geometryNode.computeSceneBoundingBox();
      let bs = new THREE.Sphere();
      bb.getBoundingSphere(bs);

      _handlers.cameraHandler.adjustToBoundingSphere(bs);
      _handlers.materialHandler.compile();
      _handlers.renderingHandler.render();
    }

    destroy() {
      delete _handlers.threeDManager;
      _helpers.destroyViewport();

      // call handler destroy methods
      if(_handlers.renderingHandler)
        _handlers.renderingHandler.destroy();

      // de-register hooks and notifiers
      that.deregisterAllHooks();
      that.deregisterAllNotifiers();

      while (_container.firstChild)
        _container.removeChild(_container.firstChild);

      // remove handlers
      for (let key in _handlers)
        delete _handlers[key];
    }

    /** @inheritdoc */
    addMesh(mesh, material, properties) {
      _helpers.addMesh(mesh, material, properties);
    }

    /** @inheritdoc */
    removeMesh(mesh) {
      _helpers.removeMesh(mesh);
    }

    /** @inheritdoc */
    addAnchor(mesh, properties) {
      _helpers.addAnchor(mesh, properties);
    }

    /** @inheritdoc */
    removeAnchor(mesh) {
      _helpers.removeAnchor(mesh);
    }

  }

  return new ThreeDManager(___settings);
};


module.exports = ThreeDManager;
