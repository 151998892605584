let RenderingHandler = function (___settings, ___handlers) {
  const THREE = require('../../../../externals/three'),
        GLOBAL_UTILS = require('../../../../shared/util/GlobalUtils'),
        TO_TINY_COLOR = require('../../../../shared/util/toTinyColor'),
        RenderingHandlerInterface = require('../../../interfaces/handlers/RenderingHandlerInterface'),
        _settings = ___settings.settings,
        _scene = ___settings.scene,
        _container = ___settings.container,
        _handlers = ___handlers;

  let that,
      _width = _container.offsetWidth,
      _height = _container.offsetHeight,
      _helpers, _renderer, _light, _hiddenRenderTarget;

  ////////////
  ////////////
  //
  // the hooks for the settings go below
  //
  ////////////
  ////////////

  let _clearColorHook = function (color) {
    if (!GLOBAL_UTILS.typeCheck(color, 'color', _handlers.threeDManager.warn, 'RenderingHandler.Hook->clearColor')) return false;

    _renderer.setClearColor(TO_TINY_COLOR(color).toThreeColor());
    that.render();
    return true;
  };

  class RenderingHandler extends RenderingHandlerInterface {

    constructor() {
      super();
      that = this;

      _helpers = new (require('../../../helpers/handlers/RenderingHandlerHelpers'))(_handlers);

      let response = _helpers.createWebGLRenderer(_container, {
        antialias: true, 
        alpha: true,
        preserveDrawingBuffer: true,
      });
      _handlers.threeDManager.success = response.success;

      if(response.success === false) {
        _handlers.threeDManager.warn(response.message);
        _handlers.threeDManager.errorResponse = response;
        return null;
      } else if(response.message !== '') {

        // creation succeeded but there is a message
        // that means we created something, but it might not be whit the desired settings
        _handlers.threeDManager.warn(response.message);
      }

      _renderer = response.renderer;
      _renderer.setPixelRatio(window.devicePixelRatio);
      _renderer.setSize(_width, _height, false /* false: do not update css style of canvas */);
      _renderer.setClearColor(TO_TINY_COLOR(_settings.getSetting('clearColor'), 'white').toThreeColor(), 1);

      _light = new THREE.Object3D();
      _light.add(new THREE.AmbientLight(0xffffff, 1));
      _handlers.threeDManager.helpers.addSceneObject(_light);

      _hiddenRenderTarget = new THREE.WebGLRenderTarget(_width, _height, {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBAFormat,
        stencilBuffer: false
      });
      _hiddenRenderTarget.texture.name = 'hidden.rt';

      _settings.registerHook('clearColor', _clearColorHook);
    }

    _beforeRender() {
      let width, height;

      // Determine if in fullscreen and get current size
      if (document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement || document.mozFullScreenElement) {
        width = screen.width;
        height = screen.height;
      } else {
        width = _container.offsetWidth;
        height = _container.offsetHeight;
      }

      // If the size is different, update everything
      if (width !== _width || height !== _height) {
        _width = width;
        _height = height;
        _renderer.setSize(_width, _height, false /* false: do not update css style of canvas */);
        _handlers.cameraHandler.updateCameraWithSize(_width, _height);
      }
    }

    ////////////
    ////////////
    //
    // RenderingHandler API
    //
    ////////////
    ////////////

    render() {
      if(_renderer && _handlers.threeDManager)
        requestAnimationFrame(that.render);

      // The viewport was just destroyed, this is the last render call
      if(!_handlers.threeDManager) return;
      
      // Don't render when the viewport should not be shown
      if (_handlers.threeDManager.getSetting('show') === false) return;

      // Don't render if there is no camera
      if (!(_handlers.cameraHandler && _handlers.cameraHandler.getCamera())) return;

      that._beforeRender();

      _handlers.threeDManager.helpers.toggleViewport(true);
      _renderer.render(_scene, _handlers.cameraHandler.getCamera());
      _helpers.processAnchors(_renderer, _scene, _handlers.cameraHandler.getCamera(), _handlers.threeDManager.helpers.getAnchors());
      _handlers.threeDManager.helpers.toggleViewport(false);
    }
    
    getScreenshot() {
      return _renderer.domElement.toDataURL('image/png');
    }

    destroy() {
      _renderer.dispose();
      try {
        _renderer.forceContextLoss();
      } catch (e) {
        // this fails only if there is an extension mission
        // in that case we can't do much
      }
    }

    getDomElement() {
      return _renderer.domElement;
    }
    
    renderHidden() {
      // The viewport was just destroyed, this is the last render call
      if(!_handlers.threeDManager) return;

      // Don't render if there is no camera
      if (!(_handlers.cameraHandler && _handlers.cameraHandler.getCamera())) return;

      _handlers.threeDManager.helpers.toggleViewport(true);
      _renderer.render(_scene, _handlers.cameraHandler.getCamera(), _hiddenRenderTarget);
      _handlers.threeDManager.helpers.toggleViewport(false);
    }

    getRendererInfo() {
      return _renderer.info;
    }
  }

  return new RenderingHandler();
};

module.exports = RenderingHandler;
