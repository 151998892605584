let MaterialHandler = function (___settings, ___handlers) {
  const THREE = require('../../../../externals/three'),
        MaterialHandlerInterface = require('../../../interfaces/handlers/MaterialHandlerInterface');

  let that;

  class MaterialHandler extends MaterialHandlerInterface {

    constructor() {
      super();
      that = this;
    }

    ////////////
    ////////////
    //
    // MaterialHandler API
    //
    ////////////
    ////////////

    /** @inheritdoc */
    getMaterial(properties) {
      return new THREE.MeshPhongMaterial(properties);
    }

    /** @inheritdoc */
    getSimpleMaterial(properties) {
      return new THREE.MeshBasicMaterial(properties);
    }
  }

  return new MaterialHandler();
};

module.exports = MaterialHandler;