let MaterialHandler = function (___settings, ___handlers) {
  const THREE = require('../../../../externals/three'),
        MaterialHandlerInterface = require('../../../interfaces/handlers/MaterialHandlerInterface'),
        _handlers = ___handlers;

  let that,
      _helpers;

  class MaterialHandler extends MaterialHandlerInterface {

    constructor() {
      super();
      that = this;

      _helpers = new (require('../../../helpers/handlers/MaterialHandlerHelpers'))(_handlers, {
        nameStandard: 'MeshPhongMaterial',
        nameSimple: 'MeshBasicMaterial'
      });

    }

    ////////////
    ////////////
    //
    // MaterialHandler API
    //
    ////////////
    ////////////

    /** @inheritdoc */
    getMaterial(properties) {
      return new THREE.MeshPhongMaterial(Object.assign({shininess: properties.metalness ? properties.metalness * 128.0 : 0.0}, properties));
    }

    /** @inheritdoc */
    getSimpleMaterial(properties) {
      return new THREE.MeshBasicMaterial(properties);
    }

    compile() {
      _handlers.renderingHandler.renderHidden();
      _helpers.checkMaterials();
    }
  }

  return new MaterialHandler();
};

module.exports = MaterialHandler;