let CameraApi = function (_api, ___refs) {
  const CameraApiInterface = require('../../../interfaces/api/CameraApiInterface');
  
  let _threeDManager = ___refs.threeDManager,
      _apiResponse = ___refs.apiResponse;

  class CameraApi extends CameraApiInterface {
    
    constructor() {
      super();
    }

    /** @inheritdoc */
    get() {
      return _apiResponse(null, function() {
        return {
          position: _threeDManager.cameraHandler.getPosition(),
          target: _threeDManager.cameraHandler.getTarget()
        };
      });
    }
  }
  return new CameraApi();
};

module.exports = CameraApi;