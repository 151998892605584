/**
 * @author Michael Oppitz
 */
let THREE = require('../../../../externals/three');
let Shaders = require('../shaders/ShaderFile');

THREE.ShapeDiverStandardMaterial = function (parameters) {
  THREE.MeshStandardMaterial.call(this);

  this.defines = {
    STANDARD: ''
  };

  if (parameters && parameters.threeDNoiseOpacity > 0) {
    this.defines.USE_3D_NOISE = '';
    if (!parameters.threeDNoiseID)
      parameters.threeDNoiseID = 0;
    if ((0 <= parameters.threeDNoiseID && parameters.threeDNoiseID < 3) || parameters.threeDNoiseID == 999)
      this.defines['USE_3D_NOISE_F' + parameters.threeDNoiseID] = '';
  }

  this.uniforms = THREE.UniformsUtils.merge([
    THREE.UniformsLib.common,
    THREE.UniformsLib.envmap,
    THREE.UniformsLib.aomap,
    THREE.UniformsLib.lightmap,
    THREE.UniformsLib.bumpmap,
    THREE.UniformsLib.normalmap,
    THREE.UniformsLib.displacementmap,
    THREE.UniformsLib.roughnessmap,
    THREE.UniformsLib.metalnessmap,
    THREE.UniformsLib.lights,
    {
      emissive: { value: new THREE.Color(0x000000) },
      roughness: { value: 0.5 },
      metalness: { value: 0 },
      envMapIntensity: { value: 1 }, // temporary
    },
    {
      lightWorldSize: { value: 0.005 },
      lightFrustum: { value: 6.5 }
    },
    {
      shadowOpacity: { value: 1.0 }
    },
    {
      lightReflectivity: { value: 1.0 }
    },
    {
      threeDNoiseOpacity: { value: 0.0 },
      threeDNoiseID: { value: 0 },
      threeDNoiseScale: { value: 1.0 },
      threeDNoiseDistanceFade: { value: 0.0 }
    },
    {
      uvTransformMap: { type: 'm3', value: new THREE.Matrix3().identity() },
      uvTransformBumpMap: { type: 'm3', value: new THREE.Matrix3().identity() },
      uvTransformNormalMap: { type: 'm3', value: new THREE.Matrix3().identity() },
      uvTransformSpecularMap: { type: 'm3', value: new THREE.Matrix3().identity() },
      uvTransformAlphaMap: { type: 'm3', value: new THREE.Matrix3().identity() },
      uvTransformEmissiveMap: { type: 'm3', value: new THREE.Matrix3().identity() },
      uvTransformRoughnessMap: { type: 'm3', value: new THREE.Matrix3().identity() },
      uvTransformMetalnessMap: { type: 'm3', value: new THREE.Matrix3().identity() },
    },
    {
      rgbMap: { type: 't', value: null },
    },
    {
      mapsSize: { type: 'i', value: 0 },
      additionalMaps0: { type: 't', value: null },
      additionalMaps1: { type: 't', value: null },
      additionalMaps2: { type: 't', value: null },
      additionalMaps3: { type: 't', value: null },
      additionalMaps4: { type: 't', value: null },
      mapPropertyType: { type: 'iv1', value: [-1, -1, -1, -1, -1] },
      mapPropertyColor: { type: 'v3v', value: [new THREE.Color(0x000000), new THREE.Color(0x000000), new THREE.Color(0x000000), new THREE.Color(0x000000), new THREE.Color(0x000000)] },
      uvTransformAddMap: { type: 'm3v', value: [new THREE.Matrix3().identity(), new THREE.Matrix3().identity(), new THREE.Matrix3().identity(), new THREE.Matrix3().identity(), new THREE.Matrix3().identity()] },
    }
  ]);
  this.lightWorldSize = this.uniforms.lightWorldSize.value;
  this.lightFrustum = this.uniforms.lightFrustum.value;

  this.shadowOpacity = this.uniforms.shadowOpacity.value;

  this.lightReflectivity = this.uniforms.lightReflectivity.value;

  this.threeDNoiseID = this.uniforms.threeDNoiseID.value;
  this.threeDNoiseScale = this.uniforms.threeDNoiseScale.value;
  this.threeDNoiseDistanceFade = this.uniforms.threeDNoiseDistanceFade.value;
  this.threeDNoiseOpacity = this.uniforms.threeDNoiseOpacity.value;
  this.mapsSize = this.uniforms.mapsSize.value;
  this.rgbMap = this.uniforms.rgbMap.value;

  let identity = new THREE.Matrix3().identity();
  let textureCount = 0;

  if (parameters) {
    if (parameters.map) {
      this.uvTransformMap = parameters.map.uvTransform;
      parameters.map.matrix = identity;
      textureCount++;
    }
    if (parameters.specularMap) {
      this.uvTransformSpecularMap = parameters.specularMap.uvTransform;
      parameters.specularMap.matrix = identity;
      textureCount++;
    }
    if (parameters.normalMap) {
      this.uvTransformNormalMap = parameters.normalMap.uvTransform;
      parameters.normalMap.matrix = identity;
      textureCount++;
    }
    if (parameters.bumpMap) {
      this.uvTransformBumpMap = parameters.bumpMap.uvTransform;
      parameters.bumpMap.matrix = identity;
      textureCount++;
    }
    if (parameters.roughnessMap) {
      this.uvTransformRoughnessMap = parameters.roughnessMap.uvTransform;
      parameters.roughnessMap.matrix = identity;
      textureCount++;
    }
    if (parameters.metalnessMap) {
      this.uvTransformMetalnessMap = parameters.metalnessMap.uvTransform;
      parameters.metalnessMap.matrix = identity;
      textureCount++;
    }
    if (parameters.alphaMap) {
      this.uvTransformAlphaMap = parameters.alphaMap.uvTransform;
      parameters.alphaMap.matrix = identity;
      textureCount++;
    }
    if (parameters.emissiveMap) {
      this.uvTransformEmissiveMap = parameters.emissiveMap.uvTransform;
      parameters.emissiveMap.matrix = identity;
      textureCount++;
    }


    // rgb map setting

    // TODO shader support for rgbMap / aoMap adaption (works right now as usual, but can be optimized)
    if (parameters) {
      /*if( (parameters.aoMap && parameters.roughnessMap && parameters.metalnessMap) && 
          (parameters.aoMap.image === parameters.roughnessMap.image && parameters.roughnessMap.image === parameters.metalnessMap.image ) ) {
        this.rgbMap = parameters.aoMap;
        parameters.aoMap = {uvTransform: parameters.aoMap.uvTransform};
        parameters.roughnessMap = {uvTransform: parameters.roughnessMap.uvTransform};
        parameters.metalnessMap = {uvTransform: parameters.metalnessMap.uvTransform};
        this.defines.USE_RGBMAP = '';
      } else if( (parameters.aoMap && parameters.roughnessMap) && 
                  parameters.aoMap.image === parameters.roughnessMap.image ){
        this.rgbMap = parameters.aoMap;
        parameters.aoMap = {uvTransform: parameters.aoMap.uvTransform};
        parameters.roughnessMap = {uvTransform: parameters.roughnessMap.uvTransform};
        this.defines.USE_RGBMAP = '';
      } else if( (parameters.aoMap && parameters.metalnessMap) && 
                  parameters.aoMap.image === parameters.metalnessMap.image ){
        this.rgbMap = parameters.aoMap;
        parameters.aoMap = {uvTransform: parameters.aoMap.uvTransform};
        parameters.metalnessMap = {uvTransform: parameters.metalnessMap.uvTransform};
        this.defines.USE_RGBMAP = '';
                  } else */
      if ((parameters.roughnessMap && parameters.metalnessMap) &&
        parameters.roughnessMap.image === parameters.metalnessMap.image) {
        this.rgbMap = parameters.roughnessMap;
        parameters.roughnessMap = { uvTransform: parameters.roughnessMap.uvTransform, isTexture: false };
        parameters.metalnessMap = { uvTransform: parameters.metalnessMap.uvTransform, isTexture: false };
        this.defines.USE_RGBMAP = '';
        textureCount--;
      }
    }

    if (parameters && parameters.additionalMaps) {
      this.mapsSize = parameters.additionalMaps.length;

      while (parameters.mapPropertyType.length < 5)
        parameters.mapPropertyType.push(-1);
      while (parameters.mapPropertyColor.length < 5)
        parameters.mapPropertyColor.push(new THREE.Color(0x000000));
      while (parameters.uvTransformAddMap.length < 5)
        parameters.uvTransformAddMap.push(new THREE.Matrix3().identity());

      this.mapPropertyType = parameters.mapPropertyType;
      this.mapPropertyColor = parameters.mapPropertyColor;
      this.uvTransformAddMap = parameters.uvTransformAddMap;

      for (let i = 0, len = parameters.additionalMaps.length; i < len; i++) {
        if (i == 0)
          this.additionalMaps0 = parameters.additionalMaps[i];
        if (i == 1)
          this.additionalMaps1 = parameters.additionalMaps[i];
        if (i == 2)
          this.additionalMaps2 = parameters.additionalMaps[i];
        if (i == 3)
          this.additionalMaps3 = parameters.additionalMaps[i];
        if (i == 4)
          this.additionalMaps4 = parameters.additionalMaps[i];
      }
      delete parameters.additionalMaps;
    }
    textureCount += this.mapsSize;

    if (parameters.envMap)
      textureCount += 6;

    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream && this.mapsSize > 0)
      this.defines.NO_SHADOWS = '';

    // there are more texture than this device can handle
    while (textureCount >= parameters.textureUnitCount) {
      if (parameters.envMap) {
        delete parameters.envMap;
        this.envMap = null;
        textureCount -= 6;
      } else if (parameters.emissiveMap && parameters.emissiveMap.image) {
        delete parameters.emissiveMap;
        this.uvTransformEmissiveMap = this.uniforms.uvTransformEmissiveMap.value;
        textureCount--;
      } else if (parameters.alphaMap && parameters.alphaMap.image) {
        delete parameters.alphaMap;
        this.uvTransformAlphaMap = this.uniforms.uvTransformAlphaMap.value;
        textureCount--;
      } else if (parameters.metalnessMap && parameters.metalnessMap.image) {
        delete parameters.metalnessMap;
        this.uvTransformMetalnessMap = this.uniforms.uvTransformMetalnessMap.value;
        textureCount--;
      } else if (parameters.roughnessMap && parameters.roughnessMap.image) {
        delete parameters.roughnessMap;
        this.uvTransformRoughnessMap = this.uniforms.uvTransformRoughnessMap.value;
        textureCount--;
      } else if (this.rgbMap) {
        this.rgbMap = null;
        delete this.defines.USE_RGBMAP;
        this.uvTransformMetalnessMap = this.uniforms.uvTransformMetalnessMap.value;
        this.uvTransformRoughnessMap = this.uniforms.uvTransformRoughnessMap.value;
        textureCount--;
      } else if (parameters.bumpMap && parameters.bumpMap.image) {
        delete parameters.bumpMap;
        this.uvTransformBumpMap = this.uniforms.uvTransformBumpMap.value;
        textureCount--;
      } else if (parameters.normalMap && parameters.normalMap.image) {
        delete parameters.normalMap;
        this.uvTransformNormalMap = this.uniforms.uvTransformNormalMap.value;
        textureCount--;
      } else if (parameters.specularMap && parameters.specularMap.image) {
        delete parameters.specularMap;
        this.uvTransformSpecularMap = this.uniforms.uvTransformSpecularMap.value;
        textureCount--;
      } else {
        // should never happen, but just to be safe break out
        break;
      }
    }

    // restrict the number of shadow maps
    this.defines.NUM_SHADOW_MAPS = parameters.textureUnitCount - textureCount;

    if (parameters && parameters.lightInfo) {
      let lightInfo = parameters.lightInfo;
      let numShadowMaps = this.defines.NUM_SHADOW_MAPS,
          numDirLights = lightInfo[1],
          numSpotLights = lightInfo[2],
          numPointLights = 0;
      let numDirShadows = numDirLights < numShadowMaps ? numDirLights : numShadowMaps,
          numSpotShadows = (numSpotLights + numDirLights) < numShadowMaps ? numSpotLights : numShadowMaps - numDirLights,
          numPointShadows = (numPointLights + numSpotLights + numDirLights) < numShadowMaps ? numPointLights : numShadowMaps - (numDirLights + numSpotLights);


      this.defines['NUM_DIR_SHADOWS'] = numDirShadows +1;
      this.defines['NUM_SPOT_SHADOWS'] = numSpotShadows +1;
      this.defines['NUM_POINT_SHADOWS'] = numPointShadows +1;
      
      delete parameters.lightInfo;
    } else {
      this.defines['NUM_DIR_SHADOWS'] = 1;
      this.defines['NUM_SPOT_SHADOWS'] = 1;
      this.defines['NUM_POINT_SHADOWS'] = 1;
    }

    delete parameters.textureUnitCount;
    this.renderAO = parameters.renderAO;
  }

  this.vertexShader = Shaders.standard_vert;
  this.fragmentShader = Shaders.standard_frag;
  this.type = 'ShapeDiverStandardMaterial';
  this.softShadow = false;

  this.needsUpdate = true;
  this.setValues(parameters);
};

THREE.ShapeDiverStandardMaterial.prototype = Object.create(THREE.MeshStandardMaterial.prototype);
THREE.ShapeDiverStandardMaterial.prototype.constructor = THREE.ShapeDiverStandardMaterial;
THREE.ShapeDiverStandardMaterial.prototype.isMeshStandardMaterial = true;

THREE.ShapeDiverStandardMaterial.prototype.copy = function (source) {
  THREE.MeshStandardMaterial.prototype.copy.call(this, source);
  this.defines = source.defines;
  this.uniforms = THREE.UniformsUtils.clone(source.uniforms);
  this.type = source.type;
  this.softShadow = source.softShadow;
  this.renderAO = source.renderAO;
  return this;
};

THREE.ShapeDiverStandardMaterial.prototype.deepCopy = function (source) {
  THREE.MeshStandardMaterial.prototype.copy.call(this, source);
  this.defines = Object.assign({}, source.defines);
  this.uniforms = THREE.UniformsUtils.clone(source.uniforms);
  this.type = source.type;
  this.softShadow = source.softShadow;
  this.renderAO = source.renderAO;
  return this;
};
