let CameraHandler = function (___settings, ___handlers) {
  const THREE = require('../../../../externals/three'),
        GLOBAL_UTILS = require('../../../../shared/util/GlobalUtils'),
        CameraHandlerInterface = require('../../../interfaces/handlers/CameraHandlerInterface'),
        _settings = ___settings.settings,
        _container = ___settings.container,
        _handlers = ___handlers;

  let that,
      _camera, _orbitControls;

  class CameraHandler extends CameraHandlerInterface {

    constructor() {
      super();
      that = this;

      _camera = new THREE.PerspectiveCamera(_settings.getSetting('fov'), _container.offsetWidth / _container.offsetHeight, _settings.getSetting('near'), _settings.getSetting('far'));
      _camera.position.set(5, 5, 5);
      _camera.up.set(0, 0, 1);
      _handlers.threeDManager.helpers.addSceneObject(_camera);

      let proxy = new (require('../../../helpers/OrbitControlsProxyStandardImplementation'))(_handlers);
      _orbitControls = new THREE.OrbitControls(_camera, proxy);
      proxy.initialize();

      _orbitControls.target.set(0, 0, 0);
    }

    ////////////
    ////////////
    //
    // CameraHandler API
    //
    ////////////
    ////////////

    getPosition() {
      return GLOBAL_UTILS.deepCopy(_orbitControls.object.position);
    }

    getTarget() {
      return GLOBAL_UTILS.deepCopy(_orbitControls.target);
    }

    getPositionAndTarget() {
      return {
        position: that.getPosition(),
        target: that.getTarget()
      };
    }

    getCamera() {
      return _camera;
    }

    adjustToBoundingSphere(bs) {
      _camera.far = 100.0 * bs.radius;
      _camera.near = 0.01 * bs.radius;
      _camera.updateProjectionMatrix();
    }

    updateCameraWithSize(width, height) {
      _camera.aspect = width / height;
      _camera.setViewOffset(width, height, 0, 0, width, height);
      _camera.updateProjectionMatrix();
    }

  }

  return new CameraHandler();
};

module.exports = CameraHandler;
