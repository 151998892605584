/**
 * @file Helper class for the ViewportApi. Implements the most common methods.
 *
 * @module ViewportApiHelpers
 * @author Michael Oppitz
 */

let ViewportApiHelpers = (function(){

  const THREE = require('../../../externals/three'),
        GLOBAL_UTILS = require('../../../shared/util/GlobalUtils'),
        splitAt = index => x => [x.slice(0, index), x.slice(index + 1)];

  let _instance,
      THREE_D_MANAGER_CONSTANTS, EVENTTYPE,
      _threeDManager, _viewportManager, _apiResponse, _listeners,
      _setRefs,
      _getSettingDefinition,
      _getSettingObject;

  function createInstance() {
    return new ViewportApiHelpers();
  }

  /**
   * @lends module:ViewportApiHelpers~ViewportApiHelpers
   */
  class ViewportApiHelpers {

    /**
     * The constructor of the ViewportApiHelpers.
     *
     * @constructs module:ViewportApiHelpers~ViewportApiHelpers
     */
    constructor(){
      _setRefs = function(refs){
        THREE_D_MANAGER_CONSTANTS = refs.THREE_D_MANAGER_CONSTANTS;
        EVENTTYPE = refs.EVENTTYPE;
        _threeDManager = refs.threeDManager;
        _viewportManager = refs.viewportManager;
        _apiResponse = refs.apiResponse;
        _listeners = refs.listeners;
      };

      _getSettingDefinition = function (settings, defs, keyChain) {
        if (!keyChain)
          keyChain = '';

        if (typeof settings === 'object') {
          for (let key in settings) {
            let s = settings[key];
            if (s.desc) {
              let o = { description: s.desc };
              if (s.type) o.type = s.type;
              let d = GLOBAL_UTILS.deepCopy(o);
              defs[keyChain.split('.value').join('') + key] = d;
            }
            _getSettingDefinition(s, defs, keyChain + key + '.');
          }
        }
      };

      _getSettingObject = function (settings, key) {
        if (key.indexOf('.') !== -1) {
          let currentKey, restKey;
          [currentKey, restKey] = splitAt(key.indexOf('.'))(key);
          let s = settings[currentKey];
          if (!s)
            return;
          if(s.value)
            s = s.value;
          return _getSettingObject(s, restKey);
        } else {
          return settings[key];
        }
      };
    }

    /** @see {@link module:ViewportApiInterface~ViewportApiInterface#getRuntimeId} */
    getRuntimeId(refs) {
      _setRefs(refs);
      return _threeDManager.runtimeId;
    }

    /** @see {@link module:ViewportApiInterface~ViewportApiInterface#convertTo2D} */
    convertTo2D(refs, position) {
      _setRefs(refs);
      let object = new THREE.Object3D(),
          pos = new THREE.Vector3(),
          canvas = _threeDManager.renderingHandler.getDomElement(),
          canvasPageCoordinates = canvas.getBoundingClientRect(),
          width = canvas.width,
          height = canvas.height;

      object.position.set(position.x, position.y, position.z);
      object.updateMatrixWorld();
      pos.setFromMatrixPosition(object.matrixWorld);
      pos.project(_threeDManager.cameraHandler.getCamera());

      pos.x = ( pos.x * (width / 2) ) + (width / 2);
      pos.y = - ( pos.y * (height / 2) ) + (height / 2);

      // take care of correction by device pixel ratio
      pos.xPixel = pos.x / devicePixelRatio;
      pos.yPixel = pos.y / devicePixelRatio;

      return {
        containerX: pos.xPixel,
        containerY: pos.yPixel,
        clientX: pos.xPixel + canvasPageCoordinates.x,
        clientY: pos.yPixel + canvasPageCoordinates.y,
        pageX: pos.xPixel + canvasPageCoordinates.x + window.scrollX,
        pageY: pos.yPixel + canvasPageCoordinates.y + window.scrollY,
      };
    }

    /** @see {@link module:ViewportApiInterface~ViewportApiInterface#addEventListener} */
    addEventListener(refs, type, cb) {
      _setRefs(refs);
      // check if event type is supported
      if ( !Object.keys(EVENTTYPE).find((k)=>(EVENTTYPE[k]===type)) )
        return _apiResponse('Unsupported event type');

      let token = GLOBAL_UTILS.createRandomId();
      _listeners[token] = cb;

      return _apiResponse(null, token);
    }

    /** @see {@link module:ViewportApiInterface~ViewportApiInterface#removeEventListener} */
    removeEventListener(refs, token) {
      _setRefs(refs);
      if(_listeners[token]){
        delete _listeners[token];
        return true;
      }
      return false;
    }

    /** @see {@link module:ViewportApiInterface~ViewportApiInterface#destroy} */
    destroy(refs) {
      _setRefs(refs);
      let type = EVENTTYPE.DESTROYED;
      let result = _viewportManager.removeThreeDManager(_threeDManager.runtimeId);

      for (let key in _listeners){
        let event = new CustomEvent(type);
        _listeners[key](event);
      }
      return result;
    }

    /** @see {@link module:ViewportApiInterface~ViewportApiInterface#reload} */
    reload(refs) {
      _setRefs(refs);
      let result = _viewportManager.reloadThreeDManager(_threeDManager.runtimeId);
      return result;
    }

    /** @see {@link module:ViewportApiInterface~ViewportApiInterface#getSettingDefinitions} */
    getSettingDefinitions(refs) {
      _setRefs(refs);
      let defs = {};
      _getSettingDefinition(THREE_D_MANAGER_CONSTANTS.settingsDefinition, defs, '');
      return defs;
    }

    /** @see {@link module:ViewportApiInterface~ViewportApiInterface#getSettings} */
    getSettings(refs, keys) {
      _setRefs(refs);
      if (!Array.isArray(keys))
        keys = Object.keys(this.getSettingDefinitions(refs));
      let settings = {};
      keys.forEach((k) => {
        if (_threeDManager.hasSetting(k)) {
          let v = _threeDManager.getSetting(k);
          GLOBAL_UTILS.forceAtPath(settings, k, v);
        }
      });
      return settings;
    }

    /** @see {@link module:ViewportApiInterface~ViewportApiInterface#getSetting} */
    getSetting(refs, k) {
      _setRefs(refs);
      if (!_threeDManager.hasSetting(k)) return;
      return _threeDManager.getSetting(k);
    }

    /** @see {@link module:ViewportApiInterface~ViewportApiInterface#updateSettingAsync} */
    updateSettingAsync(refs, k, val) {
      _setRefs(refs);
      let m = _getSettingObject(THREE_D_MANAGER_CONSTANTS.settingsDefinition, k);
      if (m === undefined) return Promise.resolve(_apiResponse('Setting does not exist', false));

      // type checking
      if (m.type !== undefined && m.type !== null) {
        if (GLOBAL_UTILS.typeCheck(m.type, 'string')) {
          if (GLOBAL_UTILS.typeCheck(val, 'm.type')) {
            return Promise.resolve(_apiResponse('Setting has wrong value type', false));
          }
        }
        else if (typeof m.type === 'function') {
          if (!m.type(val)) {
            return Promise.resolve(_apiResponse('Setting has wrong value type', false));
          }
        }
      }
      // transformation
      if (m.hasOwnProperty('transform') && typeof m.transform === 'function') {
        val = m.transform(val);
      }
      // update setting
      return _threeDManager.updateSettingAsync(k, val).then(
        (r) => {
          if (!r) {
            return Promise.resolve(_apiResponse('Update of setting failed', false));
          }
          else {
            return Promise.resolve(_apiResponse(null, true));
          }
        },
        () => {
          return Promise.resolve(_apiResponse('Update of setting failed', false));
        }
      );
    }

    /** @see {@link module:ViewportApiInterface~ViewportApiInterface#updateSettingsAsync} */
    updateSettingsAsync(refs, settings) {
      _setRefs(refs);
      // get paths of settings object
      let paths = [];
      GLOBAL_UTILS.getPaths(settings, paths);

      // create an empty object which will hold the results
      let results = {};

      // create an empty promise to attach further ones to
      let promiseChain = Promise.resolve();
      for (let path of paths) {
        // attach promise for updating the setting at path
        promiseChain = promiseChain.then(function () {
          return this.updateSettingAsync(refs, path, GLOBAL_UTILS.getAtPath(settings, path));
        });
        // attach promise for storing the result of the setting update
        promiseChain = promiseChain.then(
          function (r) {
            if (r.err) {
              GLOBAL_UTILS.forceAtPath(results, path, false);
            } else {
              GLOBAL_UTILS.forceAtPath(results, path, true);
            }
          },
          function () {
            GLOBAL_UTILS.forceAtPath(results, path, false);
          }
        );
      }

      // add final result to promise chain
      return promiseChain.then(
        function () {
          return _apiResponse(null, results);
        }
      );
    }

  }

  return {
    getInstance: function () {
      if (!_instance)
        _instance = createInstance();
      return _instance;
    }
  };
})();

module.exports = ViewportApiHelpers;
