/**
 * @file Creates a shared interface for all CameraHandlers.
 *       The CameraHandlerInterface must be implemented by all CameraHandlers.
 *       All described methods must be implemented.
 * 
 * @module CameraHandlerInterface
 * @author Michael Oppitz
 */

/**
 * Interface for the CameraHandler.
 * The CameraHandler implements all functionality related to the camera.
 * 
 * //@interface CameraHandlerInterface
 */
class CameraHandlerInterface {
  /**
   * The constructor for the {@link module:CameraHandlerInterface~CameraHandlerInterface}.
   * @constructs module:CameraHandlerInterface~CameraHandlerInterface
   */
  constructor() { }

  /**
   * Returns the camera position.
   *
   * @abstract
   * @returns {Object} The camera position
   */
  getPosition() { }

  /**
   * Returns the camera target.
   * 
   * @abstract
   * @returns {Object} The camera target
   */
  getTarget() { }

  /**
   * Returns the camera position and target.
   *
   * @abstract
   * @returns {Object} The camera position and target
   */
  getPositionAndTarget() { }
}

module.exports = CameraHandlerInterface;