/**
 * @file The LightApi is a property of the ViewportApi and must be used by all corresponding ViewportApis.
 *       It is used for all light related functionality.
 *
 * @module LightApiDefault
 * @author Michael Oppitz
 */
let LightApi = function (_api, ___refs) {

  const GLOBAL_UTILS = require('../../../../shared/util/GlobalUtils'),
        MIN_LIGHT_ID = 1,
        MAX_LIGHT_ID = 9;


  let _threeDManager = ___refs.threeDManager,
      _apiResponse = ___refs.apiResponse,
      _getLights, _lightIdValid, _getEmptyLightId;

  /**
   * @lends module:LightApiDefault~LightApi
   */
  class LightApi {

    /**
     * Available light scenes, see {@link module:LightApiDefault~LightApi#LIGHTSCENE} for values
     *
     * @typedef {Integer} module:LightApiDefault~LightApi#LightSceneType
     */

    /**
     * The light type, see {@link module:LightApiDefault~LightApi#TYPE} for values
     *
     * @typedef {Integer} module:LightApiDefault~LightApi#LightType
     */

    /**
     * Light definition
     *
     * @typedef {Object} module:LightApiDefault~LightApi#LightDefinition
     * @property {Number} [id] - Unique id of the light object. When adding a new light, do not set this property.
     * @property {module:LightApiDefault~LightApi#LightType} [type=module:LightApiDefault~LightApi#TYPE.DIRECTIONAL] - Type of light. Default is a directional light.
     * @property {module:ApiInterfaceV2~Color} [color='white'] - [r,g,b,a] array of numbers between 0-255, alpha value will be ignored, defaults to white.
     * @property {Number} [intensity=1.0] - Intensity of the light between 0 and Infinity. This {@link https://threejs.org/docs/#api/lights/SpotLight example} explains the influence of this parameter.
     * @property {Boolean} [shadows=false] - If set to true light will cast dynamic shadows. Currently this is supported for directional lights only.
     * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [position] - For spotlights, position of the light. Defaults to target - direction.
     * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [target] - For spotlights, target of the light. Defaults to center of scene bounding box.
     * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [direction={x:1,y:-1,z:1}] - For directional lights, direction of the light.
     * @property {Number} [distance=0] - For spotlights, if non-zero, light will attenuate from maximum intensity at the light's position down to zero at this distance from the light. When set to 0, then the light intensity does not decrease with the distance.
     * @property {Number} [angle=Math.PI/3] - For spotlights, maximum extent of the spotlight, in radians, from its direction. Should be no more than Math.PI/2.
     * @property {Number} [penumbra=0] - For spotlights, percent of the spotlight cone that is attenuated due to penumbra. Takes values between zero and 1.
     * @property {Number} [decay=1] - For spotlights, the amount the light dims along the distance of the light. For physically correct lighting, set this to 2.
     */

    /**
     * ### ShapeDiver Viewer - Light API
     *
     * @constructs module:LightApiDefault~LightApi
     */
    constructor() {

      /**
       * Enum for light types.
       * @readonly
       * @enum {module:LightApiDefault~LightApi#LightType}
       */
      this.TYPE = {
        /** ambient light */
        AMBIENT: 0,
        /** directional light */
        DIRECTIONAL: 1,
        /** spot light, potentially falling off */
        SPOT: 2
      };

      /**
       * Enum for default light scenes.
       * @readonly
       * @enum {module:LightApiDefault~LightApi#LightSceneType}
       */
      this.LIGHTSCENE = {
        /** default light scene */
        DEFAULT: 'default',
        /** default legacy light scene */
        DEFAULT_LEGACY: 'default_legacy',
        /** test light scene */
        TEST: 'test',
      };

      _getLights = function() {
        let lights = [];
        for (let i=MIN_LIGHT_ID, imax=MAX_LIGHT_ID; i<=imax; i++) {
          let light = _threeDManager.getSetting('lights.light'+i);
          if (light && light.hasOwnProperty('type') && light.hasOwnProperty('properties')) {
            light.properties.id = i;
            light.properties.type = light.type;
            lights.push(light.properties);
          }
        }
        return lights;
      };

      _lightIdValid = function(id) {
        if ( !GLOBAL_UTILS.typeCheck(id, 'number') || id < MIN_LIGHT_ID || id > MAX_LIGHT_ID )
          return false;
        return true;
      };

      _getEmptyLightId = function () {
        for (let i=MIN_LIGHT_ID, imax = MAX_LIGHT_ID; i <= imax; i++) {
          let light = _threeDManager.getSetting('lights.light' + i);
          if (light && !isNaN(light.type) && light.properties) {
            continue;
          }
          return i;
        }
        return -1;
      };
    }

    /**
     * Get light definitions
     *
     * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a data array of {@link module:LightApiDefault~LightApi#LightDefinition LightDefinition} objects. All lights will have the id property set.
     */
    get() {
      return _apiResponse(null, _getLights());
    }

    /**
     * Add new lights or update existing ones.
     *
     * Specify the id property of the {@link module:LightApiDefault~LightApi#LightDefinition light definition} to update an existing light.
     * Do not specify the id property of the {@link module:LightApiDefault~LightApi#LightDefinition light definition} to add a new light.
     *
     * @param {module:LightApiDefault~LightApi#LightDefinition[]} lights - Light definitions to be added (id property not set) or updated (id property set)
     * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a data array of {@link module:LightApiDefault~LightApi#LightDefinition LightDefinition} objects currently in the scene. All lights will have the id property set.
     */
    update(lights) {
      lights = lights || [];
      if (!Array.isArray(lights)) lights = [lights];
      for (let light of lights) {

        if (!GLOBAL_UTILS.typeCheck(light, 'object'))
          return _apiResponse('Invalid light definition');

        if (!Object.keys(this.TYPE).find((k) => (this.TYPE[k] === light.type)))
          return _apiResponse('Unknown light type' + light.type);
        light = GLOBAL_UTILS.deepCopy(light);
        let type = light.type; delete light.type;

        let id;
        if (light.hasOwnProperty('id')) {
          if (!_lightIdValid(light.id))
            return _apiResponse('Invalid light id' + light.id);
          id = light.id; delete light.id;
        }
        else {
          // find empty id
          id = _getEmptyLightId();
          if (!_lightIdValid(id)) {
            return _apiResponse('All available light ids used');
          }
        }

        if (!_threeDManager.updateSetting('lights.light' + id, { type: type, properties: light })) {
          return _apiResponse('Error ', _getLights());
        }
      }
      return this.get();
    }

    /**
     * Remove a light
     *
     * @param {String} id - Id of the light to remove.
     * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a data array of {@link module:LightApiDefault~LightApi#LightDefinition LightDefinition} objects still in the scene. All lights will have the id property set.
     */
    remove(id) {
      if (!_lightIdValid(id))
        return _apiResponse('Invalid light id');
      let res = _threeDManager.updateSetting('lights.light' + id, {});
      if (!res)
        return _apiResponse('Error removing light ' + id);
      return this.get();
    }

    /**
     * Restore a predefined light scene (a default configuration of lights).
     *
     * This removes all currently configured lights, and restores one of the predefined default light scenes.
     *
     * @param {module:LightApiDefault~LightApi#LightSceneType} name - Name of light scene to restore.
     * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a data array of {@link module:LightApiDefault~LightApi#LightDefinition LightDefinition} objects currently in the scene. All lights will have the id property set.
     */
    restore(name) {
      if (!Object.keys(this.LIGHTSCENE).find((k) => (this.LIGHTSCENE[k] === name)))
        return _apiResponse('Unknown light scene');
      let res = _threeDManager.lightHandler.restoreDefaultConfiguration(name);
      if (!res)
        return _apiResponse('Error restoring light scene');
      return this.get();
    }

  }
  return new LightApi();
};

module.exports = LightApi;
