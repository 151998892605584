
let OrbitControlsProxyExpoImplementation = function (___handlers) {
  const _handlers = ___handlers,
        OrbitControlsProxyInterface = require('../../../interfaces/OrbitControlsProxyInterface');

  let that;

  class OrbitControlsProxyExpoImplementation extends OrbitControlsProxyInterface {

    constructor() {
      super();
      that = this;
    }

    setClientSize(width, height) {
      this.width = width;
      this.height = height;
    }

    getClientSize() {
      return {
        width: this.width, //_handlers.renderingHandler.getClientSize().width,
        height: this.height, //_handlers.renderingHandler.getClientSize().height,
      };
    }

    onStateChange() {

    }

    onStateChangeNone() {

    }

    dispose() {
      
    }

    initialize() {
      
    }

    onTouchesBegan(event) {
      that.onTouchStart(event);
    }

    onTouchesMoved(event) {
      that.onTouchMove(event);
    }

    onTouchesEnded(event) {
      that.onTouchEnd(event);
    }

  }

  return new OrbitControlsProxyExpoImplementation();
};

module.exports = OrbitControlsProxyExpoImplementation;
