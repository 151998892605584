/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ViewerAppLoggingPartial.js*
 *
 * ### Content
 *   * Logging functionality of ViewerApp
 *
 * @module ViewerAppLoggingPartial
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
  * Import constants
  */
var viewerAppConstants = require('../ViewerAppConstants');

/**
  * Constructor of the ViewerAppLoggingPartial mixin
  * @mixin ViewerAppLoggingPartial
  * @author Alex Schiftner <alex@shapediver.com>
  */
var ViewerAppLoggingPartial = function() {

  var that = this;

  // use pub/sub communication for logging
  var _pubSubLogging = require('pubsub-js');

  /**
    * Hook into the log stream up to (or for) a certain logging level,
    * optionally limited to certain scopes
    * The convention for logging scopes is CLASSNAME.MEMBERNAME
    * @public
    * @param {module:LoggingConstants~LoggingLevel|module:LoggingConstants~LoggingLevel[]} loggingLevel - level up to which to subscribe to, or array of levels to subscribe to
    * @param {String|String[]} scope - single or several logging scopes to subscribe to, string may be empty to catch everything
    * @param {Function} callback - will be invoked with (loggingLevel, scope, payload)
    * @return {Object[]} Array of subscription tokens, to be used for unsubscribing
    */
  this.subscribeToLogStream = function(loggingLevel, scope, callback) {
    // subscribe to all given logging levels (array), or up to the given level
    if ( !Array.isArray(loggingLevel) ) {
      let ll = [];
      for ( let i=0; i<=loggingLevel; i++ )
        ll.push(i);
      loggingLevel = ll;
    }
    // subscribe to all given scopes
    if ( !Array.isArray(scope) )
      scope = [scope];
    // create the subscriptions
    let tokens = [];
    loggingLevel.forEach( function(ll) {
      scope.forEach( function(t) {
        let tt = ll.toString();
        if ( t.length > 0 )
          tt += '.' + t;
        tokens.push( _pubSubLogging.subscribe(tt, callback) );
      });
    });
    return tokens;
  };

  /**
    * Unsubscribe from log stream using tokens of previously generated subscriptions
    * @public
    * @param {Object[]} tokens - subscription tokens to unsubscribe from
    */
  this.unsubscribeFromLogStream = function(tokens) {
    tokens.forEach( function(t) {
      _pubSubLogging.unsubscribe(t);
    });
  };

  /**
    * Local function for logging to console
    * Makes use of logToConsole defined by LoggingMixin
    * @private
    * @param {String} topic - topic received from pub/sub
    * @param {Object[]} data - payload received from pub/sub
    */
  var _logToConsole = function(topic, data) {
    // get logging level from topic
    let loggingLevel = Number(topic.slice(0,1));
    let scope = topic.slice(2);

    // make use of LoggingMixin.logToConsole
    that.logToConsole(loggingLevel, scope, ...data);
  };

  // subscribe ourselves to everything and log to console according to loggingLevel
  var _pubSubLoggingSubscriptionTokens = this.subscribeToLogStream(that.getSetting('loggingLevel'), '', _logToConsole);

  /**
    * Replace log function defined by logging prototype, and send log messages to pub/sub
    * @public
    * @param {module:LoggingConstants~LoggingLevel} loggingLevel - logging level to be used
    * @param {String} scope - scope to be used for the log message, the convention is CLASSNAME.MEMBERNAME
    * @param {...Object} msg - arbitrary log message object
    */
  this.log = function() {
    // divide arguments into loggingLevel, scope, and msgs
    var args = (arguments.length === 1 ? [arguments[0]] : Array.apply(null, arguments));
    var loggingLevel = args[0];
    var scope = args[1];
    var msgs = args.slice(2);

    // concatenate logging level and scope
    var t = loggingLevel.toString();
    if ( typeof scope === 'string' && scope.length > 0 )
      t += '.' + scope;

    // publish log message
    _pubSubLogging.publish(t, [...msgs]);
  };

  /**
    * Hook for updateSetting of loggingLevel,
    * which allows us to change pub/sub subscription accordingly.
    * We register this hook using {@link module:SettingsMixin~SettingsMixin#registerHook}
    *
    * @private
    * @see {@link module:SettingsMixin~SettingsMixin#registerHook}
    * @see {@link module:SettingsMixin~SettingsMixin#updateSetting}
    * @param {module:LoggingConstants~LoggingLevel} loggingLevel - new logging level to be used
    */
  var _loggingLevelUpdateHook = function(loggingLevel) {
    // renew subscriptions using new loggingLevel
    that.unsubscribeFromLogStream( _pubSubLoggingSubscriptionTokens );
    _pubSubLoggingSubscriptionTokens = that.subscribeToLogStream(loggingLevel, '', _logToConsole);
    that.log(viewerAppConstants.loggingLevels.DEBUG, 'ViewerApp.loggingLevelUpdateHook', 'loggingLevel set from ' + that.getLoggingLevelName(that.getSetting('loggingLevel')) + ' to ' + that.getLoggingLevelName(loggingLevel));
    // return true to allow change of setting
    return true;
  };

  /**
    * Install the hook
    */
  this.registerHook('loggingLevel',_loggingLevelUpdateHook);

};

module.exports = ViewerAppLoggingPartial;
