let CameraHandler = function (___settings, ___handlers) {
  const THREE = require('../../../../externals/three'),
        GLOBAL_UTILS = require('../../../../shared/util/GlobalUtils'),
        CameraHandlerInterface = require('../../../interfaces/handlers/CameraHandlerInterface'),
        _settings = ___settings.settings,
        _container = ___settings.container,
        _handlers = ___handlers;

        
  let that,
      _camera,
      _orbitControls;

  class CameraHandler extends CameraHandlerInterface {

    constructor() {
      super();
      that = this;

      this.arEnabled = false;

      if (_camera!=null) {
        console.log('camera already exists - removing');
        _handlers.threeDManager.helpers.removeSceneObject(_camera);
      }

      // when no perspective
      if (___settings.perspectiveCameraFactory==null) {
        // camera creation code copied from default CameraHandler
        _camera = new THREE.PerspectiveCamera(_settings.getSetting('fov'), _container.offsetWidth / _container.offsetHeight, _settings.getSetting('near'), _settings.getSetting('far'));
        
      } else {
        _camera = ___settings.perspectiveCameraFactory(
          _container.width,
          _container.height,
          _settings
        );
        if (_camera.constructor.name==='Camera') this.arEnabled = true;
        _handlers.threeDManager.helpers.addSceneObject(_camera);
      }

      let data={};

      if (!this.arEnabled) {
        _camera.up.set(0, 0, 1);
        let p = _settings.getSetting('defaults.perspective.position');

        if (p!=undefined)
          _camera.position.set(p.x, p.y, p.z);


        let _proxy = new (require('../helpers/OrbitControlsProxyExpoImplementation'))(_handlers);
        _orbitControls = new THREE.OrbitControls(_camera, _proxy);
        _proxy.initialize();
        _orbitControls.target.set(0, 0, 0);

        console.log('proxy: ' + JSON.stringify(_proxy));
        data.orbitControlsProxy = _proxy;

      }

      data.camera = _camera;
      ___settings.additionalDataHandler(data); // tell ShapeDiverView about stuff

    }

    ////////////
    ////////////
    //
    // CameraHandler API
    //
    ////////////
    ////////////

    getPosition() {
      if (this.arEnabled)
        return GLOBAL_UTILS.deepCopy(_camera.position);
      else
        return GLOBAL_UTILS.deepCopy(_orbitControls.object.position);

    }

    getTarget() {
      return GLOBAL_UTILS.deepCopy(_orbitControls.target);
    }

    getPositionAndTarget() {
      return {
        position: that.getPosition(),
        target: that.getTarget()
      };
    }

    getCamera() {
      return _camera;
    }

    adjustToBoundingSphere(bs) {
      if (!this.arEnabled) {
        _camera.far = 100.0 * bs.radius;
        _camera.near = 0.01 * bs.radius;
        _camera.updateProjectionMatrix();
      }
    }

    updateCameraWithSize(width, height) {
      if (!this.arEnabled) {
        _camera.aspect = width / height;
        _camera.setViewOffset(width, height, 0, 0, width, height);
        _camera.updateProjectionMatrix();
      }
    }

  }

  return new CameraHandler();
};

module.exports = CameraHandler;
