let DomElementManager = function (api) {

  const API = api,
        TO_TINY_COLOR = require('../../shared/util/toTinyColor');

  let _domElements = {},
      types = {},
      addDomElement,
      removeDomElement;

  let _addText, _updateText, _removeText,
      _addImage, _updateImage, _removeImage;

  API.scene.addEventListener(API.scene.EVENTTYPE.ANCHOR_ADD, (evt) => {
    //console.log(evt.type, evt);
    if(!evt.anchors) return;

    for(let i = 0, len = evt.anchors.length; i < len; i++) {
      if(!addDomElement(evt.anchors[i])) {
        // error
      }
    }

  });

  API.scene.addEventListener(API.scene.EVENTTYPE.ANCHOR_REMOVE, (evt) => {
    if(!evt.anchors) return;

    for(let i = 0, len = evt.anchors.length; i < len; i++) {
      if(!removeDomElement(evt.anchors[i])) {
        // error
      }
    }

  });

  addDomElement = function(anchor) {
    if(_domElements[anchor.runtimeId] || !anchor.format) return null;

    let domEl = document.getElementById(anchor.viewport + '-shapediver-domElements');
    if(!domEl) {
      domEl = document.createElement('div');
      domEl.id = anchor.viewport + '-shapediver-domElements';
      domEl.classList.add('shapediver-domElements');
      API.viewports.get(anchor.viewport).getContainer().appendChild(domEl);
    }

    let obj = types[anchor.format];
    let domElement = obj.add(domEl, anchor);
    _domElements[anchor.runtimeId] = domElement;
    anchor.updateFunction(obj.update);
    return domElement;
  };

  removeDomElement = function(anchor) {
    if(!_domElements[anchor.runtimeId] || !anchor.format) return null;
    let obj = types[anchor.format];
    obj.remove(anchor);
  };

  _addText = function(domEl, anchor) {
    let data = anchor.data,
        color = TO_TINY_COLOR(data.color, 'black'),
        el, span, textAlign;
    
    el = document.createElement('div');
    el.type = 'text/css';
    el.style.display = 'none';
    el.classList.add('shapediver-domElement');
    domEl.appendChild(el);

    span = document.createElement('span');
    span.style.color = color.toHexString();
    span.style.opacity = color.getAlpha();
    span.innerHTML = data.text;
    span.classList.add('shapediver-domElement-span');
    el.appendChild(span);

    if(data.textAlign)
      textAlign = data.textAlign;

    if(textAlign === 'right' || textAlign === 'center') {
      span.style.textAlign = textAlign;
    } else {
      span.style.textAlign = 'left';
    }

    return {
      domElement: el,
      api: API.viewports.get(anchor.viewport)
    };
  };

  _updateText = function(anchor) {
    let el = _domElements[anchor.runtimeId].domElement,
        data = anchor.data;

    if(anchor.hidden && data.hidden !== false) {
      el.style.display = 'none';
    } else {
      el.style.display = '';
      let x, y, hor, ver;

      if(data.positioning) {
        hor = data.positioning.horizontal;
        ver = data.positioning.vertical;
      }

     
      if(hor === 'right') {
        x = anchor.containerX - el.offsetWidth;
      } else if (hor === 'center') {
        x = anchor.containerX - el.offsetWidth / 2;
      } else {
        x = anchor.containerX;
      }
      
      if(ver === 'bottom') {
        y = anchor.containerY - el.offsetHeight;
      } else if (ver === 'center') {
        y = anchor.containerY - el.offsetHeight / 2;
      } else {
        y = anchor.containerY;
      }

      el.style.left = x + 'px';
      el.style.top = y + 'px';
    }
  };

  _removeText = function(anchor) {
    let el = _domElements[anchor.runtimeId].domElement;
    el.style.display = 'none';
    document.getElementById(_domElements[anchor.runtimeId].api.getRuntimeId() + '-shapediver-domElements').removeChild(el);
  };

  _addImage = function(domEl, anchor) {
    let data = anchor.data,
        el, img;

    el = document.createElement('div');
    el.type = 'text/css';
    el.style.display = 'none';
    el.classList.add('shapediver-domElement');
    domEl.appendChild(el);

    img = document.createElement('img');
    el.appendChild(img);
    img.src = data.src;
    if(data.height) img.height = data.height;
    if(data.width) img.width = data.width;
    if(data.alt) img.alt = data.alt;

    return {
      domElement: el,
      api: API.viewports.get(anchor.viewport)
    };
  };

  _updateImage = function(anchor) {
    let el = _domElements[anchor.runtimeId].domElement,
        data = anchor.data;

    if(anchor.hidden && data.hidden !== false) {
      el.style.display = 'none';
    } else {
      el.style.display = '';
      let x, y, hor, ver;

      if(data.positioning) {
        hor = data.positioning.horizontal;
        ver = data.positioning.vertical;
      }
     
      if(hor === 'right') {
        x = anchor.containerX - el.offsetWidth;
      } else if (hor === 'center') {
        x = anchor.containerX - el.offsetWidth / 2;
      } else {
        x = anchor.containerX;
      }
      
      if(ver === 'bottom') {
        y = anchor.containerY - el.offsetHeight;
      } else if (ver === 'center') {
        y = anchor.containerY - el.offsetHeight / 2;
      } else {
        y = anchor.containerY;
      }

      el.style.left = x + 'px';
      el.style.top = y + 'px';
    }
  };

  _removeImage = function(anchor) {
    let el = _domElements[anchor.runtimeId].domElement;
    el.style.display = 'none';
    document.getElementById(_domElements[anchor.runtimeId].api.getRuntimeId() + '-shapediver-domElements').removeChild(el);
  };


  types = {
    'tag2d': {
      add: _addText,
      remove: _removeText,
      update: _updateText
    },
    'text': {
      add: _addText,
      remove: _removeText,
      update: _updateText
    },
    'image': {
      add: _addImage,
      remove: _removeImage,
      update: _updateImage
    }
  };

  return;
};

module.exports = DomElementManager;
