//let THREE = require('../externals/three');
//let TWEEN = require('@tweenjs/tween.js');
//require('./materials/Reflector');
//let GlobalUtils = require('../shared/util/GlobalUtils');
//let threeDManagerConstants = require('./ThreeDManagerConstants');

let SceneGeometryManager = (function () {
  let _instance, that, _viewportManager,
      _scene, _geometryNode, 
      _meshMaterialObjects = [],
      _anchorObjects = [];

  const THREE = require('../externals/three'),
        GLOBAL_UTILS = require('../shared/util/GlobalUtils'),
        PATH_UTILS = require('./PathUtils'),
        GEOMETRY_ADD_ID = 'geomAddEvent',
        GEOMETRY_REMOVE_ID = 'geomRemoveEvent',
        GEOMETRY_REPLACE_ID = 'geomReplaceEvent';
    
  require('../3d/controls/OrbitControls');

  function createInstance(viewportManager, ___settings) {
    return new SceneGeometryManager(viewportManager, ___settings);
  }

  class SceneGeometryManager {
    constructor(viewportManager, ___settings) {
      that = this;
      _viewportManager = viewportManager;

      // activate THREE caching across all loaders
      THREE.Cache.enabled = true;

      /**
       * The actual scene that will be rendered.
       * Stores the camera, lights and all objects in the scene.
       */
      _scene = new THREE.Scene();
      _geometryNode = new THREE.Object3D();
      _geometryNode.computeSceneBoundingBox = function() {
        let b = new THREE.Box3();
        this.traverseVisible(function(obj) {
          if (obj.hasOwnProperty('SDBoundingBox')) 
            b.union(obj.SDBoundingBox);
        });

        if(b.min.distanceTo(b.max) == 0) 
          b = new THREE.Box3(new THREE.Vector3(-1,-1,-1),new THREE.Vector3(1,1,1));
        
        return b;
      };

      _geometryNode.computeBoundingBox = function(scenePaths) {
        let b = new THREE.Box3();

        if(!Array.isArray(scenePaths) || scenePaths.length === 0)
          return this.computeSceneBoundingBox();

        for (let i = 0, len = scenePaths.length; i < len; i++) {
          let obj3D = PATH_UTILS.getPathObject(this, scenePaths[i] + '');
          if(obj3D)
            b.union(new THREE.Box3().setFromObject(obj3D));
        }
        if(b.min.distanceTo(b.max) == 0) 
          b = this.computeSceneBoundingBox();
        
        return b;
      };


      _scene.add(_geometryNode);
      PATH_UTILS.setScene(_geometryNode);

      ////////////
      ////////////
      //
      // Global prototype
      //
      ////////////
      ////////////

      require('../shared/mixins/GlobalMixin').call(that);

      ////////////
      ////////////
      //
      // Logging
      //
      // register standard logging functions,
      // and replace default logging to console by pub/sub scheme
      //
      ////////////
      ////////////

      // mix in global logging functions, or use the ones we got
      if (___settings && ___settings.loggingHandler) {
        GLOBAL_UTILS.inject(___settings.loggingHandler, that);
      } else {
        require('../shared/mixins/LoggingMixin').call(that);
      }

      ////////////
      ////////////
      //
      // Settings
      //
      ////////////
      ////////////

      require('../shared/mixins/SettingsMixin').call(that, ___settings.settings, {});

      ////////////
      ////////////
      //
      // General messaging via pub/sub
      //
      ////////////
      ////////////

      if (___settings && ___settings.messagingHandler) {
        GLOBAL_UTILS.inject(___settings.messagingHandler, that);
      }

    }

    _getMaterials(properties, overrideColor, vertexColors, hasTextureCoordinates) {
      let materials = _viewportManager.getMaterial(properties, overrideColor, vertexColors);
      if (materials.includes(null) || materials.includes(undefined))
        that.warn('The creation of a material for at least one viewport failed.');

      if (!hasTextureCoordinates) {
        for (let i = 0, len = materials.length; i < len; i++) {
          materials[i].map = null;
          materials[i].alphaMap = null;
          materials[i].aoMap = null;
          materials[i].bumpMap = null;
          materials[i].displacementMap = null;
          materials[i].emissiveMap = null;
          materials[i].normalMap = null;
          materials[i].metalnessMap = null;
          materials[i].roughnessMap = null;
        }
      }
      return materials;
    }

    _addMesh(geometryObject, properties, hasTextureCoordinates, overrideColor, vertexColors) {

      if(geometryObject.type === 'Object3D' ) {
        /**
         * Back side first
         */

        let materials = that._getMaterials(properties, overrideColor, vertexColors, hasTextureCoordinates);
        for (let i = 0, len = materials.length; i < len; i++) {
          materials[i].side = THREE.BackSide;
          materials[i].renderAO = false;
        }

        geometryObject.children[0].castShadow = true;
        if(properties.opacity && properties.opacity > 0.5) geometryObject.children[0].receiveShadow = true;

        _viewportManager.addMesh(geometryObject.children[0], materials, properties);
        _meshMaterialObjects.push({
          mesh: geometryObject.children[0],
          properties: properties,
          hasTextureCoordinates: hasTextureCoordinates, 
          overrideColor: overrideColor, 
          vertexColors: vertexColors,
        });

        /**
         * Front side after
         */

        materials = that._getMaterials(properties, overrideColor, vertexColors, hasTextureCoordinates);

        for (let i = 0, len = materials.length; i < len; i++) {
          materials[i].side = THREE.FrontSide;
          materials[i].renderAO = false;
        }

        geometryObject.children[1].castShadow = true;
        if(properties.opacity && properties.opacity > 0.5) geometryObject.children[1].receiveShadow = true;

        _viewportManager.addMesh(geometryObject.children[1], materials, properties);
        _meshMaterialObjects.push({
          mesh: geometryObject.children[1],
          properties: properties,
          hasTextureCoordinates: hasTextureCoordinates, 
          overrideColor: overrideColor, 
          vertexColors: vertexColors,
        });

      } else {
        let materials = that._getMaterials(properties, overrideColor, vertexColors, hasTextureCoordinates);

        geometryObject.castShadow = true;
        geometryObject.receiveShadow = true;
        _viewportManager.addMesh(geometryObject, materials, properties);
        _meshMaterialObjects.push({
          mesh: geometryObject,
          properties: properties,
          hasTextureCoordinates: hasTextureCoordinates, 
          overrideColor: overrideColor, 
          vertexColors: vertexColors,
        });
      }
    }

    _removeMesh(mesh) {
      _viewportManager.removeMesh(mesh);

      for(let i = 0, len = _meshMaterialObjects.length; i < len; i++)
        if(_meshMaterialObjects[i].mesh === mesh){
          _meshMaterialObjects.splice(i, 1);
          return;
        }
    }

    _addAnchor(geometryObject, properties) {
      _anchorObjects.push({
        object: geometryObject,
        properties: properties
      });

      _viewportManager.addAnchor(geometryObject, properties);
    }

    _removeAnchor(object) {
      _viewportManager.removeAnchor(object);
      for(let i = 0, len = _anchorObjects.length; i < len; i++)
        if(_anchorObjects[i].object === object){
          _anchorObjects.splice(i, 1);
          return;
        }
    }

    getScene() {
      return _scene;
    }

    getGeometryNode() {
      return _geometryNode;
    }

    getMeshMaterialObjects() {
      return _meshMaterialObjects;
    }

    /**
     * Adds new geometry to the scene
     * @param  {String} path - Path in dot-separated format describing the global path under which the geometry is to be added
     * @param  {module:OutputVersion~GeometryData[]} geometry - Geometry data objects with relative path, geometry and material
     * @param {Object} [options] - Execution options
     * @param {Number} [options.duration] - Duration of fadein in msec
     * @param {String} [options.interactionGroup]
     * @param {String} [options.interactionMode='sub']
     * @param {String} [options.dragPlaneNormal]
     * @param {String} [options.name] - Human readable name of this geometry, will be added as property SDName to the object at the specified path
     * @param {String} [options.visible] - {@link https://threejs.org/docs/#api/core/Object3D.visible visible} property of the object at the specified path
     * @return {Promise} Resolves if the geometry was successfully added
     */
    addGeometry(path, geometry, options) {
      _viewportManager.registerForContinuousRendering(GEOMETRY_ADD_ID);

      // parameter sanity check
      options = options || {};

      // if there is something at this path, we abort
      // this function is not replacing geometry
      let previousPathObject = PATH_UTILS.getPathObject(_geometryNode, path);
      if (previousPathObject != null) {
        _viewportManager.unregisterForContinuousRendering(GEOMETRY_ADD_ID);
        return Promise.reject(new Error('Geometry already exists at that path: ' + path));
      }
      let globalPathObject = PATH_UTILS.ensurePath(_geometryNode, path);
      if (GLOBAL_UTILS.typeCheck(options.name, 'string')) {
        globalPathObject.SDName = options.name;
      }
      if (GLOBAL_UTILS.typeCheck(options.visible, 'boolean')) {
        globalPathObject.visible = options.visible;
      }
      else {
        globalPathObject.visible = true;
      }
      let hasSDBoundingBox = options.hasOwnProperty('boundingBox');
      if (hasSDBoundingBox) {
        globalPathObject.SDBoundingBox = options.boundingBox;
      }

      for (let g of geometry) {

        ['geometry', 'path', 'type', 'material'].forEach(function (attr) {
          if (!g.hasOwnProperty(attr)) {
            _viewportManager.unregisterForContinuousRendering(GEOMETRY_ADD_ID);
            return Promise.reject(new Error('GeometryData object is missing property ' + attr));
          }
        });

        let relativePathObject = PATH_UTILS.ensurePath(globalPathObject, g.path);

        // material just to get the right color for points and lines
        let mat;
        if(g.material.properties.color) {
          mat = new THREE.MeshBasicMaterial({color: g.material.properties.color});
        } else {
          mat = new THREE.MeshBasicMaterial();
        }

        if (g.color)
          mat.color.set(g.color.toRgbString());
          
        if (g.geometry.attributes && g.geometry.attributes.hasOwnProperty('color'))
          mat.vertexColors = THREE.VertexColors;

        let geometryObject = null,
            hasTextureCoordinates = false;
        try {
          switch (g.type) {
            case 'mesh':
              // check for texture coordinates
              if (g.geometry instanceof THREE.BufferGeometry) {
                if (g.geometry.hasOwnProperty('attributes') && g.geometry.attributes.hasOwnProperty('uv')) {
                  hasTextureCoordinates = true;
                }
              } else {
                if (g.geometry instanceof THREE.Geometry) {
                  if (g.geometry.faceVertexUvs.length > 0) {
                    hasTextureCoordinates = true;
                  }
                }
              }

              // compute vertex normals
              // TODO: improve this, it is expensive. it is currently only needed for ambient occlusion
              if (g.geometry instanceof THREE.BufferGeometry) {
                if (!g.geometry.attributes.hasOwnProperty('normal') || g.geometry.attributes.normal == null) {
                  g.geometry.computeVertexNormals();
                }
              } else {
                if (g.geometry instanceof THREE.Geometry) {
                  if (g.geometry.faces.length > 0) {
                    let f = g.geometry.faces[0];
                    if (f.vertexNormals == null || f.vertexNormals.length == 0) {
                      g.geometry.computeFaceNormals();
                      g.geometry.computeVertexNormals();
                    }
                  }
                }
              }

              if(g.material.properties && g.material.properties.transparent === true && g.material.properties.side === THREE.DoubleSide) {
                geometryObject = new THREE.Object3D();
                geometryObject.add(new THREE.Mesh(g.geometry, mat));
                geometryObject.add(new THREE.Mesh(g.geometry, mat));
              } else {
                geometryObject = new THREE.Mesh(g.geometry, mat);
              }
              break;
            case 'points':
              geometryObject = new THREE.Points(g.geometry, new THREE.PointsMaterial({ color: mat.color, size: 1 }));
              break;
            case 'line':
              geometryObject = new THREE.Line(g.geometry, new THREE.LineBasicMaterial({ color: mat.color }));
              break;
            case 'anchor':
              geometryObject = new THREE.Object3D();
              geometryObject.position.set(g.geometry.position.x, g.geometry.position.y, g.geometry.position.z);
              geometryObject.type = 'Anchor';
              geometryObject.material = {};
              break;
            case 'tag3d':
            default:
              // CAUTION!
              // moved 'continue' statement from here downwards, due to expo.io/react native,
              // which throws an exception "'continue' is only valid inside a loop statement."
              //continue;
          }
        } catch (e) {
          _viewportManager.unregisterForContinuousRendering(GEOMETRY_ADD_ID);
          return Promise.reject(e);
        }

        // moved 'continue' statement here, due to expo.io/react native, see reason described above
        if (!geometryObject) {
          continue;
        }

        if (g.hasOwnProperty('initialMatrix')) {
          geometryObject.applyMatrix(g.initialMatrix);
        }

        // store material definition in object
        if(geometryObject.type === 'Object3D' ) {
          for(let i = 0, len = geometryObject.children.length; i < len; i++)
            geometryObject.children[i].SDMaterialDefinition = g.material;
        } else {
          geometryObject.SDMaterialDefinition = g.material;
        }

        relativePathObject.add(geometryObject);

        if(geometryObject.type !== 'Anchor') {
          that._addMesh(geometryObject, g.material.properties, hasTextureCoordinates, g.color, g.geometry.attributes && g.geometry.attributes.hasOwnProperty('color'));
        } else {
          that._addAnchor(geometryObject, Object.assign({path: path}, g.geometry));
        }

        if (typeof options.duration === 'number' && options.duration > 0) {
          // FIXME meshes
          if(geometryObject.type === 'Object3D' ) {
            for(let i = 0, len = geometryObject.children.length; i < len; i++){
              geometryObject.children[i].material.transparent = true;
              geometryObject.children[i].material.opacity = 0;
            }
          } else {
            geometryObject.material.transparent = true;
            geometryObject.material.opacity = 0;
          }
        }
      }

      _viewportManager.updateInteractions(path, options);

      if (!hasSDBoundingBox) {
        let cbb = new THREE.Box3();
        cbb.setFromObject(globalPathObject);
        globalPathObject.SDBoundingBox = cbb;
      }

      _viewportManager.adjustScene();

      if (typeof options.duration === 'number' && options.duration > 0) {
        _viewportManager.unregisterForContinuousRendering(GEOMETRY_ADD_ID);
        return _viewportManager.fadeIn(path, options.duration);
      }

      _viewportManager.unregisterForContinuousRendering(GEOMETRY_ADD_ID);
      return Promise.resolve();
    }


    /**
     * Removes geometry from the scene
     * @param  {String} path    All geometry at and below this path will be removed
     * @param  {Object} [options] Execution options
     * @param {Number} [options.duration] Duration of fade out in msec
     * @return {Promise} Resolves if there is no geometry at the given path after the operation
     */
    removeGeometry(path, options) {
      let scope = 'ThreeDManager.removeGeometry';

      // parameter sanity check
      options = options || {};

      // if the path does not exist, we consider it success and resolve
      let obj = PATH_UTILS.getPathObject(_geometryNode, path);
      if (obj == null) {
        return Promise.resolve();
      }
      _viewportManager.registerForContinuousRendering(GEOMETRY_REMOVE_ID);

      let fadeOutPromise = (typeof options.duration === 'number' && options.duration > 0) ? _viewportManager.fadeOut(path, options.duration) : Promise.resolve();
      return fadeOutPromise.then(function () {
        _viewportManager.removeFromInteractions(path);
        _viewportManager.adjustScene();
        _viewportManager.unregisterForContinuousRendering(GEOMETRY_REMOVE_ID);

        obj.traverse(function(obj){
          if(obj.type === 'Mesh'){
            that._removeMesh(obj);
          } else if(obj.type === 'Anchor') {
            that._removeAnchor(obj);
          }
        });

        if (PATH_UTILS.deletePath(_geometryNode, path)) {
          return Promise.resolve();
        } else {
          let msg = 'Could not delete scene path ' + path;
          that.error(scope, msg);
          return Promise.reject(new Error(msg));
        }
      }).catch(function (err) {
        let msg = 'Exception when removing geometry at path ' + path;
        that.error(scope, msg, err);
        return Promise.reject(err);
      });
    }

    /**
     * Adds new geometry to the scene
     * @param  {String} path - Path in dot-separated format describing the global path under which the geometry is to be replaced
     * @param  {module:OutputVersion~GeometryData[]} geometry - Geometry data objects with relative path, geometry and material
     * @param {Object} [options] - Execution options
     * @param {Number} [options.duration] - Duration of replacement fading in msec
     * @param {String} [options.visible] - {@link https://threejs.org/docs/#api/core/Object3D.visible visible} property of the object at the specified path
     * @return {Promise<Boolean>} Resolves if the geometry was successfully added
     */
    replaceGeometry(path, geometry, options) {
      // parameter sanity check
      options = options || {};

      _viewportManager.registerForContinuousRendering(GEOMETRY_REPLACE_ID);

      // see if the object or sub objects are selected and re-select them afterwards
      // possible FIXME if there are other viewports that are not default, but also include interactions, they have to be included here
      let defaultThreeDManagers = _viewportManager.getDefaultThreeDManagers(),
          selectionPerManager = {};

      for(let i = 0, len1 = defaultThreeDManagers.length; i < len1; i++) {
        let m = defaultThreeDManagers[i],
            currentlySelectedPaths = m.api.getSelected(),
            relevantSelected = [];

        if(currentlySelectedPaths.includes(path)){
          // global
          relevantSelected.push(path);
        }else {
          // sub
          let obj = PATH_UTILS.getPathObject(_geometryNode, path);
          if (obj !== null) {
            for(let j = 0, len2 = currentlySelectedPaths.length; j < len2; j++){
              let p = currentlySelectedPaths[j];
              if(PATH_UTILS.getPathObject(obj, p) !== null)
                relevantSelected.push(p);
            }
          }
        }
        selectionPerManager[m.runtimeId] = relevantSelected;
      }


      let internalOptions = {};
      if (typeof options.duration === 'number' && options.duration > 0) {
        internalOptions = { duration: 0.5 * options.duration };
      }
      ['interactionGroup', 'interactionMode', 'dragPlaneNormal', 'visible'].forEach(function (attr) {
        if (options.hasOwnProperty(attr)) internalOptions[attr] = options[attr];
      });

      //TODO: make sure draggable / hoverable / selectable status is kept if not explicitly changes

      return that.removeGeometry(path, internalOptions).catch(function (err) {
        _viewportManager.unregisterForContinuousRendering(GEOMETRY_REPLACE_ID);
        return Promise.reject(err);
      }).then(function () { that.addGeometry(path, geometry, internalOptions); }).catch(function (err) {
        _viewportManager.unregisterForContinuousRendering(GEOMETRY_REPLACE_ID);
        return Promise.reject(err);
      }).then(function () {

        // set all the paths that were selected as selected again
        let defaultThreeDManagers = _viewportManager.getDefaultThreeDManagers();
        for(let i = 0, len = defaultThreeDManagers.length; i < len; i++) {
          let m = defaultThreeDManagers[i],
              s = selectionPerManager[m.runtimeId];
          if(s && s.length != 0)
            m.api.updateSelected(m.api.getSelected().concat(s));
        }

        _viewportManager.unregisterForContinuousRendering(GEOMETRY_REPLACE_ID);
      });
    }

    /**
     * Toggles the shadows for an object with a specific path.
     * @param {String} path The path of the object
     * @param {boolean} bCast The toggle for this operation
     *
     * @return {boolean} True if the object was found
     */
    setToggleCastShadow(path, bCast) {
      let obj = PATH_UTILS.getPathObject(_geometryNode, path);
      if (obj == null) return false;
      obj.castShadow = bCast;
      obj.traverse(function (object) {
        object.castShadow = bCast;
      });
      _viewportManager.updateShadowMap();
      _viewportManager.render();
      return true;
    }
    
    /**
     * Given an object, get its path in the scene, which could be used to retrieve the object using getPathObject
     * @param  {THREE.Object3D} obj - The base object
     * @return  {String} Path to the object, starting at base object, null if not found
     */
    getObjectPath(obj) {
      return PATH_UTILS.getObjectPath(obj);
    }

    /**
     * Given a base object, pursue a dot-separated path to see if there is an object there
     * @param  {THREE.Object3D} [obj] - Optional base object to start searching from, if not specified the complete scene is used
     * @param  {String} path - Path to find object, starting at base object
     * @return {THREE.Object3D} The target object if it exists, null otherwise
     */
    getPathObject(obj, path) {
      return PATH_UTILS.getPathObject(obj, path);
    }
  }

  return {
    getInstance: function (viewportManager, ___settings) {
      if (!_instance)
        _instance = createInstance(viewportManager, ___settings);
      return _instance;
    }
  };
})();

module.exports = SceneGeometryManager;