
let ThreeDManagerHelpers = function (___settings, ___handlers) {
  const GLOBAL_UTILS = require('../../shared/util/GlobalUtils'),
        THREE = require('../../externals/three'),
        MESSAGING_CONSTANTS = require('../../shared/constants/MessagingConstants'),
        MESSAGE_PROTOTYPE = require('../../shared/messages/MessagePrototype'),
        _handlers = ___handlers,
        _scene = ___settings.scene;

  let that,
      _sceneObjects = new THREE.Object3D(),
      _meshMaterialObjects = [],
      _meshes = [],
      _materials = [],
      _anchors = [],
      _initialized = false;

  class ThreeDManagerHelpers {

    constructor() {
      that = this;
      _sceneObjects.visible = false;
      _scene.add(_sceneObjects);
    }

    /**
     * Given an interaction data message, create an Event and dispatch it via the DOM.
     *
     * @param {module:MESSAGING_CONSTANTS~InteractionDataMessage} idm - interaction data message
     */
    dispatchEvent(idm) {
      let evt = new CustomEvent(idm.type);
      for (let k of idm) {
        evt[k] = idm[k];
      }
      _handlers.renderingHandler.getDomElement().dispatchEvent(evt);
      return evt;
    }

    initialize(){
      _initialized = true;
    }

    isInitialized() {
      return _initialized;
    }

    addMesh(mesh, material, properties) {
      _meshes.push(mesh);
      _materials.push(material);
      _meshMaterialObjects.push({ mesh: mesh, material: material, properties: properties });
    }

    removeMesh(mesh) {
      for (let i = 0, len = _meshMaterialObjects.length; i < len; i++) {
        if (mesh === _meshMaterialObjects[i].mesh) {
          let mat = _materials[i];
          _meshes.splice(i, 1);
          _materials.splice(i, 1);
          _meshMaterialObjects.splice(i, 1);


          
          if(mat) {
            ['alphaMap', 'aoMap', 'bumpMap', 'displacementMap', 'emissiveMap',
              'lightMap', 'map', 'metalnessMap', 'normalMap', 'roughnessMap',
              'rgbMap', 'additionalMaps0', 'additionalMaps1', 'additionalMaps2', 'additionalMaps3', 'additionalMaps4',
              'reflectionNormalMap', 'dispersionMap', 'reflectionMap'].forEach(function (attr) {
              if (mat[attr] && mat[attr].dispose) 
                mat[attr].dispose();
                
            });
            mat.dispose();
          }

          return;
        }
      }
    }

    addAnchor(object, properties) {
      let anchor = { 
        object: object, 
        properties: Object.assign({ 
          lastPos: new THREE.Vector2(),
          runtimeId: GLOBAL_UTILS.createRandomId()
        }, properties)
      };
      _anchors.push(anchor);

      let msg_obj = {
        type: MESSAGING_CONSTANTS.messageTopics.SCENE_ANCHOR_ADD,
        anchors: [],
      };

      msg_obj.anchors.push({
        location: {
          x: object.position.x,
          y: object.position.y,
          z: object.position.z,              
        },
        viewport: _handlers.threeDManager.runtimeId,
        scenePath: properties.path,
        format: properties.format,
        data: properties.data,
        runtimeId: anchor.properties.runtimeId,
        updateFunction: update => anchor.properties.update = update
      });

      let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_ANCHORDATA, msg_obj);
      _handlers.threeDManager.message(msg_obj.type, m);
      that.dispatchEvent(msg_obj);
    }

    removeAnchor(object) {
      let anchor, properties;
      for (let i = 0, len = _anchors.length; i < len; i++) {
        if (object === _anchors[i].object) {
          anchor = _anchors[i];
          properties = anchor.properties;

          let msg_obj = {
            type: MESSAGING_CONSTANTS.messageTopics.SCENE_ANCHOR_REMOVE,
            anchors: [],
          };
    
          msg_obj.anchors.push({
            location: {
              x: object.position.x,
              y: object.position.y,
              z: object.position.z,              
            },
            viewport: _handlers.threeDManager.runtimeId,
            scenePath: properties.path,
            format: properties.format,
            data: properties.data,
            runtimeId: properties.runtimeId,
          });
    
          let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_ANCHORDATA, msg_obj);
          _handlers.threeDManager.message(msg_obj.type, m);
          that.dispatchEvent(msg_obj);

          _anchors.splice(i, 1);
          return;
        }
      }
    }

    getMeshes() {
      return _meshes;
    }

    getMaterials() {
      return _materials;
    }

    getMeshMaterialObjects() {
      return _meshMaterialObjects;
    }

    getAnchors() {
      return _anchors;
    }

    toggleMeshes(toggle) {
      for (let i = 0, len = _meshMaterialObjects.length; i < len; i++)
        if (toggle)
          _meshMaterialObjects[i].mesh.material = _meshMaterialObjects[i].material;
    }

    addSceneObject(obj) {
      _sceneObjects.add(obj);
    }

    removeSceneObject(obj) {
      _sceneObjects.remove(obj);
    }

    toggleSceneObjects(toggle) {
      _sceneObjects.visible = toggle;
    }

    toggleViewport(toggle) {
      this.toggleSceneObjects(toggle);
      this.toggleMeshes(toggle);
    }

    destroyViewport() {
      _meshes.length = 0;
      _materials.length = 0;
      _meshMaterialObjects.length = 0;
      _anchors.length = 0;
      _scene.remove(_sceneObjects);
    }
  }

  return new ThreeDManagerHelpers();
};

module.exports = ThreeDManagerHelpers;