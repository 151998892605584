
let threeDManagerConstants;
{
  const GLOBAL_UTILS = require('../../../shared/util/GlobalUtils'),
        LOGGING_CONSTANTS = require('../../../shared/constants/LoggingConstants'),
        LOGGING_LEVELS = LOGGING_CONSTANTS.loggingLevels;

  let cameraViewTypes = {
    MIN: 0,
    PERSPECTIVE: 0,
    TOP: 1,
    BOTTOM: 2,
    RIGHT: 3,
    LEFT: 4,
    BACK: 5,
    FRONT: 6,
    AR: 7,
    MAX: 7
  };

  let settingsDefinition = {
    show: {
      value: false,
      setting: 'show',
      desc: 'Show / hide the scene',
      type: 'boolean'
    },
    showTransition: {
      value: '1s'
    },
    fullscreen: {
      value: false,
      setting: 'fullscreen',
      desc: 'Enable / disable fullscreen mode',
      type: 'boolean'
    },
    gridVisibility: {
      value: true,
      setting: 'gridVisibility',
      desc: 'Show / hide the grid',
      type: 'boolean'
    },
    groundPlaneVisibility: {
      value: true,
      setting: 'groundPlaneVisibility',
      desc: 'Show / hide the ground plane',
      type: 'boolean'
    },
    groundPlaneReflectionVisibility: {
      value: false,
      setting: 'groundPlaneReflectionVisibility',
      desc: 'Enable / disable the reflectivity of the groundplane ',
      type: 'boolean'
    },
    groundPlaneReflectionThreshold: {
      value: 0.01,
      setting: 'groundPlaneReflectionThreshold',
      desc: 'Allows to control the distance to objects that are still reflected by the groundplane ',
      type: (v) => (typeof v === 'number' && v >= 0)
    },
    ignorePointerEvents: {
      value: false,
      setting: 'ignorePointerEvents',
      desc: 'Ignore pointer events',
      type: 'boolean'
    },
    render: {
      ambientOcclusion: {
        value: true,
        setting: 'render.ambientOcclusion',
        desc: 'Enable / disable ambient occlusion for rendering',
        type: 'boolean'
      },
      clearColor: {
        value: '#ffffff',
        setting: 'render.clearColor',
        desc: 'Set background color',
        type: (v) => (GLOBAL_UTILS.typeCheck(v, 'color') ? true : false)
      },
      clearAlpha: {
        value: 1.0,
        setting: 'render.clearAlpha',
        desc: 'Set background alpha value',
        type: (v) => (typeof v === 'number' && v >= 0 && v <= 1 ? true : false)
      },
      devicePixelRatio: {
        value: 1.0
      },
      pointSize: {
        value: 1.0,
        setting: 'render.pointSize',
        desc: 'Set size of point objects',
        type: (v) => (typeof v === 'number' && v >= 0 ? true : false)
      },
      sao: {
        samples: {
          value: 8
        },
        intensity: {
          value: 0.1,
        },
        kernelRadius: {
          value: 8,
        },
        standardDev: {
          value: 25
        },
      },
      shadows: {
        value: true,
        setting: 'render.shadows',
        desc: 'Enable / disable shadows for rendering',
        type: 'boolean'
      },
    },
    camera: {
      autoRotationSpeed: {
        value: 0.0,
        setting: 'camera.autoRotationSpeed',
        desc: 'Speed of autoration, can be negative, also refer to enableAutoRotation',
        type: 'number'
      },
      cameraMovementDuration: {
        value: 0,
        setting: 'camera.cameraMovementDuration',
        desc: 'Default duration of camera movements',
        type: 'notnegative'
      },
      damping: {
        value: 0.1,
        setting: 'camera.damping',
        desc: 'How much to damp camera movements by the user',
        type: 'notnegative'
      },
      defaults: {
        perspective: {
          value: {
            position: {
              value: {
                x: 5, y: 5, z: 5
              },
              setting: 'camera.defaults.perspective.position',
              desc: 'Default position for the perspective camera',
              type: 'vector3any',
              transform: (v) => (GLOBAL_UTILS.toVector3(v))
            },
            target: {
              value: {
                x: 0, y: 0, z: 0
              },
              setting: 'camera.defaults.perspective.target',
              desc: 'Default target for the perspective camera',
              type: 'vector3any',
              transform: (v) => (GLOBAL_UTILS.toVector3(v))
            }
          },
          setting: 'camera.defaults.perspective',
          desc: 'Default position and target for the perspective camera',
          type: (v) => (v && GLOBAL_UTILS.typeCheck(v.position, 'vector3obj') && GLOBAL_UTILS.typeCheck(v.target, 'vector3obj'))
        },
        orthographic: {
          value: {
            position: {
              value: {
                x: 5, y: 5, z: 5
              },
              setting: 'camera.defaults.orthographic.position',
              desc: 'Default position for the orthographic camera',
              type: 'vector3any',
              transform: (v) => (GLOBAL_UTILS.toVector3(v))
            },
            target: {
              value: {
                x: 0, y: 0, z: 0
              },
              setting: 'camera.defaults.orthographic.target',
              desc: 'Default target for the orthographic camera',
              type: 'vector3any',
              transform: (v) => (GLOBAL_UTILS.toVector3(v))
            }
          },
          setting: 'camera.defaults.orthographic',
          desc: 'Default position and target for the orthographic camera',
          type: (v) => (v && GLOBAL_UTILS.typeCheck(v.position, 'vector3obj') && GLOBAL_UTILS.typeCheck(v.target, 'vector3obj'))
        }
      },
      enableAutoRotation: {
        value: false,
        setting: 'camera.enableAutoRotation',
        desc: 'Enable / disable automatic rotation of the camera, also refer to autoRotationSpeed',
        type: 'boolean'
      },
      enableKeyPan: {
        value: false,
        setting: 'camera.enableKeyPan',
        desc: 'Enable / disable panning using the keyboard, also refer to enablePan',
        type: 'boolean'
      },
      enableOrbitControls: {
        value: true,
        setting: 'camera.enableOrbitControls',
        desc: 'Enable / disable orbit controls',
        type: 'boolean'
      },
      enablePan: {
        value: true,
        setting: 'camera.enablePan',
        desc: 'Enable / disable panning in general, also refer to enableKeyPan',
        type: 'boolean'
      },
      enableRotation: {
        value: true,
        setting: 'camera.enableRotation',
        desc: 'Enable / disable camera rotation',
        type: 'boolean'
      },
      enableZoom: {
        value: true,
        setting: 'camera.enableZoom',
        desc: 'Enable / disable zooming',
        type: 'boolean'
      },
      far: {
        value: 100
      },
      fov: {
        value: 45,
        setting: 'camera.fov',
        desc: 'Camera frustum vertical field of view angle, unit degree, interval [0,180]',
        type: (v) => (typeof v === 'number' && v > 0 && v < 180 ? true : false)
      },
      keyPanSpeed: {
        value: 1,
        setting: 'camera.keyPanSpeed',
        desc: 'Speed of panning when using the keyboard',
        type: 'number'
      },
      near: {
        value: 0.01
      },
      restrictions: {
        position: {
          cube: {
            value: {
              min: {
                value: {
                  x: -Infinity, y: -Infinity, z: -Infinity
                },
                setting: 'camera.restrictions.position.cube.min',
                desc: 'Restriction of the camera position inside a cube, minimum corner of the cube',
                type: 'vector3any'
              },
              max: {
                value: {
                  x: Infinity, y: Infinity, z: Infinity
                },
                setting: 'camera.restrictions.position.cube.max',
                desc: 'Restriction of the camera position inside a cube, maximum corner of the cube',
                type: 'vector3any'
              }
            },
            setting: 'camera.restrictions.position.cube',
            desc: 'Restriction of the camera position inside a cube, minimum and maximum corner of the cube'
          },
          sphere: {
            value: {
              center: {
                value: {
                  x: 0, y: 0, z: 0
                },
                setting: 'camera.restrictions.position.sphere.center',
                desc: 'Restriction of the camera position inside a sphere, center of the sphere',
                type: 'vector3any'
              },
              radius: {
                value: Infinity,
                setting: 'camera.restrictions.position.sphere.radius',
                desc: 'Restriction of the camera position inside a sphere, radius of the sphere',
                type: 'notnegative'
              }
            },
            setting: 'camera.restrictions.position.sphere',
            desc: 'Restriction of the camera position inside a sphere, center and radius of the sphere'
          }
        },
        target: {
          cube: {
            value: {
              min: {
                value: {
                  x: -Infinity, y: -Infinity, z: -Infinity
                },
                setting: 'camera.restrictions.target.cube.min',
                desc: 'Restriction of the camera target inside a cube, minimum corner of the cube',
                type: 'vector3any'
              },
              max: {
                value: {
                  x: Infinity, y: Infinity, z: Infinity
                },
                setting: 'camera.restrictions.target.cube.max',
                desc: 'Restriction of the camera target inside a cube, maximum corner of the cube',
                type: 'vector3any'
              }
            },
            setting: 'camera.restrictions.target.cube',
            desc: 'Restriction of the camera target inside a cube, minimum and maximum corner of the cube'
          },
          sphere: {
            value: {
              center: {
                value: {
                  x: 0, y: 0, z: 0
                },
                setting: 'camera.restrictions.target.sphere.center',
                desc: 'Restriction of the camera target inside a sphere, center of the sphere',
                type: 'vector3any'
              },
              radius: {
                value: Infinity,
                setting: 'camera.restrictions.target.sphere.radius',
                desc: 'Restriction of the camera target inside a sphere, radius of the sphere',
                type: 'notnegative'
              }
            },
            setting: 'camera.restrictions.target.sphere',
            desc: 'Restriction of the camera target inside a sphere, center and radius of the sphere'
          }
        },
        rotation: {
          value: {
            minPolarAngle: {
              value: 0,
              setting: 'camera.restrictions.rotation.minPolarAngle',
              desc: 'Minimum polar angle of the camera position with respect to the camera target, unit degree, interval [0,180]',
              type: (v) => (typeof v === 'number' && v >= 0 && v <= 180 ? true : false),
            },
            maxPolarAngle: {
              value: 180,
              setting: 'camera.restrictions.rotation.maxPolarAngle',
              desc: 'Maximum polar angle of the camera position with respect to the camera target, unit degree, interval [0,180]',
              type: (v) => (typeof v === 'number' && v >= 0 && v <= 180 ? true : false),
            },
            minAzimuthAngle: {
              value: -Infinity,
              setting: 'camera.restrictions.rotation.minAzimuthAngle',
              desc: 'Minimum azimuth angle of the camera position with respect to the camera target, unit degree, interval [-180,180]',
              type: 'number'
            },
            maxAzimuthAngle: {
              value: Infinity,
              setting: 'camera.restrictions.rotation.maxAzimuthAngle',
              desc: 'Maximum azimuth angle of the camera position with respect to the camera target, unit degree, interval [-180,180]',
              type: 'number'
            }
          },
          setting: 'camera.restrictions.rotation',
          desc: 'Minimum and maximum polar and azimuth angle of the camera position with respect to the camera target, unit degree',
          type: (v) => (v && Object.keys(v).every((k) => (typeof v[k] === 'number')))
        },
        zoom: {
          value: {
            minDistance: {
              value: 0,
              setting: 'camera.restrictions.zoom.minDistance',
              desc: 'Minimum distance between camera position and target',
              type: 'notnegative'
            },
            maxDistance: {
              value: Infinity,
              setting: 'camera.restrictions.zoom.maxDistance',
              desc: 'Maximum distance between camera position and target',
              type: 'notnegative'
            }
          },
          setting: 'camera.restrictions.zoom',
          desc: 'Minimum and maximum distance between camera position and target',
          type: (v) => (v && GLOBAL_UTILS.typeCheck(v.minDistance, 'notnegative') && GLOBAL_UTILS.typeCheck(v.maxDistance, 'notnegative'))
        }

      },
      rotationSpeed: {
        value: 0.25,
        setting: 'camera.rotationSpeed',
        desc: 'Speed of camera rotation',
        type: 'number'
      },
      panSpeed: {
        value: 0.5,
        setting: 'camera.panSpeed',
        desc: 'Speed of panning',
        type: 'number'
      },
      type: {
        value: cameraViewTypes.PERSPECTIVE,
        setting: 'camera.type',
        desc: 'Set camera type',
        type: (v) => (typeof v === 'number' && v >= cameraViewTypes.PERSPECTIVE && v <= cameraViewTypes.AR ? true : false)
      },
      zoomExtentsFactor: {
        value: 1,
        setting: 'camera.zoomExtentsFactor',
        desc: 'Factor to apply to the bounding box before zooming to extents',
        type: 'number'
      },
      zoomSpeed: {
        value: 1.0,
        setting: 'camera.zoomSpeed',
        desc: 'Speed of zooming',
        type: 'notnegative'
      },
    },
    lights: {
      light0: {
        value: {
          type: 999,
          properties: {}
        }
      },
      light1: {
        value: {
          type: 0,
          properties: { color: 0x646464, intensity: 1.0 }
        }
      },
      light2: {
        value: {
          type: 1,
          properties: { direction: { x: .5774, y: -.5774, z: .5774 }, shadows: true, intensity: 1.0 }
        }
      },
      light3: {
        value: {}
      },
      light4: {
        value: {}
      },
      light5: {
        value: {}
      },
      light6: {
        value: {}
      },
      light7: {
        value: {}
      },
      light8: {
        value: {}
      },
      light9: {
        value: {}
      },
    },
    material: {
      environmentMapAsBackground: {
        value: false,
        setting: 'material.environmentMapAsBackground',
        desc: 'Show / hide the environment map in the background',
        type: 'boolean'
      },
      environmentMap: {
        value: 'default',
        setting: 'material.environmentMap',
        desc: 'Name of the environment map to use, or an array of 6 URLs making up the cube mapped environment map (px, nx, pz, nz, py, ny)', // FIXME document environment map names in ApiInterface
        type: (v) => (typeof v === 'string' || (GLOBAL_UTILS.isArrayOfType(v, 'string') && v.length === 6))
      }
    }
  };
  function _hasSubValue(def) {
    if (typeof def === 'object') {
      for (let key in def) {
        if (key === 'value')
          return true;
        if (_hasSubValue(def[key]))
          return true;
      }
    }

    return false;
  }

  function _convert(def, global) {
    for (let key in def) {
      if (key === 'value') {
        if (_hasSubValue(def[key])) {
          return global = _convert(def[key], global);
        } else {
          return def[key];
        }
      } else if (key !== 'desc') {
        global[key] = {};
        global[key] = _convert(def[key], global[key]);
      }
    }
    return global;
  }

  let defaultSettings = {};
  _convert(settingsDefinition, defaultSettings);

  threeDManagerConstants = {
    settingsDefinition: settingsDefinition,
    defaultSettings: defaultSettings,
    loggingLevels: LOGGING_LEVELS,
    cameraViewTypes: cameraViewTypes
  };
}

module.exports = require('../../../shared/util/GlobalUtils').deepCopy(threeDManagerConstants);
