/**
 * @file Creates a shared interface for all MaterialHandlers.
 *       The MaterialHandlerInterface must be implemented by all MaterialHandlers.
 *       All described methods must be implemented.
 * 
 * @module MaterialHandlerInterface
 * @author Michael Oppitz
 */

/**
 * Interface for the MaterialHandler.
 * The MaterialHandler implements all functionality related to the materials.
 * 
 * //@interface MaterialHandlerInterface
 */
class MaterialHandlerInterface {

  /**
   * The constructor for the {@link module:MaterialHandlerInterface~MaterialHandlerInterface}.
   * @constructs module:MaterialHandlerInterface~MaterialHandlerInterface
   */
  constructor() { }

  /**
   * Creates a new Material with the given porperties.
   *
   * @abstract
   * @param {Object} [properties] The properties of the material
   * @returns {THREE.Material} The standard material of the viewport with the given properties
   */
  getMaterial(properties) { }

  /**
   * Creates a very basic material with the given porperties.
   * This is the fallback if the standard material fails
   *
   * @abstract
   * @param {Object} [properties] The properties of the material
   * @returns {THREE.Material} The simple material with the given properties
   */
  getSimpleMaterial(properties) { }
}

module.exports = MaterialHandlerInterface;