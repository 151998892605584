let ViewportManager = (function () {

  const GLOBAL_UTILS = require('../shared/util/GlobalUtils'),
        MESSAGING_CONSTANTS = require('../shared/constants/MessagingConstants'),
        _threeDManagers = [];

  let _instance,
      _initialized = false,
      _firstBB,
      _viewerApp,
      _loggingHandler;


  function createInstance(viewerApp, loggingHandler) {
    return new ViewportManager(viewerApp, loggingHandler);
  }

  class ViewportManager {
    constructor(viewerApp, loggingHandler) {
      _viewerApp = viewerApp;
      _loggingHandler = loggingHandler;

      GLOBAL_UTILS.inject({
        message: _viewerApp.message,
        subscribeToMessageStream: _viewerApp.subscribeToMessageStream,
        unsubscribeFromMessageStream: _viewerApp.unsubscribeFromMessageStream
      }, this);

      let token = this.subscribeToMessageStream(MESSAGING_CONSTANTS.messageTopics.SCENE_VISIBILITY_ON, function (/*topic, message*/) {
        _instance.unsubscribeFromMessageStream(token);
        _firstBB = _viewerApp.sceneGeometryManager.getGeometryNode().computeSceneBoundingBox();
        _initialized = true;

        for(let i = 0, len = _threeDManagers.length; i < len; i++)
          _threeDManagers[i].init(_firstBB);
      });
    }

    _convertInputObject(input){
      /**
       * can come as:
       *  1. as single object (see 1)
       *  2. as single dom
       */
      let container, type, runtimeId;

      if(input.type && input.type === 'ar') return input;

      if(input.container) {
        if(input.container instanceof Element){
          container = input.container;
        } else {
          return;
        }
        if(input.type){
          type = input.type;
        } else {
          type = 'default';
        }

        let currentId = input.runtimeId;
        if(currentId) {
          let used = false;
          for(let j = 0, len = _threeDManagers.length; j < len; j++)
            if(_threeDManagers[j].runtimeId === currentId)
              used = true;

          runtimeId = !used ? currentId : GLOBAL_UTILS.createRandomId();
        } else {
          runtimeId = GLOBAL_UTILS.createRandomId();
        }
      } else {
        if(input instanceof Element){
          container = input;
          type = 'default';
          runtimeId = GLOBAL_UTILS.createRandomId();
        }else{
          return;
        }
      }

      return {
        container: container,
        type: type,
        runtimeId: runtimeId,
      };
    }

    _createThreeDManager(object) {
      let threeDManager, api;

      if (!object)
        return null;

      // create threeDManager
      if (object.type === 'simple') {
        // simple
        threeDManager = new (require('./viewports/simple/ThreeDManager'))(
          {
            scene: _viewerApp.sceneGeometryManager.getScene(),
            geometryNode: _viewerApp.sceneGeometryManager.getGeometryNode(),
            container: object.container,
            runtimeId: object.runtimeId,
            loggingHandler: _loggingHandler,
            messagingHandler: {
              message: _viewerApp.message,
              subscribeToMessageStream: _viewerApp.subscribeToMessageStream,
              unsubscribeFromMessageStream: _viewerApp.unsubscribeFromMessageStream
            },
          }
        );

        api = new (require('./viewports/simple/api/ViewportApi'))(_viewerApp.api({version: 2}), {
          threeDManager: threeDManager,
          viewportManager: this,
        });

      } else if (object.type === 'ar') {
        // ar
        threeDManager = new (require('./viewports/ar/ThreeDManager'))(
          {
            scene: _viewerApp.sceneGeometryManager.getScene(),
            geometryNode: _viewerApp.sceneGeometryManager.getGeometryNode(),
            container: object.container,
            runtimeId: object.runtimeId,
            loggingHandler: _loggingHandler,
            messagingHandler: {
              message: _viewerApp.message,
              subscribeToMessageStream: _viewerApp.subscribeToMessageStream,
              unsubscribeFromMessageStream: _viewerApp.unsubscribeFromMessageStream
            },
            additionalDataHandler: object.additionalDataHandler, // FIXME Alex get this from somewhere
            rendererFactory: object.rendererFactory, // FIXME Alex get this from somewhere
            afterRenderCallback: object.afterRenderCallback, // FIXME Alex get this from somewhere
            perspectiveCameraFactory: object.perspectiveCameraFactory, // FIXME Alex get this from somewhere
            //ExpoTHREE: null, // FIXME Alex get this from somewhere, not used yet
            //ThreeAR:  null, // FIXME Alex get this from somewhere, not used yet
          }
        );

        api = new (require('./viewports/ar/api/ViewportApi'))(_viewerApp.api({version: 2}), // FIXME why do we pass _viewerApp here?
          {
            threeDManager: threeDManager,
            viewportManager: this,
          }
        );

      } else {
        // default
        object.type = 'default';
        threeDManager = new (require('./viewports/default/ThreeDManager'))(
          {
            scene: _viewerApp.sceneGeometryManager.getScene(),
            geometryNode: _viewerApp.sceneGeometryManager.getGeometryNode(),
            container: object.container,
            runtimeId: object.runtimeId,
            loggingHandler: _loggingHandler,
            messagingHandler: {
              message: _viewerApp.message,
              subscribeToMessageStream: _viewerApp.subscribeToMessageStream,
              unsubscribeFromMessageStream: _viewerApp.unsubscribeFromMessageStream
            },
          }
        );

        api = new (require('./viewports/default/api/ViewportApi'))(_viewerApp.api({version: 2}), // FIXME why do we pass _viewerApp here?
          {
            threeDManager: threeDManager,
            viewportManager: this,
          }
        );
      }

      threeDManager.type = object.type;
      threeDManager.runtimeId = object.runtimeId;
      threeDManager.container = object.container;
      threeDManager.api = api;
      threeDManager.startUpObject = object;
      return threeDManager;
    }

    createThreeDManager(inputObject) {
      if(!inputObject)
        return null;
      if(!Array.isArray(inputObject))
        inputObject = [inputObject];

      let newThreeDManagers = [];
      for(let i = 0, len1 = inputObject.length; i < len1; i++){
        let input = this._convertInputObject(inputObject[i]);
        let tdm = this._createThreeDManager(input);
        _threeDManagers.push(tdm);

        if(tdm.success === false){
          // no need to send a further log message, this should have been handled in the constructor of the ThreeDManager

          // call this before adding somthing to the container, otherwise it will be removed again
          tdm.api.destroy();

          continue;
        }

        tdm.updateSetting('show', false);
        tdm.updateSetting('showTransition', _viewerApp.getSetting('showSceneTransition'));

        let obj = _viewerApp.sceneGeometryManager.getMeshMaterialObjects(),
            mesh, properties, hasTextureCoordinates, overrideColor, vertexColors;

        for(let j = 0, len2 = obj.length; j < len2; j++) {
          let o = obj[j];
          mesh = o.mesh;
          properties = o.properties;
          hasTextureCoordinates = o.hasTextureCoordinates;
          overrideColor = o.overrideColor;
          vertexColors = o.vertexColors;

          let mat = tdm.materialHandler.getMaterial(properties, overrideColor, vertexColors);
          if (mat === null || mat === undefined){
            tdm.warn('The creation of a material failed.');
            continue;
          }

          if (!hasTextureCoordinates) {
            mat.map = null;
            mat.alphaMap = null;
            mat.aoMap = null;
            mat.bumpMap = null;
            mat.displacementMap = null;
            mat.emissiveMap = null;
            mat.normalMap = null;
            mat.metalnessMap = null;
            mat.roughnessMap = null;
          }
          tdm.addMesh(mesh, mat, properties);
        }

        if(tdm){
          newThreeDManagers.push(tdm.api);
          if(_initialized){
            if(tdm.type === 'default')
              _viewerApp.settingsHandler.restoreSettings(tdm);

            tdm.init(_firstBB);

            if(_viewerApp.getSetting('showScene') === true)
              tdm.updateSetting('show', true);
          }
        }
      }
      return newThreeDManagers;
    }

    removeThreeDManager(runtimeId) {
      for (let i = 0, len = _threeDManagers.length; i < len; i++){
        if(_threeDManagers[i].runtimeId === runtimeId){
          let tdm = _threeDManagers[i];
          for(let key in tdm.api)
            delete tdm.api[key];
          tdm.destroy();
          _threeDManagers.splice(i, 1);
          return true;
        }
      }
      return false;
    }

    reloadThreeDManager(runtimeId) {
      for (let i = 0, len = _threeDManagers.length; i < len; i++){
        if(_threeDManagers[i].runtimeId === runtimeId){
          let tdm = _threeDManagers[i];
          let properties = Object.assign({}, tdm.startUpObject);

          tdm.destroy();
          _threeDManagers.splice(i, 1);

          let api = this.createThreeDManager(properties)[properties.runtimeId];
          tdm.api.__proto__ = api.__proto__;
          for(let key in api)
            tdm.api[key] = api[key];
          
          return;
        }
      }
    }

    getContainers() {
      let container = [];
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        container.push(_threeDManagers[i].container);
      return container;
    }

    getDefaultThreeDManagers() {
      let managers = [];
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        if(_threeDManagers[i].type === 'default')
          managers.push(_threeDManagers[i]);
      return managers;
    }

    getApis() {
      let apis = {};
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        apis[_threeDManagers[i].runtimeId] = _threeDManagers[i].api;
      return apis;
    }

    onlyOneDefault() {
      let first = this.getDefaultThreeDManagers()[0];
      if(_threeDManagers.length === 1 && first)
        return true;
      return false;
    }

    updateSetting(s, v) {
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        _threeDManagers[i].updateSetting(s, v);
    }

    ////////////
    ////////////
    //
    // ThreeDManager
    //
    ////////////
    ////////////

    adjustScene() {
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        _threeDManagers[i].adjustScene();
    }

    fadeIn(path, duration) {
      let promises = [];
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        promises.push(_threeDManagers[i].fadeIn(path, duration));
      return Promise.all(promises);
    }

    fadeOut(path, duration) {
      let promises = [];
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        promises.push(_threeDManagers[i].fadeOut(path, duration));
      return Promise.all(promises);
    }

    updateInteractions(path, options) {
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        _threeDManagers[i].updateInteractions(path, options);
    }

    removeFromInteractions(path) {
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        _threeDManagers[i].removeFromInteractions(path);
    }

    addMesh(mesh, materials, properties) {
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        _threeDManagers[i].addMesh(mesh, materials[i], properties);
    }

    removeMesh(mesh) {
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        _threeDManagers[i].removeMesh(mesh);
      
      mesh.geometry.dispose();
      mesh = undefined;
    }

    addAnchor(object, properties) {
      let viewports = [];
      if(properties.viewports && properties.viewports.length !== 0)
        viewports = properties.viewports;

      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        if(viewports.includes(_threeDManagers[i].runtimeId) || viewports.length === 0)
          _threeDManagers[i].addAnchor(object, properties);
    }

    removeAnchor(object) {
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        _threeDManagers[i].removeAnchor(object);
    }

    ////////////
    ////////////
    //
    // RenderingHandler
    //
    ////////////
    ////////////

    render() {
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        _threeDManagers[i].renderingHandler.render();
    }

    setBlur(blur, options) {
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        _threeDManagers[i].renderingHandler.setBlur(blur, options);
    }

    registerForContinuousRendering(id, rendering) {
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        _threeDManagers[i].renderingHandler.registerForContinuousRendering(id, rendering);
    }

    unregisterForContinuousRendering(id) {
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        _threeDManagers[i].renderingHandler.unregisterForContinuousRendering(id);
    }

    updateShadowMap() {
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        _threeDManagers[i].renderingHandler.updateShadowMap();
    }

    ////////////
    ////////////
    //
    // MaterialHandler
    //
    ////////////
    ////////////

    getMaterial(properties) {
      let materials = [];
      for (let i = 0, len = _threeDManagers.length; i < len; i++)
        materials.push(_threeDManagers[i].materialHandler.getMaterial(Object.assign({}, properties)));
      return materials;
    }
  }

  return {
    getInstance: function (viewerApp, loggingHandler) {
      if (!_instance)
        _instance = createInstance(viewerApp, loggingHandler);
      return _instance;
    }
  };
})();

module.exports = ViewportManager;
