/**
 * @file The CameraApi is a property of the ViewportApi and must be used by all corresponding ViewportApis.
 *       It is used for all camera related functionality.
 *
 * @module CameraApiDefault
 * @author Michael Oppitz
 */

let CameraApi = function (___api, ___refs) {

  /** @type {module:CameraApiInterface~CameraApiInterface} */
  const CameraApiInterface = require('../../../interfaces/api/CameraApiInterface');

  let /** @type {module:ApiInterfaceV2~ApiInterfaceV2} */
      _api = ___api,
      /** @type {module:ThreeDManagerDefault~ThreeDManager} */
      _threeDManager = ___refs.threeDManager,
      /** @type {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} */
      _apiResponse = ___refs.apiResponse,
      /** @type {Function} */
      _getCameraDefinition;


  /**
   * @extends module:CameraApiInterface~CameraApiInterface
   * @lends module:CameraApiDefault~CameraApi
   */
  class CameraApi extends CameraApiInterface {
    /**
     * The camera type, see {@link module:CameraApiDefault~CameraApi#TYPE} for values
     *
     * @typedef {Number} module:CameraApiDefault~CameraApi#CameraType
     */

    /**
     * The camera definition as the position and target as {@link module:ApiInterfaceV2~ApiInterfaceV2#Point3d}.
     *
     * @typedef {Object} module:CameraApiDefault~CameraApi#CameraDefinition
     * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} position The position of the camera in the scene
     * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} target The position of the target in the scene
     */

    /**
    * Definition of a transition function for camera movements
    *
    * @typedef {Object} module:CameraApiDefault~CameraApi#TransitionParameters
    * @property {Number} [duration=1000] - duration of the transition in milliseconds
    * @property {String|Function} [easing='Quartic.InOut'] - In case a string S is provided, the corresponding easing function TWEEN.Easing[S] will be used if it exists. The easing function may also be passed directly, e.g. one of the many provided by {@link https://github.com/tweenjs/tween.js/blob/master/docs/user_guide.md Tween}, see also {@link https://5013.es/toys/tween.audio/ TweenExplained}, or a manually defined one.
    * @property {String} [interpolation='CatmullRom'] - In case a string S is provided, the corresponding interpolation function TWEEN.Interpolation[S] will be used if it exists. Tween supports Linear, Bezier, and CatmullRom.
    * @property {String} [coordinates='cylindrical'] - Defines coordinate system to use for animated camera paths. One of 'spherical' or 'cylindrical'.
    */

    /**
     * ### ShapeDiver Viewer - Camera API
     *
     * @constructs module:CameraApiDefault~CameraApi
     */
    constructor() {
      super();

      /**
        * Enum for camera types.
        * @readonly
        * @enum {module:CameraApiDefault~CameraApi#CameraType}
        */
      this.TYPE = {
        /** perspective camera */
        PERSPECTIVE: 0,
        /** orthographic camera, top view */
        TOP: 1,
        /** orthographic camera, bottom view */
        BOTTOM: 2,
        /** orthographic camera, right view */
        RIGHT: 3,
        /** orthographic camera, left view */
        LEFT: 4,
        /** orthographic camera, back view */
        BACK: 5,
        /** orthographic camera, front view */
        FRONT: 6,
        /** AR camera */
        AR: 7
      };

      _getCameraDefinition = function () {
        return {
          position: _threeDManager.cameraHandler.getPosition(),
          target: _threeDManager.cameraHandler.getTarget()
        };
      };
    }

    /** @inheritdoc */
    get() {
      return _apiResponse(null, _getCameraDefinition());
    }

    /**
     * Update camera
     *
     * If target and/or position are provided, the camera will be moved to its new orientation
     *
     * @param {module:CameraApiDefault~CameraApi#CameraDefinition} camera - The new camera properties
     * @param {module:CameraApiDefault~CameraApi#TransitionParameters} [transition] - Definition of the parameters to use for the transition (tween)
     * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a {@link module:CameraApiDefault~CameraApi#CameraDefinition CameraDefinition} according to the new properties.
     */
    updateAsync(camera, transition) {
      return _threeDManager.cameraHandler.setPositionAndTarget(
        camera.position,
        camera.target,
        transition
      ).then(function (bSuccess) {
        if (bSuccess)
          return _apiResponse(null, _getCameraDefinition());
        else
          return _apiResponse('Setting new camera target and position failed', _getCameraDefinition());
      });
    }

    /**
     * Animate camera along a path
     *
     * @param {module:CameraApiDefault~CameraApi#CameraDefinition[]} path - Array of camera definition objects defining a camera path
     * @param {module:CameraApiDefault~CameraApi#TransitionParameters} [transition] - Definition of the parameters to use for the transition (tween)
     * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a {@link module:CameraApiDefault~CameraApi#CameraDefinition CameraDefinition} according to the camera's final position.
     */
    animateAsync(path, transition) {
      if (!Array.isArray(path)) return Promise.resolve(_apiResponse('First parameter must be an array of CameraDefinitions'));
      let positions = [], targets = [];
      path.forEach((p) => {
        positions.push(p.position);
        targets.push(p.target);
      });
      return _threeDManager.cameraHandler.setCameraPath(positions, targets, transition).then(function (bSuccess) {
        if (bSuccess)
          return _apiResponse(null, _getCameraDefinition());
        else
          return _apiResponse('Camera path animation failed', _getCameraDefinition());
      });
    }

    /**
     * Zoom to show some or all objects in the scene
     *
     * @param {module:ApiInterfaceV2~ScenePathType[]} [scenePaths] - The paths of the scene which the camera should capture. If none are provided, the camera will zoom to the scene extents.
     * @param {module:CameraApiDefault~CameraApi#TransitionParameters} [transition] - Definition of the parameters to use for the transition (tween)
     * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a {@link module:CameraApiDefault~CameraApi#CameraDefinition CameraDefinition} of the camera's new orientation.
     */
    zoomAsync(scenePaths, transition) {
      return _threeDManager.cameraHandler.zoomExtents(
        transition, _threeDManager.computeBoundingBox(scenePaths)
      ).then(function (bSuccess) {
        if (bSuccess)
          return _apiResponse(null, _getCameraDefinition());
        else
          return _apiResponse('Zooming to extents failed', _getCameraDefinition());
      });
    }

    /**
     * Reset camera to its currently configured default configuration
     *
     * @param {module:CameraApiDefault~CameraApi#TransitionParameters} [transition] - Definition of the parameters to use for the transition (tween)
     * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a {@link module:CameraApiDefault~CameraApi#CameraDefinition CameraDefinition} of the camera's default definition.
     */
    resetAsync(transition) {
      return _threeDManager.cameraHandler.resetPositionAndTarget(
        transition
      ).then(function (bSuccess) {
        if (bSuccess)
          return _apiResponse(null, _getCameraDefinition());
        else
          return _apiResponse('Resetting camera position or target failed', _getCameraDefinition());
      });
    }
  }

  return new CameraApi();
};

module.exports = CameraApi;
