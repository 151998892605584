/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ViewerAppParameterManager.js*
 *
 * ### Content
 *   * Parameter functionality of ViewerApp
 *
 * @module ViewerAppParameterManager
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
  * Imported global utilities
  */
var GlobalUtils = require('../../shared/util/GlobalUtils');

/**
  * Imported plugin constant definitions
  */
var pluginConstants = require('../../shared/constants/PluginConstantsGlobal');

/**
  * Imported message constant definitions
  */
var messagingConstants = require('../../shared/constants/MessagingConstants');

/**
  * Imported message prototype
  */
var MessagePrototype = require('../../shared/messages/MessagePrototype');

/**
 * Constructor of the ViewerAppParameterManager mixin
 * @mixin ViewerAppParameterManager
 * @author Alex Schiftner <alex@shapediver.com>
 *
 * @param {Object} references - References to other managers
 * @param {Object} references.pluginManager - Reference to the plugin manager
 */
var ViewerAppParameterManager = function(___refs) {

  var that = this;

  /**
   * Object for collecting public members, this object will be returned instead of the default "this"
   */
  var _o = {};

  /**
   * Private container for parameters
   */
  var _parameters = [];

  /**
   * Import parameter implementations
   */
  var _sdparams = require('../../shared/types/ShapeDiverParameters');

  /**
   * Create private parameter factory, using our own settings (loggingLevel)
   */
  var _parameterFactory = new _sdparams.ParameterFactory( that.getSettings() );

  var _pluginManager;
  if ( ___refs && ___refs.hasOwnProperty('pluginManager') && ___refs.pluginManager.hasOwnProperty('getPluginByRuntimeId'))
    _pluginManager = ___refs.pluginManager;

  /**
   * Parameter history - parameter states
   */
  var _historyParameterStates = [];

  /**
   * Parameter history - state indices
   * Indices into _historyParameterStates
   */
  var _historyStateIndices = [];

  /**
   * Record a history state
   * @param {Object} [currentState] - optional parameter state to record, current parameter values will be used if not given
   */
  var _recordHistory = function(currentState) {
    // we record the parameter state only if we are not currently exploring the history
    if (_historyParameterStates.length !== _historyStateIndices.length) {
      // recreate history arrays
      let newStates = [];
      let newIndices = [];
      let imax = _historyStateIndices.length;
      let imin = Math.max(0,imax-50);
      for (let i=imin; i<imax; i++) { // keep max 100 history entries
        let idx = _historyStateIndices[i];
        let state = _historyParameterStates[idx];
        newStates.push(state);
        newIndices.push(i-imin);
      }
      _historyStateIndices = newIndices;
      _historyParameterStates = newStates;
    }
    // did we get a current state?
    if (!currentState) currentState = _o.getParameterState();
    // add latest state
    _historyParameterStates.push(currentState);
    _historyStateIndices.push(_historyParameterStates.length - 1);
  };

  /**
   * Check whether it is possible to go back in history
   * @return {Boolean} true if it is possible to go back in history
   */
  _o.canGoBackInHistory = function() {
    // check if there is some history
    if (_historyParameterStates.length <= 0) return false;
    // check if last index is positive
    let last_idx = _historyStateIndices[_historyStateIndices.length - 1];
    if (last_idx <= 0) return false;
    // ok, we can go back
    return true;
  };

  /**
   * Check whether it is possible to go forward in history
   * @return {Boolean} true if it is possible to go forward in history
   */
  _o.canGoForwardInHistory = function() {
    // check if there is some history
    if (_historyParameterStates.length <= 0) return false;
    // check if index can be increased (to maximum _historyParameterStates.length - 1)
    let last_idx = _historyStateIndices[_historyStateIndices.length - 1];
    if (last_idx >= _historyParameterStates.length - 1) return false;
    // ok, we can go forward
    return true;
  };

  /**
   * Go back in history, if this is possible
   * @param {String|module:MessagingConstants~MessageToken} [token] - Will be used by process messages related to this request, a random token will be created if none is provided.
   * @return {Boolean} true if we are going back in history
   */
  _o.goBackInHistory = function(token) {
    // can we go back?
    if (!_o.canGoBackInHistory()) return false;
    // attach a further state index to _historyParameterStates
    let last_idx = _historyStateIndices[_historyStateIndices.length - 1];
    let new_idx = last_idx - 1;
    _historyStateIndices.push(new_idx);
    // parameter update
    _o.setMultipleParameterValues(_historyParameterStates[new_idx], token, false, true);
    // ok, we can go back
    return true;
  };

  /**
   * Go forward in history, if this is possible
   * @param {String|module:MessagingConstants~MessageToken} [token] - Will be used by process messages related to this request, a random token will be created if none is provided.
   * @return {Boolean} true if we are going forward in history
   */
  _o.goForwardInHistory = function(token) {
    // can we go forward?
    if (!_o.canGoForwardInHistory()) return false;
    // attach a further state index to _historyParameterStates
    let last_idx = _historyStateIndices[_historyStateIndices.length - 1];
    let new_idx = last_idx + 1;
    _historyStateIndices.push(new_idx);
    // parameter update
    _o.setMultipleParameterValues(_historyParameterStates[new_idx], token, false, true);
    // ok, we can go forward
    return true;
  };

  /**
   * Register multiple parameters
   *
   * @public
   * @param {module:JSONParameter~JSONParameterSet} objdef - Definition of the parameters
   * @param {String} plugin - Runtime id of plugin the parameters belong to
   * @return {String[]} ids of the parameters on success, undefined on error
   */
  _o.registerMultipleParameters = function(objdef, plugin) {
    var ids = [];
    Object.keys(objdef).forEach( function(id) {
      // add id as property of parameter definition
      let paramDef = GlobalUtils.deepCopy( objdef[id] );
      paramDef.id = id;
      // TODO remove check once time parameter is implemented
      if ( paramDef.type.toLowerCase() !== 'time' ) {
        let res = _o.registerParameter(paramDef, plugin);
        if ( res === undefined )
          return;
        ids.push(res);
      }
    });
    return ids;
  };

  /**
   * Register a parameter
   *
   * @public
   * @param {module:JSONParameter~JSONParameter} def - Definition of the parameter
   * @param {String} plugin - Runtime id of plugin the parameter belongs to
   * @return {String} id of the parameter on success, undefined on error
   */
  _o.registerParameter = function(def, plugin) {
    var scope = 'ViewerAppParameterManager.registerParameter';

    // parameter sanity check
    if ( plugin === undefined || typeof plugin !== 'string' ) {
      that.debug(scope, 'No plugin specified');
      return;
    }
    if ( !def || typeof def !== 'object' ) {
      that.debug(scope, 'Definition of parameter missing');
      return;
    }

    // set plugin as part of parameter definition
    def.plugin = plugin;

    // check if def has a unique id, if not create one
    if ( def.id === undefined || typeof def.id !== 'string' ) {
      def.id = GlobalUtils.createRandomId();
      that.debug(scope, 'No id was specified, using ' + def.id);
    }

    // check if a parameter with the given id and plugin is already registered
    if ( undefined !== _o.getUniqueParameterBySettings({id: def.id, plugin: plugin}) ) {
      that.debug(scope, 'Parameter with id ' + def.id + ' and plugin ' + plugin + ' already exists');
      return;
    }

    // register parameter (instantiate it, add it to _parameters)
    var param = _parameterFactory.produce( def );
    if ( param === undefined ) {
      that.debug(scope, 'Parameter factory failed for definition', def);
      return;
    }
    _parameters.push( param );

    // notify world about registration of new parameter
    let messagePart = {};
    messagePart[def.id] = def;
    that.message( messagingConstants.messageTopics.PARAMETER_REGISTERED, new MessagePrototype(
      messagingConstants.messageDataTypes.PARAMETER_DEFINITION,
      messagePart
    )
    );

    // success - return id
    return def.id;
  };

  /**
   * Update properties of a registered parameter
   * The following properties can be updated: name(string), order(number), hidden(boolean)
   *
   * @public
   * @param {module:JSONParameter~JSONParameter} def - new definition of the parameter
   * @return {Boolean} true on success, false on error
   */
  _o.updateParameter = function(def) {
    let scope = 'ViewerAppParameterManager.updateParameter';

    // parameter sanity check
    if ( !def || typeof def !== 'object' ) {
      that.debug(scope, 'Definition of parameter missing');
      return false;
    }

    // get unique parameter matching def
    let idx = getUniqueParameterIdxByUpdateObject( def );
    if ( idx === undefined ) {
      that.debug(scope, 'Parameter not found', def);
      return false;
    }
    let p = _parameters[idx];

    // update parameter settings name, order, hidden
    let prev_name = p.getSetting('name');
    if ( typeof def.name === 'string' && def.name.length > 0 && def.name !== prev_name ) {
      if (!p.getSetting('_name')) {
        p.updateSetting('_name', prev_name); // remember original name
      }
      p.updateSetting('name', def.name);
    }
    if ( typeof def.order === 'number' ) {
      p.updateSetting('order', def.order);
    }
    if ( typeof def.hidden === 'boolean' ) {
      p.updateSetting('hidden', def.hidden);
    }

    // send message reporting the update to the parameter definition
    let defNew = p.getDefinition();
    let messagePart = {};
    messagePart[defNew.id] = defNew;
    that.message( messagingConstants.messageTopics.PARAMETER_UPDATE, new MessagePrototype(
      messagingConstants.messageDataTypes.PARAMETER_DEFINITION,
      p.getDefinition()
    )
    );

    // success
    return true;
  };

  /**
  * Update properties of registered parameters
  * The following properties can be updated: name(string), order(number), hidden(boolean)
   *
   * @public
   * @param {module:JSONParameter~JSONParameterSet[]} arrDef - new definitions of the parameters
   * @return {Boolean} true on success, false on error
   */
  _o.updateMultipleParameters = function(arrDef) {
    if (!Array.isArray(arrDef)) arrDef = [arrDef];
    return arrDef.every((def) => (_o.updateParameter(def)));
  };

  /**
   * Register configuration settings related to parameters which are sent by a plugin
   *
   * Handles the following properties of the config object:
   *  * controlNames,
   *  * controlOrder,
   *  * parametersHidden
   * Updates the settings 'name', 'order', and 'hidden' of parameters accordingly.
   * @public
   * @param {module:JSONConfig~JSONConfig} config - configuration object
   * @param {String} plugin - runtime id of the plugin which sent the configuration object
   * @return {Boolean} true in case of success, false otherwise
   */
  _o.registerConfig = function(config, plugin) {

    // collect updated parameter definitions, for sending messages after doing all updates
    let updatedParameters = {};

    // controlNames property
    if ( config.controlNames && typeof config.controlNames === 'object' ) {
      let cn = config.controlNames;
      Object.keys(cn).forEach( (id) => {
        let p = _o.getUniqueParameterBySettings({id: id, plugin: plugin});
        if ( p ) {
          // remember original name, if it has not been remember before
          if (!p.getSetting('_name')) {
            p.updateSetting('_name', p.getSetting('name')); // remember original name
          }
          p.updateSetting('name', cn[id]);
          updatedParameters[p.getSettingShallow('id')] = p;
        }
      });
    }

    // controlOrder property
    if ( config.controlOrder && Array.isArray(config.controlOrder) ) {
      let co = config.controlOrder;
      for (let i=0; i<co.length; i++) {
        let id = co[i];
        let p = _o.getUniqueParameterBySettings({id: id, plugin: plugin});
        if ( p ) {
          p.updateSetting('order', i);
          updatedParameters[p.getSettingShallow('id')] = p;
        }
      }
    }

    // parametersHidden property
    if ( config.parametersHidden && Array.isArray(config.parametersHidden) ) {
      let ph = config.parametersHidden;
      for (let i=0; i<ph.length; i++) {
        let id = ph[i];
        let p = _o.getUniqueParameterBySettings({id: id, plugin: plugin});
        if ( p ) {
          p.updateSetting('hidden', true);
          updatedParameters[p.getSettingShallow('id')] = p;
        }
      }
    }

    // send message reporting the update to the parameter definition
    Object.keys(updatedParameters).forEach(function(id) {
      let def = updatedParameters[id].getDefinition();
      let messagePart = {};
      messagePart[def.id] = def;
      that.message( messagingConstants.messageTopics.PARAMETER_UPDATE, new MessagePrototype(
        messagingConstants.messageDataTypes.PARAMETER_DEFINITION,
        messagePart
      )
      );
    });

    return true;
  };

  /**
   * Get a partial {@link module:JSONConfig~JSONConfig configuration object},
   * containing the data of the configuration object related to parameters:
   *  * controlNames,
   *  * controlOrder,
   *  * parametersHidden
   *
   * @public
   * @param {String} plugin - runtime id of the plugin for which the configuration object shall be returned
   * @return {module:JSONConfig~JSONConfig} partial configuration object in case of success, undefined in case of error
   */
  _o.getConfig = function(plugin) {
    // get parameters for plugin
    let parameterDefinitions = _o.getParameterDefinitionsAndValues({plugin: plugin});
    // check if we got sth
    if (!Array.isArray(parameterDefinitions)) return;
    if (parameterDefinitions.length === 0) return {};
    // create partial config object
    let config = {};
    parameterDefinitions.forEach((def) => {
      // parameter name
      if (GlobalUtils.typeCheck(def.name, 'string') && GlobalUtils.typeCheck(def._name, 'string')) {
        config.controlNames = config.controlNames || [];
        config.controlNames.push({id: def.id, name: def.name});
      }
      // hidden?
      if (GlobalUtils.typeCheck(def.hidden, 'boolean') && def.hidden) {
        config.parametersHidden = config.parametersHidden || [];
        config.parametersHidden.push(def.id);
      }
      // order
      if (GlobalUtils.typeCheck(def.order, 'number')) {
        config.controlOrder = config.controlOrder || [];
        config.controlOrder.push({id: def.id, order: def.order});
      }
    });
    return config;
  };

  /**
   * Deregister a parameter
   * Id and plugin of the parameter must be specified, because a parameter id might occur
   * several times for different instances of plugins.
   * @public
   * @param {String} id - unique id of the parameter to deregister
   * @param {String} plugin - runtime id of the plugin
   * @return {Boolean} true in case of success, false otherwise
   */
  _o.deregisterParameter = function(id, plugin) {
    var scope = 'ViewerAppParameterManager.deregisterParameter';
    var settings = {};
    settings.id = id;
    if ( plugin !== undefined )
      settings.plugin = plugin;
    var idx = getUniqueParameterIdxBySettings(settings);
    if ( idx === undefined ) {
      that.error(scope, 'Parameter with id ' + id + ' and plugin ' + plugin + ' not found');
      return false;
    }
    // FIXME remove parameter from user interface
    var param = _parameters.splice(idx, 1)[0];
    that.info(scope, 'Deregistered parameter "' + param.getSetting('name') + '" with id ' + id );
    return true;
  };

  /**
   * Get parameter by its id and optional plugin runtime id.
   * Fails if there are multiple parameters matching the search criteria.
   * @public
   * @param {String} id - unique id of the parameter to get
   * @param {String} [plugin] - runtime id of plugin the parameter belongs to
   * @return {Object} parameter object on success, undefined on error
   */
  _o.getParameterById = function(id, plugin) {
    var settings = {};
    settings.id = id;
    if ( plugin !== undefined ) {
      settings.plugin = plugin;
    }
    return _o.getUniqueParameterBySettings(settings);
  };

  /**
   * Get unique parameter by its name and optional plugin runtime id.
   * Fails if there are multiple parameters matching the search criteria.
   * @public
   * @param {String} name - name of the parameter to get
   * @param {String} [plugin] - runtime id of plugin the parameter belongs to
   * @return {Object} parameter object on success, undefined on error
   */
  _o.getParameterByName = function(name, plugin) {
    var settings = {};
    settings.name = name;
    if ( plugin !== undefined ) {
      settings.plugin = plugin;
    }
    return _o.getUniqueParameterBySettings(settings);
  };

  /**
   * Get parameter by its id or its name and optional plugin runtime id.
   * @public
   * @param {String} idOrName - unique id of the parameter to get, or its name
   * @param {String} [plugin] - runtime id of plugin the parameter belongs to
   * @return {Object} parameter object on success, undefined on error
   */
  _o.getParameterByIdOrName = function(idOrName, plugin) {
    // try to find by id
    var settings = {};
    settings.id = idOrName;
    if ( plugin !== undefined ) {
      settings.plugin = plugin;
    }
    var param = _o.getUniqueParameterBySettings(settings);
    if ( param !== undefined ) {
      return param;
    }
    // try to find by name
    delete settings.id;
    settings.name = idOrName;
    return _o.getUniqueParameterBySettings(settings);
  };

  /**
   * Get parameters by one or more of their settings. Setting values must match precisely.
   * @public
   * @param {Object} settings - properties to search for, works for any setting of parameter
   * @param {String} [settings.id] - id of parameter
   * @param {String} [settings.name] - name of parameter
   * @param {String} [settings.plugin] - runtime id of plugin the parameter belongs to
   * @param {String} [settings.type] - type of parameter
   * @return {Object[]} array of matching parameter objects, may be empty
   */
  _o.getParametersBySettings = function(settings) {
    // filter returns an empty array if no match
    return _parameters.filter( function(p) {
      return Object.keys(settings).every( function(key) {
        if ( p.hasSetting(key) && p.getSettingShallow(key) === settings[key] ) {
          return true;
        }
        return false;
      });
    });
  };

  /**
   * Check if parameter ids are unique.
   * @public
   * @return {Boolean} true if there are no duplicate parameter ids, false if there is at least one parameter id which occurs twice (for different plugin runtime ids)
   */
  _o.idsUnique = function() {
    var ids = [];
    return _parameters.every( function(p) {
      let id = p.getSetting('id');
      if ( ids.includes(id) )
        return false;
      ids.push(id);
      return true;
    });
  };

  /**
   * Check if parameter names are unique.
   * @public
   * @return {Boolean} true if there are no duplicate parameter names, false if there is at least one parameter name which occurs twice (for different plugin runtime ids)
   */
  _o.namesUnique = function() {
    var names = [];
    return _parameters.every( function(p) {
      let n = p.getSetting('name');
      if ( names.includes(n) )
        return false;
      names.push(n);
      return true;
    });
  };

  /**
   * Get parameter indices by one or more of their settings. Setting values must match precisely.
   * @private
   * @param {Object} settings - properties to search for, works for any setting of parameter
   * @param {String} [settings.id] - id of parameter
   * @param {String} [settings.name] - name of parameter
   * @param {String} [settings.plugin] - runtime id of plugin the parameter belongs to
   * @param {String} [settings.type] - type of parameter
   * @return {Object[]} array of matching parameter objects, may be empty
   */
  var getParametersIdxBySettings = function(settings) {
    var indices = [];
    _parameters.forEach( function(p, idx) {
      var match = Object.keys(settings).every( function(key) {
        if ( p.hasSetting(key) && p.getSettingShallow(key) === settings[key] ) {
          return true;
        }
        return false;
      });
      if ( match )
        indices.push(idx);
    });
    return indices;
  };

  /**
   * Get index of uniquely matching parameter for the given settings. Does not return an index if multiple parameters match the settings.
   * @private
   * @param {Object} settings - properties to search for, works for any setting of parameter
   * @param {String} [settings.id] - id of parameter
   * @param {String} [settings.name] - name of parameter
   * @param {String} [settings._name] - original name of parameter
   * @param {String} [settings.plugin] - runtime id of plugin the parameter belongs to
   * @param {String} [settings.type] - type of parameter
   * @return {Number} index of unique matching parameter, undefined if not found
   */
  var getUniqueParameterIdxBySettings = function(settings) {
    var indices = getParametersIdxBySettings(settings);
    if ( indices.length === 1 ) {
      return indices[0];
    }
  };

  /**
   * Get uniquely matching parameter for the given settings. Does not return a parameter if multiple ones match the settings.
   * @public
   * @param {Object} settings - properties to search for, works for any setting of parameter
   * @param {String} [settings.id] - id of parameter
   * @param {String} [settings.name] - name of parameter
   * @param {String} [settings.plugin] - runtime id of plugin the parameter belongs to
   * @param {String} [settings.type] - type of parameter
   * @return {Object} unique matching parameter, undefined if not found
   */
  _o.getUniqueParameterBySettings = function(settings) {
    var params = _o.getParametersBySettings(settings);
    if ( params.length === 1 ) {
      return params[0];
    }
  };

  /**
   * Given a parameter id (which might occur in several plugins), get a unique identifier for it by
   * appending the plugin runtime id, in case the parameter id occurs several times
   * @public
   * @param {Object} p - Parameter object
   * @return {String} Unique parameter identifier, undefined on error
   */
  var getUniqueParameterIdentifier = function(p) {
    let key = p.getSetting('id');
    if ( _o.getParametersBySettings({id: key}).length > 1 ) {
      key = key + '__' + p.getSetting('plugin');
    }
    return key;
  };

  /**
   * Get definitions of all registered parameters, or of a subset of parameters
   * @public
   * @param {Object} [settings] - optional properties to search for, works for any setting of parameter
   * @param {String} [settings.id] - id of parameter
   * @param {String} [settings.name] - name of parameter
   * @param {String} [settings.plugin] - runtime id of plugin the parameter belongs to
   * @param {String} [settings.type] - type of parameter
   * @return {module:JSONParameter~JSONParameterSet} Object containing parameter definitions keyed by their id, undefined on error
   */
  _o.getParameterDefinitions = function(settings) {
    var scope = 'ViewerAppParameterManager.getParameterDefinitions';
    // get definitions of all registered or found parameters, return them
    var ps;
    if ( settings !== undefined && typeof settings === 'object' ) {
      ps = _o.getParametersBySettings(settings);
    } else {
      ps = _parameters;
    }
    var parameters = {};
    ps.forEach( function(p, idx) {
      var def = p.getDefinition();
      if ( !def || typeof def !== 'object' ) {
        that.debug(scope, 'Could not get definition of parameter ' + idx);
      } else {
        // in case of names are not unique, use a combination of id and plugin as key
        parameters[ getUniqueParameterIdentifier(p) ] = def;
      }
    });
    return parameters;
  };

  /**
   * Get definitions and values of all registered parameters, or of a subset of parameters.
   * The returned parameters are sorted by plugin and (order or name).
   * @public
   * @param {Object} [settings] - optional properties to search for, works for any setting of parameter
   * @param {String} [settings.id] - id of parameter
   * @param {String} [settings.name] - name of parameter
   * @param {String} [settings.plugin] - runtime id of plugin the parameter belongs to
   * @param {String} [settings.type] - type of parameter
   * @return {module:JSONParameter~JSONParameterAndValue[]} array of parameter definitions and values, undefined on error
   */
  _o.getParameterDefinitionsAndValues = function(settings) {
    let scope = 'ViewerAppParameterManager.getParameterDefinitionsAndValues';
    // get definitions of all registered or found parameters, return them
    let ps, parameters;
    if ( settings !== undefined && typeof settings === 'object' ) {
      ps = _o.getParametersBySettings(settings);
    } else {
      ps = _parameters;
    }
    parameters = [];
    ps.forEach( function(p, idx) {
      let def = p.getDefinition();
      if ( !def || typeof def !== 'object' ) {
        that.debug(scope, 'Could not get definition of parameter ' + idx);
      } else {
        // add value to definition
        def.value = p.getValue();
        parameters.push(def);
      }
    });
    // sort parameters according to 'plugin' and 'order', or fall back to 'plugin' and 'name' if order is not defined
    parameters.sort((a,b) => {
      let as, bs;
      as = a.plugin;
      bs = b.plugin;
      if (typeof a.order === 'number' && typeof b.order === 'number') {
        as += Math.floor(1000*a.order).toString().padStart(8,'0');
        bs += Math.floor(1000*b.order).toString().padStart(8,'0');
      } else {
        as += a.name;
        bs += b.name;
      }
      if (as < bs) return -1;
      else if (as > bs) return 1;
      return 0;
    });
    return parameters;
  };

  /**
   * Parameter update object
   * @typedef {Object} ParameterUpdateObject
   * @property {String} [id] - id of parameter, takes precedence over idOrName and name
   * @property {String} [idOrName] - id or name of parameter, takes precedence over name
   * @property {String} [name] - name of parameter, last priority after id and idOrName
   * @property {String} [plugin] - runtime id of plugin the parameter belongs to
   * @property {*} value - Parameter value to set
   */

  /**
   * Object resulting from calling {@link ViewerAppParameterManager~setParameterValue} or {@link ViewerAppParameterManager~setMultipleParameterValues}
   * @typedef {Object} SetParameterResultObject
   * @property {Object} [params] - TODO be more specific: Object containing a key for each parameter id which reports the result of value checking
   * @property {Object} [plugins] - TODO be more specific: Object containing a key for each plugin runtime id which reports the result of calling the parameter update function
   * @property {Array} [err] - TODO be more specific: array of error objects
   * @property {Array} [warn] - TODO be more specific: array of warning objects
   */


  /**
   * Get index of parameter matching the parameter update object. Does not return an index if multiple parameters match.
   * @private
   * @param {ParameterUpdateObject} puo - Object defining parameter update
   * @return {Number} index of matching parameter, undefined if not found
   */
  var getUniqueParameterIdxByUpdateObject = function(puo) {

    // input sanity check
    if ( !puo || typeof puo !== 'object' )
      return;

    // define settings for search
    var settings = {};
    if ( puo.plugin !== undefined && typeof puo.plugin === 'string' )
      settings.plugin = puo.plugin;

    var idx;

    // search by id and optional plugin
    if ( puo.id !== undefined && typeof puo.id === 'string' ) {
      settings.id = puo.id;
      idx = getUniqueParameterIdxBySettings(settings);
      if ( idx !== undefined )
        return idx;
      delete settings.id;
    }

    // search by id or name and optional plugin
    if ( puo.idOrName !== undefined && typeof puo.idOrName === 'string' ) {
      settings.id = puo.idOrName;
      idx = getUniqueParameterIdxBySettings(settings);
      if ( idx !== undefined )
        return idx;
      delete settings.id;
      settings.name = puo.idOrName;
      idx = getUniqueParameterIdxBySettings(settings);
      if ( idx !== undefined )
        return idx;
      delete settings.name;
    }

    // search by original name and optional plugin
    if ( puo._name !== undefined && typeof puo._name === 'string' ) {
      settings._name = puo._name;
      idx = getUniqueParameterIdxBySettings(settings);
      if ( idx !== undefined )
        return idx;
    }

    // search by name and optional plugin
    if ( puo.name !== undefined && typeof puo.name === 'string' ) {
      settings.name = puo.name;
      idx = getUniqueParameterIdxBySettings(settings);
      if ( idx !== undefined )
        return idx;
    }

    // nothing found
  };

  /**
   * Check whether the given parameter update objects will a prior upload
   *
   * @public
   * @param {ParameterUpdateObject|ParameterUpdateObject[]} puoArr - Objects defining parameter updates
   * @return {Boolean} - undefined on error, false if no prior upload is required, true if prior upload is required
   */
  _o.updateRequiresPriorUpload = function(puoArr) {
    if (!Array.isArray(puoArr)) puoArr = [puoArr];
    let requiresPriorUpload = false;
    for (let i=0, imax=puoArr.length; i<imax; i++) {
      let puo = puoArr[i];
      // FIXME we might want to reverse this logic and check if there are any 'File' parameters which
      // are part of puoArr. This would allow us to transparently support long string values for text file upload parameters.
      // until then, we only need to check for values that are a Blob
      if (puo.value instanceof Blob) {
        // get parameter definition
        let idx = getUniqueParameterIdxByUpdateObject( puo );
        if ( idx === undefined ) {
          return;
        }
        let param = _parameters[idx];
        // check if parameter accepts Blobs
        if (param.acceptsBlobs()) {
          requiresPriorUpload = true;
        }
      }
    }
    return requiresPriorUpload;
  };

  /**
   * Run prior upload for given parameter update objects
   *
   * @public
   * @param {ParameterUpdateObject|ParameterUpdateObject[]} puoArr - Objects defining parameter updates
   * @return {Promise}
   */
  _o.priorUpload = function(puoArr) {
    var scope = 'ViewerAppParameterManager.priorUpload';

    // collect result of parameter update per plugin and parameter
    var retval = {params : [], plugins : {}};

    // helper for adding errors to retval
    var addError = function(code, msg, data) {
      if ( !retval.err ) retval.err = [];
      retval.err.push({code: code, msg: msg, data: data});
    };

    // sanity check
    if (!Array.isArray(puoArr)) {
      addError(
        pluginConstants.setParameterResults.ERROR,
        'Not an array'
      );
      return Promise.resolve(retval);
    }

    // check for plugin manager - could be unset during testing
    if ( _pluginManager === undefined ) {
      let m = 'Plugin functionality not found';
      addError(pluginConstants.setParameterResults.PLUGIN_NOT_FOUND, m);
      that.debug(scope, m);
      return Promise.resolve(retval);
    }

    // collect blob parameters per plugin
    let fileUploadRequestData = {};
    for (let i=0, imax=puoArr.length; i<imax; i++) {
      let puo = puoArr[i];
      // we only need to check for values that are a Blob
      if (puo.value instanceof Blob) {
        // get parameter definition
        let idx = getUniqueParameterIdxByUpdateObject( puo );
        if ( idx === undefined ) {
          let m = 'Could not find parameter';
          addError(pluginConstants.setParameterResults.PARAM_NOT_FOUND, m, puo);
          that.debug(scope, m, puo);
          return Promise.resolve(retval);
        }
        let param = _parameters[idx];
        puo.id = param.getSetting('id');
        puo.plugin = param.getSetting('plugin');
        // check if parameter accepts Blobs
        if (!param.checkBlob(puo.value)) {
          let m = 'Blob size or type rejected';
          addError(pluginConstants.setParameterResults.VALUE_REJECT, m, puo);
          retval.params.push({
            id: puo.id,
            plugin: puo.plugin,
            result: pluginConstants.setParameterResults.VALUE_REJECT
          });
          that.debug(scope, m);
          return Promise.resolve(retval);
        }
        // add blob to fileUploadRequestData
        if (!fileUploadRequestData.hasOwnProperty(puo.plugin)) {
          fileUploadRequestData[puo.plugin] = {request:{}, data:{}};
        }
        // ...create an object keyed by the parameter id, containing format and size of the blob
        fileUploadRequestData[puo.plugin].request[puo.id] = {format: puo.value._type, size: puo.value.size};
        fileUploadRequestData[puo.plugin].data[puo.id] = puo.value;
        retval.params.push({
          id: puo.id,
          plugin: puo.plugin,
          result: pluginConstants.setParameterResults.VALUE_OK
        });
      }
    }

    // for each plugin ...
    let pluginPromises = [];
    let pluginRuntimeIds = [];
    for (let runtimeId in fileUploadRequestData) {
      // check for plugin
      let plugin = _pluginManager.getPluginByRuntimeId(runtimeId);
      if ( !plugin ) {
        addError(
          pluginConstants.setParameterResults.PLUGIN_NOT_FOUND,
          'Plugin not found',
          runtimeId
        );
        that.debug(scope, 'Plugin ' + runtimeId + ' not found');
        retval.plugins[runtimeId] = pluginConstants.setParameterResults.PLUGIN_NOT_FOUND;
        return Promise.resolve(retval);
      }
      // ...send a model file upload request and in case of success upload blobs
      pluginPromises.push( plugin.fileUpload(fileUploadRequestData[runtimeId]) );
      pluginRuntimeIds.push( runtimeId );
    }

    // wait for all promises
    return Promise.all(pluginPromises)
      .then(
        function(arrParameterFileIds) {
          // assign file ids to puoArr
          for (let i=0, imax=arrParameterFileIds.length; i<imax; i++) {
            let plugin = pluginRuntimeIds[i];
            let parameterFileIds = arrParameterFileIds[i];
            for (let id in parameterFileIds) {
              // find entry in puoArr
              let entry = puoArr.find((e) => (e.plugin === plugin && e.id === id));
              if (!entry) {
                throw new Error(scope + ': internal error, parameter not found');
              }
              entry.value = parameterFileIds[id];
            }
          }
          return retval;
        },
        function(error) {
          addError(
            pluginConstants.setParameterResults.UPLOAD_FAILED,
            'Upload failed',
            error
          );
          return retval;
        }
      )
    ;
  };

  /**
   * Set single parameter value
   *
   * @public
   * @todo document returned object
   * @param {ParameterUpdateObject} puo - Object defining parameter update
   * @param {String|module:MessagingConstants~MessageToken} [token] - Will be used by process messages related to this request, a random token will be created if none is provided. The token will be returned as a property of the return object of this function.
   * @return {Object} undefined on general error, otherwise contains a key for each plugin that was called (runtime id), the value being the result of the setParameter operation
   */
  _o.setParameterValue = function(puo, token) {
    // use setMultipleParameterValues
    var retval = _o.setMultipleParameterValues([puo], token);
    return retval;
  };

  /**
   * Set multiple parameter values
   *
   * @public
   * @todo document returned object
   * @param {ParameterUpdateObject[]} puoArr - Objects defining parameter updates
   * @param {String|module:MessagingConstants~MessageToken} [token] - Will be used by process messages related to this request, a random token will be created if none is provided. The token will be returned as a property of the return object of this function.
   * @param {Boolean} [force=false] - If true, force a parameter update, even if all parameter values are already set
   * @param {Boolean} [bHistoryUpdate=false] - If true, assume this update is caused by a history operation, i.e. don't record history in this case
   * @return {Object} contains a key for each plugin that was called (runtime id), the value being the result of the setParameter operation
   */
  _o.setMultipleParameterValues = function(puoArr, token, force, bHistoryUpdate) {
    var scope = 'ViewerAppParameterManager.setMultipleParameterValues';

    // sanity check
    if (force === undefined)
      force = false;

    // initial history record
    var currentState = _o.getParameterState();

    // collect result of parameter update per plugin and parameter
    var retval = {params : [], plugins : {}};

    // did we get a token? if not, create one.
    token = messagingConstants.makeMessageToken(token);
    retval.token = token;

    // helper for adding errors to retval
    var addError = function(code, msg, data) {
      if ( !retval.err ) retval.err = [];
      retval.err.push({code: code, msg: msg, data: data});
    };
    var addWarning = function(code, msg, data) {
      if ( !retval.warn ) retval.warn = [];
      retval.warn.push({code: code, msg: msg, data: data});
    };

    // find parameter indices, remember as property "idx"
    // at the same time check validity of new values, and
    // collect list of plugins of given parameters
    var plugins_all = [];

    puoArr.forEach( function(puo) {
      // get parameter index
      var idx = getUniqueParameterIdxByUpdateObject( puo );
      if ( idx === undefined ) {
        addError(
          pluginConstants.setParameterResults.PARAM_NOT_FOUND,
          'Could not find parameter',
          puo
        );
        that.debug(scope, 'Could not find parameter', puo);
        return;
      }
      // remember index
      puo.idx = idx;

      // make sure id of parameter and plugin are part of puo, we need them later
      var param = _parameters[idx];
      //let uid = getUniqueParameterIdentifier(param);
      puo.id = param.getSetting('id');
      puo.plugin = param.getSetting('plugin');

      // collect plugins
      if ( !plugins_all.includes(puo.plugin) )
        plugins_all.push( puo.plugin );

      // check whether new value exists
      if ( puo.value === undefined ) {
        addError(
          pluginConstants.setParameterResults.NO_VALUE,
          'Parameter value not set',
          puo.value
        );
        retval.params.push({
          id: puo.id,
          plugin: puo.plugin,
          result: pluginConstants.setParameterResults.NO_VALUE
        });
        that.debug(scope, 'Parameter value not set', puo, param);
        return;
      }

      // check whether new value is valid
      if ( !param.checkValue(puo.value) ) {
        addError(
          pluginConstants.setParameterResults.VALUE_REJECT,
          'Parameter value rejected',
          puo.value
        );
        retval.params.push({
          id: puo.id,
          plugin: puo.plugin,
          result: pluginConstants.setParameterResults.VALUE_REJECT
        });
        that.debug(scope, 'Parameter value rejected', puo, param);
        return;
      }
    });
    if ( retval.err || retval.warn ) {
      return retval;
    }

    // set parameter values, collect list of plugins for which a parameter value update occurs
    let bAllValuesExist = true;
    var plugins_update = [];
    puoArr.forEach( function(puo) {
      let param = _parameters[puo.idx];
      //let uid = getUniqueParameterIdentifier(param);
      if ( !param.isValueDifferent(puo.value) ) {
        retval.params.push({
          id: puo.id,
          plugin: puo.plugin,
          result: pluginConstants.setParameterResults.VALUE_EXISTS
        });
        return;
      }
      // collect plugins for which a parameter value update occurs
      if ( !plugins_update.includes(puo.plugin) )
        plugins_update.push( puo.plugin );
      bAllValuesExist = false;
      if ( !param.setValue(puo.value) ) {
        addError(
          pluginConstants.setParameterResults.VALUE_REJECT,
          'Parameter value rejected',
          puo.value
        );
        retval.params.push({
          id: puo.id,
          plugin: puo.plugin,
          result: pluginConstants.setParameterResults.VALUE_REJECT
        });
        that.debug(scope, 'Parameter value rejected', puo, param);
        return;
      }
      retval.params.push({
        id: puo.id,
        plugin: puo.plugin,
        result: pluginConstants.setParameterResults.VALUE_OK
      });
    });
    if ( retval.err ) {
      let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_ERROR, retval.err, token);
      that.message( messagingConstants.messageTopics.PROCESS, m);
      return retval;
    }

    // in case we are forced, plugins_update should be the complete list of plugins
    if (force)
      plugins_update = plugins_all;

    // notify about first usage of plugins, regardless of whether there is an update or not
    plugins_update.forEach( function(/*runtimeId*/) {
      let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_STATUS, {busy: false, progress: 0}, token);
      that.message( messagingConstants.messageTopics.PROCESS, m);
    });

    // if no parameter values were changed, return here
    if ( bAllValuesExist && !force ) {
      // we were called with a token, end the process here by sending a PROCESS_SUCCESS message
      let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_SUCCESS, 'All parameter values up to date', token);
      that.message(messagingConstants.messageTopics.PROCESS, m);
      return retval;
    }

    // if there is no history records yet, create a first one
    if (_historyStateIndices.length === 0) {
      _recordHistory(currentState);
    }

    // remember all parameter values for history
    if (!bHistoryUpdate && !bAllValuesExist) {
      // if there is no history records yet,
      _recordHistory();
    }

    // send a process status message
    let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_STATUS, {busy: true, progress: 0}, token);
    that.message(messagingConstants.messageTopics.PROCESS, m);

    // if there is more than one plugin, and we are given a token, send a PROCESS_FORK message,
    // and call set*ParameterValues using the extended tokens
    let tokens = {};
    if ( plugins_update.length > 1 ) {
      let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_FORK, [], token);
      let len = (plugins_update.length+'').length;
      for (let i=0; i<plugins_update.length; i++) {
        // create new subtoken ids which are unique and not parts of others (therefore pad to same length using zeros)
        let t = GlobalUtils.deepCopy(token);
        t.id = token.id + '.' + (Array(len).join('0') + i).slice(-len);
        tokens[plugins_update[i]] = t;
        m.parts[0].data.push(t.id);
      }
      that.message(messagingConstants.messageTopics.PROCESS, m);
    }
    else {
      // same token for all plugins
      plugins_update.forEach( function(runtimeId) {
        tokens[runtimeId] = GlobalUtils.deepCopy(token);
      });
    }

    // collect data for a PARAMETER_VALUE_UPDATE message to be sent
    let paramUpdateMsgData = {};

    // for each plugin
    plugins_update.forEach( function(runtimeId) {

      // filter puoArr by plugin
      var puoArrPlugin = puoArr.filter( function(puo) { return puo.plugin === runtimeId; } );

      // collect data for a PARAMETER_VALUE_UPDATE message to be sent
      paramUpdateMsgData[runtimeId] = {};
      let msgData = paramUpdateMsgData[runtimeId];
      puoArrPlugin.forEach( function(puo) {
        msgData[puo.id] = _parameters[puo.idx].getValue();
      });

      // get plugin
      var plugin;
      if ( _pluginManager === undefined ) {
        // this might happen when testing, succeed anyway
        addWarning(
          pluginConstants.setParameterResults.PLUGIN_NOT_FOUND,
          'Plugin functionality not found',
          runtimeId
        );
        that.warn(scope, 'Plugin functionality not found');
        retval.plugins[runtimeId] = pluginConstants.setParameterResults.PLUGIN_NOT_FOUND;
        return;
      }
      plugin = _pluginManager.getPluginByRuntimeId(runtimeId);
      if ( !plugin ) {
        addError(
          pluginConstants.setParameterResults.PLUGIN_NOT_FOUND,
          'Plugin not found',
          runtimeId
        );
        that.debug(scope, 'Plugin ' + runtimeId + ' not found');
        retval.plugins[runtimeId] = pluginConstants.setParameterResults.PLUGIN_NOT_FOUND;
        return;
      }

      // check capabilities of plugin, and call setMultipleParameterValues or setAllParameterValues
      var kvps = {};
      if ( plugin.getCapabilities().includes(pluginConstants.pluginCapabilities.PARTIAL_UPDATES) ) {
        // partial updates are supported, compile list of updated parameters
        // FIXME further restrict the list to parameters whose value has changed
        puoArrPlugin.forEach( function(puo) {
          // FIXME ideally we would be able to configure using a plugin capability
          // whether a plugin expects native values or strings
          kvps[puo.id] = _parameters[puo.idx].getValueString();
        });
        // call setMultipleParameterValues - FIXME check whether it exists, otherwise try setParameterValue
        let result = plugin.setMultipleParameterValues(kvps, tokens[runtimeId]);
        retval.plugins[runtimeId] = result;

      } else {
        // partial updates are NOT supported, compile list of all parameters of plugin
        puoArrPlugin.forEach( function(puo) {
          // FIXME ideally we would be able to configure using a plugin capability
          // whether a plugin expects native values or strings
          kvps[puo.id] = _parameters[puo.idx].getValueString();
        });
        _parameters.forEach( function(param) {
          var id = param.getSetting('id');
          if ( runtimeId === param.getSetting('plugin') && !kvps.hasOwnProperty(id) ) {
            // FIXME ideally we would be able to configure using a plugin capability
            // whether a plugin expects native values or strings
            kvps[id] = param.getValueString();
          }
        });
        // call setAllParameterValues
        let result = plugin.setAllParameterValues(kvps, tokens[runtimeId]);
        retval.plugins[runtimeId] = result;
      }
    });

    // send parameter value update message
    m = new MessagePrototype(messagingConstants.messageDataTypes.PARAMETER_UPDATE, paramUpdateMsgData, token);
    that.message( messagingConstants.messageTopics.PARAMETER_VALUE_UPDATE, m);

    if ( retval.err || retval.warn ) {
      let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_ERROR, retval.err, token);
      that.message( messagingConstants.messageTopics.PROCESS, m);
    }
    return retval;
  };

  /**
   * Request current parameter values for all parameters of a plugin
   *
   * @public
   * @param {String} plugin - plugin runtime id for which to get parameter values
   * @param {Boolean} bString - return stringified parameter values
   * @return {Object} key value pairs to be used for {@link module:PluginInterface~setAllParameterValues}
   */
  _o.getParameterValuesForPlugin = function(plugin, bString) {
    var params = _o.getParametersBySettings({plugin: plugin});
    var kvps = {};
    params.forEach( function(p) {
      if (bString) {
        kvps[p.getSetting('id')] = p.getValueString();
      }
      else {
        kvps[p.getSetting('id')] = p.getSetting('value');
      }
    });
    return kvps;
  };

  /**
   * Initial refresh of the outputs of a plugin,
   * unless the plugin is configured to defer loading of the geometry
   * (setting 'deferGeometryLoading').
   *
   * @public
   * @todo document returned object
   * @param {String} plugin - plugin runtime id for which to get parameter values
   * @param {String|module:MessagingConstants~MessageToken} [token] - Will be used by process messages related to this
   *                                            request, a random token will be created if none is provided.
   *                                            The token will be returned as a property of the return object of this function.
   * @return {Object} contains a key for each plugin that was called (runtime id), the value being the result of the setParameter operation
   */
  _o.refreshPluginInitial = function(runtimeId, token) {

    let retval = {plugins: {}};
    token = messagingConstants.makeMessageToken(token);
    retval.token = token;

    // helper for adding errors to retval
    var addError = function(code, msg, data) {
      if ( !retval.err ) retval.err = [];
      retval.err.push({code: code, msg: msg, data: data});
    };

    // check for plugin
    if ( _pluginManager === undefined ) {
      addError(
        pluginConstants.setParameterResults.PLUGIN_NOT_FOUND,
        'Plugin functionality not found',
        runtimeId
      );
      // No PROCESS_ERROR message needed here, process gets started in that.refreshPlugin
      // let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_ERROR, retval.err, token);
      // that.message( messagingConstants.messageTopics.PROCESS, m);
      retval.plugins[runtimeId] = pluginConstants.setParameterResults.PLUGIN_NOT_FOUND;
      return retval;
    }
    let plugin = _pluginManager.getPluginByRuntimeId(runtimeId);
    if ( !plugin ){
      addError(
        pluginConstants.setParameterResults.PLUGIN_NOT_FOUND,
        'Plugin not found',
        runtimeId);
      // No PROCESS_ERROR message needed here, process gets started in that.refreshPlugin
      // let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_ERROR, retval.err, token);
      // that.message( messagingConstants.messageTopics.PROCESS, m);
      retval.plugins[runtimeId] = pluginConstants.setParameterResults.PLUGIN_NOT_FOUND;
      return retval;
    }

    // check if loading should be defered
    if ( plugin.hasSetting('deferGeometryLoading') && plugin.getSettingShallow('deferGeometryLoading') ) {
      // No PROCESS_ERROR message needed here, process gets started in that.refreshPlugin
      // let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_SUCCESS, 'Geometry loading deferred', token);
      // that.message( messagingConstants.messageTopics.PROCESS, m);
      retval.plugins[runtimeId] = pluginConstants.setParameterResults.ABORT;
      return retval;
    }

    // start the refresh
    return _o.refreshPlugin(runtimeId, token);
  };

  /**
   * Refresh the outputs of a plugin by calling its set*ParameterValues function for the current values of its parameters
   *
   * @public
   * @todo document returned object
   * @param {String} plugin - runtime id of plugin which should be updated
   * @param {String|module:MessagingConstants~MessageToken} [token] - Will be used by process messages related to this
   *                request, a random token will be created if none is provided.
   *                The token will be returned as a property of the return object of this function.
   * @return {Object} contains a key for each plugin that was called (runtime id), the value being the result of the setParameter operation
   */
  _o.refreshPlugin = function(runtimeId, token) {

    let retval = {plugins: {}};
    token = messagingConstants.makeMessageToken(token);
    retval.token = token;

    // helper for adding errors to retval
    var addError = function(code, msg, data) {
      if ( !retval.err ) retval.err = [];
      retval.err.push({code: code, msg: msg, data: data});
    };

    // create array of parameter values
    var params = _o.getParametersBySettings({plugin: runtimeId});
    var puoArr = [];
    params.forEach( function(p) {
      puoArr.push({id: p.getSetting('id'), value: p.getSetting('value'), plugin: runtimeId});
    });

    // if there are no parameters, directly call setAllParameterValues of plugin
    if (puoArr.length <= 0 && _pluginManager) {

      // get plugin
      let plugin = _pluginManager.getPluginByRuntimeId(runtimeId);
      if (!plugin) {
        addError(
          pluginConstants.setParameterResults.PLUGIN_NOT_FOUND,
          'Plugin not found',
          runtimeId);
        // No PROCESS_ERROR message needed here, process gets started at the end of this function
        // let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_ERROR, retval.err, token);
        // that.message( messagingConstants.messageTopics.PROCESS, m);
        retval.plugins[runtimeId] = pluginConstants.setParameterResults.PLUGIN_NOT_FOUND;
        return retval;
      }
      // send process msg
      let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_STATUS, {busy: true, progress: 0}, token);
      that.message(messagingConstants.messageTopics.PROCESS, m);

      // setAllParameterValues of plugin
      retval.plugins[runtimeId] = plugin.setAllParameterValues({}, token);
      return retval;
    }
    return _o.setMultipleParameterValues(puoArr, token, true);
  };

  /**
   * Request current parameter values for all parameters - for backwards compatibility
   *
   * Returns an object containing key value pairs of parameter id, and parameter value.
   * Parameter ids might not be unique, hence the resulting object might not include values for all existing parameters.
   * @public
   * @return {Object} key value pairs (parameter id, parameter value)
   */
  _o.getParameterValuesCompat = function() {
    var kvps = {};
    _parameters.forEach( function(p) {
      kvps[ p.getSettingShallow('id') ] = p.getSetting('value');
    });
    return kvps;
  };

  /**
   * Get current state of parameter values (for all parameters)
   *
   * This function is used by the history functionality to record parameter states.
   * @public
   * @return {ParameterUpdateObject[]} array of parameter update objects describing the current parameter state
   */
  _o.getParameterState = function() {
    var puoArr = [];
    _parameters.forEach( function(p) {
      puoArr.push(
        {
          id: p.getSettingShallow('id'),
          plugin: p.getSettingShallow('plugin'),
          value: p.getSettingShallow('value')
        }
      );
    });
    return puoArr;
  };

  return _o;
};

module.exports = ViewerAppParameterManager;
