/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *SettingsHandler.js*
 *
 * ### Content
 *   * Functionality for persistent viewer settings
 *
 * @module SettingsHandler
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * Import global utils
 */
var GlobalUtils = require('../../shared/util/GlobalUtils');

/**
 * Import ViewerApp constants
 */
var viewerAppConstants = require('../ViewerAppConstants');

/**
 * Import ThreeDManager constants
 */
var threeDManagerConstants = require('../../3d/viewports/default/ThreeDManagerConstants');

/**
  * Imported global plugin constants
  */
var pluginConstantsGlobal = require('../../shared/constants/PluginConstantsGlobal');


/**
  * Constructor of the SettingsHandler mixin
  * @mixin SettingsHandler
  * @author Alex Schiftner <alex@shapediver.com>
  *
  * @param {Object} [settings] - Settings to be used
  * @param {HtmlElement} [settings.threeDManager] - Reference to the ThreeDManager
  * @param {HtmlElement} [settings.pluginManager] - Reference to the plugin manager
  * @param {HtmlElement} [settings.parameterManager] - Reference to the parameter manager
  * @param {HtmlElement} [settings.exportManager] - Reference to the export manager
  * @param {HtmlElement} [settings.app] - Reference to the ViewerApp
  */
var SettingsHandler = function(___settings) {

  var that = this;

  const _pluginManager = ___settings.pluginManager,
        _parameterManager = ___settings.parameterManager,
        _exportManager = ___settings.exportManager,
        _app = ___settings.app;

  /**
   * Object for collecting public members, this object will be returned instead of the default "this"
   */
  var _o = {};

  /**
   * remember stored settings we have received and used for restoration
   */
  var _storedSettings = false;

  /**
   * remember origin (as received by message) of settings we have received and used for restoration
   */
  var _storedSettingsOrigin; // eslint-disable-line no-unused-vars

  /**
   * remember version of settings we have received and used for restoration
   */
  var _storedSettingsVersion; // eslint-disable-line no-unused-vars

  /**
   * mapping of setting property names to internal settings
   * each property of mapping is an object defining how to handle restoration of the setting
   */
  var _mapping = {};

  /**
   * Mapping definition
   * @typedef {Object} MappingDefinition
   * @property {Object|Function} handler - object whose setting should be set, or a function which should be called for storing the setting
   * @property {Function} [handlerInverse] - in case handler is a function, optional inverse handler to read the setting value
   * @property {String} [setting] - name of the setting which should be set
   * @property {String|Function} [type] - name of data type or callback for type checking
   * @property {Array} [hook] - if restoration of a setting implies restoration of another one, use this (see autoRotateSpeed for an example)
   */

  //_mapping.version =

  let _getMapping = function(threeDManager) {
    let mapping = {};

    // Settings related to threeDManager
    if (threeDManager) {

      // Settings related to threeDManager.lightHandler
      if ( threeDManager.lightHandler ) {
        let getLightIntensity = function(lightPath, lightType) {
          let light = threeDManager.getSetting(lightPath);
          if (light.type !== lightType) return;
          let light_properties = light.properties;
          if (!light_properties) return;
          let intensity = light_properties.intensity;
          return GlobalUtils.typeCheck(intensity, 'number') ? intensity.toString() : '1.0';
        };

        mapping.ambientIntensity = {
          // CAUTION in case we change the lighting setup, the following will have to be changed
          handler: (val) => {
            let temp = threeDManager.getSetting('lights.light1');
            temp.properties.intensity = parseFloat(val);
            return threeDManager.updateSetting('lights.light1', temp);
          },
          handlerInverse: () => {
            return getLightIntensity('lights.light1', 0);
          }
        };
        mapping.spotlightIntensity = {
          // CAUTION in case we change the lighting setup, the following will have to be changed
          handler: (val) => {
            let temp = threeDManager.getSetting('lights.light2');
            temp.properties.intensity = parseFloat(val);
            return threeDManager.updateSetting('lights.light2', temp);
          },
          handlerInverse: () => {
            return getLightIntensity('lights.light2', 1);
          }
        };
      }

      // Settings related to threeDManager.cameraHandler
      if ( threeDManager.cameraHandler ) {
        mapping.topView = {
          handler: (v) => {
            return threeDManager.updateSetting('camera.type',
              v ? threeDManagerConstants.cameraViewTypes.TOP : threeDManagerConstants.cameraViewTypes.PERSPECTIVE
            );
          },
          handlerInverse: () => {
            let cameraType = threeDManager.getSetting('camera.type');
            return cameraType === threeDManagerConstants.cameraViewTypes.TOP ? true : false;
          }
        };
      }

      mapping.ambientOcclusion = {
        handler: threeDManager,
        setting: 'render.ambientOcclusion',
        type: 'boolean'
      };
      mapping.autoRotateSpeed = {
        handler: threeDManager,
        setting: 'camera.autoRotationSpeed',
        type: 'number',
        hook: (v) => (v != 0 ? [['enableAutoRotation', true]] : [])
      };
      mapping.backgroundColor = {
        type: (v) => (GlobalUtils.typeCheck(v, 'string') && v.length === 10),
        hook: function(v) {
          return [
            ['clearColor', v.substring(0,8).replace('0x','#')],
            ['clearAlpha', parseInt(v.substring(8,10), 16) / 255]
          ];
        },
        handlerInverse: () => {
          let color = threeDManager.getSetting('render.clearColor');
          let alpha = threeDManager.getSetting('render.clearAlpha');
          return color && alpha ? color.replace('#','0x') + (255*alpha).toString(16).padStart(2,'0') : '0xffffffff';
        }
      };
      mapping.camera = {
        handler: threeDManager,
        setting: 'camera.defaults.perspective',
        type: 'object',
        handlerInverse: () => (threeDManager.cameraHandler.getPositionAndTarget())
      };
      mapping.clearAlpha = {
        handler: threeDManager,
        setting: 'render.clearAlpha',
        type: 'number'
      };
      mapping.clearColor = {
        handler: threeDManager,
        setting: 'render.clearColor',
        type: 'string'
      };
      mapping.controlDamping = {
        handler: threeDManager,
        setting: 'camera.damping',
        type: 'number'
      };
      mapping.disablePan = {
        handler: threeDManager,
        setting: 'camera.enablePan',
        type: 'boolean',
        transform: (v) => (!v),
        handlerInverse: () => (!threeDManager.getSetting('camera.enablePan'))
      };
      mapping.disableZoom = {
        handler: threeDManager,
        setting: 'camera.enableZoom',
        type: 'boolean',
        transform: (v) => (!v),
        handlerInverse: () => (!threeDManager.getSetting('camera.enableZoom'))
      };
      mapping.enableAutoRotation = {
        handler: threeDManager,
        setting: 'camera.enableAutoRotation',
        type: 'boolean'
      };
      mapping.environmentMap = {
        handler: threeDManager,
        setting: 'material.environmentMap',
        type: (v) => (GlobalUtils.typeCheck(v, 'string') || GlobalUtils.isArrayOfType(v, 'string') ? true : false)
      };
      mapping.fov = {
        handler: threeDManager,
        setting: 'camera.fov',
        type: 'number'
      };
      mapping.pointSize = {
        handler: threeDManager,
        setting: 'render.pointSize',
        type: (v) => (typeof v === 'number' && v >= 0 ? true : false)
      };
      mapping.rotateSpeed = {
        handler: threeDManager,
        setting: 'camera.rotationSpeed',
        type: 'number'
      };
      mapping.showEnvironmentMap = {
        handler: threeDManager,
        setting: 'material.environmentMapAsBackground',
        type: 'boolean'
      };
      mapping.showGrid = {
        handler: threeDManager,
        setting: 'gridVisibility',
        type: 'boolean'
      };
      mapping.showGroundPlane = {
        handler: threeDManager,
        setting: 'groundPlaneVisibility',
        type: 'boolean'
      };
      mapping.showShadows = {
        handler: threeDManager,
        setting: 'render.shadows',
        type: 'boolean'
      };
      mapping.version = {
        handler: (v) => {_storedSettingsVersion = v;}
      };
      mapping.zoomExtentFactor = {
        handler: threeDManager,
        setting: 'camera.zoomExtentsFactor',
        type: 'number'
      };
      mapping.zoomSpeed = {
        handler: threeDManager,
        setting: 'camera.zoomSpeed',
        type: 'number'
      };

      // ignored settings
      mapping.directUpdates = { // whether to immediately send a customization request after a parameter update in the UI
        handler: () => true
      };
      mapping.directionallight2Intensity = {
        handler: () => true
      };
      mapping.edgeColor = { // new viewer does not support automatic edge detection
        handler: () => true
      };
      mapping.edgeColorByObject = { // new viewer does not support automatic edge detection
        handler: () => true
      };
      mapping.showEdges = { // new viewer does not support automatic edge detection
        handler: () => true
      };
      mapping.build_version = { // viewer version which was used to store the settings object
        handler: () => true
      };
      mapping.build_date = { // viewer version which was used to store the settings object
        handler: () => true
      };

    }

    return mapping;
  };


  // ParameterManager.registerConfig is called directly to handle the following:
  // user specified ordering of parameters - array of ids of parameters
  _mapping.controlOrder = {
    handler: () => true
  };
  // key value pairs mapping parameter ids to user specified parameter names
  _mapping.controlNames = {
    handler: () => true
  };
  // user specified array of hidden parameters (ids)
  _mapping.parametersHidden = {
    handler: () => true
  };

  if (_app) {

    _mapping.defaultMaterialColor = {
      handler: _app,
      setting: 'defaultMaterial.color',
      type: 'string'
    };

    _mapping.bumpAmplitude = {
      handler: _app,
      setting: 'defaultMaterial.bumpAmplitude',
      type: 'number'
    };

  }

  /**
   * Get value of a stored setting
   * @param {String} key
   * @return {Object} undefined in case setting does not exist, value of stored setting otherwise
   */
  _o.getStoredSetting = function(key) {
    if (!_storedSettings) return false;
    return GlobalUtils.deepCopy(GlobalUtils.getAtPath(_storedSettings, key));
  };

  /**
   * Check whether a stored setting exists
   * @param {String} key
   * @return {Boolean} true in case stored setting exists, false otherwise
   */
  _o.hasStoredSetting = function(key) {
    if (!_storedSettings) return false;
    return GlobalUtils.getAtPath(_storedSettings, key) !== undefined;
  };

  /**
   * Restore a single setting.
   * @param {String} key
   * @param {Object} value
   * @return {Boolean} TODO return a Promise instead
   */
  _o.restoreSetting = function(prop, val, mapping) {
    let scope = 'SettingsHandler.restoreSetting';

    // check if we have a mapping for the property
    if (mapping.hasOwnProperty(prop)) {
      let m = mapping[prop];

      // check if handler is a function
      if (m.hasOwnProperty('handler') && typeof m.handler === 'function') {
        return m.handler(val);
      }

      // sanity check
      if (m.handler === undefined || typeof m.handler !== 'object' ||
        !GlobalUtils.typeCheck(m.setting, 'string') || m.setting.length <= 0 ||
        !m.handler.hasOwnProperty('updateSettingAsync')) {
        // no handler, try if we got a hook configuration
        if (m.hasOwnProperty('hook') && typeof m.hook === 'function') {
          let a = m.hook(val);
          return a.forEach((h) => (_o.restoreSetting(h[0], h[1], mapping)));
        }
        else {
          that.debug(scope, 'Mapping for property ' + prop + ' not configured correctly', m);
          return false;
        }
      }
      // type checking
      if (m.type !== undefined && m.type !== null) {
        if (GlobalUtils.typeCheck(m.type, 'string')) {
          if (typeof val !== m.type) {
            if (GlobalUtils.typeCheck(val, 'string') && m.type === 'number') {
              val = parseFloat(val);
              if ( Number.isNaN(val) ) {
                that.warn(scope, 'Not restoring setting ' + prop + ' due to NaN', _storedSettings[prop]);
                return false;
              }
            }
            else {
              that.warn(scope, 'Not restoring setting ' + prop + ' due to unexpected data type', _storedSettings[prop]);
              return false;
            }
          }
        }
        else if ( typeof m.type === 'function' ) {
          if ( !m.type(val) ) {
            that.warn(scope, 'Not restoring setting ' + prop + ' due to unexpected data type', _storedSettings[prop]);
            return false;
          }
        }
      }
      // transformation
      if (m.hasOwnProperty('transform') && typeof m.transform === 'function') {
        val = m.transform(val);
      }
      // update setting
      m.handler.updateSettingAsync(m.setting, val).then(
        (r) => {
          if ( !r ) {
            that.warn(scope, 'Setting ' + prop + ' could not be restored', _storedSettings[prop]);
          }
          else {
            // if there is a hook, execute it
            if (m.hasOwnProperty('hook') && typeof m.hook === 'function') {
              let a = m.hook(val);
              a.forEach((h) => (_o.restoreSetting(h[0], h[1], mapping)));
            }
          }
        },
        (e) => {
          that.warn(scope, 'Setting ' + prop + ' could not be restored', _storedSettings[prop], e);
        }
      );
    }
    else {
      that.warn(scope, 'Unknown setting ' + prop);
      return false;
    }

    return true;
  };

  /**
   * Restore all of the settings from private copy.
   * @return {Boolean} TODO return a Promise instead
   */
  _o.restoreSettings = function(threeDManager) {
    if ( _storedSettings === undefined || typeof _storedSettings !== 'object' )
      return false;

    // iterate over all settings we received
    let bSuccess = true;

    if(!threeDManager)
      threeDManager = ___settings.threeDManager;

    if(!Array.isArray(threeDManager))
      threeDManager = [threeDManager];

    for(let i = 0, len = threeDManager.length; i < len; i++) {
      let mapping;
      if(i === 0){
        mapping = Object.assign(_mapping, _getMapping(threeDManager[i]));
      } else {
        mapping = _getMapping(threeDManager[i]);
      }

      for ( let prop in _storedSettings ) {
        // value to set
        let val = _storedSettings[prop];
        if ( !_o.restoreSetting(prop, val, mapping) )
          bSuccess = false;
      }
    }

    return bSuccess;
  };

  /**
   * Save settings.
   *
   * Settings will be saved using a plugin, where the plugin will be determined by the following logic:
   *   * plugin which was specified as a parameter, if any
   *   * plugin which we received settings from initially, if any
   *   * first plugin which has the capability to store settings, if any
   * @param {String} [runtimeId] - optional runtime id of the plugin to use
   * @return {Promise<Boolean>} resolves to true if saving the settings has succeeded, false or reject otherwise
   */
  _o.saveSettings = function(runtimeId) {
    // look for first plugin which provides the SETTINGS capability if none was specified
    let settingsPlugin;
    if (!GlobalUtils.typeCheck(runtimeId,'string')) {
      // try whether settings can be saved to plugin defined by _storedSettingsOrigin
      if (_storedSettingsOrigin && _storedSettingsOrigin.plugin) {
        settingsPlugin = _pluginManager.getPluginByRuntimeId(_storedSettingsOrigin.plugin);
        if (settingsPlugin && !settingsPlugin.getCapabilities().includes(pluginConstantsGlobal.pluginCapabilities.SETTINGS)) {
          settingsPlugin = undefined;
        }
      }
      if (!settingsPlugin) {
        settingsPlugin = _pluginManager.getPluginByCapabilities( pluginConstantsGlobal.pluginCapabilities.SETTINGS );
      }
    } else {
      settingsPlugin = _pluginManager.getPluginByRuntimeId( runtimeId );
    }
    if (!settingsPlugin || !GlobalUtils.typeCheck(settingsPlugin.saveSettings, 'function')) {
      return Promise.resolve(false);
    }
    // compile backwards compatible settings object
    let settingsObject = _o.getCurrentSettingsObject();
    if (!settingsObject) {
      return Promise.resolve(false);
    }
    // add controlNames, controlOrder, parametersHidden of parameters
    let parameterSettings = _parameterManager.getConfig(settingsPlugin.getRuntimeId());
    Object.keys(parameterSettings).forEach((key) => {
      if ( settingsObject.hasOwnProperty(key) ) {
        settingsObject[key] = [...settingsObject[key], ...parameterSettings[key]];
      } else {
        settingsObject[key] = parameterSettings[key];
      }
    });
    // add controlNames, controlOrder, parametersHidden of exports
    let exportSettings = _exportManager.getConfig(settingsPlugin.getRuntimeId());
    Object.keys(exportSettings).forEach((key) => {
      if ( settingsObject.hasOwnProperty(key) ) {
        settingsObject[key] = [...settingsObject[key], ...exportSettings[key]];
      } else {
        settingsObject[key] = exportSettings[key];
      }
    });
    // controlNames array to object
    if ( settingsObject.hasOwnProperty('controlNames') ) {
      let tmp = settingsObject.controlNames;
      settingsObject.controlNames = {};
      tmp.forEach((kvp) => (settingsObject.controlNames[kvp.id] = kvp.name));
    }
    // controlOrder array conversion
    if ( settingsObject.hasOwnProperty('controlOrder') ) {
      settingsObject.controlOrder.sort((a,b)=>(a.order-b.order));
      settingsObject.controlOrder = settingsObject.controlOrder.map((kvp) => (kvp.id));
    }
    // call saveSettings of plugin
    return settingsPlugin.saveSettings(settingsObject)
      .then(
        () => (true)
      )
    ;
  };

  /**
   * Receiver for messages.
   *
   * @param  {String|module:MessagingConstants~MessageToken} token - Unique token of the process
   * @param  {module:MessagingConstants~ProcessStatusMessage} data - data of message part
   * @param  {module:MessagingConstants~MessageDataTypes} type - type of message part (e.g. APP_SETTINGS)
   * @param  {module:MessagingConstants~MessageOrigin} origin - origin of message
   */
  _o.messageReceiver = function(token, data, type, origin) {
    //let scope = 'SettingsHandler.messageReceiver';

    // no need to process settings twice
    if ( _storedSettings )
      return;

    // register initial loading processes
    if ( type === viewerAppConstants.messageDataTypes.APP_SETTINGS ) {
      _storedSettings = data;
      _storedSettingsOrigin =  origin;
      // TODO once restoreSettings returns a Promise, wait for it and then set 'hasRestoredSettings',
      // and send a SETTINGS_REGISTERED message
      _o.restoreSettings();
      // remember that settings have been restored
      that.updateSetting('hasRestoredSettings', true);
    }

  };

  /**
   * Get current settings object for saving it using a CommPlugin
   * @return {Object} settings object ready to be stored
   */
  _o.getCurrentSettingsObject = function(threeDManager) {
    let settingsObject = {},
        key,
        settingDefinition,
        settingHandler,
        settingHandlerInverse,
        settingName;
    // for now we always return a version 1 settings object
    settingsObject.version = that.getSetting('build_version');
    settingsObject.build_version = that.getSetting('build_version');
    settingsObject.build_date = that.getSetting('build_date');

    if(!threeDManager)
      threeDManager = ___settings.threeDManager;

    if(!Array.isArray(threeDManager))
      threeDManager = [threeDManager];

    for(let i = 0, len = threeDManager.length; i < len; i++) {
      let mapping;
      if(i === 0){
        mapping = Object.assign(_mapping, _getMapping(threeDManager[i]));
      } else {
        mapping = _getMapping(threeDManager[i]);
      }

      // loop over settings defined in mapping
      for (key in mapping) {
        settingDefinition = mapping[key];
        settingHandler = settingDefinition.handler;
        settingHandlerInverse = settingDefinition.handlerInverse;
        settingName = settingDefinition.setting;
        // check if there is an inverse handler
        if ( GlobalUtils.typeCheck(settingHandlerInverse, 'function') ) {
          settingsObject[key] = settingHandlerInverse();
        }
        // else check if we can get the setting in the traditional way
        else if ( GlobalUtils.typeCheck(settingName, 'string') &&
          settingHandler &&
          GlobalUtils.typeCheck(settingHandler.getSetting, 'function') ) {
          settingsObject[key] = settingHandler.getSetting(settingName);
        }
      }
    }

    return settingsObject;
  };

  return _o;
};

module.exports = SettingsHandler;
