/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *LoggingMixin.js*
 *
 * ### Content
 *   * mixin containing basic functionality for logging
 *   * provides a standard logging function which logs to the console
 *   * provides individual logging functions per log level (error, warn, info, debug)
 *
 * @module LoggingMixin
 * @requires module:SettingsMixin
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * Imported logging constants
 */
var loggingConstants = require('../constants/LoggingConstants');

/**
 * Constructor of the logging mixin
 * @mixin LoggingMixin
 * @author Alex Schiftner <alex@shapediver.com>
 * @requires module:SettingsMixin
 */
var LoggingMixin = function() {

  var that = this;

  ////////////
  ////////////
  //
  // Logging
  //
  ////////////
  ////////////

  /**
   * Get logging level name for given logging level
   * @public
   * @param {module:LoggingConstants~LoggingLevel} loggingLevel - logging level to be used
   * @return {String} Name of logging level
   */
  this.getLoggingLevelName = function(loggingLevel) {
    // get logging level name
    let loggingLevelName = loggingLevel.toString();
    Object.keys(loggingConstants.loggingLevels).forEach( function(key) {
      if ( loggingConstants.loggingLevels[key] == loggingLevel )
        loggingLevelName = key;
    });
    return loggingLevelName;
  };

  /**
   * Print log message to console, without checking configured log level.
   * Used by default dispatcher for log messages.
   * @public
   * @param {module:LoggingConstants~LoggingLevel} loggingLevel - logging level to be used
   * @param {String} scope - scope to be used, the convention is CLASSNAME.MEMBERNAME
   * @param {...Object} msg - arbitrary log message objects
   */
  this.logToConsole = function() {
    var args = (arguments.length === 1 ? [arguments[0]] : Array.apply(null, arguments));
    var loggingLevel = args[0] & loggingConstants.loggingLevels.LVLBITS;
    var scope = args[1];
    var msgs = args.slice(2);
    // console log formatting to be improved
    if ( loggingLevel === loggingConstants.loggingLevels.ERROR )
      console.error( 'ERROR.' + scope, ':', ...msgs); // eslint-disable-line no-console
    else if ( loggingLevel === loggingConstants.loggingLevels.WARN )
      console.warn( 'WARN.' + scope, ':', ...msgs); // eslint-disable-line no-console
    else if ( loggingLevel === loggingConstants.loggingLevels.INFO )
      console.log( 'INFO.' + scope, ':', ...msgs); // eslint-disable-line no-console
    else if ( loggingLevel === loggingConstants.loggingLevels.DEBUG )
      console.log( 'DEBUG.' + scope, ':', ...msgs); // eslint-disable-line no-console
    else
      console.log( 'UNKNOWN.' + scope, ':', ...msgs); // eslint-disable-line no-console
  };

  /**
   * Default dispatcher for log messages
   *
   * Default implementation sends log message to console, if logging level exceeds currently configured one.
   *
   * This implementation will typically be used for debugging only, and be replaced by ViewerApp.
   * @public
   * @param {module:LoggingConstants~LoggingLevel} loggingLevel - logging level to be used
   * @param {String} scope - scope to be used, the convention is CLASSNAME.MEMBERNAME
   * @param {...Object} msg - arbitrary log message objects
   */
  this.log = function() {
    var loggingLevel = arguments[0] & loggingConstants.loggingLevels.LVLBITS;
    if ( loggingLevel <= that.getLoggingLevel() ) {
      // console log formatting to be improved
      that.logToConsole.apply(that, arguments);
    }
  };

  /**
   * Log a message with loggingLevel ERROR.
   * @public
   * @param {String} scope - scope to be used, the convention is CLASSNAME.MEMBERNAME
   * @param {...Object} msg - arbitrary log message objects
   */
  this.error = function() {
    that.log.apply(that, [loggingConstants.loggingLevels.ERROR, ...arguments]);
  };

  /**
   * Log a message with loggingLevel WARN.
   * @public
   * @param {String} scope - scope to be used, the convention is CLASSNAME.MEMBERNAME
   * @param {...Object} msg - arbitrary log message objects
   */
  this.warn = function() {
    that.log.apply(that, [loggingConstants.loggingLevels.WARN, ...arguments]);
  };

  /**
   * Log a message with loggingLevel INFO.
   * @public
   * @param {String} scope - scope to be used, the convention is CLASSNAME.MEMBERNAME
   * @param {...Object} msg - arbitrary log message objects
   */
  this.info = function() {
    that.log.apply(that, [loggingConstants.loggingLevels.INFO, ...arguments]);
  };

  /**
   * Log a message with loggingLevel DEBUG.
   * @public
   * @param {String} scope - scope to be used, the convention is CLASSNAME.MEMBERNAME
   * @param {...Object} msg - arbitrary log message objects
   */
  this.debug = function() {
    that.log.apply(that, [loggingConstants.loggingLevels.DEBUG, ...arguments]);
  };

  // /**
  //  * Shortcut log functions according to loggingLevels defined in loggingConstants.loggingLevels
  //  *
  //  * error, warn, info, debug
  //  */
  // Object.keys(loggingConstants.loggingLevels).forEach( function(key) {
  //   that[key.toLowerCase()] = function(scope, msg) {
  //     that.log(loggingConstants.loggingLevels[key], scope, msg);
  //   };
  // });

  /**
   * Change loggingLevel setting
   * @public
   * @param {module:LoggingConstants~LoggingLevel} loggingLevel - new logging level to be used
   * @return {Boolean}
   */
  this.setLoggingLevel = function(loggingLevel) {
    return that.updateSetting('loggingLevel', loggingLevel);
  };

  /**
   * Get loggingLevel setting
   * @public
   * @return {module:LoggingConstants~LoggingLevel} loggingLevel setting
   */
  this.getLoggingLevel = function() {
    return that.getSetting('loggingLevel');
  };

  return this;
};

// here we could define public functions
//LoggingMixin.prototype.

// export the constructor
module.exports = LoggingMixin;
