/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *SceneManager.js*
 *
 * ### Content
 *   * Scene functionality of ViewerApp
 *
 * @module SceneManager
 * @author Mathias Höbinger <mathias@shapediver.com>
 */

var THREE = require('../../externals/three');

var toTinyColor = require('../../shared/util/toTinyColor');

var messagingConstants = require('../../shared/constants/MessagingConstants');

var MessagePrototype = require('../../shared/messages/MessagePrototype');

var SubScene = require('../../shared/scene/OutputVersion');

var GlobalUtils = require('../../shared/util/GlobalUtils');

var GLBLoader = require('../../shared/singletons/GLBLoader');

var OBJLoader = require('../../shared/singletons/OBJLoader');

var THREELoader = require('../../shared/singletons/THREELoader');

var Tag3DLoader = require('../../shared/singletons/Tag3DLoader');

var MaterialAttributes = require('../../shared/materials/MaterialAttributes');

/**
 * Unique id of an entity that creates and manages sub-scenes
 * @typedef {String} CreatorId
 */

/**
  * Id, unique within a creators namespace, that describes a particular set of geometry
  * which can be replaced at once.
  * @typedef {String} OutputId
  */

/**
   * Globally unique id of a process
   * @typedef {String} ProcessId
   */

/**
 * This singleton describes the full extent of the objects and their attributes
 * as their creating entities intend them to be displayed at any time by the ViewerApp's 3d viewer.
 * It consists of several sub-scenes managed by an arbitrary number of entities.
 * The attributes of objects in the scene can be temporarily altered by the operations indexed in the
 * SceneOperations object.
 * @typedef {Object.<module:SceneManager~CreatorId, module:OutputVersion~SubScene>} ViewerScene
 */

/**
 * Record of a process assembling a SubScene from one or several OutputVersionIds
 * @typedef {Object} SubSceneCreationProcess
 * @property {module:SceneManager~CreatorId} creatorId - The (runtime)-id of the plugin or entity responsible for this subscene.
 * @property {module:SceneManager~ProcessId} processId - A unique id for the process of creating this specific SubScene instance. We will identify and route messages by this id.
 * @property {Integer} totalOutputVersions - The total number of output versions this SubScene will consist of.
 * @property {Integer} finishedOutputVersions - The number of output versions which have been finished so far.
 *
 */

/**
 * Model data item
 * @typedef {Object} ModelDataItem
 * @property {String} name - name of the data item
 * @property {module:SceneManager~OutputId} id - id of the data item
 * @property {module:SceneManager~CreatorId} plugin - plugin runtime id this item belongs to
 * @property {*} data - the data
 */

/**
 * Constructor of the SceneManager class
 * @class
 * @classdesc The SceneManager class should be instanciated only once by the ViewerApp.
 * It manages the geometry content and material attributes of the 3d scene currently shown
 * in the 3d viewer.
 *
 * @param {Object} references - Instantiation settings
 * @param {Object} references.sceneGeometryManager - Pointer to the scene geometry manager
 */
var SceneManager = function(___refs) {

  var that = this;

  // the viewer app's 3d manager
  var sceneGeometryManager = ___refs.sceneGeometryManager;

  /**
   * Object for collecting public members, this object will be returned instead of the default "this"
   */
  var _o = {};

  /**
   * Descriptor for the scene currently being shown in the ViewerApp's 3d viewer
   * @type {module:SceneManager~ViewerScene}
   */
  var _viewerScene = {};

  /**
   * Defines attributes for output versions which are reapplied after each regular update.
   * @type {Object}
   */
  var _persistentAttributes = {};

  /**
   * Non copyable attributes of output versions
   *
   * FIXME check whether attribute 'transformations' should be part of this,
   * maybe make sure transformations is always stored as array of matrix elements internally
   */
  var _nonCopyableOvAttributes = ['canvas','threeObject','transformations'];

  /**
   * Index of partial SubScenes. Those are scenes which the SceneManager is currently working to assemble by adding {@link module:OutputVersion~OutputVersion OutputVersions} to them.
   * They are moved to the _completeSubScenes index once finished.
   * @type {Object.<module:SceneManager~CreatorId, Object.<module:SceneManager~ProcessId, module:OutputVersion~SubScene>>}
   */
  var _partialSubScenes = {};

  /**
   * Index of finished SubScenes. Once the SceneManager has completed assembling a SubScene, it moves it to this index.
   * In a next step, it is decided if the SubScene will be added to the _viewerScene or discarded.
   * @type {Object.<module:SceneManager~CreatorId, Object.<module:SceneManager~ProcessId, module:OutputVersion~SubScene>>}
   */
  var _completeSubScenes = {};

  /**
   * An index of currently active sub-scene creation processes, keyed by the process id
   * @type {Object.<module:SceneManager~ProcessId, module:SceneManager~SubSceneCreationProcess>}
   */
  var _subSceneCreationProcesses = {};

  /**
   * Current values of all model data outputs
   * @type {Object.<module:SceneManager~CreatorId, module:SceneManager~ModelDataItem[]>}
   */
  var _modelData = {};

  /**
   * We will always chain publishing operations to the previous one, to avoid race conditions
   * @type {Promise}
   */
  var _publishInProgress = Promise.resolve();

  /**
   * Contains the last serial number for a subscene process published to the scene by each creator
   * @type {Object}
   */
  var _lastPublishedSerial = {};

  /**
   * Contains the last serial number for a subscene process begun by each creator
   * @type {Object}
   */
  var _lastKnownSerial = {};

  /**
   * Collect serials for all running processes
   * @type {Object}
   */
  var _serials = {};

  /**
   * List of properties which should be passed on from output version to sceneGeometryManager
   */
  var _threeDProps = ['interactionGroup', 'interactionMode', 'duration', 'dragPlaneNormal', 'visible'];

  /**
   * Error constructor helper for functions which return Promises
   */
  var _error = function(scope, msg, data) {
    console.log(scope, msg, data)
    let e = new Error(msg);
    e.scope = scope;
    e.data = data;
    return Promise.reject(e);
  };

  ////////////
  ////////////
  //
  // Private methods
  //
  ////////////
  ////////////


  /**
   * Add OutputVersion to a given SubScene
   * @param  {Object} processToken - Process token (contains the process id, etc)
   * @param  {module:OutputVersion~OutputVersion} outputVersion - The OutputVersion to be added to the SubScene
   * @return {Promise<*>} resolve to SubScene if it was successfully published, to true if the output version has been successfully processed but the subscene is not complete yet, to false if output version was ignored because it has been superseded, rejects on error
   */
  var _addOutputVersion = function(processToken, outputVersion) {
    let error, processId, proc, creatorId, outputId, persAttr, persAttrOv, k, bPersApplied;

    // error constructor
    error = (msg, data) => (_error('SceneManager._addOutputVersion', msg, data));

    // preparation
    processId = processToken.id;
    proc = _subSceneCreationProcesses[processId];
    creatorId = proc.creatorId;
    outputId = outputVersion.id;

    // override attributes of outputVersion with stored persistent attributes
    bPersApplied = false;
    if(_persistentAttributes[creatorId] && _persistentAttributes[creatorId][outputId]) {
      persAttr = _persistentAttributes[creatorId][outputId];
      if ( persAttr.ov ) {
        bPersApplied = true;
        persAttrOv = persAttr.ov;
        for (k in persAttrOv) {
          if ( k === '_json' ) {
            // copy _json and apply persistent attributes to the copy
            outputVersion._json_scene = GlobalUtils.deepCopy(outputVersion[k], _nonCopyableOvAttributes);
            GlobalUtils.defaults(outputVersion._json_scene, persAttrOv._json, true);
          }
          else if (k !== '_json_scene') {
            outputVersion[k] = persAttrOv[k];
          }
        }
      }
    }
    if (!bPersApplied) {
      delete outputVersion._json_scene;
    }

    // attempt to add the new output version to the SubScene
    if (!_partialSubScenes[creatorId][processId].subScene.addOutputVersion(outputVersion)) {
      return error('could not add output version to subscene');
    }

    // record new finished output version
    ++proc.finishedOutputVersions;

    // return true if there are more to come
    if (proc.finishedOutputVersions < proc.totalOutputVersions) {
      return Promise.resolve(true);
    }

    // we finalize the process, typically by deciding whether to replace geometry in the viewer's scene
    // then we send a message with the result
    return _finishProcess(processToken);
  };

  /**
 * Add or replace the SubScene for a given creator id with the given SubScene definition
 *
 * This command does two things: it instructs the sceneGeometryManager to do any necessary
 * additions and replacements in the scene to adjust the SubScene for a creatorId
 * to the specified object. Then it also replaces the SubScene object stored in the
 * _viewerScene for this creator id with the specified object.
 *
 * @param  {module:OutputVersion~SubScene} subScene - New SubScene object
 * @param  {module:SceneManager~CreatorId} creatorId - Id of the creator entity responsible for this SubScene
 * @param {Object} token - Message token
 * @param {Object} [opts] - Additional options for publishing the SubScene
 * @param {Boolean} [opts.forceUpdate] - If true, all geometry in the scene will be replace with fresh copies from their definitions
 * @return {Promise<*>} resolve to SubScene if it was successfully published, to false if a newer SubScene has already been published, rejects on error.
 */
  var _publishSubScene = function(subScene, creatorId, token, opts) {
    let scope, error, processId, forceUpdate;
    scope = 'SceneManager._publishSubScene';

    // error constructor
    error = (msg, data) => (_error(scope, msg, data));

    // preparation
    processId = token.id;
    opts = opts || {};
    forceUpdate = typeof opts.forceUpdate === 'boolean' ? opts.forceUpdate : false;

    // we require a defaultMaterial setting
    // FIXME this should be moved to settings hook for defaultMaterial
    if (!that.hasSetting('defaultMaterial')) {
      return error('no default material configured');
    }

    // check if a newer process for the creator has already published
    let processSerial = _serials[creatorId + '.' + processId];
    let lastSerial = _lastPublishedSerial[creatorId];
    if (lastSerial && lastSerial.serial > processSerial)
    {
      return Promise.resolve(false);
    }

    // we put our publishing process in a queue behind the previous ones
    let publishPromise = _publishInProgress
      .then(
        function() {

          // we have to get and convert the default material first
          // FIXME the conversion of the default material should be put into a settings hook
          let defaultMaterial = null;
          let defMatAttr = new MaterialAttributes();

          // convert the default material ...
          return defMatAttr.fromJSONMaterialObject( that.getSetting('defaultMaterial') )
            .then( //... and remember it / catch errors
              function(mat) {
                defaultMaterial = mat;
              },
              function(err) {
                return error('could not create default material', err);
              }
            )
            .then( //
              function() {

                // the output ids contained in the new subscene we want to publish
                let outputIds = subScene.outputIds;

                // get the current subscene of the creator, and the current output ids in the subscene
                let currentSubScene = _viewerScene.hasOwnProperty(creatorId) ? _viewerScene[creatorId]._subSceneObject : null;
                let currentOutputIds = currentSubScene !== null ? currentSubScene.outputIds : [];

                // append existing output versions to new SubScene if they are missing
                for (let curOId of currentOutputIds) {
                  if (outputIds.indexOf(curOId) === -1) {
                    subScene.addOutputVersion(currentSubScene.getOutputVersion(curOId));
                    outputIds.push(curOId);
                  }
                }

                // array of promises returned by the sceneGeometryManager to add/replace/remove geometry
                // if all resolve, this means that all necessary geometry has been replaced
                let sceneGeometryManagerPromises = [];

                // we collect model data from all outputs so we can publish them
                let subSceneModelData = {};

                // create tree representation of the subScene
                // FIXME what is this used for?
                // ANSWER by Mathias: Not much at the moment, mainly it is used in 'removeOutputIds'
                // It was thought as a quick way to check whether an output id exists, and to access it
                let tree = {};

                for (let i = outputIds.length-1; i > -1; --i) {

                  let oid = outputIds[i];

                  // get output version from new SubScene
                  let newOutputVersion = subScene.getOutputVersion(oid);

                  // only output versions which include geometry need to go to the viewer scene
                  if (newOutputVersion.hasOwnProperty('geometry')) {

                    // we compile all materials into an output version to put it into the scene
                    let compiledOutputVersion = subScene.getCompiledOutputVersion(oid, defaultMaterial);
                    if (!compiledOutputVersion || !Array.isArray(compiledOutputVersion.geometry)) {
                      return error('could not compile output version ' + oid);
                    }

                    // create tree representation of output version
                    // FIXME it's not clear what we use this tree representation for
                    tree[oid] = {};
                    for (let g of compiledOutputVersion.geometry) {
                      let o = tree[oid];
                      let p = g.path.split('.');
                      for (let s of p) {
                        o[s] = o[s] || {};
                        o = o[s];
                      }
                      ['type', 'geometry', 'material', 'initialMatrix'].forEach(function(attr) {
                        if (g.hasOwnProperty(attr)) {
                          o[attr] = g[attr];
                        }
                      });
                      o._isSDGeometry = true;
                    }

                    // if the output id doesn't yet exist in the scene, we add it
                    if (currentOutputIds.indexOf(oid) === -1) {

                      /// collect options for adding output to scene

                      // add name to output version object
                      let options = {};
                      if (compiledOutputVersion.hasOwnProperty('name')) {
                        options.name = compiledOutputVersion.name;
                      }

                      // add bounding box
                      if (compiledOutputVersion.hasOwnProperty('bbmin') && compiledOutputVersion.hasOwnProperty('bbmax')) {
                        try {
                          let bb = new THREE.Box3(
                            GlobalUtils.toVector3(compiledOutputVersion.bbmin),
                            GlobalUtils.toVector3(compiledOutputVersion.bbmax)
                          );
                          options.boundingBox = bb;
                        }
                        catch (err) {
                          that.debug(scope, 'invalid bounding box for output version ' + oid, err);
                        }
                      }

                      // further options
                      _threeDProps.forEach((attr) => {
                        if (compiledOutputVersion.hasOwnProperty(attr)) {
                          options[attr] = compiledOutputVersion[attr];
                        }
                      });

                      // add geometry for output to scene
                      sceneGeometryManagerPromises.push(sceneGeometryManager.addGeometry(
                        creatorId + '.' + oid, /*path*/
                        compiledOutputVersion.geometry,
                        options
                      ));
                    }
                    else {
                      // if the output id does exist, we check whether we have to replace it
                      // because of a change in geometry or material
                      let needsUpdate = forceUpdate;
                      if (!needsUpdate) {
                        needsUpdate = currentSubScene.getOutputVersion(oid).version !== newOutputVersion.version;
                      }
                      if (!needsUpdate) {
                        if (newOutputVersion.materialId) {
                          let newMaterial = subScene.getOutputVersion(newOutputVersion.materialId);
                          let currentMaterial = currentSubScene.getOutputVersion(newOutputVersion.materialId);
                          if (!currentMaterial || (newMaterial.version !== currentMaterial.version)) {
                            needsUpdate = true;
                          }
                        }
                      }

                      if (needsUpdate) {
                        // collect options for geometry replacement
                        let options = {};
                        _threeDProps.forEach((attr) => {
                          if (compiledOutputVersion.hasOwnProperty(attr)) {
                            options[attr] = compiledOutputVersion[attr];
                          }
                        });

                        // FIXME why do we specify a default duration here?
                        if (options.duration === undefined)
                          options.duration = 500;

                        // FIXME why do we not add the bounding box and the name to the options here,
                        // like when adding geometry?

                        // ANSWER by Mathias: Because these should stay fixed throughout a session. If you want to
                        // change that, please discuss it with Michael.

                        sceneGeometryManagerPromises.push(sceneGeometryManager.replaceGeometry(
                          creatorId + '.' + oid, /*path*/
                          compiledOutputVersion.geometry,
                          options
                        ));
                      }
                    }
                  }
                  else {
                    // output versions which do not include geometry may need to be removed from the scene
                    // this function will return a promise that resolves even if there was no geometry to remove
                    // it only rejects if there is geometry, but it could not be removed for some reason
                    if (currentSubScene) {
                      let curOutputVersion = currentSubScene.getOutputVersion(oid);
                      if (curOutputVersion && curOutputVersion.geometry) {
                        sceneGeometryManagerPromises.push(sceneGeometryManager.removeGeometry(creatorId + '.' + oid));
                      }
                    }
                  }

                  // collect updated model data if any exists
                  if (newOutputVersion.hasOwnProperty('data')) {
                    subSceneModelData[oid] = newOutputVersion.data;
                  }
                }

                // once all geometry has been added or replaced in the scene, we update
                // our record by overwriting the SubScene
                return Promise.all(sceneGeometryManagerPromises)
                  .then(
                    function() {
                      // store the published subscene and model data
                      _viewerScene[creatorId] = tree;
                      _viewerScene[creatorId]._subSceneObject = subScene;
                      _modelData[creatorId] = subSceneModelData;

                      // remember last published process serial for the creator
                      _lastPublishedSerial[creatorId] = {serial: processSerial, id: processId};

                      // message to notify about publishing of subscene
                      let m1 = new MessagePrototype(messagingConstants.messageDataTypes.SUBSCENE_DEFINITION, subScene, token);
                      that.message(messagingConstants.messageTopics.SCENE_SUBSCENE_PUBLISHED, m1);

                      // message to notify about data update
                      if ( Object.keys(subSceneModelData).length > 0 ) {
                        let m = new MessagePrototype(messagingConstants.messageDataTypes.DATA, subSceneModelData, token);
                        that.message(messagingConstants.messageTopics.DATA, m);
                      }

                      // successfully published
                      return subScene;
                    }
                  )
                  // .catch( // rejection of one of sceneGeometryManagerPromises
                  //   function(err) {
                  //   }
                  // )
                ;
              }
            )
          ;
        }
      )
      // .catch( // rejection in the previous Promise _publishInProgress
      //   function(err) {
      //   }
      // )
    ;

    _publishInProgress = publishPromise;
    return publishPromise;
  };


  /**
   * Once all output versions of a SubScene have been loaded successfully, we
   * move the SubScene to the index of completed SubScenes and decide whether
   * to insert the SubScene into the 3d scene.
   * @param {Object} token - Message token
   * @return {Promise<*>} resolve to SubScene if it was successfully published, false if output version was ignored because it has been superseded, rejects on error
   */
  var _finishProcess = function(token) {
    let error, processId, proc, creatorId;

    // error constructor
    error = (msg, data) => (_error('SceneManager._finishProcess', msg, data));

    // preparations
    processId = token.id;
    proc = _subSceneCreationProcesses[processId];
    creatorId = proc.creatorId;

    // move the SubScene from the partial SubScene container to the one for complete ones
    _completeSubScenes[creatorId] = _completeSubScenes[creatorId] || {};
    _completeSubScenes[creatorId][processId] = _partialSubScenes[creatorId][processId];
    delete _partialSubScenes[creatorId][processId];
    if (Object.keys(_partialSubScenes[creatorId]).length === 0) {
      delete _partialSubScenes[creatorId];
    }

    //TODO: decide whether to update the 3d scene and do it
    //TODO: for now, we always update

    // subscene to publish
    let subScene = _completeSubScenes[creatorId][processId].subScene;

    // current subscene (keep handle, because _publishSubScene will replace it on success)
    let currentSubScene = _viewerScene[creatorId] ? _viewerScene[creatorId]._subSceneObject : null;

    // send a proces status message, which allows us to do some timing
    let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_STATUS,
      {state: messagingConstants.messageTopics.SCENE_SUBSCENE_READY, progress: 0.75}, token
    );
    that.message(messagingConstants.messageTopics.PROCESS, m);

    // we attempt to publish the new subscene and replace any
    // part of the old one that has changed in the scene
    return _publishSubScene(subScene, creatorId, token, proc.options)
      .then(
        function(subScene) {
          // remove complete SubScene from container since it is now published and stored as active SubScene
          delete _completeSubScenes[creatorId][processId];
          if (Object.keys(_completeSubScenes[creatorId]).length === 0) {
            delete _completeSubScenes[creatorId];
          }

          // pass on subscene
          return subScene;
        },
        function(err) {
          // we have failed to update the SubScene
          // this means that we should revert to the previous state
          if (currentSubScene !== null) {
            return _publishSubScene(currentSubScene, creatorId, token)
              .then(
                function(/*subScene*/) {
                  // successfully restored previous state after failure to publish new subscene
                  // pass on error which caused the first call to _publishSubScene to fail
                  // we might inform the user about the problem and tell that the previous state was restored
                  return error('publishing of subscene failed, restored previous state', err);
                }
              )
            ;
          }
          else {
            // there was no previous version of this SubScene
            // we will remove all traces of the failed attempt
            if (_viewerScene.hasOwnProperty(creatorId)) {
              delete _viewerScene[creatorId];
            }
            return sceneGeometryManager.removeGeometry(creatorId)
              .then(
                function() {
                  // successfully restored previous state after failure to publish new subscene
                  // pass on error which caused the first call to _publishSubScene to fail
                  // we might inform the user about the problem and tell that the previous state was restored
                  return error('publishing of subscene failed, restored previous state', err);
                }
              )
            ;
          }
        }
      )
      .catch(
        function(err) {
          console.log(err)
          // purge the process information, pass on the error
          return error('publishing of subscene failed', err);
        }
      )
    ;
  };


  /**
   * Converts a JSON output version description into an OutputVersion object by
   * downloading all necessary geometries and textures.
   * @param  {module:JSONOutputVersion~JSONOutputVersion} jsonOutputVersion - The JSON output version description
   * @param {Boolean} allowPartial - If true, output versions will be converted even if vital attributes such as the version or content are missing
   * @return {Promise<module:OutputVersion~OutputVersion>} resolves to OutputVersion object, rejects on error
   */
  var _convertOutputVersion = function(jsonOutputVersion, allowPartial) {
    // error constructor
    let error = (msg, data) => (_error('SceneManager._convertOutputVersion', msg, data));

    // parameter sanity checks
    if (allowPartial === undefined) {
      allowPartial = false;
    }
    jsonOutputVersion = jsonOutputVersion || {};

    // sanity checks
    if (!allowPartial) {
      if (!GlobalUtils.typeCheck(jsonOutputVersion.version, 'string')) {
        return error('version missing');
      }
      if (!Array.isArray(jsonOutputVersion.content)) {
        return error('content array missing');
      }
    }

    // create OutputVersion object with basic info
    let outputVersionObject = {
      id: jsonOutputVersion.id,
    };
    ['version', 'name', ..._threeDProps].forEach(function(attr) {
      if ( jsonOutputVersion[attr] !== undefined && jsonOutputVersion[attr] !== null ) {
        outputVersionObject[attr] = jsonOutputVersion[attr];
      }
    });
    if (GlobalUtils.typeCheck(jsonOutputVersion.material, 'string')) {
      outputVersionObject.materialId = jsonOutputVersion.material;
    }

    // bounding box min and max corners - copy if valid
    if (GlobalUtils.typeCheck(jsonOutputVersion.bbmin, 'vector3any')) {
      outputVersionObject.bbmin = GlobalUtils.toVector3(jsonOutputVersion.bbmin);
    }
    if (GlobalUtils.typeCheck(jsonOutputVersion.bbmax, 'vector3any')) {
      outputVersionObject.bbmax = GlobalUtils.toVector3(jsonOutputVersion.bbmax);
    }

    // get output version extents from bounbing box info, or assume extents to be 1.0
    let extents = outputVersionObject.bbmin && outputVersionObject.bbmax ? outputVersionObject.bbmin.distanceTo(outputVersionObject.bbmax) : 1.0;
    // define a tolerance for NURBS geometry discretisation during glb loading, based on extents
    // FIXME this is not ideal, ideally we would know the tolerance configured for the GH model and use it
    let tolerance = extents / 10000;

    // separate materials, geometry and data
    let matJSON = [];
    let geomJSON = [];
    let dataJSON = [];
    if (Array.isArray(jsonOutputVersion.content)) {
      for (let content of jsonOutputVersion.content) {
        if (!GlobalUtils.typeCheck(content.format, 'string')) {
          return error('format of content item missing');
        }
        switch(content.format) {
          case 'material':
            // check for property data
            if (!content.data) {
              return error('data of material content item missing');
            }
            // create deep copy except for properties named canvas
            var matCopy = GlobalUtils.deepCopy(content.data, _nonCopyableOvAttributes);
            matJSON.push(matCopy);
            break;
          case 'glb':
          case 'obj':
          case 'tag2d':
          case 'tag3d':
          case 'anchor':
          case 'three':
            geomJSON.push(GlobalUtils.deepCopy(content, _nonCopyableOvAttributes));
            break;
          default:
            dataJSON.push(GlobalUtils.deepCopy(content));
        }
      }
    }

    // load materials
    let matPromises = [];
    for (let mj of matJSON) {
      let ma = new MaterialAttributes();
      matPromises.push(ma.fromJSONMaterialObject(mj));
    }

    // load geometry
    let geomPromises = [];
    for (let gj of geomJSON) {
      let transforms = [];
      if (Array.isArray(gj.transformations)) {
        for (let t of gj.transformations)
        {
          if ( t.isMatrix4 ) {
            transforms.push(t.clone());
          }
          else {
            let matrix = new THREE.Matrix4();
            // t is given in column-major format, THREE.Matrix4.fromArray needs column-major, we are good
            matrix.fromArray(t);
            transforms.push(matrix);
          }
        }
      }
      if (gj.hasOwnProperty('href') && gj.format === 'glb') {
        geomPromises.push(GLBLoader.loadGeometry(gj.href, transforms, tolerance));
      }
      else if (gj.format === 'obj') {
        if (gj.data && gj.data.objUrl) {
          geomPromises.push(OBJLoader.loadGeometry(gj.data.objUrl, gj.data.mtlUrl, gj.data.path, gj.data.texturePath, gj.data.side, transforms));
        }
      }
      else if (gj.format === 'three') {
        if (gj.data && gj.data.threeObject) {
          geomPromises.push(THREELoader.loadGeometry(gj.data.threeObject, transforms));
        }
      }
      else if (gj.format === 'tag3d' && gj.hasOwnProperty('data') && Array.isArray(gj.data)) {
        geomPromises.push(Tag3DLoader.load3DTags(gj.data));
      }
      else if ((gj.format === 'tag2d' || gj.format === 'anchor') && gj.hasOwnProperty('data') && Array.isArray(gj.data)) {
        let anchorArray = [];
        gj.data.forEach(function(gjd, index) {
          let anchorDescription = {
            path: 'Anchor_' + index,
            type: 'anchor',
            geometry: {
              position: new THREE.Vector3(gjd.location.X, gjd.location.Y, gjd.location.Z),
              viewports: gjd.viewports,
              format: gj.format === 'tag2d' ? gj.format : gjd.format,
              data: gj.format === 'tag2d' ? {color: gjd.color, text: gjd.text} : gjd.data
            }
          };
          anchorArray.push(anchorDescription);

        });
        geomPromises.push(Promise.resolve(anchorArray));
      }
    }

    // wait for all geometry objects to load, then set scene paths
    let geomArrPromise = Promise.all(geomPromises)
      .then(
        function(geomArray) {
          geomArray.forEach(function(content, index) {
            for (let geom of content) {
              geom.path = 'content_' + index + '.' + geom.path;
            }
          });
          return geomArray;
        }
      )
      .catch(
        function(err) {
          return error('loading of geometry failed', err);
        }
      )
    ;

    // wait for all material objects to load
    let matArrPromise = Promise.all(matPromises)
      .catch(
        function(err) {
          return error('loading of material failed', err);
        }
      )
    ;

    // pass through data objects
    let dataArrPromise = Promise.resolve(dataJSON);

    // wait for everything to be done, add stuff to the output version object, resolve
    return Promise.all([geomArrPromise, matArrPromise, dataArrPromise])
      .then(
        function(result) {

          if (result[0].length > 0) {
            outputVersionObject.geometry = [];
            for (let content of result[0]) {
              for (let g of content) {
                outputVersionObject.geometry.push(g);
              }
            }
          }

          if (result[1].length > 0) {
            outputVersionObject.material = result[1];
          }

          if (result[2].length > 0) {
            outputVersionObject.data = result[2];
          }

          // remember json object which was used to create the converted output version object
          outputVersionObject._json = jsonOutputVersion;

          return outputVersionObject;
        }
      )
      // .catch(
      //   // no sense to catch something here, individual catch blocks above for geometry and materials
      //   function(err) {
      //   }
      // )
    ;
  };

  /**
   * Get rid of any remnants of a process
   * @param  {ProcessId} processId The id of the process to be forgotten
   */
  var _purgeProcess = function(processId) {
    for (let creator in _partialSubScenes) {
      delete _partialSubScenes[creator][processId];
      delete _serials[creator + '.' + processId];
    }
    delete _subSceneCreationProcesses[processId];
  };

  ////////////
  ////////////
  //
  // SceneManager API
  //
  ////////////
  ////////////


  /**
   * Message handler - Initialize a new subscene creation process
   * @param {Integer} numberOfOutputs - Total number of outputs in this subscene.
   * @param {module:SceneManager~CreatorId} creatorId - Id of the entity responsible for this subscene.
   * @param {module:MessagingConstants~MessageToken} processToken - Token identifying the subscene creation process
   * @param {Object} [options] - Process options
   * @param {Boolean} [options.forceUpdate] - If true, all outputs will be added to the scenes as fresh copies, even if their version hasn't changed
   * @return {Boolean} true if message was successfully processed, false on error or if message was ignored because it has been superseded
   */
  _o.initSubScene = function(numberOfOutputs, creatorId, processToken, options) {
    let scope, error, processId, processSerial;
    scope = 'SceneManager.initSubScene';

    // error constructor
    error = function(msg, data) {
      // create error object
      let e;
      if (data instanceof Error) {
        e = data;
      }
      else {
        e = new Error(msg);
        e.scope = scope;
        e.data = data;
      }
      // send PROCESS_ERROR
      if (processId) {
        let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_ERROR, e, processToken);
        that.message(messagingConstants.messageTopics.PROCESS, m);
      }
      // log error
      that.error(e.scope, e.message, e);
      // return false
      return false;
    };

    // sanity checks
    if (!processToken || !processToken.id) {
      return error('invalid token');
    }
    processId = processToken.id;

    processSerial = processToken.serial ? processToken.serial : Date.now();
    if (typeof processSerial !== 'number') {
      processSerial = parseInt(processSerial);
      if (isNaN(processSerial)) {
        processSerial = Date.now();
      }
    }

    if (
      (_lastKnownSerial.hasOwnProperty(creatorId) && _lastKnownSerial[creatorId].serial > processSerial) ||
      (_lastPublishedSerial.hasOwnProperty(creatorId) && _lastPublishedSerial[creatorId].serial > processSerial)
    )
    {
      let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_ABORT, 'Subscene obsolete because of newer request', processToken);
      that.message(messagingConstants.messageTopics.PROCESS, m);
      return false;
    }

    // check if process is already registered
    if (_subSceneCreationProcesses.hasOwnProperty(processId)) {
      return error('attempt to register duplicate process ' + processId);
    }

    // remember latest serial for creator
    _lastKnownSerial[creatorId] = {serial: processSerial, id: processId};

    // remember serial for (creator, process id)
    _serials[creatorId + '.' + processId] = processSerial;

    _subSceneCreationProcesses[processId] = {
      initTime: Date.now(),
      creatorId: creatorId,
      processId: processId,
      totalOutputVersions: numberOfOutputs,
      finishedOutputVersions: 0,
      options: options
    };

    _partialSubScenes[creatorId] = _partialSubScenes[creatorId] || {};
    _partialSubScenes[creatorId][processId] = {
      creatorId: creatorId,
      processId: processId,
      subScene: new SubScene()
    };

    return true;
  };

  /**
   * Message handler - Add a new output version to a partial subscene
   * @param  {module:JSONOutputVersion~JSONOutputVersion} jsonOutputVersion The output version information
   * @param {module:SceneManager~CreatorId} creatorId - Id of the entity responsible for this output version
   * @param {module:MessagingConstants~MessageToken} processToken - The token identifying the process which triggered this geometry creation
   * @return {Promise<*>} resolve to SubScene if it was successfully published, to true if the output version has been successfully processed but the subscene is not complete yet, to false if output version was ignored because it has been superseded, rejects on error
   */
  _o.addJSONOutputVersionToSubScene = function(jsonOutputVersion, creatorId, processToken) {
    let scope, error, processId;
    scope = 'SceneManager.addJSONOutputVersionToSubScene';

    // error constructor
    error = function(msg, data) {
      // create error object
      let e;
      if (data instanceof Error) {
        e = data;
      }
      else {
        e = new Error(msg);
        e.scope = scope;
        e.data = data;
      }
      // send PROCESS_ERROR
      if (processId) {
        let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_ERROR, e, processToken);
        that.message(messagingConstants.messageTopics.PROCESS, m);
        // purge process
        _purgeProcess(processId);
      }
      // log error
      that.error(e.scope, e.message, e);
      // reject
      return Promise.reject(e);
    };

    // sanity checks
    if (!processToken || !processToken.id) {
      return error('invalid token');
    }
    processId = processToken.id;

    return _convertOutputVersion(jsonOutputVersion)
      .then(
        function(outputVersion) {
          return _o.addOutputVersionToSubScene(outputVersion, creatorId, processToken);
        },
        function(err) {
          return error('conversion of output version failed', err);
        }
      )
    ;

  };

  /**
   * Message handler - Add a new output version to a partial subscene
   * @param  {module:OutputVersion~OutputVersion} outputVersion - The output version information
   * @param {module:SceneManager~CreatorId} creatorId - Id of the entity responsible for this output version
   * @param {module:MessagingConstants~MessageToken} - The token identifying the process which triggered this geometry creation
   * @return {Promise<*>} resolve to SubScene if it was successfully published, to true if the output version has been successfully processed but the subscene is not complete yet, to false if output version was ignored because it has been superseded, rejects on error
   */
  _o.addOutputVersionToSubScene = function(outputVersion, creatorId, processToken) {
    let scope, error, processId, proc, lastSerial;
    scope = 'SceneManager.addOutputVersionToSubScene';

    // error constructor
    error = function(msg, data, blnWarn) {
      // create error object
      let e;
      if (data instanceof Error) {
        e = data;
      }
      else {
        if (blnWarn) {
          e = {message: msg};
        } else {
          e = new Error(msg);
        }
        e.scope = scope;
        e.data = data;
      }
      // send PROCESS_ERROR
      if (processId) {
        let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_ERROR, e, processToken);
        that.message(messagingConstants.messageTopics.PROCESS, m);
        // purge process
        _purgeProcess(processId);
      }
      // log error
      if (blnWarn) {
        that.warn(e.scope, e.message, e);
        // resolve to false
        return Promise.resolve(false);
      } else {
        that.error(e.scope, e.message, e);
        // reject
        return Promise.reject(e);
      }
    };

    // sanity checks
    if (!outputVersion.id) {
      return error('output version without id');
    }

    if (!processToken || !processToken.id) {
      return error('invalid token');
    }
    processId = processToken.id;

    if (!_subSceneCreationProcesses[processId]) {
      return error('unknown process id '+processId+', probably process was superseded', undefined, true);
    }
    proc = _subSceneCreationProcesses[processId];
    if (proc.creatorId !== creatorId) {
      return error('unexpected creatorId');
    }

    if (!_partialSubScenes[creatorId] || !_partialSubScenes[creatorId][processId]) {
      return error('no partial subscene for creatorId/processId, probably process was superseded', undefined, true);
    }

    // check if processId corresponds to the last process started using initSubScene
    lastSerial = _lastKnownSerial[creatorId];
    if (!lastSerial) {
      return error('could not find latest serial for creator id '+creatorId+', probably process was superseded', undefined, true);
    }

    if (lastSerial.id !== processId) {
      //console.log('creator ' + creatorId + ', got ' + processId + ', expected ' + lastSerial.id);
      //console.log(outputVersion);
      _purgeProcess(processId);
      let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_ABORT, 'Subscene obsolete because of newer request', processToken);
      that.message(messagingConstants.messageTopics.PROCESS, m);
      return Promise.resolve(false);
    }

    return _addOutputVersion(processToken, outputVersion)
      .then(
        function(res) {
          // send appropriate PROCESS message, purge process in case of ABORT and SUCCESS
          let m;
          if ( res === false ) {
            m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_ABORT, 'Subscene superseded, not published', processToken);
            // purge process info
            _purgeProcess(processToken.id);
          }
          else if ( res === true ) {
            // compute progress based on number of processed output versions
            let progress = 0.25 + 0.5 * (proc.finishedOutputVersions / proc.totalOutputVersions);
            m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_STATUS,
              {state: messagingConstants.messageTopics.SCENE_SUBSCENE_OUTPUTVERSION, progress: progress}, processToken
            );
          }
          else {
            m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_SUCCESS, 'Subscene published', processToken);
            m.addPart(messagingConstants.messageDataTypes.SUBSCENE_DEFINITION, res);
            // purge process info
            _purgeProcess(processToken.id);
          }
          that.message(messagingConstants.messageTopics.PROCESS, m);
          // pass on return value
          return res;
        },
        function(err) {
          return error('adding output version failed', err);
        }
      )
    ;
  };

  /**
   * Count number of subscenes which are currently being built
   * @return {Integer} Number of partial subscenes
   */
  _o.numberOfPartialSubScenes = function() {
    return Object.keys(_partialSubScenes).length;
  };

  /**
   * Count number of complete subscenes currently in cache
   * @return {Integer} Number of complete subscenes
   */
  _o.numberOfCompleteSubScenes = function() {
    return Object.keys(_completeSubScenes).length;
  };

  /**
   * Returns the current value of model data properties fitting certain filters
   *
   * This function returns the array of model data items which fit all filters you specify.
   *
   * @param  {Object} [properties] Describes the data object you want to retrieve
   * @param  {module:SceneManager~CreatorId} [properties.creatorId] The id of the plugin responsible for the data output
   * @param  {String} [properties.name] The name of the model data item
   * @param  {String} [properties.id] The output id within the SubScene to which this data item belongs. Each output id can include an array of data items.
   * @return {module:SceneManager~ModelDataItem[]} All model data items that fulfill the given filters.
   */
  _o.getModelData = function(properties) {
    let outData = [];
    properties = properties || {};
    let creators = properties.creatorId ? [properties.creatorId] : Object.keys(_modelData);
    for (let creator of creators) {
      if (_modelData.hasOwnProperty(creator)) {
        let ids = Object.keys(_modelData[creator]);
        for (let id of ids) {
          if (properties.id == null || id == properties.id) {
            for (let md of _modelData[creator][id]) {
              if (properties.name == null || md.name == properties.name) {
                let d = GlobalUtils.deepCopy(md);
                delete d.format;
                d.plugin = creator;
                d.id = id;
                outData.push(d);
              }
            }
          }
        }
      }
    }
    return outData;
  };

  /**
   * Returns the human-readable names of the geometry outputs in a SubScene
   * @param  {String} [creatorId] CreatorId (e.g. plugin id) for which the names of geometry outputs should be supplied. Will return data for all creators if omitted.
   * @return {Object} One property for each creator. The property includes one sub-property per name, which in turn holds an array of output ids.
   */
  _o.getGeometryNames = function(creatorId) {
    let index = {};
    let keys = (creatorId == null) ? Object.keys(_viewerScene) : [creatorId];
    keys.forEach(function(key) {
      if (_viewerScene.hasOwnProperty(key)) {
        if ( _viewerScene[key].hasOwnProperty('_subSceneObject')) {
          index[key] = {};
          let names = _viewerScene[key]._subSceneObject.outputNames;
          for (let n of names) {
            index[key][n] = [];
            let currentIds = _viewerScene[key]._subSceneObject.getOutputVersionIdByName(n);
            for (let curId of currentIds) {
              let ov = _viewerScene[key]._subSceneObject.getOutputVersion(curId);
              if (ov && ov.hasOwnProperty('geometry')) {
                index[key][n].push(curId);
              }
            }
          }
        }
      }
    });
    return index;
  };

  /**
   * Given a geometry name, returns the paths for all output ids containing geometry with that name
   * @param  {String} name      Geometry name specified in the objects as the SDName property
   * @param  {String} [creatorId] Creator id for which to list paths. If omitted, all creators are parsed
   * @return {String[]}           Array of paths to geometry which has the given name
   */
  _o.getGeometryPathsByName = function(name, creatorId) {
    let creators = (creatorId == null) ? Object.keys(_viewerScene) : [creatorId];
    let paths = [];
    creators.forEach(function(creator) {
      if (_viewerScene.hasOwnProperty(creator)) {
        if ( _viewerScene[creator].hasOwnProperty('_subSceneObject')) {
          let currentIds = _viewerScene[creator]._subSceneObject.getOutputVersionIdByName(name);
          for (let curId of currentIds) {
            let ov = _viewerScene[creator]._subSceneObject.getOutputVersion(curId);
            if (ov && ov.hasOwnProperty('geometry')) {
              paths.push(creator + '.' + curId);
            }
          }
        }
      }
    });
    return paths;
  };

  /**
 * Return a copy of the JSON version of output version in scene.
 * The returned asset describes the original state, with or without persistent attributes applied.
 *
 * CAUTION: some properties are returned by reference (canvas, threeObject, transformations)
 * @param  {String} outputId - The output id
 * @param  {String} creatorId - The creator id
 * @param  {Boolean} [blnScene=false] - If true return the output version with persistent attributes applied, if false (default) return the original output version.
 * @return {module:JSONOutputVersion~JSONOutputVersion}  A copy of the requested JSON output version
 */
  _o.getJSONOutputVersion = function(outputId, creatorId, blnScene) {
    if (!_viewerScene.hasOwnProperty(creatorId))
      return null;
    let ov = _viewerScene[creatorId]._subSceneObject.getOutputVersion(outputId);
    if (!ov)
      return null;
    // we return the json with / without persistent changes applied
    let prop = '_json';
    if (!ov.hasOwnProperty(prop))
      return null;
    if (blnScene && ov.hasOwnProperty('_json_scene'))
      prop = '_json_scene';
    // deep copy except for special properties
    ov = GlobalUtils.deepCopy(ov[prop], _nonCopyableOvAttributes);
    ov.scenePath = creatorId + '.' + outputId;
    return ov;
  };

  /**
   * Return all output ids for a given creator
   * @param  {String} creatorId The creator id
   * @return {String[]}           List of output ids currently in the scene for this creator, empty array if none found
   */
  _o.getOutputIds = function(creatorId) {
    if (_viewerScene.hasOwnProperty(creatorId)) {
      return _viewerScene[creatorId]._subSceneObject.outputIds;
    }
    return [];
  };

  /**
   * Remove output ids from the current published scene
   * @param  {String} creatorId - The creator id
   * @param  {String|String[]} outputIds - Array of output ids to be removed from the scene, or single output id
   * @return {Promise} Resolves if all output ids were either removed or not present
   */
  _o.removeOutputIds = function(creatorId, outputIds) {
    // sanity check
    if (!Array.isArray(outputIds)) outputIds = [outputIds];
    // return a chain of promises
    let p = Promise.resolve();
    if (_viewerScene[creatorId]) {
      let cIdObj = _viewerScene[creatorId];
      for (let id of outputIds) {
        if (cIdObj[id]) {
          cIdObj._subSceneObject.removeOutputVersion(id);
          delete cIdObj[id];
          p = p
            .then(
              function() {
                return sceneGeometryManager.removeGeometry(creatorId + '.' + id);
              }
            )
          ;
        }
      }
    }
    return p;
  };

  /**
   * Set persistent attributes
   *
   * Attributes defined using this function will be reapplied to any new OutputVersion with the given
   * id after each regular update. To remove a specific attribute, provide it with value null.
   *
   * @param  {String} outputId - The output id
   * @param  {String} creatorId - The creator id
   * @param  {module:JSONOutputVersion~JSONOutputVersion} attributes - Any attribute of this object will be applied permanently to this output id.
   * @return {Promise<module:JSONOutputVersion~JSONOutputVersion>} All currently defined persistent attributes for the given output id.
   */
  _o.setPersistentAttributes = function(outputId, creatorId, attributes) {
    let scope, error, updatedJSON, attributesCopy, p;
    scope = 'SceneManager.setPersistentAttributes';

    // error constructor
    error = (msg, data) => (_error(scope, msg, data));

    _persistentAttributes[creatorId] = _persistentAttributes[creatorId] || {};
    _persistentAttributes[creatorId][outputId] = _persistentAttributes[creatorId][outputId] || {};
    _persistentAttributes[creatorId][outputId].json = _persistentAttributes[creatorId][outputId].json || {};

    // apply attributes to persistent output version json
    // in case an attribute is set to null, remove it from the persistent attributes
    updatedJSON = GlobalUtils.deepCopy(_persistentAttributes[creatorId][outputId].json, _nonCopyableOvAttributes);
    attributesCopy = GlobalUtils.deepCopy(attributes, _nonCopyableOvAttributes);
    for (p in attributesCopy) {
      if (attributesCopy[p] === null) {
        delete updatedJSON[p];
      } else {
        updatedJSON[p] = attributesCopy[p];
      }
    }

    // FIXME in case nothing is left in updatedJSON except the id, we could remove the entries
    updatedJSON.id = outputId;

    return _convertOutputVersion(updatedJSON, true)
      .then(
        function(outputVersion) {
          let ov, token, m;

          // update stored persistent attributes, both in JSON and converted format
          _persistentAttributes[creatorId][outputId].json = updatedJSON;
          _persistentAttributes[creatorId][outputId].ov = outputVersion;

          // check if there is an existing output version to be updated
          if (!_viewerScene.hasOwnProperty(creatorId)) {
            return updatedJSON;
          }
          ov = _viewerScene[creatorId]._subSceneObject.getOutputVersion(outputId);
          if (!ov) {
            return updatedJSON;
          }

          // found an existing output version to be updated -> update it
          token = {id: GlobalUtils.createRandomId(), serial: Date.now()};

          // start a process - CAUTION: if we do this, we have to stop it again somewhere
          m = new MessagePrototype(
            messagingConstants.messageDataTypes.PROCESS_STATUS,
            {busy: false, progress: 0, creator: scope},
            token
          );
          that.message(messagingConstants.messageTopics.PROCESS, m);

          if (!_o.initSubScene(1, creatorId, token, {forceUpdate: true})) {
            return error('ViewerApp.initSubScene failed');
          }

          return _o.addOutputVersionToSubScene(ov, creatorId, token)
            .then(
              function() {
                return updatedJSON;
              }
            )
          ;
        }
      )
      // .catch(
      //   function(err) { //_convertOutputVersion failed
      //
      //   }
      // )
    ;
  };

  /**
 * Retrieve persistent attributes for an output id
 *
 * @param  {String} outputId   The output id
 * @param  {String} creatorId  The creator id
 * @return {module:JSONOutputVersion~JSONOutputVersion} All currently defined persistent attributes for the given output id.
 */
  _o.getPersistentAttributes = function(outputId, creatorId) {
    if (_persistentAttributes[creatorId] && _persistentAttributes[creatorId][outputId]) {
      return _persistentAttributes[creatorId][outputId].json;
    }
    return null;
  };

  /**
   * Return all creator ids for geometry currently in the scene
   * @return {Strinf[]} Array of creator ids
   */
  _o.getCreatorIds = function() {
    return Object.keys(_viewerScene);
  };

  return _o;
};

module.exports = SceneManager;
