/**
 * @file The default renderingHandler. Handlers all functionality related to rendering.
 *
 * @module RenderingHandlerDefault
 * @author Michael Oppitz
 */

let RenderingHandler = function (___settings, ___handlers) {
  const THREE = require('../../../../externals/three'),
        TWEEN = require('@tweenjs/tween.js'),
        GLOBAL_UTILS = require('../../../../shared/util/GlobalUtils'),
        TO_TINY_COLOR = require('../../../../shared/util/toTinyColor'),
        RenderingHandlerInterface = require('../../../interfaces/handlers/RenderingHandlerInterface'),
        RENDER_MODES = {
          STANDARD: 0,
          BEAUTY: 1,
          BLENDING: 2,
        },
        BEAUTY_RENDER_BLENDING_ID = 'beautyRenderBlending',
        UNREGISTERED_RESIZE_EVENT_ID = 'unregisteredResizeEvent',
        REGISTERED_RESIZE_EVENT_ID = 'registeredResizeEvent',
        START_UP_ID = 'startUpId',
        CAMERA_MOVING_ID = 'cameraMoving',
        MESSAGE_PROTOTYPE = require('../../../../shared/messages/MessagePrototype'),
        MESSAGING_CONSTANTS = require('../../../../shared/constants/MessagingConstants'),
        _settings = ___settings.settings,
        _scene = ___settings.scene,
        _geometryNode = ___settings.geometryNode,
        _container = ___settings.container,
        // Processes can register with a unique ID to not render.
        _noRenderingList = [START_UP_ID],
        // Processes can register with a unique ID to render restricted only.
        _restrictedRenderingList = [],
        // Processes can register with a unique ID to render continously.
        _continuousRenderingList = [],
        _handlers = ___handlers;

  let that,
      _width = _container.offsetWidth,
      _height = _container.offsetHeight,
      // As a first mode the standard mode is chosen
      _renderMode = RENDER_MODES.STANDARD,
      _continuousRendering = false,
      _helpers, _renderer,
      _updateShadowMap,
      _resizeTimer = 0,
      _blurPromise = null;

  ////////////
  ////////////
  //
  // the hooks for the settings go below
  //
  ////////////
  ////////////

  /**
   * A notifier that is called to render after a setting has been updated.
   *
   * @param {String} name The name of the setting
   * @param {*} oldVal The old value of the setting
   * @param {*} newVal The new value of the setting
   */
  let _renderNotifier = function (name, oldVal, newVal) {
    if (oldVal !== newVal)
      that.render();
  };

  /**
   * Toggles the shadows.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _shadowsHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', _handlers.threeDManager.warn, 'RenderingHandler.Hook->shadows')) return false;
    _handlers.lightHandler.setToggleLightShadows(toggle);
    return true;
  };

  /**
   * Toggles the ambient occlusion.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _ambientOcclusionHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', _handlers.threeDManager.warn, 'RenderingHandler.Hook->ambientOcclusion')) return false;
    return true;
  };

  /**
   * Sets the clear color renderer.
   *
   * @param {HexaDecimalNumber} color The new color as hexadecimal (e.g. 0xffffff)
   * @returns {Boolean} If the mode was successfully set
   */
  let _clearColorHook = function (color) {
    if (!GLOBAL_UTILS.typeCheck(color, 'color', _handlers.threeDManager.warn, 'RenderingHandler.Hook->clearColor')) return false;

    _renderer.setClearColor(TO_TINY_COLOR(color).toThreeColor());
    that.render();
    return true;
  };

  /**
   * Sets the clear alpha renderer.
   *
   * @param {Number} alpha The new alpha
   * @returns {Boolean} If the mode was successfully set
   */
  let _clearAlphaHook = function (alpha) {
    if (!GLOBAL_UTILS.typeCheck(alpha, 'notnegative', _handlers.threeDManager.warn, 'RenderingHandler.Hook->clearAlpha')) return false;

    _renderer.setClearAlpha(alpha);
    that.render();
    return true;
  };

  /**
   * Sets the size of point objects.
   *
   * @param {Number} size The new size
   * @returns {Boolean} If the mode was successfully set
   */
  let _pointSizeHook = function (size) {
    if (!GLOBAL_UTILS.typeCheck(size, 'notnegative', _handlers.threeDManager.warn, 'RenderingHandler.Hook->pointSize')) return false;

    // FIXME make viewport specific
    _scene.traverse(function (o) {
      if (o.hasOwnProperty('material') && o instanceof THREE.Points) {
        o.material.size = size;
      }
    });
    that.render();
    return true;
  };

  /**
   * @extends module:RenderingHandlerInterface~RenderingHandlerInterface
   * @lends module:RenderingHandlerDefault~RenderingHandler
   */
  class RenderingHandler extends RenderingHandlerInterface {

    /**
     * Constructor of the Rendering Handler
     *
     * @param {Object} ___settings - Instantiation settings
     * @param {Object} ___settings.settings - The default settings object
     * @param {THREE.Scene} ___settings.scene - The 3D scene
     * @param {HTMLElement} ___settings.container - The container that the renderer should be in
     */
    constructor() {
      super();

      that = this;

      _helpers = new (require('../../../helpers/handlers/RenderingHandlerHelpers'))(_handlers);

      let response = _helpers.createWebGLRenderer(_container, {
        antialias: true,
        alpha: true,
        preserveDrawingBuffer: true,
      });
      _handlers.threeDManager.success = response.success;

      // handle response (logging and messaging)
      _helpers.handleCreateWebGLRendererResponse('RenderingHandler', response, 'WebGL context was created', 'WebGL context could not be created');
      if(response.success === false) {
        return null;
      }

      _renderer = response.renderer;
      _renderer.setPixelRatio(window.devicePixelRatio);
      _renderer.setSize(_width, _height, true /* false: do not update css style of canvas */);
      _renderer.setClearColor(TO_TINY_COLOR(_settings.getSetting('clearColor'), 'white').toThreeColor(), _settings.getSetting('clearAlpha'));
      _renderer.shadowMap.enabled = true;
      // With the autoUpdate disabled, the function updateShadowMap has to be called to update the shadow map
      _renderer.shadowMap.autoUpdate = false;
      _renderer.shadowMap.needsUpdate = true;
      _updateShadowMap = false;
      _renderer.shadowMap.type = THREE.PCFShadowMap;
      _renderer.gammaFactor = 1.0;
      _renderer.gammaInput = false;
      _renderer.gammaOutput = true;
      _renderer.textureUnitCount = response.renderer.context.getParameter(response.renderer.context.MAX_TEXTURE_IMAGE_UNITS);
      if(!_renderer.textureUnitCount) _renderer.textureUnitCount = 8;
      _renderer.depth = false;
      /**
      * Perform continuous render calls if the window size changes
      * Unfortunately it is not possible currently to be notified about resizing of individual DOM elements,
      * see https://developer.mozilla.org/en-US/docs/Web/Events/resize
      */
      window.onresize = function () {
        that.registerForContinuousRendering(REGISTERED_RESIZE_EVENT_ID);
        clearTimeout(_resizeTimer);
        _resizeTimer = setTimeout(function () {
          that.unregisterForContinuousRendering(REGISTERED_RESIZE_EVENT_ID);
          that.render();
        }, 250);
      };

      /**
       * Perform a render call if the window size changes
       * FIXME Alex this likely never gets called,
       * see https://developer.mozilla.org/en-US/docs/Web/Events/resize
       */
      document.body.addEventListener('resize', function () {
        that.render();
      });

      ////////////
      ////////////
      //
      // Beauty Render Handler
      //
      ////////////
      ////////////

      _handlers.beautyRenderHandler = new (require('./BeautyRenderHandler'))({
        settings: _settings,
        scene: _scene,
        geometryNode: _geometryNode,
        renderer: _renderer
      }, _handlers);
      _handlers.threeDManager.beautyRenderHandler = _handlers.beautyRenderHandler;

      requestAnimationFrame(that._tweenAnimate);

      _settings.registerNotifier('shadows', _renderNotifier);
      _settings.registerNotifier('ambientOcclusion', _renderNotifier);
      _settings.registerHook('shadows', _shadowsHook);
      _settings.registerHook('ambientOcclusion', _ambientOcclusionHook);
      _settings.registerHook('clearColor', _clearColorHook);
      _settings.registerHook('clearAlpha', _clearAlphaHook);
      _settings.registerHook('pointSize', _pointSizeHook);
    }

    /**
     * Registers a resize event in the _continuousRenderingList and unregisterst 100 ms later.
     */
    _resizeEvent() {
      that.registerForContinuousRendering(UNREGISTERED_RESIZE_EVENT_ID, false);
      setTimeout(function () { that.unregisterForContinuousRendering(UNREGISTERED_RESIZE_EVENT_ID); }, 100);
    }


    /**
     * This function is always called before rendering.
     * It clears the renderer, updates the uniforms and updates the shadow map, if requested.
     *
     * IMPORTANT: Has to be called before every render call!
     */
    _beforeRender() {
      _renderer.clear();
      let width, height;

      // Determine if in fullscreen and get current size
      if (document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement || document.mozFullScreenElement) {
        width = screen.width;
        height = screen.height;
      } else {
        width = _renderer.domElement.parentNode.offsetWidth;
        height = _renderer.domElement.parentNode.offsetHeight;
      }

      // If the size is different, update everything
      if (width !== _width || height !== _height) {
        that._resizeEvent();
        _width = width;
        _height = height;
        _renderer.setSize(_width, _height, true /* false: do not update css style of canvas */);
        _handlers.cameraHandler.updateCameraWithSize(_width, _height);
        _handlers.beautyRenderHandler.setSize(_width, _height);
      }

      _handlers.beautyRenderHandler.updateCustomUniforms();

      if (_updateShadowMap == true) {
        _updateShadowMap = false;
        _renderer.shadowMap.needsUpdate = true;
      }
    }

    /**
     * Depending on the _continuousRenderingList the render mode is chosen.
     *
     * If there is exactly one entry, which is the _beautyRenderBlendingID, BLENDING is chosen if the call was selfInduced.
     * If there is no entry in the list BEAUTY is chosen, if the previous mode wasn't blending.
     * In all other scenarios STANDARD is chosen.
     *
     * @param {Boolean} selfInduced If this a call via processContinuousRenderingList and render
     */
    _evaluateCurrentRenderMode(selfInduced) {
      // If the blending, processing or beauty render id are the only ones in the list, do these tasks. Otherwise, render normally.
      if (_restrictedRenderingList.length == 0 && _continuousRenderingList.length == 1 && selfInduced && _continuousRenderingList.indexOf(BEAUTY_RENDER_BLENDING_ID) != -1) {
        // continue blending
        _renderMode = RENDER_MODES.BLENDING;
      } else if (_restrictedRenderingList.length == 0 && _continuousRenderingList.length == 0) {
        // render beauty
        _renderMode = RENDER_MODES.BEAUTY;
      } else {
        // render normally
        _renderMode = RENDER_MODES.STANDARD;
      }
    }

    /**
     * Utility function which allows TWEENjs to work
     */
    _tweenAnimate(time) {
      requestAnimationFrame(that._tweenAnimate);
      TWEEN.update(time);
    }

    _toggle(toggle){
      _handlers.threeDManager.helpers.toggleViewport(toggle);
      _scene.background = toggle ? _handlers.materialHandler.getSceneBackground() : null;
    }

    ////////////
    ////////////
    //
    // RenderingHandler API
    //
    ////////////
    ////////////

    destroy() {
      _renderer.dispose();
      try {
        _renderer.forceContextLoss();
      } catch (e) {
        // this fails only if there is an extension mission
        // in that case we can't do much
      }
    }

    /** @inheritdoc */
    render(selfInduced) {
      // The viewport was just destroyed, this is the last render call
      if(!_handlers.threeDManager || !_renderer.domElement) return;

      // Don't render when the viewport should not be shown
      if (_handlers.threeDManager.getSetting('show') === false) return;

      // Not possible to render without a camera
      if (!_handlers.cameraHandler) return;

      // At least one process in the _noRenderingList registered
      if (_noRenderingList.length != 0) return;

      // At least one process in the _restrictedRenderingList registered
      if (selfInduced && _restrictedRenderingList.length != 0) return;

      // Chose the right render mode
      that._evaluateCurrentRenderMode(selfInduced);

      if (_renderMode == RENDER_MODES.STANDARD) {

        // Renders the with the standard render
        requestAnimationFrame(function () {
          // The viewport was just destroyed, this is the last render call
          if (!_handlers.threeDManager) return;

          that._beforeRender();
          that._toggle(true);
          _handlers.beautyRenderHandler.renderStandard();
          _helpers.processAnchors(_renderer, _scene, _handlers.cameraHandler.getCamera(), _handlers.threeDManager.helpers.getAnchors());
          that._toggle(false);
        });

        // Quit blending, if it was active
        if (_continuousRenderingList.indexOf(BEAUTY_RENDER_BLENDING_ID) !== -1) {
          that.unregisterForContinuousRendering(BEAUTY_RENDER_BLENDING_ID);
          // send message to notify about cancelled beauty rendering
          let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.GENERIC/*, data, token*/);
          _handlers.threeDManager.message(MESSAGING_CONSTANTS.messageTopics.SCENE_RENDER_BEAUTY_CANCEL, m);
        }

      } else if (_renderMode == RENDER_MODES.BLENDING) {
        // Continues the blending of the beauty render
        requestAnimationFrame(function () {
          // The viewport was just destroyed, this is the last render call
          if (!_handlers.threeDManager) return;

          that._beforeRender();
          that._toggle(true);
          _handlers.beautyRenderHandler.renderBlending();
          that._toggle(false);
        });

      } else if (_renderMode == RENDER_MODES.BEAUTY) {
        // Start the beauty render
        requestAnimationFrame(function () {
          // The viewport was just destroyed, this is the last render call
          if (!_handlers.threeDManager) return;

          that._beforeRender();
          that._toggle(true);
          _handlers.beautyRenderHandler.renderBeauty(!selfInduced);
          _helpers.processAnchors(_renderer, _scene, _handlers.cameraHandler.getCamera(), _handlers.threeDManager.helpers.getAnchors());
          that._toggle(false);
        });
      }
    }

    /**
     * Register to not render.
     * No rendering will be done until unregisterForNoRendering is called.
     * The rendering may be blocked even further, if other processes are registered.
     *
     * @param {String} id An ID to find the process in the list
     * @param {Boolean} rendering If a last render call should be done, if undefined set to false
     */
    registerForNoRendering(id, rendering) {
      if (id === undefined)
        return;
      if (rendering === undefined)
        rendering = false;
      if (rendering)
        that.render();
      _noRenderingList.push(id);
    }

    /**
     * Removes the process with the given ID from the _noRenderingList.
     * If this was the last process in the list, the restricted rendering will start again.
     *
     * @param {String} id An ID to find the process in the list
     */
    unregisterForNoRendering(id) {
      let index = _noRenderingList.indexOf(id);
      if (index > -1)
        _noRenderingList.splice(index, 1);

      if (_noRenderingList.length == 0)
        that.render();
    }

    /**
     * Register to render resticted only.
     * The rendering will only be done when called specfically or when unregisterForRestrictedRendering is called.
     * The restriction may continue even further, if other processes are registered.
     *
     * @param {String} id An ID to find the process in the list
     * @param {Boolean} rendering If the rendering should be actived, if undefined set to false
     */
    registerForRestrictedRendering(id, rendering) {
      if (id === undefined)
        return;
      if (rendering === undefined)
        rendering = false;
      _restrictedRenderingList.push(id);
      if (rendering)
        that.render();
    }

    /**
     * Removes the process with the given ID from the _restrictedRenderingList.
     * If this was the last process in the list, the restricted rendering will stop.
     *
     * @param {String} id An ID to find the process in the list
     */
    unregisterForRestrictedRendering(id) {
      let index = _restrictedRenderingList.indexOf(id);
      if (index > -1)
        _restrictedRenderingList.splice(index, 1);
    }

    /**
     * Process the _continuousRenderingList and start a rendering if needed.
     *
     * @param {Boolean} selfInduced If this a call via requestAnimationFrame
     */
    processContinuousRenderingList(selfInduced) {
      // Not possible to render without a camera
      if (!_handlers.cameraHandler) return;

      // If already rendering, return
      if (!selfInduced && _continuousRendering) return;

      // Update the orbit contols to enable damping
      _handlers.cameraHandler.updateOrbitControls();

      if (_continuousRenderingList.length > 0 || _renderMode == RENDER_MODES.STANDARD) {
        _continuousRendering = true;
        that.render(true);
        requestAnimationFrame(function () {
          that.processContinuousRenderingList(true);
        });
      } else {
        _continuousRendering = false;
      }
    }

    /**
     * Register to render continuously.
     * The rendering will continue until the function unregisterForContinuousRendering is called.
     * The rendering may continue even further, if other processes are registered.
     *
     * @param {String} id An ID to find the process in the list
     * @param {Boolean} rendering If the rendering should be actived, if undefined set to true
     */
    registerForContinuousRendering(id, rendering) {
      if (id === undefined)
        return;
      if (rendering === undefined)
        rendering = true;
      
      // exception for the camera id
      if(!_continuousRenderingList.includes(CAMERA_MOVING_ID) || id !== CAMERA_MOVING_ID){
        _continuousRenderingList.push(id);
        if (rendering)
          that.processContinuousRenderingList();
      }

    }

    /**
     * Removes the process with the given ID from the _continuousRenderingList.
     * If this was the last process in the list, the continuous rendering will stop.
     *
     * @param {String} id An ID to find the process in the list
     */
    unregisterForContinuousRendering(id) {
      let index = _continuousRenderingList.indexOf(id);
      if (index > -1)
        _continuousRenderingList.splice(index, 1);
    }

    /** @inheritdoc */
    updateShadowMap() {
      _updateShadowMap = true;
    }

    /**
     * Returns the DOMElement of the renderer.
     * @returns {DOMElement} - DOMElement of the renderer
     */
    getDomElement() {
      return _renderer.domElement;
    }

    /**
     * Returns the extension with the given name, if available.
     * @param {String} name The name of the extension
     * @returns {Object} The extension with the specified name, if available
     */
    getExtension(name) {
      return _renderer.extensions.get(name);
    }

    /** @inheritdoc */
    getScreenshot() {
      // Comment by Alex: options could be passed on here
      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toDataURL
      // optionally we should support canvas.toBlob (see https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob)
      // maybe implement a separate function for this
      return _renderer.domElement.toDataURL('image/png');
    }

    /** @inheritdoc */
    setBlur(blur, options) {
      if (options == null || !options.hasOwnProperty('duration')) {
        let p = _blurPromise || Promise.resolve();
        _blurPromise = p.then(function () {
          _renderer.domElement.style.filter = blur ? 'blur(3px)' : '';
        });
        return;
      }

      _blurPromise = _blurPromise || Promise.resolve();
      _blurPromise = _blurPromise.then(function () {
        let blurProperties = { r: blur ? 0.0 : 3.0 };
        return new Promise(function (resolve) {
          let tweenBlur = new TWEEN.Tween(blurProperties).to({
            r: blur ? 3.0 : 1.0
          }, options.duration).onUpdate(function () {
            _renderer.domElement.style.filter = 'blur(' + blurProperties.r + 'px)';
          }).onComplete(function () {
            _renderer.domElement.style.filter = blur ? 'blur(3px)' : '';
            resolve();
          });
          tweenBlur.start();
        });
      });
    }

    getRendererInfo() {
      return _renderer.info;
    }

    getTextureUnitCount() {
      return _renderer.textureUnitCount;
    }
  }

  return new RenderingHandler(___settings);
};

module.exports = RenderingHandler;
