/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiReflectorV2.1.js*
 *
 * ### Content
 *   * Implementation of the ShapeDiver 3D Viewer API V2.1 reflector
 *
 * @module ApiReflectorV2
 * @author ShapeDiver <contact@shapediver.com>
 */


const GLOBAL_UTILS = require('../../shared/util/GlobalUtils'),
      FUNCTION_PREFIX = '___fn_',
      INCLUDE_PROTOTYPE = true;

/**
*
*/
let Reflector = function(targetWindow, targetOrigin, debug) {

  const TARGET_WINDOW = targetWindow;

  // targetOrigin may contain a path, get rid of it
  let _targetOrigin;
  let _proto;
  if ( targetOrigin.startsWith('http://') ) {
    _targetOrigin = targetOrigin.substring(7);
    _proto = 'http://';
  } else if ( targetOrigin.startsWith('https://') ) {
    _targetOrigin = targetOrigin.substring(8);
    _proto = 'https://';
  } else {
    _targetOrigin = null;
  }
  if ( _targetOrigin ) {
    let idx = _targetOrigin.indexOf('/');
    if (idx >= 0) {
      _targetOrigin = _targetOrigin.substring(0, idx);
    }
    _targetOrigin = _proto + _targetOrigin;
  }

  /**
  * container for ids of reflected objects
  */
  let _reflectedObjects = {};

  /**
  * container for tokens and their promises
  */
  let _tokenPromises = {};

  // make sure we got some meaningful value for debug
  if (typeof debug !== 'boolean') debug = false;

  /**
  * Post a message using standard properties
  */
  let _postMessage = function(message) {
    debug && console.log('POSTING MSG', message);
    _targetOrigin && TARGET_WINDOW.postMessage(message, _targetOrigin);
  };

  /**
  * Receive a message, handle it
  */
  let _receiveMessage = function(event) {
    debug && console.log('RECEIVING MSG', event);
    // do we have a target origin?
    if (!_targetOrigin) {
      debug && console.log('NO TARGET ORIGIN TO CHECK AGAINST');
      return;
    }
    // we only accept events from our target origin
    let origin = event.origin;
    if (origin !== _targetOrigin) {
      debug && console.log('ORIGIN MISMATCH', origin, _targetOrigin);
      return;
    }
    // events without data are meaningless
    let data = event.data;
    if (!data || typeof data !== 'object') {
      return;
    }
    // events without object id are meaningless
    if (typeof data.id !== 'string') {
      return;
    }

    // check if we got a command token
    if (data.token) {

      // should we restore a reflected object?
      if (data.restore) {
        if (_restore(data.restore, data.id)) {
          _postMessage({id: data.id, token: data.token, result: true});
        } else {
          _postMessage({id: data.id, token: data.token, error: 'Restoring object failed'});
        }
        return;
      }


      // below this point data.id must be contained in _reflectedObjects
      if (!_reflectedObjects.hasOwnProperty(data.id)) {
        debug && console.log('Could not find object ' + data.id);
        return;
      }
      let obj = _reflectedObjects[data.id];

      // is this a command?
      if (data.command) {
        let fn = GLOBAL_UTILS.getAtPath(obj, data.command);
        if (typeof fn !== 'function') {
          // in case data.command ends with "Async", try to find command without "Async"
          if (data.command.endsWith('Async')) {
            fn = GLOBAL_UTILS.getAtPath(obj, data.command.slice(0,-5));
          }
          if (typeof fn !== 'function') {
            debug && console.log('Could not find function ' + data.id);
            return;
          }
        }

        let args = Array.isArray(data.arguments) ? data.arguments : null;
        debug && console.log('Calling function with arguments', fn, args);
        let result = fn.apply(null, args);

        // notify other side about invocation result
        if (result instanceof Promise) {
          result.then(
            function(res) {
              // FIXME result could contain function objects which need to get reflected
              _postMessage({id: data.id, token: data.token, result: res});
            },
            function(err) {
              // FIXME err could contain function objects which need to get reflected
              _postMessage({id: data.id, token: data.token, error: err});
            }
          );
        } else {
          // fn returned directly
          // FIXME result could contain function objects which need to get reflected
          _postMessage({id: data.id, token: data.token, result: result});
        }
        return;
      }

      // is this a command result?
      if (data.hasOwnProperty('result')) {
        if (_tokenPromises.hasOwnProperty(data.token)) {
          _tokenPromises[data.token].resolve(data.result);
          delete _tokenPromises[data.token];
        }
        return;
      }

      // is this a command result error?
      if (data.error) {
        if (_tokenPromises.hasOwnProperty(data.token)) {
          _tokenPromises[data.token].reject(data.error);
          delete _tokenPromises[data.token];
        }
        return;
      }

    }


  };


  /**
  * Reflect an object, which may contain functions, into another JavaScript context,
  * typically from another window in a browser, e.g. an iframe
  *
  * @param {Object} obj - object to reflect
  * @param {String} id - unique id which should be used for registering the object on both sides
  * @return {Promise} resolves once receiver has restored object
  */
  let _reflect = function(obj, id) {
    // get paths of leafs from obj, stopping at certain leaves
    let leafs = [],
        obj_reflected = {};
    GLOBAL_UTILS.getPaths(obj, leafs, '', [], INCLUDE_PROTOTYPE);

    // for each leaf check if it is a function object...
    leafs.forEach( function(key) {
      let val = GLOBAL_UTILS.getAtPath(obj, key);
      if ( typeof val === 'function' ) {
        if (key.endsWith('Async')) {
          GLOBAL_UTILS.forceAtPath(obj_reflected, key, FUNCTION_PREFIX + key);
        } else if (!key.endsWith('EventListener')) { // avoid event listener functions for now
          GLOBAL_UTILS.forceAtPath(obj_reflected, key + 'Async', FUNCTION_PREFIX + key + 'Async');
        }
      } else {
        GLOBAL_UTILS.forceAtPath(obj_reflected, key, val);
      }
    });

    // keep reference to object
    _reflectedObjects[id] = obj;

    // send a message which tells the receiver to restore the object
    return _postMessageAndWait({id: id, restore: obj_reflected});
  };

  /**
  * Restore an object which has been received from another JavaScript context,
  * typically from another window in a browser, e.g. an iframe
  *
  * @param {Object} obj_reflected - reflected object to restore
  * @param {String} id - id of source object which had been reflected
  * @return {Object} restored object on success, null on error
  */
  let _restore = function(obj_reflected, id) {
    // check if we already got an object with this id
    if (_reflectedObjects.hasOwnProperty(id)) {
      return null;
    }

    // get paths of leafs from obj, stopping at certain leaves
    let leafs = [],
        obj = {};
    GLOBAL_UTILS.getPaths(obj_reflected, leafs, '', [], INCLUDE_PROTOTYPE);

    // for each leaf check if it is a string starting with FUNCTION_PREFIX...
    leafs.forEach( function(key) {
      let val = GLOBAL_UTILS.getAtPath(obj_reflected, key);
      if ( typeof val === 'string' ) {
        if (val.startsWith(FUNCTION_PREFIX)) {
          // replace string by function which sends a postmessage
          GLOBAL_UTILS.forceAtPath(obj, key, _reflectedFunctionFactory(id, key));
        } else {
          GLOBAL_UTILS.forceAtPath(obj, key, val);
        }
      } else {
        GLOBAL_UTILS.forceAtPath(obj, key, val);
      }
    });

    // keep reference to object
    _reflectedObjects[id] = obj;

    return obj;
  };

  /**
  * Post a message and return a promise waiting for a reply
  */
  let _postMessageAndWait = function(message) {
    let token = GLOBAL_UTILS.createRandomId();
    message.token = token;
    _postMessage(message);
    // return a promise which will eventually resolve or reject depending on the result of the invoked command
    return new Promise( function(resolve, reject) {
      _tokenPromises[token] = {resolve: resolve, reject: reject};
    });
  };

  /**
  * Factory for functions which get forwarded to targetWindow by means of postMessage
  *
  * @param {String} id - id of target object which should be used for invoking the command
  * @param {String} command - Command to invoke on the object identified by target_id
  */
  let _reflectedFunctionFactory = function(id, command) {
    return function() {
      let message = {
        id: id,
        command: command,
        arguments: Array.prototype.slice.call(arguments)
      };
      // FIXME message (arguments) might need to be reflected itself, in case arguments contain a function
      return _postMessageAndWait(message);
    };
  };

  /**
  * We register our own event listener
  */
  window.addEventListener('message', _receiveMessage, false);


  /**
  * The reflector class instances of which get returned
  */
  class Reflector {

    constructor() {
    }

    /**
    * Reflect an object, which may contain functions, into another JavaScript context,
    * typically from another window in a browser, e.g. an iframe
    *
    * @param {Object} obj - object to reflect
    * @param {String} id - unique id which should be used for registering the object on both sides
    * @return {Promise} resolves once receiver has restored object
    */
    reflect(obj, id) {
      return _reflect(obj, id);
    }

    /**
    * Get restored or original object with given id
    *
    * @param {String} id - id of restored object to get
    * @return {Object} restored object, null if not exists
    */
    get(id) {
      if (_reflectedObjects.hasOwnProperty(id)) {
        return _reflectedObjects[id];
      } else {
        return null;
      }
    }

    /**
    * Destroy reflector
    */
    destroy() {
      window.removeEventListener('message', _receiveMessage, false);
      // FIXME further cleanup
    }

    /**
    * Destroy reflector
    */
    receiveMessage(event) {
      _receiveMessage(event);
    }

  }

  return new Reflector();
};

// export the constructor
module.exports = Reflector;
