let RenderingHandler = function (___settings, ___handlers) {
  const THREE = require('../../../../externals/three'),
        GLOBAL_UTILS = require('../../../../shared/util/GlobalUtils'),
        TO_TINY_COLOR = require('../../../../shared/util/toTinyColor'),
        RenderingHandlerInterface = require('../../../interfaces/handlers/RenderingHandlerInterface'),
        _settings = ___settings.settings,
        _scene = ___settings.scene,
        _container = ___settings.container,
        _handlers = ___handlers;

  let that,
      _width = _container.width,
      _height = _container.height,
      _renderer, _light;

  ////////////
  ////////////
  //
  // the hooks for the settings go below
  //
  ////////////
  ////////////

  let _clearColorHook = function (color) {
    if (!GLOBAL_UTILS.typeCheck(color, 'color', _handlers.threeDManager.warn, 'RenderingHandler.Hook->clearColor')) return false;

    _renderer.setClearColor(TO_TINY_COLOR(color).toThreeColor());
    that.render();
    return true;
  };

  class RenderingHandler extends RenderingHandlerInterface {

    constructor() {
      super();
      that = this;

      // Comment by Alex: Here we could pass the canvas as a property, in which case no extra canvas will be created
      // by THREE.WebGLRenderer (see https://threejs.org/docs/#api/renderers/WebGLRenderer)
      _renderer = ___settings.rendererFactory({antialias: true, alpha: true, preserveDrawingBuffer: true});
      //console.log('clearAlpha: ' + _settings.getSetting('clearAlpha'));

      _renderer.setSize(_width, _height);
      _renderer.setPixelRatio( window.devicePixelRatio );
      console.log('size: ' + _width + ' ' + _height);

      _light = new THREE.Object3D();
      _light.add(new THREE.AmbientLight(0xffffff, 1));
      _handlers.threeDManager.helpers.addSceneObject(_light);

      _settings.registerHook('clearColor', _clearColorHook);
    }

    ////////////
    ////////////
    //
    // RenderingHandler API
    //
    ////////////
    ////////////

    render() {
      if(_renderer && _handlers.threeDManager)
        requestAnimationFrame(that.render);

      // The viewport was just destroyed, this is the last render call
      if(!_handlers.threeDManager) return;

      // Don't render when the viewport should not be shown
      if (_handlers.threeDManager.getSettingShallow('show') === false) return;

      // Don't render if there is no camera
      if (!(_handlers.cameraHandler && _handlers.cameraHandler.getCamera())) return;

      _handlers.threeDManager.helpers.toggleViewport(true);
      _renderer.render(_scene, _handlers.cameraHandler.getCamera());
      _handlers.threeDManager.helpers.toggleViewport(false);
    }

    getScreenshot() {
      // TODO
    }

    destroy() {
      // TODO
    }

    getDomElement() {
      // TODO
    }
  }

  return new RenderingHandler();
};

module.exports = RenderingHandler;
