let LightHandler = function (___settings, ___handlers) {
  const THREE = require('../../../../externals/three'),
        GLOBAL_UTILS = require('../../../../shared/util/GlobalUtils'),
        TO_TINY_COLOR = require('../../../../shared/util/toTinyColor'),
        THREE_D_MANAGER_CONSTANTS = require('../ThreeDManagerConstants'),
        LIGHT_TYPES = {
          AMBIENT: 0,
          DIRECTIONAL: 1,
          SPOT: 2,
          FLASHLIGHT: 999
        },
        DEFAULT_LIGHTS = require('../defaults/DefaultLights'),
        PROPERTY_TYPES = {
          color: 'color',
          intensity: 'notnegative',
          shadows: 'boolean',
          position: 'vector3any',
          target: 'vector3any',
          direction: 'vector3any',
          distance: 'notnegative',
          angle: 'notnegative',
          penumbra: 'factor',
          decay: 'notnegative',
          helper: 'boolean'
        },
        _settings = ___settings.settings,
        _handlers = ___handlers;

  let that,
      _allLights = new THREE.Object3D(),
      _lights = new THREE.Object3D(),
      _orthographicLights = new THREE.Object3D(),
      _flashLight,
      _sceneBS = new THREE.Sphere(),
      _lightInfo = {};

  class LightHandler {

    constructor() {
      that = this;

      _lightInfo[LIGHT_TYPES.AMBIENT] = 0;
      _lightInfo[LIGHT_TYPES.DIRECTIONAL] = 0;
      _lightInfo[LIGHT_TYPES.SPOT] = 0;

      _handlers.threeDManager.helpers.addSceneObject(_allLights);
      _allLights.add(_lights);
      _allLights.add(_orthographicLights);
      _orthographicLights.add(new THREE.AmbientLight(0xffffff, 1));

      _lights.visible = _handlers.threeDManager.getSetting('camera.type') == THREE_D_MANAGER_CONSTANTS.cameraViewTypes.PERSPECTIVE;
      _orthographicLights.visible = _handlers.threeDManager.getSetting('camera.type') != THREE_D_MANAGER_CONSTANTS.cameraViewTypes.PERSPECTIVE;

      _flashLight = new THREE.SpotLight(0xffffff, 0.4, 0, Math.PI / 2, 5, 5);
      _flashLight.position.set(0, 0, 1);
      _flashLight.target = _handlers.cameraHandler.getCamera();
      _flashLight.castShadow = false;
      _flashLight.visible = false;
      _allLights.add(_flashLight);

      _sceneBS.radius = 25.0;

      _settings.registerHook('light0', function (object) {
        let scope = 'LightHandler.Hook->lights.light0';
        if (object && object.type && object.properties) {
          // try to get corresponding light object
          let light;
          for (let j = 0; j < _lights.children.length; j++) {
            if (_lights.children[j].lightID == 0)
              light = _lights.children[j];
          }
          let curObject = _settings.getSetting('light0');
          if (curObject.hasOwnProperty('type') &&
            curObject.type === object.type &&
            light &&
            light.lightType === object.type) {
            if (!that._changeLightProperties(0, object.properties))
              return false;
          } else if (object.type === LIGHT_TYPES.FLASHLIGHT) {
            if (!that._addLight(0, object.type, object.properties))
              return false;
          } else {
            _handlers.threeDManager.warn(scope, 'Light 0 can only be of type Flashlight.');
            return false;
          }
        } else {
          // ignore errors that might happen during light removal
          that._removeLight(0);
        }
        return true;
      });
      _settings.updateSetting('light0', _settings.getSetting('light0'));


      // register settings hooks for settings light1 to light9
      for (let i = 1; i < 10; i++) {
        let lightName = 'light' + i;
        _settings.registerHook(lightName, function (object) {
          if (object.hasOwnProperty('type') && object.hasOwnProperty('properties')) {
            // try to get corresponding light object
            let light;
            for (let j = 0; j < _lights.children.length; j++) {
              if (_lights.children[j].lightID == i)
                light = _lights.children[j];
            }
            let curObject = _settings.getSetting(lightName);
            if (curObject.hasOwnProperty('type') &&
              curObject.type == object.type &&
              light &&
              light.lightType == object.type) {
              if (!that._changeLightProperties(i, object.properties))
                return false;
            } else {
              if (!that._addLight(i, object.type, object.properties))
                return false;
            }
          } else {
            // ignore errors that might happen during light removal
            that._removeLight(i);
          }
          return true;
        });
        // initial refresh
        _settings.updateSetting(lightName, _settings.getSetting(lightName));
      }
    }

    /**
     * Checks the data types of properties for lights.
     *
     * @param {Number} type The light type, FIXME we could check properties per light type
     * @param {Object} properties The properties for the light
     * @return {Boolean} true if all properties are fine, false if not
     */
    _checkProperties(type, properties) {
      let scope = 'LightHandler._checkProperties';
      for (let k in properties) {
        if (PROPERTY_TYPES[k] === undefined) {
          _handlers.threeDManager.warn(scope, 'Unknown property ' + k);
          return false;
        }
        if (!GLOBAL_UTILS.typeCheck(properties[k], PROPERTY_TYPES[k], _handlers.threeDManager.warn, scope, 'Property ' + k))
          return false;
      }
      return true;
    }


    /**
     * Changes the properties of a light object (Object3D that contains the light).
     * For some properties, there are some specialized things to do, like converting values.
     *
     * @param {THREE.Object3D} lightObject The light object
     * @param {Number} type The light type
     * @param {Object} properties The properties for the light
     * @return {Boolean} true on success, false on error
     */
    _changeProperties(lightObject, type, properties) {
      // check data types of properties
      if (!that._checkProperties(type, properties))
        return false;

      let light = lightObject.children[0];
      if (type == LIGHT_TYPES.AMBIENT) {
        for (let key in properties) {
          if (light.hasOwnProperty(key)) {
            if (key == 'color') {
              light[key] = TO_TINY_COLOR(properties[key]).toThreeColor();
            } else {
              light[key] = properties[key];
            }
          }
        }
      } else if (type == LIGHT_TYPES.DIRECTIONAL) {
        for (let key in properties) {
          if (light.hasOwnProperty(key)) {
            if (key == 'color') {
              light[key] = TO_TINY_COLOR(properties[key]).toThreeColor();
            } else {
              light[key] = properties[key];
            }
          }
        }

        // Adjust the shadows
        if (properties.hasOwnProperty('shadows') && properties.shadows == true) {
          light.shadows = true;
          light.castShadow = ___settings.shadows;
          light.shadow.camera.up.set(0, 0, 1);
          light.shadow.camera.far = 8 * _sceneBS.radius;
          light.shadow.camera.right = 1.5 * _sceneBS.radius;
          light.shadow.camera.left = -1.5 * _sceneBS.radius;
          light.shadow.camera.top = 1.5 * _sceneBS.radius;
          light.shadow.camera.bottom = -1.5 * _sceneBS.radius;
          light.shadow.mapSize.width = 2048;
          light.shadow.mapSize.height = 2048;
          light.shadow.radius = 2;
          light.shadow.bias = -0.00175;
          light.shadow.camera.updateProjectionMatrix();
        } else if (properties.hasOwnProperty('shadows') && properties.shadows == false) {
          light.shadows = false;
          light.castShadow = false;
        }

        // Adjust the direction, position and target
        if (properties.hasOwnProperty('direction')) {
          light.direction = GLOBAL_UTILS.toVector3(properties.direction).normalize();
        } else if (properties.hasOwnProperty('position') && properties.hasOwnProperty('target')) {
          light.direction = GLOBAL_UTILS.toVector3(properties.target).sub(GLOBAL_UTILS.toVector3(properties.position)).normalize();
        } else if (properties.hasOwnProperty('position')) {
          light.direction = GLOBAL_UTILS.toVector3(_sceneBS.center).sub(GLOBAL_UTILS.toVector3(properties.position)).normalize();
        }

        let dirLightTarget = new THREE.Object3D();
        lightObject.add(dirLightTarget);
        light.target = dirLightTarget;

        light.position.set(_sceneBS.center.x + light.direction.x * _sceneBS.radius * 2.35, _sceneBS.center.y + light.direction.y * _sceneBS.radius * 2.35, _sceneBS.center.z + light.direction.z * _sceneBS.radius * 2.35);
        light.target.position.set(_sceneBS.center.x, _sceneBS.center.y, _sceneBS.center.x);

        if (!properties.hasOwnProperty('helper')) {
          for (let i = 0; i < lightObject.children.length; i++) {
            if (lightObject.children[i].helper == true)
              lightObject.children[i].update();
          }
        } else if (properties.hasOwnProperty('helper') && properties.helper == true) {
          for (let i = 0; i < lightObject.children.length; i++) {
            if (lightObject.children[i].helper == true)
              lightObject.remove(lightObject.children[i]);
          }

          let helper = new THREE.DirectionalLightHelper(light);
          helper.up.set(0, 0, 1);
          helper.helper = true;
          lightObject.add(helper);
        } else if (properties.hasOwnProperty('helper') && properties.helper == false) {
          for (let i = 0; i < lightObject.children.length; i++) {
            if (lightObject.children[i].helper == true)
              lightObject.remove(lightObject.children[i]);
          }
        }
      } else if (type == LIGHT_TYPES.SPOT) {
        for (let key in properties) {
          if (light.hasOwnProperty(key)) {
            if (key == 'color') {
              light[key] = TO_TINY_COLOR(properties[key]).toThreeColor();
            } else {
              light[key] = properties[key];
            }
          }
        }

        let spotLightTarget = new THREE.Object3D();
        lightObject.add(spotLightTarget);
        light.target = spotLightTarget;

        let position, target, direction;
        position = properties.hasOwnProperty('position') ? GLOBAL_UTILS.toVector3(properties.position) : undefined;
        target = properties.hasOwnProperty('target') ? GLOBAL_UTILS.toVector3(properties.target) : undefined;
        direction = properties.hasOwnProperty('direction') ? GLOBAL_UTILS.toVector3(properties.direction) : undefined;

        // Adjust the direction, position and target
        if (position && target) {
          light.position.copy(position);
          light.target.position.copy(target);
        } else if (position && direction) {
          light.position.copy(position);
          light.target.position.copy(position.clone().add(direction.clone().multiplyScalar(_sceneBS.radius * 2.35)));
        } else if (position) {
          light.position.copy(position);
          light.target.position.copy(_sceneBS.center);
        } else if (target && direction) {
          light.position.copy(target.clone().add(direction.clone().multiplyScalar(_sceneBS.radius * 2.35)));
          light.target.position.copy(target);
        } else if (direction) {
          light.position.copy(_sceneBS.center.clone().add(direction.clone().multiplyScalar(_sceneBS.radius * 2.35)));
          light.target.position.copy(_sceneBS.center);
        }


        // Adjust the shadows
        if (properties.hasOwnProperty('shadows') && properties.shadows == true) {
          light.shadows = false;
          light.castShadow = false;

          // FIXME
          /*light.shadows = true;
          light.castShadow = ___settings.shadows;
          light.shadow.camera.up.set(0, 0, 1);
  
          light.shadow.mapSize.width = 1024;
          light.shadow.mapSize.height = 1024;
  
          light.shadow.camera.near = .001 * _sceneBS.radius;
          light.shadow.camera.far = 8 * _sceneBS.radius;
          light.shadow.camera.fov = 45;
          light.shadow.radius = 1;
          light.shadow.bias = -0.00005;
          light.shadow.camera.updateProjectionMatrix();*/
        } else if (properties.hasOwnProperty('shadows') && properties.shadows == false) {
          light.shadows = false;
          light.castShadow = false;
        }

        if (!properties.hasOwnProperty('helper')) {
          for (let i = 0; i < lightObject.children.length; i++) {
            if (lightObject.children[i].helper == true)
              lightObject.children[i].update();
          }
        } else if (properties.hasOwnProperty('helper') && properties.helper == true) {
          for (let i = 0; i < lightObject.children.length; i++) {
            if (lightObject.children[i].helper == true)
              lightObject.remove(lightObject.children[i]);
          }

          let helper = new THREE.SpotLightHelper(light);
          helper.up.set(0, 0, 1);
          helper.helper = true;
          lightObject.add(helper);
        } else if (properties.hasOwnProperty('helper') && properties.helper == false) {
          for (let i = 0; i < lightObject.children.length; i++) {
            if (lightObject.children[i].helper == true)
              lightObject.remove(lightObject.children[i]);
          }
        }
      }

      return true;
    }

    /**
   * Adds a light of given type and properties to the scene.
   *
   * @param {Number} type The type of light
   * @param {Object} properties The properties of the light
   * @return {Number} The id of the created light, -1 on error
   */
    _addLight(id, type, properties) {
      let scope = 'LightHandler._addLight';
      let light;

      if (type === LIGHT_TYPES.AMBIENT) {
        // THREE.AmbientLight(color : Integer, intensity : Float)
        light = new THREE.AmbientLight(0xffffff, 0.5);
      } else if (type === LIGHT_TYPES.DIRECTIONAL) {
        // THREE.DirectionalLight(color : Integer, intensity : Float)
        light = new THREE.DirectionalLight(0xffffff, 0.5);
        light.direction = new THREE.Vector3(1, -1, 1).normalize();
      } else if (type === LIGHT_TYPES.SPOT) {
        // THREE.SpotLight(color : Integer, intensity : Float, distance : Float, angle : Radians, penumbra : Float, decay : Float)
        light = new THREE.SpotLight(0xffffff, 0.5, 100, Math.PI / 4, .25, .1);
        light.up.set(0, 0, 1);
      } else if (type === LIGHT_TYPES.FLASHLIGHT) {
        // flashlight is supported for backwards compatibility on id 0 only
        if (id !== 0) {
          _handlers.threeDManager.warn(scope, 'Invalid light type, for this id.');
          return -1;
        }
        // Adjust and add the flashlight
        _flashLight.visible = _lights.visible;
        _handlers.cameraHandler.getCamera().add(_flashLight);
        _handlers.renderingHandler.updateShadowMap();
        _handlers.renderingHandler.render();
        _lightInfo[LIGHT_TYPES.SPOT]++;
        return 0;
      } else {
        _handlers.threeDManager.warn(scope, 'Invalid light type, no light was added to the scene.');
        return -1;
      }

      // remove light at current id, if any
      that._removeLight(id);

      let object = new THREE.Object3D();
      object.add(light);

      // set properties of light, type check of properties happens in _changeProperties
      if (!that._changeProperties(object, type, properties)) {
        return -1;
      }
      _lightInfo[type]++;

      object.lightID = id;
      object.lightType = type;
      _lights.add(object);

      _handlers.renderingHandler.updateShadowMap();
      _handlers.renderingHandler.render();

      return object.lightID;
    }

    /**
     * Removes the light with the given id from the scene.
     *
     * @param {Number} id The id of the light
     * @return {Boolean} true on success, false on error
     */
    _removeLight(id) {
      if (id == 0) {
        if(_flashLight.visible) {
          _lightInfo[LIGHT_TYPES.SPOT]--;
          _flashLight.visible = false;
          _handlers.renderingHandler.render();
          return true;
        }
      }

      for (let i = 0; i < _lights.children.length; i++) {
        if (_lights.children[i].lightID == id) {
          _lightInfo[_lights.children[i].lightType]--;
          _handlers.renderingHandler.updateShadowMap();
          _lights.remove(_lights.children[i]);
          _handlers.renderingHandler.render();
          return true;
        }
      }
      return false;
    }

    /**
     * Changes the properties of the light with the given id to the properties given.
     *
     * @param {Number} id The id of the light
     * @param {Object} properties The properties of the light
     * @return {Boolean} true on success, false on error
     */
    _changeLightProperties(id, properties) {
      for (let i = 0; i < _lights.children.length; i++) {
        if (_lights.children[i].lightID == id) {
          // type check of properties happens in _changeProperties
          if (!that._changeProperties(_lights.children[i], _lights.children[i].lightType, properties))
            return false;
          _handlers.renderingHandler.updateShadowMap();
          _handlers.renderingHandler.render();
          return true;
        }
      }
      return false;
    }

    ////////////
    ////////////
    //
    // LightHandler API
    //
    ////////////
    ////////////

    /**
     * Updates the position of the first directional light according to the bounding sphere.
     *
     * @param {THREE.Sphere} bs The bounding sphere around the scene
     */
    adjustToBoundingSphere(bs) {
      //FIXME
      _sceneBS = bs;

      for (let i = 0; i < _lights.children.length; i++) {
        let light = _lights.children[i];
        if (light.lightType == LIGHT_TYPES.DIRECTIONAL) {
          light = light.children[0];
          light.position.set(bs.center.x + light.direction.x * bs.radius * 2.35, bs.center.y + light.direction.y * bs.radius * 2.35, bs.center.z + light.direction.z * bs.radius * 2.35);
          light.target.position.set(bs.center.x, bs.center.y, bs.center.x);
          if (light.shadows) {
            light.shadow.camera.far = 8 * bs.radius;
            light.shadow.camera.right = 1.5 * bs.radius;
            light.shadow.camera.left = -1.5 * bs.radius;
            light.shadow.camera.top = 1.5 * bs.radius;
            light.shadow.camera.bottom = -1.5 * bs.radius;
            light.shadow.camera.updateProjectionMatrix();
          }
        }
      }
    }

    /**
     * Switch between the light settings of  the perspective camera and serveral orthographic views
     *
     * @param  {module:ThreeDManagerConstants~CameraViewType} view
     * @return {Boolean} true if the light settings were changed succefully
     */
    adaptLightingToCameraType(type) {
      _lights.visible = type == THREE_D_MANAGER_CONSTANTS.cameraViewTypes.PERSPECTIVE;
      _orthographicLights.visible = type != THREE_D_MANAGER_CONSTANTS.cameraViewTypes.PERSPECTIVE;
      if (_settings.getSetting('light0.type') == LIGHT_TYPES.FLASHLIGHT)
        _flashLight.visible = type == THREE_D_MANAGER_CONSTANTS.cameraViewTypes.PERSPECTIVE;

      return true;
    }

    /**
     * IMPORTANT: This function must only be called from RenderingHandler.Hook->shadows.
     * Toggles the shadows of the lights.
     *
     * @param {Boolean} toggle To what to set the mode
     * @returns {Boolean} If the mode was successfully set
     */
    setToggleLightShadows(toggle) {
      // No sanity check needed as this is done in RenderingHandler.Hook->shadows
      for (let i = 0; i < _lights.children.length; i++) {
        if (_lights.children[i].children[0].hasOwnProperty('shadow'))
          _lights.children[i].children[0].castShadow = toggle;
      }
    }

    /**
     * Restore a default light scene
     *
     * @param {String} name - name of the light scene to restore
     * @return {Boolean} true on success, false on error
     */
    restoreDefaultConfiguration(name) {
      let scope = 'LightHandler.getDefaultConfiguration';
      if (!GLOBAL_UTILS.typeCheck(name, 'string')) {
        _handlers.threeDManager.warn(scope, 'The given name is not a string.');
        return false;
      }
      if (!DEFAULT_LIGHTS[name]) {
        _handlers.threeDManager.warn(scope, 'The light configuration with name ' + name + ' does not exist.');
        return false;
      }
      let config = DEFAULT_LIGHTS[name];

      for (let i = 0; i < 10; i++) {
        let lightName = 'light' + i;
        _settings.updateSetting(lightName, {});
      }

      for (let light in config) {
        _settings.updateSetting(String(light), config[light]);
      }
      return true;
    }

    getLightInfo() {
      return _lightInfo;
    }

    toggleLights(t) {
      _allLights.visible = t;
      _flashLight.visible = t;
    }
  }

  return new LightHandler(___settings);
};

module.exports = LightHandler;
