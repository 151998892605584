let THREE = require('../externals/three');

var GlobalUtils = require('../shared/util/GlobalUtils');

// the scene index object
var _sceneIndex = {};

// pointer to the scene
var _scene = null;

// scene must be set by ThreeDManager
var setScene = function(scene) {
  _scene = scene;
  _scene._sdIndexObj = _sceneIndex;
  _sceneIndex.obj = _scene;
};

/**
* Given a 3D object, find a child with a certain name
* @param  {THREE.Object3D} obj  The object
* @param  {String} name The potential child's name
* @return {THREE.Object3D}      The child, null if none was found
*/
var getChildByName = function (obj, name) {
  let indexObj = obj._sdIndexObj;
  if (indexObj == null) {
    return null;
  }
  return GlobalUtils.getAtPath(indexObj, name + '.obj'); // FIXME Mathias please explain why we add '.obj' here?
};

/**
* Given a 3D object, find or create child with a given name
* @param  {THREE.Object3D} obj  The object
* @param  {String} name The child name
* @return {THREE.Object3D}      The child
*/
var getOrCreateChild = function (obj, name) {
  let indexObj = obj._sdIndexObj;
  if (indexObj == null) {
    return null;
  }
  let child = getChildByName(obj, name);
  if (child == null) {
    child = new THREE.Object3D();
    child.SDLocalPath = name;
    obj.add(child);
    indexObj[name] = {obj: child};
    child._sdIndexObj = indexObj[name];
  }
  return child;
};

/**
 * Given a base object, pursue a dot-separated path to see if there is an object there
 * @param  {THREE.Object3D} [obj] - Optional base object to start searching from, if not specified the complete scene is used
 * @param  {String} path - Path to find object, starting at base object
 * @return {THREE.Object3D} The target object if it exists, null otherwise
 */
var getPathObject = function (obj, path) {
  obj = obj || _scene;
  let indexObj = obj._sdIndexObj;
  if (indexObj == null) {
    return null;
  }
  return GlobalUtils.getAtPath(indexObj, path + '.obj'); // FIXME Mathias please explain why we add '.obj' here?
};

/**
* Make sure that there is an object at the specified path,
* creating any necessary objects in between
* @param  {THREE.Object3D} obj  The base object
* @param  {String} path Path to find object, starting at base object
* @return {THREE.Object3D}      The target object, potentially newly created
*/
var ensurePath = function (obj, path) {
  let pathParts = path.split('.');
  let recObj = obj;
  for (let pp of pathParts) {
    recObj = getOrCreateChild(recObj, pp);
  }
  return recObj;
};

/**
 * FIXME Mathias please document
 */
var deletePath = function (obj, path) {
  let toBeDeleted = getPathObject(obj, path);
  if (toBeDeleted == null) {
    return true;
  }
  let name = toBeDeleted.SDLocalPath;
  if (name == null || !GlobalUtils.typeCheck(name, 'string') || name == '') {
    return false;
  }
  let parent = toBeDeleted.parent;
  if (parent == null) {
    return false;
  }
  let parentIndexObject = parent._sdIndexObj;
  if (parentIndexObject == null) {
    return false;
  }
  parent.remove(toBeDeleted);
  delete parentIndexObject[name];

  return true;
};

/**
 * Given an object, get its path in the scene, which could be used to retrieve the object using getPathObject
 * @param  {THREE.Object3D} obj - The base object
 * @return  {String} Path to the object, starting at base object, null if not found
 */
var getObjectPath = function(obj) {
  if (!obj) return null;

  // move upwards in the scene tree until we find an object that has a property 'SDLocalPath'
  let o = obj;
  while (o && !o.hasOwnProperty('SDLocalPath')) {
    o = o.parent;
  }

  // move upwards in the scene tree, collecting values of 'SDLocalPath'
  let strArr = [];
  while (o && o.hasOwnProperty('SDLocalPath')) {
    strArr.push(o.SDLocalPath);
    o = o.parent;
  }

  // join array to path
  strArr.reverse();
  let path = strArr.join('.');
  return path === '' ? null : path;
};

// exports
var pathUtils = {
  getChildByName: getChildByName,
  getOrCreateChild: getOrCreateChild,
  getPathObject: getPathObject,
  getObjectPath: getObjectPath,
  ensurePath: ensurePath,
  deletePath: deletePath,
  setScene: setScene
};

module.exports = pathUtils;
