/**
 * @file The ViewportApi is used for all 3D related functionality.
 * 
 * @module ViewportApiDefault
 * @author Michael Oppitz
 */

let ViewportApi = function (___api, ___refs) {

  const /** @type {module:ThreeDManagerConstantsDefault~ThreeDManagerConstants} */
        THREE_D_MANAGER_CONSTANTS = require('../ThreeDManagerConstants'),
        /** @type {module:ViewportApiInterface~ViewportApiInterface} */
        ViewportApiInterface = require('../../../interfaces/api/ViewportApiInterface'),
        /** @type {module:CameraApiDefault~CameraApi} */
        CameraApi = require('./CameraApi'),
        /** @type {module:LightApiDefault~LightApi} */
        LightApi = require('./LightApi');
  
  let /** @type {module:ApiInterfaceV2~ApiInterfaceV2} */
      _api = ___api,
      /** @type {module:ThreeDManagerDefault~ThreeDManager} */
      _threeDManager = ___refs.threeDManager,
      /** @type {module:ViewportManager~ViewportManager} */
      _viewportManager = ___refs.viewportManager,
      /** @type {module:ViewportApiHelpers~ViewportApiHelpers} */
      _viewportApiHelpers,
      /** @type {Object} */
      _references,
      /** @todo restructure this like original api */
      _listeners = {};

  /**
   * @extends module:ViewportApiInterface~ViewportApiInterface
   * @lends module:ViewportApiDefault~ViewportApi
   */
  class ViewportApi extends ViewportApiInterface {
    
    /**
     * @constructs module:ViewportApiDefault~ViewportApi
     */
    constructor() {
      super();

      this.utils = _api.utils;
      this.EVENTTYPE = _api.viewports.EVENTTYPE;

      /**
       * The camera api.
       * 
       * @type {module:CameraApiDefault~CameraApi}
       */
      this.camera = new CameraApi(_api, {
        threeDManager: _threeDManager,
        viewportManager: _viewportManager,
        apiResponse: this.utils.APIResponse
      });

      /**
       * The light api.
       * 
       * @type {module:LightApiDefault~LightApi}
       */
      this.lights = new LightApi(_api, {
        threeDManager: _threeDManager,
        apiResponse: this.utils.APIResponse
      });

      _viewportApiHelpers = require('../../../helpers/api/ViewportApiHelpers').getInstance();
      
      _references = {
        THREE_D_MANAGER_CONSTANTS: THREE_D_MANAGER_CONSTANTS,
        EVENTTYPE: this.EVENTTYPE,
        threeDManager: _threeDManager,
        viewportManager: _viewportManager,
        apiResponse: this.utils.APIResponse,
        listeners: _listeners,
      };
    }

    /**
     * Update selection status of objects.
     *
     * @param {module:ApiInterfaceV2~ScenePathType[]} [select] - Optional list of scene paths of objects which should be selected (please note that there might be further selected objects after this update, which had already been selected)
     * @param {module:ApiInterfaceV2~ScenePathType[]} [deselect] - Optional list of scene paths of objects which should be deselected
     * @returns {Boolean} true if selection status could be set for all specified scene paths, false if at least one error occured.
     */
    updateSelected(select, deselect) {
      return _threeDManager.interactionHandler.setSelectedPaths(select, deselect);
    }

    /**
     * Get scene paths of selected objects.
     *
     * @returns {module:ApiInterfaceV2~ScenePathType[]} Array of scene paths of selected objects.
     */
    getSelected() {
      return _threeDManager.interactionHandler.getSelectedPaths();
    }

    /** @inheritdoc */
    getRuntimeId() {
      return _viewportApiHelpers.getRuntimeId(_references);
    }

    /** @inheritdoc */
    getContainer() {
      return _threeDManager.container;
    }

    /** @inheritdoc */
    convertTo2D(position) {
      return _viewportApiHelpers.convertTo2D(_references, position);
    }

    /** @inheritdoc */
    addEventListener(type, cb) {
      return _viewportApiHelpers.addEventListener(_references, type, cb);
    }

    /** @inheritdoc */
    removeEventListener(token) {
      return _viewportApiHelpers.removeEventListener(_references, token);
    }

    /** @inheritdoc */
    destroy() {
      return _viewportApiHelpers.destroy(_references);
    }

    /** @ignore */
    reload() {
      return _viewportApiHelpers.reload(_references);
    }

    /** @inheritdoc */
    getScreenshot() {
      return _threeDManager.renderingHandler.getScreenshot();
    }

    /** @inheritdoc */
    getSettingDefinitions() {
      return _viewportApiHelpers.getSettingDefinitions(_references);
    }

    /** @inheritdoc */
    getSettings(keys) {
      return _viewportApiHelpers.getSettings(_references, keys);
    }

    /** @inheritdoc */
    getSetting(k) {
      return _viewportApiHelpers.getSetting(_references, k);
    }

    /** @inheritdoc */
    updateSettingAsync(k, val) {
      return _viewportApiHelpers.updateSettingAsync(_references, k, val);
    }

    /** @inheritdoc */
    updateSettingsAsync(settings) {
      return _viewportApiHelpers.updateSettingsAsync(_references, settings);
    }
  }

  return new ViewportApi();
};

module.exports = ViewportApi;