/**
 * @file The default materialHandler. Handler all functionality related to the materials.
 *
 * @module MaterialHandlerDefault
 * @author Michael Oppitz
 */

let MaterialHandler = function (___settings, ___handlers) {
  const THREE = require('../../../../externals/three'),
        GLOBAL_UTILS = require('../../../../shared/util/GlobalUtils'),
        SHADERS = require('../shaders/ShaderFile'),
        MaterialHandlerInterface = require('../../../interfaces/handlers/MaterialHandlerInterface'),
        MATERIAL_ATTRIBUTES = require('../../../../shared/materials/MaterialAttributes'),
        envMapUrlPrefix = 'https://d363mqea3saz7f.cloudfront.net/envmaps/', // FIXME get rid of this, should be a setting
        envMapNamesJPG = ['default', 'default_bw', 'blurred_lights', 'georgentor', 'georgentor_blur', 'georgentor_blue_blur', 'georgentor_bw_blur', 'mountains', 'ocean', 'piazza_san_marco', 'room_abstract_1', 'sky', 'storage_room', 'storm', 'subway_entrance', 'subway_entrance_bw_blur', 'white', 'yokohama'],
        envMapNamesPNG = ['levelsets'],
        envMapFilenames = ['px', 'nx', 'pz', 'nz', 'py', 'ny'],
        _settings = ___settings.settings,
        _handlers = ___handlers;

  require('../materials/ShapeDiverStandardMaterial');

  let that,
      _helpers,
      _sceneBackground,
      _environmentMap = null,
      _usedEnvironmentMaps = [],
      _mipmapBlackListed = false,
      _lightWorldSize = 0.005,
      _lightFrustum = 6.5,
      _textureUnitCount = 0;


  ////////////
  ////////////
  //
  // the hooks for the settings go below
  //
  ////////////
  ////////////

  /**
   * Changes the environment by calling the appropriate function in ShapeDiverMaterialLoader.
   * The viewer will be set to busy while it is loading.
   *
   * @param  {String} name  Can be the name of a predefined environment map like
   *                        'default','default_bw','blurred_lights','georgentor','georgentor_blur','georgentor_blue_blur',
   *                        'georgentor_bw_blur','mountains','ocean','piazza_san_marco','room_abstract_1','sky',
   *                        'storm','subway_entrance','subway_entrance_bw_blur','white','yokohama'
   *                        or an array of urls ('px','nx','pz','nz','py','ny').
   * @returns {Promise<Boolean>} Returns a promise, which will resolve to true or false depending on success
   */
  let _environmentMapHook = function (name) {
    let scope = 'MaterialHandler.setEnvironmentMap';
    if (name === undefined) {
      _handlers.threeDManager.warn(scope, 'No input was provided, the environment map was not set.');
      return Promise.resolve(false);
    }
    let name_internal, url;

    // check if name is a JSON.stringified version of an array of urls
    if ( GLOBAL_UTILS.typeCheck(name, 'string') && name.startsWith('["https') && name.endsWith('"]') ) {
      let name_orig = name;
      try {
        name = JSON.parse(name_orig);
      } catch (e) {
        name = name_orig;
      }
    }

    // deal with string or array
    if (GLOBAL_UTILS.typeCheck(name, 'string')) {
      name_internal = name.toLowerCase().replace(/ /g, '_');
    } else if (Array.isArray(name)) {
      url = name;
      if (url.length !== 6) {
        _handlers.threeDManager.warn(scope, 'The number of URLs was not 6, the environment map was not set.');
        return Promise.resolve(false);
      }

      name_internal = JSON.stringify(url, null, 0);
    }

    for (let envMap of _usedEnvironmentMaps) {
      if (envMap.name === name_internal) {
        that._assignEnvironmentMap(envMap);
        return Promise.resolve(true);
      }
    }

    if (url === undefined) {
      url = [];
      let i;
      if (envMapNamesJPG.indexOf(name_internal) >= 0) {
        // found in list of available environment maps with file type jpg
        for (i = 0; i < envMapFilenames.length; i++)
          url.push(envMapUrlPrefix + name_internal + '/' + envMapFilenames[i] + '.jpg');
      }
      else if (envMapNamesPNG.indexOf(name_internal) >= 0) {
        // found in list of available environment maps with file type png
        for (i = 0; i < envMapFilenames.length; i++)
          url.push(envMapUrlPrefix + name_internal + '/' + envMapFilenames[i] + '.png');
      }
      else if (name.startsWith('https://') || name.startsWith('http://')) {
        if (!name.endsWith('/')) {
          name += '/';
        }
        for (i = 0; i < envMapFilenames.length; i++)
          url.push(name + envMapFilenames[i] + '.jpg');
      }
      else {
        _handlers.threeDManager.warn(scope, 'Environment map name not found, and not a base URL');
        return Promise.resolve(false);
      }
    }

    return that._loadEnvironmentMap(name_internal, url).then(function (response) {
      _usedEnvironmentMaps.push(response);
      that._assignEnvironmentMap(response);
      return true;
    }, function () {
      _handlers.threeDManager.warn(scope, 'Was not able to load the environment map, the environment map was not set.');
      return false;
    });
  };

  /**
   * Toggles the environment map as background.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _environmentMapAsBackgroundHook = function (toggle) {
    let scope = 'MaterialHandler.Hook->environmentMapAsBackground';
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', _handlers.threeDManager.warn, scope)) return false;

    if (_handlers.threeDManager.getSetting('groundPlaneReflectionVisibility') && toggle) {
      _handlers.threeDManager.warn(scope, 'The ground plane reflection and the environment as background do not work together right now.');
      return false;
    }

    if (toggle) {
      _sceneBackground = _environmentMap;
    } else {
      _sceneBackground = null;
    }
    _handlers.renderingHandler.render();
    return true;
  };

  /**
   * @extends module:MaterialHandlerInterface~MaterialHandlerInterface
   * @lends module:MaterialHandlerDefault~MaterialHandler
   */
  class MaterialHandler extends MaterialHandlerInterface {

    /**
     * Constructor of the Material Handler
     */
    constructor() {
      super();

      that = this;

      _helpers = new (require('../../../helpers/handlers/MaterialHandlerHelpers'))(_handlers, {
        nameStandard: 'ShapeDiverStandardMaterial',
        nameSimple: 'MeshPhongMaterial'
      });

      if (!_handlers.renderingHandler.getExtension('EXT_shader_texture_lod'))
        _mipmapBlackListed = true;

      _textureUnitCount = _handlers.renderingHandler.getTextureUnitCount();

      _settings.registerHook('environmentMap', _environmentMapHook);
      _settings.updateSettingAsync('environmentMap', _settings.getSetting('environmentMap'));
      _settings.registerHook('environmentMapAsBackground', _environmentMapAsBackgroundHook);
    }


    /**
     * Tries to load an environment map with the given urls and returns a
     * promise with the environment map if it succeded.
     *
     * @param {String} name The name of the environment map
     * @param {String[]} url The urls for the environment map
     * @returns {Promise} Promise with the environment map and name if succeded
     */
    _loadEnvironmentMap(name, url) {
      return new Promise(function (resolve, reject) {
        function loadDone(environmentMap) {
          environmentMap.format = THREE.RGBFormat;
          environmentMap.mapping = THREE.CubeReflectionMapping;

          // Deactivate generation of mip maps if the device is on the black list
          if (_mipmapBlackListed) {
            environmentMap.generateMipmaps = false;
            environmentMap.minFilter = THREE.LinearFilter;
            environmentMap.magFilter = THREE.LinearFilter;
          }

          resolve({
            name: name,
            map: environmentMap
          });
        }
        function error(xhr) {
          reject(xhr);
        }
        new THREE.CubeTextureLoader().load(url, loadDone, null, error);
      });
    }

    /**
     * Assigns the enviornment map to the current selection.
     * Adds the environment map to the _scene.background, if activated.
     * Updates all materials.
     *
     * @param {Object} mapObject The environment map object with the name and the map
     */
    _assignEnvironmentMap(mapObject) {
      _environmentMap = mapObject.map;
      if (_settings.getSetting('environmentMapAsBackground')) _sceneBackground = _environmentMap;

      let materials = _handlers.threeDManager.helpers.getMaterials();
      for (let i = 0, len = materials.length; i < len; i++) {
        let mat = materials[i];
        if (mat.type == 'ShapeDiverStandardMaterial' && mat.envMap) {
          mat.envMap = _environmentMap;
          mat.needsUpdate = true;
        }
      }

      _handlers.renderingHandler.render();
    }

    ////////////
    ////////////
    //
    // MaterialHandler API
    //
    ////////////
    ////////////

    /**
     * Updates the light world size in the shader to the current bounding sphere.
     *
     * @param {THREE.Sphere} bs The bounding sphere around the scene
     */
    adjustToBoundingSphere(bs) {
      _lightWorldSize = bs.radius / 25.0 * .005;
      _lightFrustum = bs.radius / 25.0 * 6.5;

      let materials = _handlers.threeDManager.helpers.getMaterials();
      for (let i = 0, len = materials.length; i < len; i++) {
        let mat = materials[i];
        if (mat.type == 'ShapeDiverStandardMaterial') {
          mat.lightWorldSize = _lightWorldSize;
          mat.lightFrustum = _lightFrustum;
        }
      }
    }

    /**
     * Returns the current environment map.
     *
     * @returns {THREE.TextureCube} The map
     */
    getEnvironmentMap() {
      return _environmentMap;
    }

    /** @inheritdoc */
    getMaterial(properties, overrideColor, vertexColors) {
      if (properties == null)
        properties = {};

      let mat = new THREE.ShapeDiverStandardMaterial(Object.assign({ lightInfo: _handlers.lightHandler.getLightInfo(), textureUnitCount: _textureUnitCount, lightWorldSize: _lightWorldSize, lightFrustum: _lightFrustum, envMap: _environmentMap, renderAO: true }, properties));

      if (overrideColor)
        mat.color.set(overrideColor.toRgbString());
      if (vertexColors)
        mat.vertexColors = THREE.VertexColors;
      return mat;
    }

    /** @inheritdoc */
    getSimpleMaterial(properties) {
      return new THREE.MeshPhongMaterial(Object.assign({shininess: properties.metalness ? properties.metalness * 128.0 : 0.0}, properties));
    }

    compile() {
      _handlers.beautyRenderHandler.renderHidden();
      _helpers.checkMaterials();
    }

    /**
     * Create a new ShapeDiverStandardMaterial from a {@link module:JSONMaterial~JSONMaterial JSONMaterial} object
     * @param  {module:JSONMaterial~JSONMaterial} jsonMaterial Input JSON material description
     * @return {Promise<THREE.ShapeDiverStandardMaterial>}
     */
    SDSMfromJSON(jsonMaterial) {
      let matAttr = new MATERIAL_ATTRIBUTES();
      return matAttr.fromJSONMaterialObject(jsonMaterial).then(function () {
        return that.getMaterial(matAttr.properties);
      }).catch(function (err) {
        return Promise.reject(err);
      });
    }

    changeNoiseFunction(part) {
      let obj = _handlers.threeDManager.helpers.getMeshMaterialObjects(),
          i, len, mat;
      for (i = 0, len = obj.length; i < len; i++) {
        mat = obj[i].material;
        mat.fragmentShader = SHADERS.standard_frag.replace('vec3 noise(vec3 m) { return vec3(0); }', part);
        mat.needsUpdate = true;
      }

      obj = _handlers.threeDManager.getExchangeMaterials();
      for (i = 0, len = obj.length; i < len; i++) {
        mat = obj[i].material;
        mat.fragmentShader = SHADERS.standard_frag.replace('vec3 noise(vec3 m) { return vec3(0); }', part);
        mat.needsUpdate = true;
      }
      _handlers.renderingHandler.render();
    }

    setSceneBackground(b) {
      _sceneBackground = b;
    }

    getSceneBackground() {
      return _sceneBackground;
    }

  }

  return new MaterialHandler(___settings);
};

module.exports = MaterialHandler;
