/**
 * @file Creates a shared interface for all RenderingHandlers.
 *       The RenderingHandlerInterface must be implemented by all RenderingHandlers.
 *       All described methods must be implemented.
 * 
 * @module RenderingHandlerInterface
 * @author Michael Oppitz
 */

/**
 * Interface for the RenderingHandler.
 * The RenderingHandler implements all functionality related to the rendering.
 * 
 * //@interface RenderingHandlerInterface
 */
class RenderingHandlerInterface {

  /**
   * The constructor for the {@link module:RenderingHandlerInterface~RenderingHandlerInterface}.
   * @constructs module:RenderingHandlerInterface~RenderingHandlerInterface
   */
  constructor() { }

  /**
   * Renders the scene depending on the render mode.
   *
   * @abstract
   * @param {Boolean} selfInduced If this a call via processContinuousRenderingList
   */  
  render(selfInduced) { }

  /**
   * Get screenshot as a png data URI.
   * Returns a screenshot of the viewer canvas without any control elements as a data URI using the png image format.
   *
   * @abstract
   * @returns {String} Data URI representing the png screeshot image (data:image/png;base64,...).
   */
  getScreenshot() { }

  /**
   * Adds or removes a global blur to the viewer canvas
   * 
   * @param {Boolean} blur Whether to add or remove the _blur
   * @param {Object} options Options for adding or removing the blur
   */
  setBlur(blur, options) { }

  /**
   * Register to render continuously.
   * The rendering will continue until the function unregisterForContinuousRendering is called.
   * The rendering may continue even further, if other processes are registered.
   *
   * @param {String} id An ID to find the process in the list
   * @param {Boolean} rendering If the rendering should be actived, if undefined set to true
   */
  registerForContinuousRendering(id, rendering) { }

  /**
   * Removes the process with the given ID from the _continuousRenderingList.
   * If this was the last process in the list, the continuous rendering will stop.
   *
   * @param {String} id An ID to find the process in the list
   */
  unregisterForContinuousRendering(id) { }

  /**
   * Updates the shadow map with the next render call.
   */
  updateShadowMap() { }
}

module.exports = RenderingHandlerInterface;