/**
 * @file Creates a shared interface for all CameraApis.
 *       The CameraApiInterface must be implemented by all CameraApis.
 *       All described methods must be implemented.
 *
 * @module CameraApiInterface
 * @author Michael Oppitz
 */

/**
 * Interface for the CameraApi.
 * The CameraApi implements all functionality related to the camera.
 *
 * //@interface CameraApiInterface
 */
class CameraApiInterface {
  /**
   * The constructor for the {@link module:CameraApiInterface~CameraApiInterface}.
   * @constructs module:CameraApiInterface~CameraApiInterface
   */
  constructor() { }

  /**
   * The camera definition including the camera's position and target as {@link module:ApiInterfaceV2~ApiInterfaceV2#Point3d}.
   *
   * @typedef {Object} module:CameraApiInterface~CameraApiInterface#CameraDefinition
   * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} position The position of the camera in the scene
   * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} target The position of the target in the scene
   */

  /**
   * Returns the position and target of the camera as a {@link module:CameraApiInterface~CameraApiInterface#CameraDefinition}.
   * If no target is available for this type of camera, the property target is undefined.
   *
   * @abstract
   * @returns {module:CameraApiInterface~CameraApiInterface#CameraDefinition} The current camera definition
   */
  get() { }
}

module.exports = CameraApiInterface;
