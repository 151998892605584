/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiInterfaceV2.1.js*
 *
 * ### Content
 *   * Abstract definition of the ShapeDiver 3D Viewer API V2.1
 *
 * @module ApiInterfaceV2
 * @author ShapeDiver <contact@shapediver.com>
 */

////////////
////////////
//
// Shared Types
//
////////////
////////////

/**
 * Every API function returns an object of this format
 *
 * @typedef {Object} module:ApiInterfaceV2~ApiInterfaceV2#APIResponse
 * @property {Error} [err] - Error object if applicable, note that both err and data may be set.
 * @property {*} [err.data] - Optional further data explaining the error.
 * @property {*} [data] - The data resulting from the API call if applicable.
 * @property {*} [payload] - Payload which optionally may be passed to asynchronous functions.
*/

/**
* 3D point or vector defined by an array of three numbers
* @typedef {Number[]} module:ApiInterfaceV2~ApiInterfaceV2#Point3dArray
*/

/**
* 3D point or vector defined by an object of three coordinates x,y,z
* @typedef {Object} module:ApiInterfaceV2~ApiInterfaceV2#Point3d
* @property {Number} [x] - x coordinate of point or vector
* @property {Number} [y] - y coordinate of point or vector
* @property {Number} [z] - z coordinate of point or vector
*/

/**
* Color including alpha value defined by an array of 4 numbers between 0 and 255 [red, green, blue, alpha]. Further supported are all formats accepted by {@link https://www.npmjs.com/package/tinycolor2 tinycolor}.
* @typedef {Number[]|String|Number} module:ApiInterfaceV2~ApiInterfaceV2#Color
*/

/**
* Event listener token - when adding an event listener, remember this token if you wish to remove the event listener again later
* @typedef {Object} module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken
*/

/**
* Scene path type - a string describing the path of an object in the 3D scene.
*
* Scene paths are composed from the following parts in dot-separated format:
*   * Runtime id of the plugin owning the object. In case of an object added via an API object, the runtime id of the API object.
*   * Id of the asset which the object belongs to.
*   * Further path of the object below the asset.
* An example scene path: CommPlugin_1.Asset_Id_1.further.path.to.object
*
* @typedef {String} module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType
*/

/**
* Event type definition
* @typedef {Object} module:ApiInterfaceV2~ApiInterfaceV2#Event
* @property {String} type - type of event as specified when registering an event listener
* @property {Object} api - Reference to the instance of the API object which was used to register the event listener
* @property {Object} [token] - optional message token created by internal processes of the ShapeDiver 3D viewer
* @property {String} [token.id] - id of the message token, the same id is used for all messages belonging to the same process
* @property {Number} [token.serial] - serial number of the message token, increases for every message belonging to the same process
* @property {*} [token.payload] - payload which optionally may be passed to asynchronous functions, e.g. a token used to identify progress or status messages of asynchronous processes
* @property {Number} [progress] - In case this is a progress event, a number describing the progress in interval [0,1]
* @property {String} [message] - An optional status message which could be displayed to the user, e.g. if computation fails etc
* @property {Number} [messageLevel] - Optional level of message to show to the user, one of 0 (error), 1 (warn), 2 (info), 3 (debug)
* @property {String} [messageScope] - An optional description of the scope which the status message originates from
* @property {String} [containerId] - Optional id of container this message relates to
* @property {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType} [scenePath] - In case of an event related to a geometric object in the scene, this specifies the path in the scene graph
* @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [dragPosStart] - In case this is a dragstart event, the starting position of a drag operation
* @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [dragPosAbs] - In case this is a dragmove or dragend event, the current absolute position of the drag operation
* @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [dragPosRel] - In case this is a dragmove or dragend event, the current relative position of the drag operation
* @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [hoverPos] - In case this is a hover event, the ray intersection point
* @property {Number} [clientX] - In case of an interaction event, the horizontal coordinate (according to the current window) of the mouse pointer when a mouse event was triggered
* @property {Number} [clientY] - In case of an interaction event, the vertical coordinate (according to the current window) of the mouse pointer when a mouse event was triggered
* @property {module:ApiInterfaceV2~AnchorEventData[]} [anchors] - In case of an anchor event, an array of {@link module:ApiInterfaceV2~AnchorEventData AnchorEventData} objects
* @property {module:ApiInterfaceV2~ApiExportInterface#ExportResult|*} [export] - data related to an export: status message, or availability message (final success)
* @property {ParameterDefinition|*} [parameter] - data related to a parameter, depending on event type: a parameter definition, or an object mapping plugin id to updated parameter ids to updated parameter value
* @property {String[]} [outputIds] - list of asset ids, for event type SUBSCENE_PUBLISHED
* @property {Object} [settings] - data related to a settings update
* @property {String} [settings.key] - key of the setting which changed
* @property {*} [settings.valueNew] - new value of the setting
* @property {*} [settings.valueOld] - old value of the setting
*/

/**
 * Event listener callback
 * @callback module:ApiInterfaceV2~ApiInterfaceV2#EventListenerCallback
 * @param {module:ApiInterfaceV2~ApiInterfaceV2#Event} event - event object depending on event type
 */


/**
 * @callback module:ApiInterfaceV2~ApiInterfaceV2#AnchorUpdateCallback
 * @param {module:ApiInterfaceV2~ApiInterfaceV2#AnchorEventDataUpdate} event
 */

/**
  * Anchor data type definition
  * @typedef {Object} module:ApiInterfaceV2~ApiInterfaceV2#AnchorEventDataAdd
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} location - The 3D point defining the location of the anchor
  * @property {String} [viewport] - runtime id of the viewport reporting the anchor position
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType} [scenePath] - this specifies the path of the anchor object in the scene graph
  * @property {String} [format] - optional format description of the anchor object, this property is passed on from the anchor definition (e.g. 'tag2d')
  * @property {Object} [data] - optional data of the anchor object, this property is passed on from the anchor definition (e.g. a Tag2D description)
  * @property {String} [runtimeId] - a unique runtimeId for this anchor
  * @property {Function} [updateFunction] - sets the callback to update the dom element is linked to the anchor every the projected coordinates of the anchor on the screen change.
  *                                         The callback that must be provided has to be of type {@link module:ApiInterfaceV2~ApiInterfaceV2#AnchorUpdateCallback}.
  */

/**
  * Anchor data type definition
  * @typedef {Object} module:ApiInterfaceV2~ApiInterfaceV2#AnchorEventDataUpdate
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} location - The 3D point defining the location of the anchor
  * @property {Number} [containerX] - horizontal coordinate (according to the viewer container) of the 3D location of the anchor
  * @property {Number} [containerY] - vertical coordinate (according to the viewer container) of the 3D location of the anchor
  * @property {Number} [clientX] - horizontal coordinate (according to the current window, scrolling not considered) of the 3D location of the anchor
  * @property {Number} [clientY] - vertical coordinate (according to the current window, scrolling not considered) of the 3D location of the anchor
  * @property {Number} [pageX] - horizontal coordinate (according to the current window) of the 3D location of the anchor
  * @property {Number} [pageY] - vertical coordinate (according to the current window) of the 3D location of the anchor
  * @property {Number} [containerWidth] - the width of the viewer container
  * @property {Number} [containerHeight] - the height of the viewer container
  * @property {String} [viewport] - runtime id of the viewport reporting the anchor position
  * @property {Boolean} [hidden] - true if the anchor is hidden behind other objects, false if not
  * @property {Boolean} [overflow] - true if the 2D anchor position is outside the viewport container
  * @property {Number} [distance] - distance between the camera and the anchor location (perspective camera only)
  * @property {Number} [viewingAngle] - the angle between the camera direction and direction from the camera origin to the anchor
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType} [scenePath] - this specifies the path of the anchor object in the scene graph
  * @property {String} [format] - optional format description of the anchor object, this property is passed on from the anchor definition (e.g. 'tag2d')
  * @property {Object} [data] - optional data of the anchor object, this property is passed on from the anchor definition (e.g. a Tag2D description)
  * @property {String} [runtimeId] - a unique runtimeId for this anchor
  */

/**
  * Anchor data type definition
  * @typedef {Object} module:ApiInterfaceV2~ApiInterfaceV2#AnchorEventDataRemove
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} location - The 3D point defining the location of the anchor
  * @property {String} [viewport] - runtime id of the viewport reporting the anchor position
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType} [scenePath] - this specifies the path of the anchor object in the scene graph
  * @property {String} [format] - optional format description of the anchor object, this property is passed on from the anchor definition (e.g. 'tag2d')
  * @property {Object} [data] - optional data of the anchor object, this property is passed on from the anchor definition (e.g. a Tag2D description)
  * @property {String} [runtimeId] - a unique runtimeId for this anchor
  */





/**
* Definition of a transition function
* @typedef {Object} module:ApiInterfaceV2~ApiInterfaceV2#TransitionParameters
* @property {Number} [duration] - duration of the transition in milliseconds
* @property {String|Function} [easing='Quartic.InOut'] - In case a string S is provided, the corresponding easing function TWEEN.Easing[S] will be used if it exists. The easing function may also be passed directly, e.g. one of the many provided by {@link https://github.com/tweenjs/tween.js/blob/master/docs/user_guide.md Tween}, see also {@link https://5013.es/toys/tween.audio/ TweenExplained}, or a manually defined one.
* @property {String} [interpolation='CatmullRom'] - In case a string S is provided, the corresponding interpolation function TWEEN.Interpolation[S] will be used if it exists. Tween supports Linear, Bezier, and CatmullRom.
* @property {String} [coordinates='cylindrical'] - Defines coordinate system to use for animated camera paths. One of 'spherical' or 'cylindrical'.
*/


////////////
////////////
//
// Parameter Interface
//
////////////
////////////

/**
  * Export event type, see {@link module:ApiInterfaceV2~ApiParameterInterface#EVENTTYPE} for values
  * @typedef {String} module:ApiInterfaceV2~ApiParameterInterface#ParameterEventType
  */

/**
 * Parameter update result, see {@link module:ApiInterfaceV2~ApiParameterInterface#RESULT} for values
 *
 * @typedef {String} module:ApiInterfaceV2~ApiParameterInterface#ParameterUpdateResult
 */

/**
 * Parameter update object, provides the possibility to select parameters by their id or name
 * @typedef {Object} module:ApiInterfaceV2~ApiParameterInterface#ParameterUpdateObject
 * @property {String} [id] - id of parameter, takes precedence over idOrName and name
 * @property {String} [idOrName] - id or name of parameter, takes precedence over name
 * @property {String} [name] - name of parameter, last priority after id and idOrName
 * @property {String} [plugin] - optional runtime id of plugin the parameter belongs to, specify this when using several sessions to the same ShapeDiver model
 * @property {*} value - Parameter value to set
 * @property {module:ApiInterfaceV2~ApiParameterInterface#ParameterUpdateResult} [result] - Result of the parameter update, will be set in response to update
 */

/**
* Parameter type, see {@link module:ApiInterfaceV2~ApiParameterInterface#TYPE} for values
* @typedef {String} module:ApiInterfaceV2~ApiParameterInterface#ParameterType
*/

/**
 * Parameter visualization, see {@link module:ApiInterfaceV2~ApiParameterInterface#VISUALIZATION} for values
 * @typedef {String} module:ApiInterfaceV2~ApiParameterInterface#ParameterVisualization
 */

/**
* Parameter definition
* @typedef {Object} module:ApiInterfaceV2~ApiParameterInterface#ParameterDefinition
* @property {String} id - unique id of the parameter (unique within a plugin instance)
* @property {String} [name] - name of the parameter
* @property {String} [_name] - original name of the parameter, will be set in case the parameter name got changed from its original value
* @property {String} [note] - description of the parameter
* @property {module:ApiInterfaceV2~ApiParameterInterface#ParameterType} [type] - type of the parameter
* @property {Number} [decimalplaces] - number of decimal places for type Float
* @property {String[]} [choices] - choices for type StringList
* @property {Number|String|Boolean} defval - default value, represented as string
* @property {Number|String|Boolean} value - current value of the parameter
* @property {String} [group] - group of the parameter for visualization purposes
* @property {String} [max] - maximum value for numeric types, max length for type String, max size for type File/Blob
* @property {String} [min] - minimum value for numeric types
* @property {String} [plugin] - optional runtime id of plugin the parameter belongs to
* @property {Boolean} [hidden] - must exist and be true if the parameter should be hidden from the ui
* @property {Number} [order] - optional number used for ordering parameters in the ui
* @property {module:ApiInterfaceV2~ApiParameterInterface#ParameterVisualization} [visualization] - how to visualize the parameter
*/

/**
* ### ShapeDiver Viewer - Parameter API
*
* The parameters interface is part of the global {@link module:ApiInterfaceV2~ApiInterfaceV2 API}.
*
* It allows to
*  * get the definition of currently registered parameters, and
*  * update the values of currently registered parameters, which in turns results in an update of the scene.
*
* @interface ApiParameterInterface
*/
var ApiParameterInterface = function() {

  /**
   * Enum for supported parameter event types.
   * @readonly
   * @enum {module:ApiInterfaceV2~ApiParameterInterface#ParameterEventType}
   */
  this.EVENTTYPE = {
    /** a parameter has been registered */
    REGISTERED : 'registered',
    /** the definition of a parameter has been updated, typically one of the properties name or order or hidden */
    UPDATE : 'update',
    /** the value of a parameter has been changed */
    VALUE_UPDATE : 'value.update',
  };

  /**
  * Enum for parameter update result.
  * @readonly
  * @enum {module:ApiInterfaceV2~ApiParameterInterface#ParameterUpdateResult}
  */
  this.RESULT = {
    /** the parameter was not found */
    PARAM_NOT_FOUND: 'param_not_found',
    /** no value was given */
    NO_VALUE: 'no_value',
    /** the new parameter value was rejected */
    VALUE_REJECT : 'value_reject',
    /** the parameter value had already been set */
    VALUE_EXISTS : 'value_exists',
    /** the new parameter value was accepted */
    VALUE_OK : 'value_ok',
    /** the plugin responsible to handle the parameter update was not found */
    PLUGIN_NOT_FOUND: 'plugin_not_found',
    /** the parameter update failed */
    ERROR: 'error',
    /** the parameter update results in a live update */
    LIVE: 'live',
    /** the parameter update results in an update which can be answered from the local cache */
    CACHE: 'cache',
    /** the parameter update results in an update which needs to be loaded from somewhere */
    LOAD: 'load',
    /** the parameter update was aborted, it was superseded by another update */
    ABORT: 'abort'
  };

  /**
  * Enum for parameter type.
  * @readonly
  * @enum {module:ApiInterfaceV2~ApiParameterInterface#ParameterType}
  */
  this.TYPE = {
    /** floating point number */
    FLOAT: 'Float',
    /** integer number */
    INT: 'Int',
    /** even integer number */
    EVEN: 'Even',
    /** odd integer number */
    ODD: 'Odd',
    /** string with maximum length */
    STRING: 'String',
    /** color string, including opacity (10 digits, e.g. 0xffffffaa) */
    COLOR: 'Color',
    /** choose from a list of strings */
    STRINGLIST: 'StringList',
    /** boolean */
    BOOL: 'Bool',
    /** date / time */
    TIME: 'Time',
    /** JavaScript File or Blob */
    FILE: 'File'
  };

  /**
  * Enum for parameter visualization.
  * @readonly
  * @enum {module:ApiInterfaceV2~ApiParameterInterface#ParameterVisualization}
  */
  this.VISUALIZATION = {
    /** currently the only choice for numeric types (Float, Int, Even, Odd) */
    SLIDER: 'slider',
    /** for type StringList */
    SEQUENCE: 'sequence',
    /** for type StringList */
    CYCLE: 'cycle',
    /** for type StringList */
    DROPDOWN: 'dropdown',
    /** for type StringList */
    CHECKLIST: 'checklist',
    /** for type Time */
    CLOCK: 'clock',
    /** for type Time */
    CALENDAR: 'calendar'
  };

  /**
  * Get parameter definitions for some or all parameters
  *
  * @param {module:ApiInterfaceV2~ApiParameterInterface#ParameterDefinition} [filter] - Definitions for parameters whose definition matches the properties of this filter object will be returned, may be empty in which case all parameter definitions will be returned
  * @param {String} [filter.id] - id of parameter
  * @param {String} [filter.name] - name of parameter
  * @param {String} [filter.plugin] - runtime id of plugin the parameter belongs to
  * @param {String} [filter.type] - type of parameter
  * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an array of {@link module:ApiInterfaceV2~ApiParameterInterface#ParameterDefinition parameter definitions}
  */
  this.get = function() {};

  /**
   * Update parameter values and corresponding parts of the scene.
   *
   * Depending on which parameters are included in the request, the update may involve several plugins.
   * The return value will include a status code for each parameter which was specified in the request.
   *
   * @param  {module:ApiInterfaceV2~ApiParameterInterface#ParameterUpdateObject[]} values - Objects defining the parameters to update and their new values
   * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a data array of {@link ParameterUpdateObject ParameterUpdateObject} objects, each including the result property, for all parameters which had been provided. In case the scene update fails or is aborted due to a newer update, the APIResponse will not contain a data array but an err.
   */
  this.updateAsync = function() {};

  /**
   * Check whether it is possible to go back in history
   * @see {@link module:ApiInterfaceV2~ApiParameterInterface#canGoForwardInHistory canGoForwardInHistory}
   * @see {@link module:ApiInterfaceV2~ApiParameterInterface#goBackInHistoryAsync goBackInHistoryAsync}
   * @see {@link module:ApiInterfaceV2~ApiParameterInterface#goForwardInHistoryAsync goForwardInHistoryAsync}
   * @return {Boolean} true if it is possible to go back in history
   */
  this.canGoBackInHistory = function() {};

  /**
   * Check whether it is possible to go forward in history
   * @see {@link module:ApiInterfaceV2~ApiParameterInterface#canGoBackInHistory canGoBackInHistory}
   * @see {@link module:ApiInterfaceV2~ApiParameterInterface#goBackInHistoryAsync goBackInHistoryAsync}
   * @see {@link module:ApiInterfaceV2~ApiParameterInterface#goForwardInHistoryAsync goForwardInHistoryAsync}
   * @return {Boolean} true if it is possible to go forward in history
   */
  this.canGoForwardInHistory = function() {};

  /**
   * Go back in history, if this is possible
   * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function
   * @see {@link module:ApiInterfaceV2~ApiParameterInterface#canGoBackInHistory canGoBackInHistory}
   * @see {@link module:ApiInterfaceV2~ApiParameterInterface#canGoForwardInHistory canGoForwardInHistory}
   * @see {@link module:ApiInterfaceV2~ApiParameterInterface#goForwardInHistoryAsync goForwardInHistoryAsync}
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a data array of {@link ParameterUpdateObject ParameterUpdateObject} objects, corresponding to the parameter state after the movement in history. In case the scene update fails or is aborted due to a newer update, the APIResponse will not contain a data array but an err.
   */
  this.goBackInHistoryAsync = function() {};

  /**
   * Go forward in history, if this is possible
   * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function
   * @see {@link module:ApiInterfaceV2~ApiParameterInterface#canGoBackInHistory canGoBackInHistory}
   * @see {@link module:ApiInterfaceV2~ApiParameterInterface#canGoForwardInHistory canGoForwardInHistory}
   * @see {@link module:ApiInterfaceV2~ApiParameterInterface#goBackInHistoryAsync goBackInHistoryAsync}
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a data array of {@link ParameterUpdateObject ParameterUpdateObject} objects, corresponding to the parameter state after the movement in history. In case the scene update fails or is aborted due to a newer update, the APIResponse will not contain a data array but an err.
   */
  this.goForwardInHistoryAsync = function() {};

  /**
  * Update properties of registered parameters.
  *
  * The following properties can be updated: name, order, hidden
  * This function will fail in case no unique parameter can be found for each parameter definition given.
  *
  * @param {module:ApiInterfaceV2~ApiParameterInterface#ParameterDefinition[]} [definitions] - updated parameter definitions
  * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with its data set to a boolean according to success or error
  */
  this.updateProperties = function() {};

  /**
   * Add a callback to be invoked when a certain event occurs
   *
   * @param {module:ApiInterfaceV2~ApiParameterInterface#ParameterEventType} type - Type of event to subscribe to
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerCallback} callback - Function to be called when the event fires
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an {@link module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken EventListenerToken} object. The event listener token can be used to remove the event listener by calling {@link module:ApiInterfaceV2~ApiParameterInterface#removeEventListener removeEventListener}.
   */
  this.addEventListener = function() {};

  /**
   * Remove an event listener
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken} token - event listener token for event listener to be removed
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean data, indicating whether the event listener could be removed.
   */
  this.removeEventListener = function() {};

};

////////////
////////////
//
// Export Interface
//
////////////
////////////

/**
 * Export request result, see {@link module:ApiInterfaceV2~ApiExportInterface#RESULT} for values
 *
 * @typedef {String} module:ApiInterfaceV2~ApiExportInterface#ExportRequestResult
 */

/**
  * Export event type, see {@link module:ApiInterfaceV2~ApiExportInterface#EVENTTYPE} for values
  * @typedef {String} module:ApiInterfaceV2~ApiExportInterface#ExportEventType
  */

/**
 * Definition of an export
 * @typedef {Object} module:ApiInterfaceV2~ApiExportInterface#ExportDefinition
 * @property {String} id - unique id of export
 * @property {String} plugin - plugin runtime id of export
 * @property {String} name - name of export
 * @property {String} [_name] - original name of the export, will be set in case the export name got changed from its original value
 * @property {String} type - type of export, currently we support 'email', 'download', and 'shapeways'
 * @property {Boolean} [hidden] - must exist and be true if the export should be hidden from the ui
 * @property {Number} [order] - optional number used for ordering exports in the ui
 */

/**
 * Data required for requesting an export
 * @typedef {Object} module:ApiInterfaceV2~ApiExportInterface#ExportRequestObject
 * @property {String} [id] - id of export, takes precedence over idOrName and name
 * @property {String} [idOrName] - id or name of export, takes precedence over name
 * @property {String} [name] - name of export, last priority after id and idOrName
 * @property {String} [plugin] - runtime id of plugin the export belongs to
 * @property {Boolean} [silent=false] - if true, do not display any information about the export to the user
 */

/**
 * Export content item of a {@link module:ApiInterfaceV2~ApiExportInterface#ExportResult ExportResult}
 * @typedef {Object} module:ApiInterfaceV2~ApiExportInterface#ExportResultContentItem
 * @property {String} format - format of the exported file, e.g. stl|3dm|dxf|dwg|…
 * @property {String} href - download link to the exported file, time limited
 * @property {Number} size - size in bytes of the exported file
 */

/**
 * Definition of the object resulting from a finished export, or from a status of an ongoing export
 * @typedef {Object} module:ApiInterfaceV2~ApiExportInterface#ExportResult
 * @property {String} id - unique id of the export
 * @property {String} plugin - plugin runtime id of export
 * @property {String} name - name of the export
 * @property {String} type - type of export, currently we support 'email', 'download', and 'shapeways'
 * @property {String} [msg] - optional status message for the export, e.g. describing server side progress or status
 * @property {String} [version] - version id of the export
 * @property {String} [filename] - optional filename to be used for files which are part of content (FILENAME.FILETYPE)
 * @property {module:ApiInterfaceV2~ApiExportInterface#ExportResultContentItem[]} [content] - will be set once the export has been finished for type download. Typically this array has only one entry.
 * @property {Object} [result] - optional object describing the result of the export for types email and shapeways
 * @property {String} [result.msg] - optional message to display to the user
 * @property {String} [result.err] - optional error message to display to the user
 * @property {String} [result.href] - optional link to direct the user to
 * @property {String} [result.modelId] - optional ShapeWays model id
 * @property {module:ApiInterfaceV2~ApiExportInterface#ExportRequestResult} [resultcode] - result code
 */

/**
* ### ShapeDiver Viewer - Export API
*
* The export api is part of the global {@link module:ApiInterfaceV2~ApiInterfaceV2 API}.
*
* It allows to
*  * get the definition of currently registered exports,
*  * request exports, and
*  * add and remove listeners for related events.
*
* @interface ApiExportInterface
*/
var ApiExportInterface = function() {

  /**
  * Enum for export request result.
  * @readonly
  * @enum {module:ApiInterfaceV2~ApiExportInterface#ExportRequestResult}
  */
  this.RESULT = {
    /** the export was not found */
    EXPORT_NOT_FOUND: 'export_not_found',
    /** the plugin responsible to handle the export request was not found */
    PLUGIN_NOT_FOUND: 'plugin_not_found',
    /** the export request failed */
    ERROR: 'error',
    /** the export request can be answered from the local cache */
    CACHE: 'cache',
    /** the export request needs to be loaded from somewhere */
    LOAD: 'load'
  };

  /**
   * Enum for supported export event types.
   * @readonly
   * @enum {module:ApiInterfaceV2~ApiExportInterface#ExportEventType}
   */
  this.EVENTTYPE = {
    /** the result of an export has become available */
    AVAILABLE : 'available',
    /** a new export has been registered */
    REGISTERED : 'registered',
    /** the definition of an export has been updated, typically one of the properties name or order or hidden */
    UPDATE : 'update',
    /** status event for an ongoing export */
    STATUS : 'status'
  };

  /**
  * Get all export definitions
  *
  * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an array of {@link module:ApiInterfaceV2~ApiExportInterface#ExportDefinition export definitions}
  */
  this.get = function() {};

  /**
  * Requests an export for current parameter values.
  *
  * @param  {module:ApiInterfaceV2~ApiExportInterface#ExportRequestObject} requestObject - Object defining the export request.
  * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function.
  * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a {@link module:ApiInterfaceV2~ApiExportInterface#ExportResult export result} object.
  */
  this.requestAsync = function() {};

  /**
  * Update properties of registered exports.
  *
  * The following properties can be updated: name, order, hidden
  * This function will fail in case no unique export can be found for each export definition given.
  *
  * @param {module:ApiInterfaceV2~ApiExportInterface#ExportDefinition[]} [definitions] - updated export definitions
  * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with its data set to a boolean according to success or error
  */
  this.updateProperties = function() {};

  /**
   * Add a callback to be invoked when a certain event occurs
   *
   * @param {module:ApiInterfaceV2~ApiExportInterface#ExportEventType} type - Type of event to subscribe to
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerCallback} callback - Function to be called when the event fires
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an {@link module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken EventListenerToken} object. The event listener token can be used to remove the event listener by calling {@link module:ApiInterfaceV2~ApiExportInterface#removeEventListener removeEventListener}.
   */
  this.addEventListener = function() {};

  /**
   * Remove an event listener
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken} token - event listener token for event listener to be removed
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean data, indicating whether the event listener could be removed.
   */
  this.removeEventListener = function() {};

};

////////////
////////////
//
// Plugin Interface
//
////////////
////////////

/**
 * Plugin status type, see {@link module:ApiInterfaceV2~ApiPluginInterface#STATUS} for values
 * @typedef {Number} module:ApiInterfaceV2~ApiPluginInterface#PluginStatus
 */

/**
  * Plugin state description
  * @typedef {Object} module:ApiInterfaceV2~ApiPluginInterface#PluginStatusDescription
  * @property {String} id - runtime id of the plugin instance
  * @property {module:ApiInterfaceV2~ApiPluginInterface#PluginStatus} status - status of the plugin instance
  * @property {String} name - name of the plugin
  * @property {String} shortName - short name of the plugin
  *
  */

/**
 * ### ShapeDiver Viewer - Plugin API
 *
 * The plugin interface is part of the global {@link module:ApiInterfaceV2~ApiInterfaceV2 API}.
 *
 * It allows to
 *  * get the status of currently registered plugin instances, and
 *  * register and deregister plugin instances.
 *
 * @interface ApiPluginInterface
 */
var ApiPluginInterface = function() {

  /**
  * Enum for plugin status.
  * @readonly
  * @enum {module:ApiInterfaceV2~ApiPluginInterface#PluginStatus}
   */
  this.STATUS = {
    /** the plugin instance was created, a unique runtime id is not available yet  */
    NONE: 'none',
    /** the plugin instance got a unique runtime id and is ready to load  */
    READY: 'ready',
    /** the plugin instance failed for whatever reason and is not usable  */
    FAILED: 'failed',
    /** the plugin instance successfully loaded, any potential initialization is not yet finished */
    LOADED: 'loaded',
    /** plugin is operating, i.e. any potential initialization that was triggered by loading succeeded, ready to receive parameter updates */
    ACTIVE: 'active',
    /** plugin was unloaded and is ready to be garbage collected */
    UNLOADED: 'unloaded'
  };

  /**
   * Retrieve the current state of plugins
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API response with a data array of type {@link module:ApiInterfaceV2~ApiPluginInterface#PluginStatusDescription PluginStatusDescription}.
   *                                            Description of the current state of all plugins currently known to the viewer.
   *                                            Plugins may be asked for their status individually using their getStatus() method.
   */
  this.get = function() {};

  /**
   * Register a plugin
   *
   * Loads the plugin and returns once the plugin attains plugin status {@link module:ApiInterfaceV2~ApiPluginInterface#STATUS ACTIVE} or
   * {@link module:ApiInterfaceV2~ApiPluginInterface#STATUS FAILED}.
   * Unless the plugin's setting deferGeometryLoading is true, the plugin will be asked to display geometry for default parameter values.
   * @param {Object} plugin - The plugin to register
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with data of type {@link module:ApiInterfaceV2~ApiPluginInterface#PluginStatusDescription PluginStatusDescription}
   *                                                      for the plugin to register.
   */
  this.registerPluginAsync = function() {};

  /**
   * Create and register a new instance of the ShapeDiver Communication Plugin
   *
   * Loads the plugin and returns once the plugin attains plugin status {@link module:ApiInterfaceV2~ApiPluginInterface#STATUS ACTIVE} or
   * {@link module:ApiInterfaceV2~ApiPluginInterface#STATUS FAILED}.
   * Unless the plugin's setting deferGeometryLoading is true, the plugin will be asked to display geometry for default parameter values.
   * @param {Object} settings - Settings to use for creating the CommPlugin instance
   * @param {String} settings.ticket - The ticket defining the model and other attributes of the session
   * @param {String} [settings.authorization=''] - optional authorization token to include with requests to the
   *                                         model view interface (prepend 'Bearer ' yourself if necessary)
   * @param {Boolean} [settings.deferGeometryLoading] - Initialise the session but do not load geometry for default parameter values (wait for parameter update)
   * @param {String} [settings.modelViewUrl='https://model-view.shapediver.com:443'] - The model view interface url
   * @param {String} [settings.runtimeId] - Optional runtime id, if none is provided one will be created automatically, must be unique,
   *                                        used for identifying parameters with the same id across multiple plugins
   * @param {Number} [settings.ajaxTimeout=30000] - Timeout in milliseconds for ajax requests
   * @param {Number} [settings.loggingLevel] - Initial logging level - will be overridden once plugin gets registered, hence this is rarely used
   * @param {Number} [settings.messageLoggingLevel] - Initial message logging level - will be overridden once plugin gets registered, hence this is rarely used
   *
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with data of type {@link module:ApiInterfaceV2~ApiPluginInterface#PluginStatusDescription PluginStatusDescription}
   *                                                      for the newly registered plugin.
   */
  this.registerCommPluginAsync = function() {};

  /**
   * Refresh the outputs of a plugin by calling its set*ParameterValues function for the current values of its parameters.
   *
   * Use this to initially load geometry if the plugin was created using 'deferGeometryLoading' set to true.
   *
   * @param {String} id - runtime id of the plugin whose outputs should be refreshed
   * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a data array of {@link ParameterUpdateObject ParameterUpdateObject} objects,
   *                                                      corresponding to the parameter state of the plugin.
   */
  this.refreshPluginAsync = function() {

  };

  /**
   * Save default parameter values for one or all plugins, using the current state of parameter values.
   *
   * This will fail if the plugin does not provide the capability to store model settings.
   * Instances of the CommPlugin provide this capability in case the model view session allows this.
   *
   * Be aware of the following: In case multiple instances of the CommPlugin using the same model are registered,
   * and you call this function without specifying a plugin runtime id, it is undefined which state will get stored.
   *
   * @param {String|String[]} [id] - runtime id(s) of the plugin(s) for which default parameter values should be stored,
   *   leave this undefined to store default parameter values for all plugin which provide the required capability
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse whose data property will be true or false depending on success.
   */
  this.saveDefaultParameterValuesAsync = function() {

  };

  /**
  * Deregister a plugin
  * @param {String} id - runtime id of the plugin to deregister
  * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with data of type
  *   {@link module:ApiInterfaceV2~ApiPluginInterface#PluginState PluginStatusDescription} for the plugin deregister.
  */
  this.deregisterPluginAsync = function() {};

};


////////////
////////////
//
// State Interface
//
////////////
////////////

/**
  * Viewer state description
  * @typedef {Object} module:ApiInterfaceV2~ApiStateInterface#ViewerAppState
  * @property {Boolean} busy - true if the viewer is in busy mode, false if the viewer is in idle mode
  * @property {Number} progress - minimum progress of all processes currently reporting a progress, interval [0,1]
  * @property {Object.<String,Object>} processes - List of currently active processes
  * @property {Array} failedStatusMessages - array of status messages corresponding to fatal errors
  */

/**
  * Status event type, see {@link module:ApiInterfaceV2~ApiStateInterface#EVENTTYPE} for values
  * @typedef {String} module:ApiInterfaceV2~ApiStateInterface#StateEventType
  */

/**
 * ### ShapeDiver Viewer - State API
 *
 * The state interface is part of the global {@link module:ApiInterfaceV2~ApiInterfaceV2 API}.
 *
 * It allows to
 *  * get an overview of the current state of the viewer instance,
 *  * influence the state of the viewer instance and its visual representation,
 *  * add and remove listeners for related events.
 * @interface ApiStateInterface
 */
var ApiStateInterface = function() {

  /**
   * Enum for supported state event types.
   * @readonly
   * @enum {@link module:ApiInterfaceV2~ApiStateInterface#StateEventType}
   */
  this.EVENTTYPE = {
    /** viewer has entered busy state */
    BUSY : 'busy',
    /** viewer has entered idle state */
    IDLE : 'idle',
    /** a message which may be displayed to the user */
    MESSAGE : 'message',
    /** a message reporting a fatal error, e.g. the WebGL context was lost and could not be recovered  */
    FAILED : 'failed',
  };

  /**
   * Retrieve the current state of the viewer
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API response with data of type {@link @link module:ApiInterfaceV2~ApiStateInterface#ViewerAppState}. Description of the current state of the viewer application.
   */
  this.get = function() {};

  /**
   * Register the API as a busy process of the viewer app
   * @param  {Boolean} busy - If true, a busy process will be registered, causing the viewer app to enter busy mode. If false and a busy process of the API had been registered before, it will be removed.
   * @param  {Number} [progress] - Set the percentage of completion in interval [0,1]. If progress bars are displayed, this will influence them.
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API response with data of type {@link @link module:ApiInterfaceV2~ApiStateInterface#ViewerAppState}. Description of the current state of the viewer application.
   */
  this.setBusy = function() {};

  /**
   * Add a callback to be invoked when a certain event occurs
   *
   * @param {@module:ApiInterfaceV2~ApiStateInterface#StateEventType} type - Type of event to subscribe to
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerCallback} callback - Function to be called when the event fires
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an {@link module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken EventListenerToken} object. The event listener token can be used to remove the event listener by calling {@link module:ApiInterfaceV2~ApiStateInterface#removeEventListener removeEventListener}.
   */
  this.addEventListener = function() {};

  /**
   * Remove an event listener
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken} token - event listener token for event listener to be removed
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean data, indicating whether the event listener could be removed.
   */
  this.removeEventListener = function() {};
};


////////////
////////////
//
// Viewport Interface
//
////////////
////////////

/**
  * Viewport type, see {@link module:ApiInterfaceV2~ApiViewportsInterface#TYPE} for values
  * @typedef {String} module:ApiInterfaceV2~ApiViewportsInterface#ViewportType
  */

/**
  * Viewport event type, see {@link module:ApiInterfaceV2~ApiViewportsInterface#EVENTTYPE} for values
  * @typedef {String} module:ApiInterfaceV2~ApiViewportsInterface#ViewportEventType
  */

/**
 * @typedef module:ApiInterfaceV2~ApiViewportsInterface#ViewportDefinition
 * @property {HTMLElement} container the domElement in which to put the viewer
 * @property {module:ApiInterfaceV2~ApiViewportsInterface#ViewportType} type the type of the viewport
 * @property {String} runtimeId a unique runtimeId, if the id is not unique, a new one will be created
 */


/**
 *
 * ```
 * // container for the viewport, typically this is a <div>
 * var _container = document.getElementById('sdv-container-viewport');
 * let viewportApi = api.viewports.create({
 *   container: _container,
 *   type: api.viewports.TYPE.DEFAULT,
 *   runtimeID: 'defaultViewport'
 * });
 *
 * // to get the camera definition
 * viewportApi.camera.get();
 *
 * // get a screenshot of the current viewport
 * viewportApi.getScreenshot();
 *
 * // if there is only one default viewport
 * // api.scene.camera.get();
 * // and
 * // api.scene.getScreenshot();
 * // would do exactly the same
 * ```
 *
 * The api for a specific viewport. If there is only one viewport {@link module:ApiInterfaceV2~ApiSceneInterface api.scene} can be used instead.
 * Due to the implementation being identical to {@link module:ApiInterfaceV2~ApiSceneInterface api.scene} but only viewport specific, the documentation is omitted. Please see {@link module:ApiInterfaceV2~ApiSceneInterface api.scene} instead.
 *
 * The viewportApi has a camera api ({@link module:CameraApiDefault~CameraApi viewportApi.camera}) and a lights api ({@link module:LightApiDefault~LightApi viewportApi.lights}) that have the same methods as the corresponding apis in {@link module:ApiInterfaceV2~ApiSceneInterface api.scene}.
 * The specific documentation is omitted. Please see {@link module:CameraApiDefault~CameraApi api.scene.camera} or {@link module:LightApiDefault~LightApi api.scene.lights}.
 *
 * @typedef module:ApiInterfaceV2~ApiViewportsInterface#ViewportApi
 * @property {module:CameraApiDefault~CameraApi} camera the camera api
 * @property {module:LightApiDefault~LightApi} lights the light api
 */

/**
 * ### ShapeDiver Viewer - Viewport API
 *
 * The viewports interface is part of the global {@link module:ApiInterfaceV2~ApiInterfaceV2 API}.
 *
 * It allows to
 *  * get viewport apis
 *  * create and destroy viewports
 *
 * @interface ApiViewportsInterface
 */
var ApiViewportsInterface = function() {
  /**
   * Enum for the viewport types.
   * @readonly
   * @enum {module:ApiInterfaceV2~ApiViewportsInterface#ViewportType}
   */
  this.TYPE = {
    /** the default viewport */
    DEFAULT: 'default',
    /** the simple viewport - just basic functionality */
    //SIMPLE: 'simple',
    /** the ar viewport - for special ar projects */
    //AR: 'ar',
  };

  /**
   * Enum for the viewport api events.
   * @readonly
   * @enum {module:ApiInterfaceV2~ApiViewportsInterface#ViewportEventType}
   */
  this.EVENTTYPE = {
    /** the viewport has been destroyed */
    DESTROYED: 'destroyed',
  };

  /**
   * Get api for existing viewports
   *
   * @param {String} runtimeId the runtimeId of the viewport, or undefined to return an object containing apis for all viewports
   * @return {module:ApiInterfaceV2~ApiViewportsInterface#ViewportApi} the api of the viewport with the given runtimeId, or an object containing apis for all viewports
   */
  this.get = function() {};

  /**
   * Creates a new viewport
   *
   * @param {module:ApiInterfaceV2~ApiViewportsInterface#ViewportDefinition} viewportDefinition definition of the viewport
   * @return {module:ApiInterfaceV2~ApiViewportsInterface#ViewportApi} the api of the new viewport
   */
  this.create = function() {};

  /**
   * Destroys an existing viewport
   *
   * @param {String} runtimeId the runtimeId of the viewport
   * @return {boolean} true if there was a viewport to remove
   */
  this.destroy = function() {};

};

////////////
////////////
//
// Scene Interface
//
////////////
////////////

/**
  * Scene asset item format, see {@link module:ApiInterfaceV2~ApiSceneInterface#FORMAT} for values
  * @typedef {String} module:ApiInterfaceV2~ApiSceneInterface#SceneAssetItemFormat
  */

/**
  * Anchor definition
  * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#Anchor
  * @property {String} version - Always '1.0'
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} location - The 3D point defining the location of the anchor
  * @property {String[]} [viewports] - optional array of viewport runtime ids, for which the anchors shall report their position, defaults to all viewports
  * @property {String} [format] - optional format description of the anchor object, this property will be passed on to events related to the anchor,
  *                               predefined formats include {@link module:ApiInterfaceV2~ApiSceneInterface#AnchorDataFormatText text}
  *                               and {@link module:ApiInterfaceV2~ApiSceneInterface#AnchorDataFormatImage image}
  * @property {Object} [data] - optional data of the anchor object, this property will be passed on to events related to the anchor,
  *                             predefined objects include {@link module:ApiInterfaceV2~ApiSceneInterface#AnchorDataFormatText AnchorDataFormatText}
  *                             and {@link module:ApiInterfaceV2~ApiSceneInterface#AnchorDataFormatImage AnchorDataFormatImage}
  */

/**
 * Anchor data object for format 'tag2d'
 * (legacy support, use text now)
 * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#AnchorDataFormatTag2D
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} color
 * @property {String} text
 */

/**
 * Anchor data object for format 'text'
 * Displays text at the anchor location
 * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#AnchorDataFormatText
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} color [r,g,b,a]
 * @property {String} text
 * @property {Boolean} hidden if it can be hidden by other objects (default true)
 * @property {String} positioning.horizontal left, center, right (default left)
 * @property {String} positioning.vertical bottom, center, top (default top)
 * @property {String} textAlign left, center, right (default left)
 */

/**
 * Anchor data object for format 'image'
 * Displays an image at the anchor location
 * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#AnchorDataFormatImage
 * @property {String} src
 * @property {Boolean} hidden if it can be hidden by other objects (default true)
 * @property {String} positioning.horizontal left, middle, right (default left)
 * @property {String} positioning.vertical bottom, middle, top (default top)
 * @property {Number|String} height can be given in px, or percentage of current div (default '')
 * @property {Number|String} width can be given in px, or percentage of current div (default '')
 * @property {String} alt alternative text, if image display fails
 */

/**
  * 2d text tag definition
  * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#Tag2d
  * @property {String} version - Always '1.0'
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} color - [r,g,b,a] array of numbers between 0-255
  * @property {String} text - The text of the 2d tag
  * @property {Object} location - The point defining the location of the 2d tag
  * @property {Number} location.X
  * @property {Number} location.Y
  * @property {Number} location.Z
  */

/**
  * 3d text tag definition
  * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#Tag3d
  * @property {String} version - Always '1.0'
  * @property {String} justification - One of ['BL','BC','BR','ML','MC','MR','TL','TC','TR']
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} color - [r,g,b,a] array of numbers between 0-255
  * @property {Number} size - Text size in model units
  * @property {String} text - The text for the tag
  * @property {Object} location - The plane defining the 3d tag's position and orientation in the scene
  * @property {Object} location.origin
  * @property {Number} location.origin.X
  * @property {Number} location.origin.Y
  * @property {Number} location.origin.Z
  * @property {Object} location.normal
  * @property {Number} location.normal.X
  * @property {Number} location.normal.Y
  * @property {Number} location.normal.Z
  * @property {Object} location.xAxis
  * @property {Number} location.xAxis.X
  * @property {Number} location.xAxis.Y
  * @property {Number} location.xAxis.Z
  * @property {Object} location.yAxis
  * @property {Number} location.yAxis.X
  * @property {Number} location.yAxis.Y
  * @property {Number} location.yAxis.Z
  *
  */

/**
  * OBJ reference
  * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#ObjMeshReference
  * @property {String} objUrl A string containing the path/URL of the .obj file.
  * @property {String} [mtlUrl] A string containing the path/URL of the .mtl file.
  * @property {String} [path] Set base path for resolving references. If set this path will be prepended to each loaded and found reference.
  * @property {String} [texturePath] If set this path will be prepended found texture reference. If not set and setPath is, it will be used as texture base path.
  * @property {String} [side='double'] - The sidedness to use for the mesh, one of 'front', 'back', 'double'
  */

/**
  * THREE geometry reference
  * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#ThreeGeometryReference
  * @property {THREE.Mesh|THREE.Line|THREE.Points} threeObject - A THREE object, {@link https://threejs.org/docs/#api/objects/Mesh Mesh}, {@link https://threejs.org/docs/#api/objects/Line Line}, or {@link https://threejs.org/docs/#api/objects/Points Points} are supported
  */

/**
  * Transformation matrix - Array containing the 16 elements of a 4x4 matrix in row-major format, or a {@link https://threejs.org/docs/#api/math/Matrix4 THREE.Matrix4}.
  * @typedef {Number[]|THREE.Matrix4} module:ApiInterfaceV2~ApiSceneInterface#Transformation
  */

/**
 * Material version 1.0 type definition
 * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#MaterialV1
 * @see {@link module:ApiInterfaceV2~ApiSceneInterface#MaterialV2}
 * @see {@link module:ApiInterfaceV2~ApiSceneInterface#MaterialV3}
 * @see {@link module:ApiInterfaceV2~ApiSceneInterface#Texture}
 * @property {String} version - must be set to "1.0"
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [ambient] - Ambient color. CAUTION: This property is ignored, it has been superseded.
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} diffuse - Main color
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [emission] - Emissive (light) color of the material, essentially a solid color unaffected by other lighting. Default is black. CAUTION: This property is ignored, it has been superseded.
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [specular] - Specular color of the material. Default is a Color set to 0x111111 (very dark grey). CAUTION: This property is ignored, it has been superseded by environment maps.
 * @property {Number} [shine] - How shiny the specular highlight is; a higher value gives a sharper highlight. Default is 30.
 * @property {Number} [transparency] - Float in the range of 0.0 - 1.0 indicating how transparent the material is. A value of 0.0 indicates fully opaque, 1.0 is fully transparent.
 * @property {String} [bitmaptexture] - Url to an image which will be used as a color texture
 * @property {String} [bumptexture] - Url to an image which will be used as a bump texture
 * @property {String} [transparencytexture] - Url to an image which will be used as a transparency texture
 */

/**
  * Material version 2.0 type definition
  * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#MaterialV2
  * @see {@link module:ApiInterfaceV2~ApiSceneInterface#MaterialV1}
  * @see {@link module:ApiInterfaceV2~ApiSceneInterface#MaterialV3}
  * @see {@link module:ApiInterfaceV2~ApiSceneInterface#Texture}
  * @property {String} version - must be set to "2.0"
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} color - Material color
  * @property {Number} [metalness] - How much the material is like a metal. Non-metallic materials such as wood or stone use 0.0, metallic use 1.0, with nothing (usually) in between. Default is 0.5. A value between 0.0 and 1.0 could be used for a rusty metal look. If metalnesstexture is also provided, both values are multiplied.
  * @property {Number} [roughness] - How rough the material appears. 0.0 means a smooth mirror reflection, 1.0 means fully diffuse. Default is 0.5. If roughnesstexture is also provided, both values are multiplied.
  * @property {Number} [transparency] - Float in the range of 0.0 - 1.0 indicating how transparent the material is. A value of 0.0 indicates fully opaque, 1.0 is fully transparent.
  * @property {Number} [alphaThreshold] - Sets the alpha value to be used when running an alpha test. The material will not be renderered if the opacity is lower than this value. Default is 0.
  * @property {String} [bitmaptexture] - URL to the color map. Default is null. The texture map color is modulated by the material color.
  * @property {String} [metalnesstexture] - The blue channel of this texture is used to alter the metalness of the material.
  * @property {String} [roughnesstexture] - The green channel of this texture is used to alter the roughness of the material.
  * @property {String} [bumptexture] - URL to the texture to create a bump map. The black and white values map to the perceived depth in relation to the lights. Bump doesn't actually affect the geometry of the object, only the lighting. If a normal map is defined this will be ignored.
  * @property {String} [normaltexture] - URL to the texture to create a normal map. The RGB values affect the surface normal for each pixel fragment and change the way the color is lit. Normal maps do not change the actual shape of the surface, only the lighting.
  * @property {String} [transparencytexture] - URL to the alpha map, which is a grayscale texture that controls the opacity across the surface (black: fully transparent; white: fully opaque). Default is null.
  */

/**
 * Texture type definition
 * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#Texture
 * @see {@link module:ApiInterfaceV2~ApiSceneInterface#MaterialV3}
 * @property {String} [href] - Link to the bitmap to be used as texture, either href or canvas must be specified
 * @property {Object} [canvas] - Canvas to be used as texture, either href or canvas must be specified
 * @property {Number[]} [offset] - Offset used on uv coordinates for this texture, in each direction U and V. CAUTION: This updates the texture coordinates, be aware of this when using several textures for one mesh.
 * @property {Number[]} [repeat] - How many times the texture is repeated across the surface, in each direction U and V. CAUTION: This updates the texture coordinates, be aware of this when using several textures for one mesh.
 * @property {Number} [rotation] - How much the texture is rotated around the center point, in radians. Positive values are counter-clockwise. Default is 0. CAUTION: This updates the texture coordinates, be aware of this when using several textures for one mesh.
 * @property {Number[]} [center] - The point around which rotation occurs. A value of (0.5, 0.5) corresponds to the center of the texture. Default is (0, 0), the lower left. CAUTION: This updates the texture coordinates, be aware of this when using several textures for one mesh.
 */

/**
  * 3D Noise description
  * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#ThreeDNoise
  * @see {@link module:ApiInterfaceV2~ApiSceneInterface#MaterialV3}
  * @property {Number} [opacity] - The opacity of this effect, where a value of 0 disables this effect (default: 0.0)
  * @property {Number} [noiseID] - The ID for the noise function that is used (range: 0-3, default: 0)
  * @property {Number} [scale] - The scale of the noise (default: 1.0)
  * @property {Number} [distance] - The amount the noise fades with distance (default: 0.0)
  */

/**
  * Material version 3.0 type definition
  * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#MaterialV3
  * @see {@link module:ApiInterfaceV2~ApiSceneInterface#MaterialV1}
  * @see {@link module:ApiInterfaceV2~ApiSceneInterface#MaterialV2}
  * @see {@link module:ApiInterfaceV2~ApiSceneInterface#Texture}
  * @see {@link module:ApiInterfaceV2~ApiSceneInterface#ThreeDNoise}
  * @property {String} version - must be set to "3.0"
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} color - Material color
  * @property {Number} [metalness] - How much the material is like a metal. Non-metallic materials such as wood or stone use 0.0, metallic use 1.0, with nothing (usually) in between. Default is 0.5. A value between 0.0 and 1.0 could be used for a rusty metal look. If metalnesstexture is also provided, both values are multiplied.
  * @property {Number} [roughness] - How rough the material appears. 0.0 means a smooth mirror reflection, 1.0 means fully diffuse. Default is 0.5. If roughnesstexture is also provided, both values are multiplied.
  * @property {Number} [transparency] - Float in the range of 0.0 - 1.0 indicating how transparent the material is. A value of 0.0 indicates fully opaque, 1.0 is fully transparent.
  * @property {Number} [alphaThreshold] - Sets the alpha value to be used when running an alpha test. The material will not be renderered if the opacity is lower than this value. Default is 0.
  * @property {Number} [shadowOpacity] - The adjustement for the opacity of the shadow. Default is 1.
  * @property {Number} [lightReflectivity] - The amount the light is reflected in metal-like surfaces. Default is 1.
  * @property {module:ApiInterfaceV2~ApiSceneInterface#ThreeDNoise} [threeDNoise] - The options for the 3D Noise effect that can optionally be turned on using its opacity.
  * @property {module:ApiInterfaceV2~ApiSceneInterface#Texture} [bitmaptexture] - URL to the color map. Default is null. The texture map color is modulated by the material color.
  * @property {module:ApiInterfaceV2~ApiSceneInterface#Texture} [metalnesstexture] - The blue channel of this texture is used to alter the metalness of the material.
  * @property {module:ApiInterfaceV2~ApiSceneInterface#Texture} [roughnesstexture] - The green channel of this texture is used to alter the roughness of the material.
  * @property {module:ApiInterfaceV2~ApiSceneInterface#Texture} [bumptexture] - URL to the texture to create a bump map. The black and white values map to the perceived depth in relation to the lights. Bump doesn't actually affect the geometry of the object, only the lighting. If a normal map is defined this will be ignored.
  * @property {module:ApiInterfaceV2~ApiSceneInterface#Texture} [normaltexture] - URL to the texture to create a normal map. The RGB values affect the surface normal for each pixel fragment and change the way the color is lit. Normal maps do not change the actual shape of the surface, only the lighting.
  * @property {module:ApiInterfaceV2~ApiSceneInterface#Texture} [transparencytexture] - URL to the alpha map, which is a grayscale texture that controls the opacity across the surface (black: fully transparent; white: fully opaque). Default is null.
  */


/**
 * Scene asset item - each scene asset can have multiple items
 * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#SceneAssetItem
 * @property {module:ApiInterfaceV2~ApiSceneInterface#SceneAssetItemFormat} format
 * @property {String} [href] - Link to the geometry data. Either this property or the 'data' property must be provided.
 * @property {module:ApiInterfaceV2~ApiSceneInterface#Anchor|module:ApiInterfaceV2~ApiSceneInterface#Tag2d|module:ApiInterfaceV2~ApiSceneInterface#Tag3d|module:ApiInterfaceV2~ApiSceneInterface#MaterialV1|module:ApiInterfaceV2~ApiSceneInterface#MaterialV2|module:ApiInterfaceV2~ApiSceneInterface#MaterialV3|module:ApiInterfaceV2~ApiSceneInterface#ObjMeshReference|module:ApiInterfaceV2~ApiSceneInterface#ThreeGeometryReference|*} [data] - Data as defined by the 'format' property.
 * @property {module:ApiInterfaceV2~ApiSceneInterface#Transformation[]} [transformations] - Optional property defining the transformation matrices to be used for displaying instances of this output. If this property exists it must contain at least one transformation. CAUTION: These transformations are only applied to asset items whose format is 'glb', 'obj', and 'three'.
 */

/**
 * Interaction mode type definition, used to define whether a complete asset or its subobjects should become selectable, hoverable, or draggable, see {@link module:ApiInterfaceV2~ApiSceneInterface#INTERACTIONMODETYPE} for values
 * @typedef {String} module:ApiInterfaceV2~ApiSceneInterface#InteractionModeType
 */

/**
  * Scene asset
  *
  * This definition describes an asset containing geometry and/or materials.
  *
  * If an asset contains no material data, it may instead link to another asset which does.
  * This makes it possible to replace geometry and materials separately - if a
  * material is replaced, that change is reflected in all the geometry objects which
  * link to it.
  *
  * All properties can be optional depending on the context.
  *
  * If the API returns an asset, it usually includes all properties.
  *
  * For geometry updates one can send only the properties which need to be changed,
  * along with the id of the object to be changed.
  *
  * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#SceneAsset
  * @property {String} id - The unique id of this asset. Ids of existing assets can be retrieved using {@link module:ApiInterfaceV2~ApiSceneInterface#get get}. This id should be remembered to reference the asset later, e.g. to update or remove it.
  * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType} [scenePath] - scene path of the asset (composition of the runtime id of the owner of the asset, and the unique id of the asset)
  * @property {String} [name] - The human readable name of the asset
  * @property {String} [group] - If provided, defines into which group this asset belongs. Default is 'Default'.
  * @property {String} [material] - Id of the material asset to be used for the geometry defined by this asset. Can be ommited if this asset contains its own materials, or if the geometry in this asset has embedded materials.
  * @property {String} [version] - Unique version id of this asset. If the same asset is provided again later with the same version id, caching might improve performance.
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3dArray} [bbmin] - Minimum coordinates of the axis-aligned bounding box of the geometry in this asset. If not provided, this will be computed. Not used for material assets. Set this to null when updating geometry and you don't know the updated bounding box, which will cause the bounding box to be recomputed in the viewer. If you don't do this, the previous bounding box information will persist.
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3dArray} [bbmax] - Maximum coordinates of the axis-aligned bounding box of the geometry in this asset. If not provided, this will be computed. Not used for material assets. Set this to null when updating geometry and you don't know the updated bounding box, which will cause the bounding box to be recomputed in the viewer. If you don't do this, the previous bounding box information will persist.
  * @property {module:ApiInterfaceV2~ApiSceneInterface#SceneAssetItem[]} content - The geometries and materials to be added to the scene.
  * @property {Number} [duration] - Duration of transition in msec for fading in and out, defaults to 500 for replacements and 0 for addition and removal.
  * @property {String} [interactionGroup] - Specifies the id of the interaction group to which this asset belongs.
  * @property {module:ApiInterfaceV2~ApiSceneInterface#InteractionModeType} [interactionMode=ApiSceneInterface.INTERACTIONMODETYPE.SUB] - Allows to specify whether the complete asset or its individual subobjects are selectable/hoverable/draggable. Requires an interactionGroup to be specified. See {@link module:ApiInterfaceV2~ApiSceneInterface#INTERACTIONMODETYPE} for values.
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d|module:ApiInterfaceV2~ApiInterfaceV2#Point3dArray} [dragPlaneNormal] - Optional normal to the plane which should be used for dragging. If not specified, dragging will happen in a plane normal to the viewing direction.
  * @property {Function} [addEventListener] - When the API returns an asset, it adds this function to allow registering to events limited to this specific object (scene path). Refer to the documentation of the global {@link module:ApiInterfaceV2~ApiSceneInterface#addEventListener addEventListener} function.
  * //FIXME allow to specify a global transformation for the complete asset here
  */

/**
  * Scene asset filter - used to define filter criteria for retrieving information about assets in the scene
  *
  * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#SceneAssetFilter
  * @property {String} [id] - Unique id of the asset.
  * @property {String} [name] - Human readable name of the asset.
  * @property {String} [group] - Group the asset belongs to.
  * @property {String} [material] - Id of the material asset to be used for the geometry defined by this asset.
  * @property {String} [version] - Unique version id of this asset.
  * @property {Number} [duration] - Duration of transition in msec for fading in and out.
  * @property {String} [interactionGroup] - Id of the interaction group to which this asset belongs.
  */

/**
  * Scene event type, see {@link module:ApiInterfaceV2~ApiSceneInterface#EVENTTYPE} for values
  * @typedef {String} module:ApiInterfaceV2~ApiSceneInterface#SceneEventType
  */

/**
  * Scene data item - generic data attached to the scene
  * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#ModelDataItem
  * @property {String} name - name of the data item
  * @property {String} id - unique id of the data item
  * @property {String} plugin - plugin runtime id this item belongs to
  * @property {*} data - the data
  */

/**
  * Selection type, see {@link module:ApiInterfaceV2~ApiSceneInterface#SELECTIONMODETYPE} for values
  * @typedef {String} module:ApiInterfaceV2~ApiSceneInterface#SelectionModeType
  */

/**
 * Interaction Effect
 *
 * Defines the effect used on the active and passive objects of an interaction group during an interaction event.
 *
 * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#InteractionEffect
 * @property {String} [name='colorHighlight'] - The name of a predefined effect type to use. Currently 'colorHighlight' and 'opacityHighlight' are supported.
 * @property {Object} [options] - Options for the effect
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [options.color='red'] Color for the 'colorHighlight' effect
 * @property {Number} [options.opacity=0.5] Opacity value between 0 and 1 for the 'opacityHighlight' effect
 */

/**
 * Interaction Group
 *
 * This type defines the interaction behaviour of a group of objects within the scene.
 *
 * @typedef {Object} module:ApiInterfaceV2~ApiSceneInterface#InteractionGroup
 * @property {String} id - Id of the interaction group
 * @property {Boolean} [hoverable=false] - Will the group objects react if the user hovers over them with the mouse?
 * @property {Object} [hoverEffect] - Effects used for hovering events
 * @property {module:ApiInterfaceV2~ApiSceneInterface#InteractionEffect} [hoverEffect.active] - Effect used for the hovering object
 * @property {module:ApiInterfaceV2~ApiSceneInterface#InteractionEffect} [hoverEffect.passive] - Effect used for the other (non hovering) objects in the group
 * @property {Boolean} [selectable=false] - Will the group objects be selectable?
 * @property {Object} [selectionEffect] - Effect used for selection events
 * @property {module:ApiInterfaceV2~ApiSceneInterface#InteractionEffect} [selectionEffect.active] - Effect used for selected objects
 * @property {module:ApiInterfaceV2~ApiSceneInterface#InteractionEffect} [selectionEffect.passive] - Effect used for non-selected objects
 * @property {module:ApiInterfaceV2~ApiSceneInterface#SelectionModeType} [selectionMode='single'] - Allows to specify whether single or multiple objects belonging to the group are selectable, see {@link module:ApiInterfaceV2~ApiSceneInterface#SELECTIONMODETYPE} for values
 * @property {Boolean} [draggable=false] - Will the group objects be draggable?
 * @property {Object} [dragEffect] - Effect used for dragging events
 * @property {module:ApiInterfaceV2~ApiSceneInterface#InteractionEffect} [dragEffect.active] - Effect used for dragged objects
 * @property {module:ApiInterfaceV2~ApiSceneInterface#InteractionEffect} [dragEffect.passive] - Effect used for not currently dragged objects
 */

/**
 * @typedef module:ApiInterfaceV2~ApiSceneInterface#2DCoordinateSummary
 * @property {Number} [containerX] - horizontal coordinate (according to the viewer container) of the 3D position
 * @property {Number} [containerY] - vertical coordinate (according to the viewer container) of the 3D position
 * @property {Number} [clientX] - horizontal coordinate (according to the current window, scrolling not considered) of the 3D position
 * @property {Number} [clientY] - vertical coordinate (according to the current window, scrolling not considered) of the 3D position
 * @property {Number} [pageX] - horizontal coordinate (according to the current window) of the 3D position
 * @property {Number} [pageY] - vertical coordinate (according to the current window) of the 3D position
 */

/**
 * ### ShapeDiver Viewer - Scene API
 *
 * The scene interface is part of the global {@link module:ApiInterfaceV2~ApiInterfaceV2#scene API v2 interface}.
 *
 *  * provides access to the {@link module:ApiInterfaceV2~ApiSceneInterface#camera camera} and {@link module:ApiInterfaceV2~ApiSceneInterface#lights lights} of the scene
 *  * allows to add, update, and remove assets in the scene
 *  * allows to define the interaction behavior of the geometry in the scene (hovering, selection, dragging)
 *  * allows to add and remove handlers for related events
 *
 * @interface ApiSceneInterface
 */
var ApiSceneInterface = function() {

  /**
   * Enum for supported scene asset item formats.
   * @readonly
   * @enum {module:ApiInterfaceV2~ApiSceneInterface#SceneAssetItemFormat}
   * @see {@link module:ApiInterfaceV2~ApiSceneInterface#SceneAssetItem}
   */
  this.FORMAT = {
    /** property href of the {@link module:ApiInterfaceV2~ApiSceneInterface#SceneAssetItem SceneAssetItem} points to a glb file ({@link https://github.com/KhronosGroup/glTF/tree/master/extensions/1.0/Khronos/KHR_binary_glTF binary glTF 1.0}) */
    GLB : 'glb',
    /** property data of the {@link module:ApiInterfaceV2~ApiSceneInterface#SceneAssetItem SceneAssetItem} contains an {@link module:ApiInterfaceV2~ApiSceneInterface#ObjMeshReference ObjMeshReference} which points to a {@link https://en.wikipedia.org/wiki/Wavefront_.obj_file Wavefront OBJ file} (supports meshes only, no lines, curves, or surfaces) */
    OBJ : 'obj',
    /** property data of the {@link module:ApiInterfaceV2~ApiSceneInterface#SceneAssetItem SceneAssetItem} contains a {@link module:ApiInterfaceV2~ApiSceneInterface#ThreeGeometryReference ThreeGeometryReference} which allows to directly add {@link https://threejs.org/docs/#api/objects/Mesh Mesh}, {@link https://threejs.org/docs/#api/objects/Line Line}, or {@link https://threejs.org/docs/#api/objects/Points Points} objects to the scene */
    THREE : 'three',
    /** property data of the {@link module:ApiInterfaceV2~ApiSceneInterface#SceneAssetItem SceneAssetItem} contains generic data (will not be displayed in the scene, but can be requested using {@link module:ApiInterfaceV2~ApiSceneInterface#getData}) */
    DATA : 'data',
    /** property data of the {@link module:ApiInterfaceV2~ApiSceneInterface#SceneAssetItem SceneAssetItem} contains a {@link module:ApiInterfaceV2~ApiSceneInterface#MaterialV3 material definition} */
    MATERIAL : 'material',
    /** property data of the {@link module:ApiInterfaceV2~ApiSceneInterface#SceneAssetItem SceneAssetItem} contains a {@link module:ApiInterfaceV2~ApiSceneInterface#Tag2d Tag2d} definition */
    TAG2D : 'tag2d',
    /** property data of the {@link module:ApiInterfaceV2~ApiSceneInterface#SceneAssetItem SceneAssetItem} contains a {@link module:ApiInterfaceV2~ApiSceneInterface#Tag3d Tag3d} definition */
    TAG3D: 'tag3d',
    /** property data of the {@link module:ApiInterfaceV2~ApiSceneInterface#SceneAssetItem SceneAssetItem} contains an {@link module:ApiInterfaceV2~ApiSceneInterface#Anchor anchor} definition */
    ANCHOR: 'anchor'
  };

  /**
   * Enum for supported scene event types.
   * @readonly
   * @enum {module:ApiInterfaceV2~ApiSceneInterface#SceneEventType}
   */
  this.EVENTTYPE = {
    /**  anchor objects have been added to the scene */
    ANCHOR_ADD : 'anchor.add',
    /**  anchor objects have been removed from the scene */
    ANCHOR_REMOVE : 'anchor.remove',
    /** dragging of an object started */
    DRAG_START : 'drag.start',
    /** continuously fired during dragging of an object */
    DRAG_MOVE : 'drag.move',
    /** dragging of an object ended */
    DRAG_END : 'drag.end',
    /** pointing device has entered a hoverable object  */
    HOVER_ON : 'hover.on',
    /** continuously fired during hovering of an object  */
    HOVER_OVER : 'hover.over',
    /** pointing device has left a hoverable object */
    HOVER_OFF : 'hover.off',
    /** beauty rendering has started */
    RENDER_BEAUTY_START : 'render.beauty.start',
    /** beauty rendering has been cancelled */
    RENDER_BEAUTY_CANCEL : 'render.beauty.cancel',
    /** beauty rendering has ended */
    RENDER_BEAUTY_END : 'render.beauty.end',
    /** object has been selected */
    SELECT_ON: 'select.on',
    /** object has been unselected */
    SELECT_OFF: 'select.off',
    /** a subscene has become visible in the viewer after an update or initialization */
    SUBSCENE_PUBLISHED: 'subscene.published',
    /** scene has become visible */
    VISIBILITY_ON: 'visibility.on',
    /** scene has become invisible */
    VISIBILITY_OFF: 'visibility.off',
  };

  /**
   * Enum for supported interaction modes. Allows to specify whether a complete asset or its individual subobjects should become selectable/hoverable/draggable.
   * @readonly
   * @enum {module:ApiInterfaceV2~ApiSceneInterface#InteractionModeType}
   */
  this.INTERACTIONMODETYPE = {
    /** object and subobjects are globally selectable/hoverable/draggable, i.e. all of them at the same time */
    GLOBAL : 'global',
    /** object and subobjects are individually selectable/hoverable/draggable */
    SUB : 'sub',
  };

  /**
   * Enum for supported selection modes. Allows to specify whether a single or multiple objects within an interaction group are selectable.
   * @readonly
   * @enum {module:ApiInterfaceV2~ApiSceneInterface#SelectionModeType}
   */
  this.SELECTIONMODETYPE = {
    /** A single object of the interaction group can be selected, all objects of the group are deselected on a click which does not hit an object of the group. */
    SINGLE : 'single',
    /** Selection status of objects belonging to the interaction group is toggled by clicking on them, selection status does not change on a click which does not hit an object of the group. */
    MULTIPLE : 'multiple',
  };

  /**
   * Add and update assets (typically geometry) in the scene.
   *
   * By default, the assets added through instances of this API live in their own
   * namespace within the scene (one namespace per API instance, defined by its {@link module:ApiInterfaceV2~ApiInterfaceV2#getRuntimeId runtime id}). To access and update the assets
   * provided by a plugin, please specify the plugin runtime id as namespace.
   *
   * @param {module:ApiInterfaceV2~ApiSceneInterface#SceneAsset[]} assets - The assets which should be added to or updated in the scene. Missing information will default to the corresponding data currently in the scene.
   * @param {String} [namespace] - By default assets belonging to the namespace of the instance of this API will be updated/added. If the asset to be updated/added is owned by a plugin, specify its runtime id here.
   * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type {@link module:ApiInterfaceV2~ApiSceneInterface#SceneAsset SceneAsset}. Contains a full description of the added/updated assets as shown in the scene after the update, persistent attributes applied.
   */
  this.updateAsync = function() {};

  /**
   * Retrieve assets from the scene
   *
   * Returns scene assets corresponding to given filter criteria.
   * The returned assets describe their original state, without persistent attributes applied.
   *
   * @param {module:ApiInterfaceV2~ApiSceneInterface#SceneAssetFilter} [filter] - Optional partial asset description whose properties are used as filter criteria. Most commonly, the id or name will be defined.
   * @param {String} [namespace] - By default the assets belonging to the namespace of the instance of this API will be returned. If you want to request assets owned by a plugin, specify its runtime id here.
   * @param {Boolean} [blnScene=false] - If true use assets with persistent attributes applied, if false (default) use the original assets without persistent attributes applied.
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API response with a data array of type {@link module:ApiInterfaceV2~ApiSceneInterface#SceneAsset SceneAsset}. Contains a full description of the assets fulfilling the filter criteria. Returned data array may be empty.
   */
  this.get = function() {};

  /**
   * Retrieves generic data assets from the scene, optionally limited according to certain filters
   *
   * @param {module:ApiInterfaceV2~ApiSceneInterface#SceneAssetFilter} [filter] - Optional partial asset description whose properties are used as filter criteria. Properties 'name' and 'id' will be used only, other properties will be ignored.
   * @param {String} [namespace] - By default all data assets in the scene will be returned. If you want to limit the request to data assets of a specific owner (e.g. this API instance), specify the owner's runtime id here.
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API response with a data array of type {@link module:ApiInterfaceV2~ApiSceneInterface#ModelDataItem ModelDataItem}. Contains all data assets fulfilling the filter criteria.
   */
  this.getData = function() {};

  /**
   * Converts the given 3D coordinates into various 2D coordinates
   * @see {@link module:ApiInterfaceV2~ApiSceneInterface#2DCoordinateSummary}
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} position - The 3D point to convert
   * @returns {module:ApiInterfaceV2~ApiSceneInterface#2DCoordinateSummary} A summary of the different 2D coordinates, array of summaries in case of multiple viewports, null on error
   */
  this.convertTo2D = function() {};

  /**
   * Get screenshot as a png data URI.
   *
   * Returns a screenshot of the viewer canvas without any control elements as a data URI using the png image format.
   * @return {String|String[]} Data URI representing the png screeshot image (data:image/png;base64,...). Array of data URIs in case of multiple viewports.
   */
  this.getScreenshot = function() {};

  /**
   * Update selection status of objects.
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} [select] - Optional list of scene paths of objects which should be selected (please note that there might be further selected objects after this update, which had already been selected)
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} [deselect] - Optional list of scene paths of objects which should be deselected
   * @return {Boolean} true if selection status could be set for all specified scene paths, false if at least one error occured.
   */
  this.updateSelected = function() {};

  /**
   * Get scene paths of selected objects.
   *
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} Array of scene paths of selected objects.
   */
  this.getSelected = function() {};

  /**
   * Remove assets from the scene
   *
   * Remove scene assets corresponding to the given filter criteria.
   *
   * @param {module:ApiInterfaceV2~ApiSceneInterface#SceneAssetFilter} [filter] - Optional partial asset description whose properties are used as filter criteria. Most commonly, the id or name will be defined.
   * @param {String} [namespace] - By default the assets belonging to the namespace of the instance of this API will be removed. If you want to remove assets owned by a plugin, specify its runtime id here.
   * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type {@link module:ApiInterfaceV2~ApiSceneInterface#SceneAsset SceneAsset}. Contains a full description of the removed assets before removal, persistent attributes applied.
   */
  this.removeAsync = function() {};

  /**
   * Renders the scene
   *
   * Except in rare cases (e.g. after updating canvas textures) there is no need to call this, as rendering will be called automatically.
   */
  this.render = function() {};

  /**
   * Define persistent attributes for scene assets which will survive and override regular updates.
   *
   * By default, the assets added through instances of this API live in their own
   * namespace within the scene (one namespace per API instance, defined by its {@link module:ApiInterfaceV2~ApiInterfaceV2#getRuntimeId runtime id}).
   * To access and update the assets provided by a plugin, please specify the plugin runtime id.
   *
   * @param {module:ApiInterfaceV2~SceneAsset[]} assets - Any information in these asset definitions will supersede information included in updates by the respective plugin. To remove a persistent attribute, set its value to null. The 'id' attribute needs to be set in any case.
   * @param {String} [namespace] - By default persistent attributes of assets belonging to the namespace of the instance of this API will be updated/added. If the asset to be updated/added is owned by a plugin, specify its runtime id here.
   * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type {@link module:ApiInterfaceV2~ApiSceneInterface#SceneAsset SceneAsset}. Contains a full description of the currently configured persistent attributes of the updated assets.
   */
  this.updatePersistentAsync = function() {};

  /**
   * Retrieve the persistent attributes of assets
   *
   * @param {module:ApiInterfaceV2~ApiSceneInterface#SceneAssetFilter} [filter] - Optional partial asset description whose properties are used as filter criteria. Most commonly, the id or name will be defined. Be aware that this filter is applied to the assets without persistent attributes applied.
   * @param {String} [namespace] - By default the persistent attributes of assets belonging to the namespace of the instance of this API will be returned. If you want to request persistent attributes of assets owned by a plugin, specify its runtime id here.
   * @return {module:ApiInterfaceV2~SceneAsset[]} API response with a data array of type {@link module:ApiInterfaceV2~ApiSceneInterface#SceneAsset SceneAsset}. Contains persistent attributes of assets fulfilling the given filter criteria.
   */
  this.getPersistent = function() {};

  /**
   * Add or change an interaction group definition
   *
   * Interaction groups define the behaviour of their members for hovering, selection and dragging.
   *
   * @param {module:ApiInterfaceV2~ApiSceneInterface#InteractionGroup|module:ApiInterfaceV2~ApiSceneInterface#InteractionGroup[]} groups
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean indicating whether the interaction groups could be updated.
   */
  this.updateInteractionGroups = function() {};

  /**
   * Returns all currently defined interaction groups
   *
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a list of {@link module:ApiInterfaceV2~ApiSceneInterface#InteractionGroup[] interaction groups}
   */
  this.getInteractionGroups = function() {};

  /**
   * Add a global event listener to the scene
   *
   * @param {module:ApiInterfaceV2~ApiSceneInterface#SceneEventType} type - Type of event type to subscribe to. A {@link module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType scene path} may be dot-appended to limit the subscription to parts of the scene.
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerCallback} callback - Function to be called when the event fires
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an {@link module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken EventListenerToken} object. The event listener token can be used to remove the event listener by calling {@link module:ApiInterfaceV2~ApiSceneInterface#removeEventListener removeEventListener}.
   */
  this.addEventListener = function () {};

  /**
   * Remove an event listener
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken} token - event listener token for event listener to be removed
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean data, indicating whether the event listener could be removed.
   */
  this.removeEventListener = function() {};

};


////////////
////////////
//
// Global API
//
////////////
////////////

/**
  * Viewer general event type, see {@link module:ApiInterfaceV2~ApiInterfaceV2#EVENTTYPE} for values
  * @typedef {String} module:ApiInterfaceV2~ApiInterfaceV2#ApiEventType
  */

/**
  * ### ShapeDiver - Viewer API
  *
  * Definition of the API v2, the default API to use when working with the ShapeDiver 3D Viewer v2.
  * This interface describes the object returned when requesting an API v2 object from an instance of the ShapeDiver 3D Viewer v2, e.g. like so:
  *
  * ```
  * // container for the viewport, typically this is a <div>
  * var _container = document.getElementById('sdv-container-viewport');
  *
  * // viewer settings (minimum example)
  * var _viewerSettings = {
  *   // container to use
  *   container: _container
  * };
  *
  * // create the viewer, get back an API v2 object
  * var api = new SDVApp.ParametricViewer(_viewerSettings);
  *
  * // further API v2 objects can be created if needed, each one having its own runtime id:
  * var api_other = api.getApiV2();
  * console.log('Runtime id of default API object', api.getRuntimeId());
  * console.log('Runtime id of second API object', api_other.getRuntimeId());
  * ```
  *
  * The API v2 provides access to the
  *  * {@link module:ApiInterfaceV2~ApiInterfaceV2#scene 3D scene} including geometry, camera, lights, and interactions,
  *  * {@link module:ApiInterfaceV2~ApiInterfaceV2#plugins plugins}, e.g. for adding further instances of the ShapeDiver Communication Plugin,
  *  * {@link module:ApiInterfaceV2~ApiInterfaceV2#viewports viewports}, e.g. for adding and removing viewports,
  *  * {@link module:ApiInterfaceV2~ApiInterfaceV2#parameters parameters} which may have been registered by plugins,
  *  * {@link module:ApiInterfaceV2~ApiInterfaceV2#parameters exports} which may have been registered by plugins,
  *  * current {@link module:ApiInterfaceV2~ApiInterfaceV2#state state} of the viewer, and
  *  * {@link module:ApiInterfaceV2~ApiInterfaceV2#getSettings settings}
  *
  * @interface ApiInterfaceV2
  */
var ApiInterfaceV2 = function() {

  /**
   * Enum for supported event types.
   * @readonly
   * @enum {module:ApiInterfaceV2~ApiInterfaceV2#ApiEventType}
   */
  this.EVENTTYPE = {
    /** the value of a setting has been changed */
    SETTINGS_UPDATE : 'settings.update',
    /** a settings registration request (typically triggered by initialisation of a CommPlugin) has finished processing and settings have been updated */
    SETTINGS_REGISTERED : 'settings.registered',
  };

  /**
   * State interface - use this to observe and manage the general state of the viewer
   * @type {module:ApiInterfaceV2~ApiStateInterface}
   */
  this.state = new ApiStateInterface();

  /**
   * Parameter interface - use this to find out which parameters have been registered by plugins, and to update their values, which in turn updates the scene
   * @type {module:ApiInterfaceV2~ApiParameterInterface}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#plugins}
   */
  this.parameters = new ApiParameterInterface();

  /**
   * Export interface - use this to find out which exports have been registered by plugins, and to request exports
   * @type {module:ApiInterfaceV2~ApiExportInterface}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#plugins}
   */
  this.exports = new ApiExportInterface();

  /**
   * Plugin interface - use this to register and deregister instances of plugins, ask for their status, etc
   * @type {module:ApiInterfaceV2~ApiPluginInterface}
   */
  this.plugins = new ApiPluginInterface();

  /**
   * Viewports interface - use to manage the viewports
   * @type {module:ApiInterfaceV2~ApiViewportsInterface}
   */
  this.viewports = new ApiViewportsInterface();

  /**
   * Scene interface - use this to manage the scene (geometry, nteractions)
   * @type {module:ApiInterfaceV2~ApiSceneInterface}
   */
  this.scene = new ApiSceneInterface();

  /**
   * Add a callback to be invoked when a certain event occurs
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ApiEventType} type - Type of event to subscribe to
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerCallback} callback - Function to be called when the event fires
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an {@link module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken EventListenerToken} object. The event listener token can be used to remove the event listener by calling {@link module:ApiInterfaceV2~ApiInterfaceV2#removeEventListener removeEventListener}.
   */
  this.addEventListener = function() {};

  /**
   * Remove an event listener
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken} token - event listener token for event listener to be removed
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean data, indicating whether the event listener could be removed.
   */
  this.removeEventListener = function() {};

  /**
   * Provides an object describing all available settings
   *
   * @return {Object} object describing all available settings, their type, and constraints
   */
  this.getSettingDefinitions = function() {};

  /**
   * Provides a copy of all or multiple settings
   *
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#getSetting}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#updateSettingAsync}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#updateSettingsAsync}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#getSettingDefinitions}
   * @param {String[]} [keys] - optional array of keys, keys which don't exist are ignored, if omitted all settings will be returned
   * @return {Object} object containing a copy of the settings
   */
  this.getSettings = function() {};

  /**
   * Provides a copy of an individual setting
   *
   * @param {String} key - name of setting (e.g. 'scene.render.shadows')
   * @return {*} a copy of the setting, undefined if not found
   */
  this.getSetting = function() {};

  /**
   * Update an individual setting, if allowed
   *
   * | Name of setting        | Type           | Description  |
   * | :-------------: |:-------------:| -----|
   * | blurSceneWhenBusy | Boolean | Blur or don't blur the scene while a process is busy |
   * | defaultMaterial.color | {@link module:ApiInterfaceV2~ApiInterfaceV2#Color Color} | Color of the default material |
   * | defaultMaterial.bumpAmplitude | Number | Bump amplitude of the default material |
   * | defaultMaterial.metalness | Number | Metalness of the default material |
   * | defaultMaterial.roughness | Number | Roughness of the default material |
   * | loggingLevel | Number | Level of log messages shown on the console, allowed values: -1 (none), 0 (error), 1 (warn), 2 (info), 3 (debug) |
   * | messageLoggingLevel | Number | Log level to be used for logging internal messages, allowed values: -1 (none), 0 (error), 1 (warn), 2 (info), 3 (debug) |
   * | scene.show | Boolean | Show / hide the scene |
   * | showMessages | Boolean | Show or don't show user messages in the viewport(s) |
   *
   * @param {String} key - name of setting
   * @param {Object} val - new value of setting
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse whose data property will be true if setting could be changed, false if not.
   */
  this.updateSettingAsync = function() {};

  /**
    * Update a bunch of settings according to the enumerable properties of an object, if allowed.
    * @param {Object} settings - object whose own properties should be used as settings
    * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse whose data property will be set to a copy of the input object, whose property values are replaced by true or false depending on whether the corresponding setting could be updated.
    */
  this.updateSettingsAsync = function() {};

  /**
    * Persistently store current state of settings.
    * This will fail if there is no plugin providing the capability to store model settings.
    * Instances of the CommPlugin provide this capability in case the model view session allows this.
    * @property {String} [plugin] - Optional runtime id of plugin to use for storing settings. Leave undefined to automatically choose a plugin which supports storing settings.
    * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse whose data property will be true or false depending on success.
    */
  this.saveSettingsAsync = function() {};

  /**
   * Get the runtime id used for this API object.
   * The runtime id is used by default as the namespace (= the first part of the scene path) when manipulating the scene (adding and removing assets, etc).
   *
   * The runtime id can optionally be specified as a setting when creating the API object, otherwise a random one will be created.
   * ```
   * // container for the viewer, typically this is a <div>
   * var _container = document.getElementById('sdv-container');
   *
   * // viewer settings example, using a given API runtime id
   * var _viewerSettings = {
   *   // container to use
   *   container: _container,
   *   // we want to get back an API v2 object, and use a specific runtime id for the API object
   *   api: {version: 2, runtimeId: 'My_runtime_id'},
   * };
   *
   * // create the viewer, get back an API v2 object
   * var api = new SDVApp.ParametricViewer(_viewerSettings);
   * console.log(api.getRuntimeId()); // shows 'My_runtime_id'
   *
   * // further API v2 objects can be created if needed:
   * var api_other = api.getApiV2({runtimeId: 'My_other_runtime_id'});
   * console.log(api_other.getRuntimeId()); // shows 'My_other_runtime_id'
   * ```
   *
   * @return {String} runtime id used for API instance, should be unique
   */
  this.getRuntimeId = function() {};

};

this.utils = {};

this.utils.APIResponse = {};

module.exports = ApiInterfaceV2;
